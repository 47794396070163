import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD7ZaM7-weZiC9zhuv0LA_BLtbKMq6vcLs',
  authDomain: 'aisw-ww-prod.firebaseapp.com',
  databaseURL: 'https://aisw-ww-prod-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'aisw-ww-prod',
  storageBucket: 'aisw-ww-prod.appspot.com',
  messagingSenderId: '287116487249',
  appId: '1:287116487249:web:d77aeb375c6508fe75e670'
}
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
} else {
  firebase.app()
}
// firebase.initializeApp(firebaseConfig)

export default firebase

export async function getUserHeaders (headers = {}) {
  try {
    headers.Authorization = await getAuthorization()
  } catch (err) {
    console.warn('Could not get Authorization:', err)
  }
  return headers
}

export const getAuthorization = async () => {
  let i = 0
  while(!firebase.auth().currentUser) {
    await Wait(200)
    i++
    if (i > 20) {
      throw new Error('Could not get Authorization after 4 Seconds')
    }
  }
  const token = await firebase.auth().currentUser?.getIdToken()
  if (token) {
    return 'Bearer ' + token
  }
  return null
}

function Wait (time) {
  return new Promise((resolve) => {
    return setTimeout(resolve, time)
  })
}
