import React, { Component } from 'react'
import * as EBML from 'ts-ebml'
import REC from 'recordrtc'
import './recorder.scss'

window.EBML = window.EBML || EBML

export class Recorder extends Component {
  static propTypes = {

  }

  static defaultProps = {

  }

  constructor (props) {
    super(props)
    this.state = {
      recording: false,
      recordAudio: false,
      userConsent: false,
      micStream: null,
      permissionDenied: false
    }
    this.recorder = null
    this.AC = new window.AudioContext()
    this.streams = []
    this.WebRTCSupport = (typeof navigator.mediaDevices !== 'undefined' && navigator.mediaDevices.getUserMedia)
  }

  questionPageLeave = () => {
    // Custom Message is actualy not displayed on Dialog, Modern Browsers do not like custom Messages
    // Yet returning a String seems required to display the Dialog. So we keep the message just in case.
    return 'Du hast deine laufende Bildschirmaufnahme noch nicht Beendet. Bist du sicher, dass du die Seite verlassen willst?'
  }

  startCapture = () => {
    if (this.state.recording) {
      return
    }
    window.onbeforeunload = this.questionPageLeave
    this.setState({ recording: true })
    const RecStream = new window.MediaStream()
    const video = this.props.video
    const vidStream = video.captureStream ? video.captureStream() : video.mozCaptureStream()
    this.streams.push(vidStream)

    if (this.state.recordAudio) {

      const merger = this.AC.createChannelMerger(1)
      const mic = this.AC.createMediaStreamSource(this.state.micStream)
      const vid = this.AC.createMediaStreamSource(vidStream)
      mic.connect(merger, 0, 0)
      vid.connect(merger, 0, 0)
      const dest = this.AC.createMediaStreamDestination()
      merger.connect(dest)
      dest.stream.getAudioTracks().forEach((track) => {
        RecStream.addTrack(track)
      })
    } else {
      vidStream.getAudioTracks().forEach((track) => {
        RecStream.addTrack(track)
      })
    }
    const canvas = this.props.videoParent.canvas
    const canvasStream = canvas.captureStream ? canvas.captureStream() : canvas.mozCaptureStream()
    this.streams.push(canvasStream)
    canvasStream.getVideoTracks().forEach((track) => {
      RecStream.addTrack(track)
    })
    this.recorder = REC(RecStream, { type: video, checkForInactiveTracks: true })
    this.recorder.startRecording()
  }

  stopCapture = () => {
    if (this.state.recording) {
      window.onbeforeunload = null
      this.recorder.stopRecording(() => {
        REC.getSeekableBlob(this.recorder.getBlob(), (seekable) => {
          REC.invokeSaveAsDialog(seekable)
        })
        this.streams.forEach((stream) => {
          stream.stop()
        })
        this.streams = []
        this.setState({ recording: false })
      })
    }
  }

  toggleRecordAudio = async () => {
    if (!this.state.userConsent) {
      let micStream = null
      try {
        micStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      } catch (err) {
        if (err.message.includes('Permission denied')) {
          console.warn('No Permission')
          this.setState({ permissionDenied: true })
        }
        console.error(err.message)
        return
      }
      this.setState({ userConsent: true, micStream, recordAudio: true })
      return
    }
    const recordAudio = !this.state.recordAudio
    this.setState({ recordAudio })
  }

  render () {
    return (<div className='ScreenRecorder'>
      {!this.state.recording && <button className='btn info recorderStart' onClick={this.startCapture} >Bildschirmaufnahme starten</button>}
      {this.state.recording && <button className='btn warning recorderStart' onClick={this.stopCapture} >Bildschirmaufnahme beenden</button>}
      {this.WebRTCSupport && <div className='RecorderOptions'>
        <input
          disabled={this.state.permissionDenied}
          type='checkbox'
          checked={this.state.recordAudio}
          onChange={this.toggleRecordAudio}
          name='recordMic'
        />
        <label style={this.state.permissionDenied ? { textDecoration: 'line-through', color: 'grey' } : {}} htmlFor='recordMic'>Microphone Aufnehmen</label>
      </div> }
    </div>
    )
  }
}

export default Recorder
