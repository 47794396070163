import React from 'react'
import VortexSpinner from '../spinner/vortex'
import SequenceItem from './sequenceItem'
import { sortableContainer } from 'react-sortable-hoc'
import '../item.scss'

const SortableContainer = sortableContainer(({ children, additionalClass }) => {
  return <div className={additionalClass}>{children}</div>
})

export default function SequenceList (props) {
  if (props.sequences === null || props.sequences === undefined) {
    return (<div className='mbsc-row'>
      <VortexSpinner />
    </div>
    )
  }
  const { sequences,
    clipActions,
    editSequence,
    deleteSequence,
    updateSequence,
    createSequence,
    onAnnotationClick,
    onSequenceSort,
    updateAnnotation,
    clipSequence,
    showLabel,
    useColor,
    showAnnotations,
    useAnnotationColor,
    sortable,
    seek,
    seekAnnotation,
    afterSeek,
    disableAutoSort,
    className,
    showClubIcons,
    showClubIconsInBaseLabel,
    disableButtonLabel,
    disableLabelShowMore,
    disableTagCreate,
    filterAnnotations
  } = props
  // const needsConfirm = props.needsConfirm !== undefined ? props.needsConfirm : true
  /**
   * @type {any[]}
   */
  const _sequences = disableAutoSort ? sequences : sequences.sort((a, b) => a.timestamp - b.timestamp)
  const sequenceElements = _sequences
    // remove duplicates
    .filter((value, index) => _sequences.indexOf(value) === index)
    .map((item, index) => {
      let isInClip = {}
      const matchData = props.matches ? props.matches.find((m) => m.RowKey === item.matchId) : undefined
      const onClick = props.onSequenceClick ? props.onSequenceClick(item) : () => {}
      const onMinuteClick = props.onMinuteClick ? props.onMinuteClick(item) : () => {}
      if (clipSequence) {
        const property = item.RowKey ? 'RowKey' : 'originId'
        const clipIndex = clipSequence.findIndex((c) => c[property] === item[property])
        isInClip = clipIndex >= 0 ? { clipIndex } : {}
      }
      const key = item.RowKey ? item.RowKey : item.originId
      return <SequenceItem
        key={key}
        onClick={onClick || seek}
        onMinuteClick={onMinuteClick || seek}
        afterSeek={afterSeek}
        sequence={item}
        history={props.history}
        sortable={sortable}
        clipActions={clipActions}
        editSequence={editSequence}
        deleteSequence={deleteSequence}
        updateSequence={updateSequence}
        needsConfirm={props.needsConfirm}
        onAnnotationClick={onAnnotationClick || seekAnnotation}
        showAnnotations={showAnnotations}
        updateAnnotation={updateAnnotation}
        createSequence={createSequence}
        showLabel={showLabel}
        useColor={useColor}
        matchData={matchData}
        disableRename={props.disableRename}
        useAnnotationColor={useAnnotationColor}
        showMatchLink={props.showMatchLink}
        useRelativeTimestamp={props.useRelativeTimestamp}
        useRelativeTimestampForAnnotations={props.useRelativeTimestampForAnnotations}
        deleteAnnotation={props.deleteAnnotation}
        showFullAnnotationTime={props.showFullAnnotationTime}
        knownSequenceLabel={props.knownSequenceLabel}
        showClubIcons={showClubIcons}
        showClubIconsInBaseLabel={showClubIconsInBaseLabel}
        disableButtonLabel={disableButtonLabel}
        disableLabelShowMore={disableLabelShowMore}
        disableTagCreate={disableTagCreate}
        filterAnnotations={filterAnnotations}
        index={index}
        {...isInClip}
      />
    })
  const additionalClass = className || ''
  if (sortable) {
    return <SortableContainer useDragHandle helperClass={'DraggableGhostHelper'} onSortEnd={onSequenceSort}>{sequenceElements}</SortableContainer>
  }
  if (props.mockClipCardContainer) {
    return <div className='ClipCard col-xs'><div className='ClipCardContent'> <div className='clipsDetailsInnerContent'> <div className={additionalClass}>{sequenceElements}</div> </div> </div></div>
  }
  return <div className={additionalClass}>{sequenceElements}</div>
}
