import React from 'react'
import './unsuportedBrowser.scss'

export default function UnsuportedBrowser (props) {
  return (
    <div className='login unsuportedBrowser col-xs-12'>
      <div className='row'>
        <div className='col-xs-12 center swLogo'>
          <img className='SWLogo' alt='SoccerwatchLogo' src='https://storage.googleapis.com/sw-sc-de-assets/assets/Logos/SINGLE/WHITE_1L.png' />
        </div>
        <div className='col-xs-12 center headText'>
          <h1>Willkommen beim soccerwatch.tv Coachingtool,</h1>
        </div>
        <div className='col-xs-12 center'>
          <h4>Dein Browser - Internet Explorer - verfügt nicht über die notwendigen Funktionalitäten, um das soccerwatch.tv Coachingtool zu nutzen. Bitte verwende einen anderen Browser, um mit dem soccerwatch.tv Coachingtool deine Spiele zu analysieren.
          </h4>
        </div>
      </div>
      <div className='row downloadButtonRow'>
        <div className='col-xs-6 center'>
          <div className='row'>
            <div className='col-xs-12'>
              <a href='https://www.mozilla.org/de/firefox/new/'><img className='browserLogo' src='/firefox.svg' alt='firefox.logo' /></a>
            </div>
            <div className='col-xs-12'>
              <button className='btn'>Jetzt Firefox Herunterladen</button>
            </div>
            <div className='col-xs-12'>
              <p className='grant firefoxGrant'>Von Mozilla Corporation - https://phabricator.services.mozilla.com/D41016, MPL 2, https://commons.wikimedia.org/w/index.php?curid=81490422</p>
            </div>
          </div>
        </div>
        <div className='col-xs-6 center'>
          <div className='row'>
            <div className='col-xs-12'>
              <a href='https://www.google.de/chrome/'><img className='browserLogo' src='/chrome.svg' alt='chrome.logo' /></a>
            </div>
            <div className='col-xs-12'>
              <button className='btn'>Jetzt Chrome Herunterladen</button>
            </div>
            <div className='col-xs-12'>
              <p className='grant chromeGrant'>Von Google - Google Play Store, Gemeinfrei, https://commons.wikimedia.org/w/index.php?curid=46544760</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
