import React, { Component } from 'react'
import i18n from 'i18next'
import mobiscroll from '@mobiscroll/react'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import TokenSite from './token'
import { Link } from 'react-router-dom'
import VortexSpinner from '../../utility/spinner/vortex'
const { Popup } = mobiscroll

function getLogoSrc (id) {
  if (!id || id === undefined || id === '') {
    return 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
  }
  return `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${id}.png`
}

function TrainerSubscription ({ sub }) {
  const clubName = sub.data.clubName || `ClubId: ${sub.data.club} ${i18n.t('user.clubNameLoading')}`
  const [logoSrcId, setlogoSrcId] = React.useState(sub.data.club)
  return <Link key={sub.RowKey} to={`/user/subscription/${sub.RowKey}/`} className='Subscription col-xs own' >
    <img src={getLogoSrc(logoSrcId)} onError={() => { setlogoSrcId(false) }} className='image clubLogo' alt='ClubLogo' />
    <div className='clubName'>
      {clubName}
    </div>
    <div className='addBtn'>
      { sub.activeUntil ? `${i18n.t('user.activeUntil')} ${sub.activeDate}`
        : `${i18n.t('user.validUntiL')} ${sub.validDate}`
      }
    </div>
    <div className='addBtn'>
      <button className='btn btn-fluid success'>{i18n.t('user.manageAccess')}</button>
    </div>
  </Link>
}
function SubTrainerSubscription ({ sub }) {
  const clubName = sub.data.clubName || `ClubId: ${sub.data.club} ${i18n.t('user.clubNameLoading')}`
  const [logoSrcId, setlogoSrcId] = React.useState(sub.data.club)
  return <div key={sub.RowKey} className='Subscription col-xs guest' >
    <img src={getLogoSrc(logoSrcId)} onError={() => { setlogoSrcId(false) }} className='image clubLogo' alt='ClubLogo' />
    <div className='clubName'>
      {clubName}
    </div>
    {/* <div className='addBtn'>
      {i18n.t('user.validUntiL')} {sub.validDate}
    </div> */}
  </div>
}

function AddSubscriptionButton () {
  return <Link to={`/user/newSubscription/`} className='Subscription col-xs own' >
    <span className='clubLogo mbsc-ic mbsc-ic-fa-plus-square' />
    {/* <img src={getLogoSrc(logoSrcId)} onError={() => { setlogoSrcId(false) }} className='image clubLogo' alt='ClubLogo' /> */}
    <div className='clubName'>
      {i18n.t('user.newSub')}
    </div>
    <div className='addBtn'>
      {/* Gültig bis {sub.validDate} */}
    </div>
  </Link>
}

function SubscriptionList ({ subscriptions }) {
  if (!subscriptions) {
    return <span>{i18n.t('user.invalidSubscriptions')}</span>
  }
  return subscriptions.map((sub) => {
    return sub.type === 'TrainerSubscription' ? <TrainerSubscription key={sub.RowKey} sub={sub} /> : <SubTrainerSubscription key={sub.RowKey} sub={sub} />
  })
}

function UserSubscriptionContainer ({ children, subscriptions, userName, isSub, noSubscriptions }) {
  return (
    <>
      <div className='siteHeader'>
        <h1>{ isSub ? i18n.t('user.guestSubs') : i18n.t('user.subscription')}</h1>
        <h4>{ isSub ? i18n.t('user.guestSubFrom', { userName }) : `${i18n.t('user.from')} ${userName}`}</h4>
        { noSubscriptions &&
        <div>
          <h5>{i18n.t('user.invalidSubscriptions')}</h5>
          <div onClick={logout} className='logout btn info'><small>{i18n.t('dashboard.logout')} <span className='mbsc-ic mbsc-ic-enter' /></small></div>
        </div>
        }
      </div>
      <div className='row'>
        <SubscriptionList subscriptions={subscriptions} />
        {!!children && children}
        <div className='Subscription col-xs filler' />
        <div className='Subscription col-xs filler' />
      </div>
    </>)
}

const logout = () => {
  if (window.GlobalCoachingTool) {
    window.GlobalCoachingTool.logout()
  }
}

class UserArea extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount () {
    this.props.updateUserData && this.props.updateUserData()
    !this.props.clubsLoaded && this.props.getClubs && this.props.getClubs()
  }

  render () {
    const user = this.props.user
    if(!user) {
      return (
        <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content history={this.props.history} className='userSite'>
          <VortexSpinner />
        </Content>
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
      )
    }
    const name = user.name || user.UserName
    const userName = name.charAt(0).toUpperCase() + name.slice(1)
    if (!user.subTrainerSubscriptions) {
      user.subTrainerSubscriptions = {}
    }
    const subTrainerSubs = Object.keys(user.subTrainerSubscriptions).map((userSub) => {
      const subs = user.subTrainerSubscriptions[userSub]
      const userName = subs[0].data.mainTrainer.name || userSub
      return <UserSubscriptionContainer key={userName} userName={userName} subscriptions={subs} isSub />
    })
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content history={this.props.history} className='userSite'>
          <div className='col-xs-12'>
            <UserSubscriptionContainer userName={userName} subscriptions={user.trainerSubscriptions} noSubscriptions={this.props.noSubscriptions}>
              <AddSubscriptionButton />
            </UserSubscriptionContainer>
            {subTrainerSubs}
          </div>
        </Content>
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
    )
  }
}

export default UserArea
