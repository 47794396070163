import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { PointSelector } from './PointSelector'
import { CalibrationControls } from './CalibrationControls'
import {
  getMetersPerPixel,
  persistFieldCalibrationLine,
  usePersistedDistance,
  usePersistedName,
  usePersistedPoints,
} from './PersistCalibration'
import { useLineVisualizer } from './LineVisualizer'

export const LineEditor = (props) => {
  const { lineIndex, fieldID, getAuthorization } = props
  const [pointDrafts, setPointDrafts] = usePersistedPoints(fieldID, getAuthorization, lineIndex)
  const [savedPoints, setSavedPoints] = usePersistedPoints(fieldID, getAuthorization, lineIndex)
  const [lineName, setLineName] = usePersistedName(fieldID, getAuthorization, lineIndex)
  const [distance, setDistance] = usePersistedDistance(fieldID, getAuthorization, lineIndex)
  const [isNewLine, setIsNewLine] = useState(props.isNewLine)
  const [editingPointA, setEditingPointA] = useState(isNewLine)
  const [editingPointB, setEditingPointB] = useState(false)
  const indexOfCurrentlyEditedPoint = editingPointA ? 0 : (editingPointB ? 1 : -1)

  // Draw line on field
  useLineVisualizer(props.paper, lineName, pointDrafts)
  
  /**
   * @param {number} pointIndex
   */
   const savePoint = useCallback((currentPointDrafts = pointDrafts) => {
    const savedPointsCopy = [...savedPoints]

    savedPointsCopy[indexOfCurrentlyEditedPoint] = currentPointDrafts[indexOfCurrentlyEditedPoint]
    setSavedPoints(savedPointsCopy)

    // In new line mode, first the user edits point A and then point B. So, when point B is saved,
    // new line mode can be quit
    if (isNewLine && editingPointB) {
      setIsNewLine(false)
      setEditingPointB(false)
    }

    if (isNewLine && editingPointA) {
      setEditingPointA(false)
      setEditingPointB(true)
    } else if (window.GlobalCoachingTool && window.GlobalCoachingTool.setSidebarOpen) {
      window.GlobalCoachingTool.setSidebarOpen(true)
    }
  }, [editingPointA, editingPointB, indexOfCurrentlyEditedPoint, isNewLine, pointDrafts, savedPoints, setSavedPoints])

  /**
   * @param {number} pointIndex
   */
  const abortPointEdit = () => {
    // Reset current draft to old saved version
    if (pointDrafts[indexOfCurrentlyEditedPoint] !== undefined) {
      pointDrafts[indexOfCurrentlyEditedPoint] = savedPoints[indexOfCurrentlyEditedPoint]
    }
  }

  const onSave = () => {
    const line = {
      pointA: savedPoints[0],
      pointB: savedPoints[1],
      distance: distance,
      name: lineName,
    }

    persistFieldCalibrationLine(fieldID, getAuthorization, lineIndex, line, true)
      .catch(console.error)
      .then(() => {
        props.onDone(getMetersPerPixel(line))
      })
  }

  const onDistanceChange = useCallback((evt) => {
    const inputValueAsNumber = parseFloat(evt.target.value)
    setDistance(
      isNaN(inputValueAsNumber) ? '' : inputValueAsNumber,
    )
  }, [setDistance])

  const onLineNameChange = useCallback(evt => setLineName(evt.target.value), [setLineName])

  const onPointDraftChange = useCallback((newPoint, eventType) => {
    
    if (pointDrafts.length > indexOfCurrentlyEditedPoint && indexOfCurrentlyEditedPoint >= 0) {
      const pointDraftsCopy = [
        ...pointDrafts,
      ]
      pointDraftsCopy[indexOfCurrentlyEditedPoint] = newPoint

      setPointDrafts(pointDraftsCopy)

      if (isNewLine && eventType === 'mouseUp') {
        savePoint(pointDraftsCopy)
      }
      
    }
  }, [indexOfCurrentlyEditedPoint, isNewLine, pointDrafts, savePoint, setPointDrafts])


  return <>
     {
       indexOfCurrentlyEditedPoint >= 0 ?
         <PointSelector
           {...props}
           onChange={onPointDraftChange}
           startingPoint={pointDrafts[indexOfCurrentlyEditedPoint]}
           pointIdentifier={indexOfCurrentlyEditedPoint}
         /> :
         null
     }
     <CalibrationControls
       canSave={savedPoints.reduce((allExist, currentPoint) => allExist && Boolean(currentPoint), true)}
       onSave={onSave}
       onAbort={props.onAbort}
       onSavePoint={savePoint}
       onAbortPointEdit={abortPointEdit}
       savedPoints={savedPoints}
       ContextButtonsContainer={props.ContextButtonsContainer}
       distance={distance}
       onDistanceChange={onDistanceChange}
       lineName={lineName}
       onLineNameChange={onLineNameChange}
       indexOfCurrentlyEditedPoint={indexOfCurrentlyEditedPoint}
       editingPointA={editingPointA}
       editingPointB={editingPointB}
       setEditingPointA={setEditingPointA}
       setEditingPointB={setEditingPointB}
     />
  </>
}

LineEditor.propTypes = {
  videoParent: PropTypes.any,
  videoState: PropTypes.any,
  video: PropTypes.any,
  paper: PropTypes.any,
  drawingLayer: PropTypes.any,
  onAbort: PropTypes.func,
  onDone: PropTypes.func,
  ContextButtonsContainer: PropTypes.instanceOf(Element),
  // TODO: should actually be an element, but for some reason elements are obejcts ¯\_(ツ)_/¯
  sidebarContainer: PropTypes.object,
  lineIndex: PropTypes.number,
  fieldID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getAuthorization: PropTypes.func.isRequired,
  isNewLine: PropTypes.bool.isRequired,
}

LineEditor.defaultProps = {
  onAbort: () => {},
  onDone: () => {},
}
