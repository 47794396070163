import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PlayerPositionService from './PositionService/PlayerPositionService'
import mobiscroll from '@mobiscroll/react'
import './TrackedPositionsVisualizer.scss'
import i18n from 'i18next'
import { useOnTimeUpdate } from './PositionEditorPlugin'

const ListItem = ({ item }) => <li
  data-player-number={item.playerNumber}
  data-team-id={item.teamId}
>
  {item.playerNumber}
</li>

export const TrackedPositionsVisualizer = (props) => {
  const [trackedPlayers, setTrackedPlayers] = useState({})

  const { getCurrentVideoTime } = props

  const update = useCallback(async () => {
    const timestamp = getCurrentVideoTime()
    /**
     * @type {PlayerPosition[]}
     */
    const positionsByTeamsAndPlayers = await props.playerPositionService.getByAllPositionsForTracking()

    Object.keys(positionsByTeamsAndPlayers).forEach(teamId => {
      positionsByTeamsAndPlayers[teamId] = Object.keys(positionsByTeamsAndPlayers[teamId]).filter(playerId => {
        /**
         * @type {PlayerPosition[]}
         */
        const positions = positionsByTeamsAndPlayers[teamId][playerId]
        return Boolean(positions.find(position => position.Timestamp === timestamp))
      })
    })

    setTrackedPlayers(positionsByTeamsAndPlayers)
  }, [getCurrentVideoTime, props.playerPositionService])

  useOnTimeUpdate(props.video, update)

  // Use useEffect as componentDidMount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { update() }, [])

  useEffect(() => {
    if (props.playerPositionService) {
      const subscriptionName = ''
      props.playerPositionService.subscribeToChange(subscriptionName, update)
      return () => props.playerPositionService.unsubscribeFromChange(subscriptionName)
    }
  }, [getCurrentVideoTime, props.playerPositionService, update])

  const stages = {
    left: [
      {
        percent: -75,
        color: 'red',
        text: 'Delete',
        icon: 'remove',
        confirm: true,
        action: (evt) => props.playerPositionService.deletePosition(
          evt.target.getAttribute('data-team-id'),
          evt.target.getAttribute('data-player-number'),
          props.getCurrentVideoTime(),
        ),
      },
    ],
  }

  // props.playerPositionService.deletePosition(teamId, playerNumber, timestamp

  return <mobiscroll.Page theme={'ios'} className={'tracked-positions-visualizer'}>
    {Object.keys(trackedPlayers)
      // Remove teams with no tracked positions
      .filter(key => trackedPlayers[key].length > 0)
      .map((teamId, index) => {
        const trackedPlayerNumbers = trackedPlayers[teamId].map((playerNumber, index) => ({
          id: index,
          playerNumber,
          teamId,
        })).sort(({ playerNumber: playerNumberA }, { playerNumber: playerNumberB }) => playerNumberA - playerNumberB)

        return <mobiscroll.FormGroup key={index}>
          <mobiscroll.FormGroupTitle>
            {i18n.t('playerPositionTracker.teamPositionsTitle', {
              teamId,
              numberOfTrackedPlayers: trackedPlayerNumbers.length,
            })}
          </mobiscroll.FormGroupTitle>
          <mobiscroll.Listview
            itemType={ListItem}
            data={trackedPlayerNumbers}
            theme={'ios'}
            actions={stages}
            actionsWidth={25}
            iconSlide
            hover={{
              timeout: 50,
              direction: 'right',
            }}
          />
        </mobiscroll.FormGroup>
      })}
  </mobiscroll.Page>
}
TrackedPositionsVisualizer.propTypes = {
  playerPositionService: PropTypes.instanceOf(PlayerPositionService).isRequired,
  getCurrentVideoTime: PropTypes.func.isRequired,
  video: PropTypes.any,
}
