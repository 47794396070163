import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import VortexSpinner from '../../utility/spinner/vortex'
import i18next from 'i18next'
// import { Link } from 'react-router-dom'
const { Popup, Input, Button } = mobiscroll

// function getLogoSrc (id) {
//   if (!id || id === undefined || id === '') {
//     return 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
//   }
//   return `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${id}.png`
// }

// function promptMail () {
//   return new Promise((resolve, reject) => {
//     prompt({
//       title: 'Nutzer Einladen',
//       placeholder: 'E-Mail',
//       inputType: 'text',
//       callback: resolve
//     })
//   })
// }

class SubscriptionEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      working: false,
      mail: '',
      tokenIndex: -1
    }
  }

  componentDidMount = async () => {
    const sub = this.props.subscription
    if (sub.type === 'TrainerSubscription') {
      // if (!sub.data.subToken || !sub.data.subToken.length || sub.data.subToken.includes('')) {

      // Remove unused var but keep function call in case of side effects
      // const token = await this.getToken(sub.id)
      // await this.getToken(sub.RowKey)

      // if (!token) {
      //   return
      // }
      this.props.updateUserData && this.props.updateUserData()
      // }
    }
    !this.props.clubsLoaded && this.props.getClubs && this.props.getClubs()
  }

  // getToken = async (subscriptionId) => {
  //   const headers = await this.props.getAuthorization()
  //   const token = await this.props.getSubscriptionToken(subscriptionId, headers)
  //   if (token.error) {
  //     console.error(token)
  //     return false
  //   }
  //   return token
  // }

  sendTokenTo = async () => {
    this.setState({ working: true })
    const mainSub = this.props.subscription
    const tokenIndex = this.state.tokenIndex
    // const userMail = this.props.user.email
    const recieverMail = this.state.mail
    if (!recieverMail || recieverMail === '') {
      return
    }
    const grants = [{
      type: 'SubTrainerSubscription',
      paymentId: `sub-${this.props.subscription.RowKey}`,
      paymentType: 'TrainerToken',
      validUntil: '*',
      activeUntil: '*',
      data: {
        tokenIndex,
        club: mainSub.data.club,
        mainTrainer: {
          sub: this.props.user.sub,
          license: mainSub.RowKey,
          name: this.props.user.name
        }
      }
    }]
    const body = {
      recieverMail,
      grants
      // SubscriptionId,
      // userMail,
      // token
    }
    const header = await this.props.getAuthorization()
    // Remove unused var but keep function call in case of side effects
    // const res = await this.props.sendSubToken(body, header)
    const res = await this.props.sendSubToken(body, header)
    // console.log(res)
    this.props.updateUserData && await this.props.updateUserData()
    this.setState({ tokenIndex: -1, mail: '', working: false })
    return window.gtag && window.gtag('event', 'subTrainer invitation send', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.props.user.clubList,
      event_category: 'subTrainer',
      event_label: 'User send an Subtrainer-Invitation',
      value: this.props.subscription.data.club
    })
    // { token, userMail, SubscriptionId }
  }

  setMail = (e) => {
    const mail = e.target.value
    this.setState({ mail })
  }

  setTokenIndex = (tokenIndex) => {
    this.setState({ tokenIndex })
  }

  render () {
    const subscription = this.props.subscription
    const subToken = subscription.data.subTrainer.map((subTrainer, tokenIndex) => {
      return (
        <div className='SubscriptionEdit col-xs' key={tokenIndex}>
          {/* <Input disabled readOnly value={token} >Token</Input> */}
          {/* <Input disabled readOnly value={mail} >User</Input> */}
          <div className='row'>
            <span className='col-xs-12'>Slot {tokenIndex + 1}</span><br />
            <span className='col-xs-12'>{subTrainer.name !== '' ? subTrainer.name : i18next.t('subscriptionEdit.notSet')}</span>
            <div className='col-xs-12'>
              <Button className='btn info' onClick={() => { this.setTokenIndex(tokenIndex) }}>{i18next.t('subscriptionEdit.inviteUser')}</Button>
            </div>
          </div>
        </div>
      )
    })
    const clubName = subscription.data.clubName || `ClubId: ${subscription.data.club} ${i18next.t('user.clubNameLoading')}`
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content history={this.props.history} className='userSite'>
          <div className='col-xs-12'>
            <div className='DataView row'>
              {/* <div className='clubLogoContainer col-xs-4'>
              <img src={getLogoSrc(subscription.data.club)} className='image clubLogo' alt='ClubLogo' />
            </div> */}
              <div className='col-xs-12'>
                <h3 className='siteHeader'>{i18next.t('subscriptionEdit.guestSub')} {clubName}</h3>
              </div>
              <div className='col-xs-12'>
                <p className='explanationText'>{i18next.t('subscriptionEdit.explanationP1')}</p>
                <p className='explanationText'>{i18next.t('subscriptionEdit.explanationP2', { CLUB: clubName })}</p>
                <p className='explanationText'>{i18next.t('subscriptionEdit.explanationP3')}</p>
              </div>
            </div>
            <div className='row'>
              <form className='formArea col-xs' onSubmit={() => false} action='javascript:void(0);'>
                {this.state.tokenIndex > -1
                  ? <div>
                    <div className='inputBar'>
                      <Input className='input' readOnly={this.state.working} placeholder={i18next.t('subscriptionEdit.recieverMail')} onChange={this.setMail} />
                    </div>
                    <button onClick={() => { this.setTokenIndex(-1) }} className={`btn ${this.state.working ? 'disabled' : 'warning'}`}>{i18next.t('subscriptionEdit.back')}</button>
                    <button onClick={this.sendTokenTo} className={`btn ${this.state.working ? 'disabled' : 'success'}`}>{i18next.t('subscriptionEdit.send')}</button>
                    {this.state.working && <VortexSpinner />}
                  </div>
                  : <div className='row'>{subToken}</div>
                }
              </form>
            </div>
          </div>
        </Content>
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
    )
  }
}

export default SubscriptionEdit
