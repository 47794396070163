import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './bigPlayButton.scss'

class BigPlayButton extends Component {
  pause () {
    if (!this.props.show) {
      this.props.pause()
    }
  }

  togglePlay = () => {
    if (this.props.show) {
      this.props.play()
    } else {
      this.props.pause()
    }
  }

  render () {
    // if (this.props.show) {
    const videoElement = document.querySelector('div.video-player .children')
    if (!videoElement) { return null }
    return ReactDOM.createPortal((
      <div onClick={this.togglePlay} className='BigPlayButtonContainer'>
        {/* <h1 >Play</h1> */}
        <div className='BigPlayButtonWrapper'>
          {this.props.show && <span className='BigPlayButton mbsc-ic mbsc-ic-fa-play ng-star-inserted' />}
        </div>
      </div>), videoElement)
    // }
    // return null
  }
}

export default BigPlayButton
