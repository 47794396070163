import React, { Component } from 'react'

// import i18n from 'i18next'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'

class Site404 extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content className='Site404'>
          <div className='centerBox'>
            <h1>404</h1>
            <h2 className='siteHeader'>Diese Seite existiert nicht!</h2>
          </div>
        </Content>
      </>
    )
  }
}

export default Site404
