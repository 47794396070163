import discovery from '@soccerwatch/discovery'
import { getUserHeaders } from '../components/firebase'
import listTillFinished from '../lib/helper/listTillFinished'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function getAnnotationTagsForMatch (SequenceId) {
  let annotations
  const headers = await getUserHeaders()
  const url = `${discovery.API_SEQUENCE}/AnnotationTagList/${SequenceId}`
  // const url = `http://10.0.0.164:4000/AnnotationTagList/${SequenceId}`
  try {
    annotations = await listTillFinished(url, headers)
  } catch (error) {
    console.error(error)
    // this.setState({ error: err.toString() }, () => {
    //   this.refs.error.instance.show()
    // })
    return { error }
  }
  return annotations
}

async function uploadAnnotation (SequenceId, body) {
  const url = `${discovery.API_SEQUENCE}/AnnotationTag/${SequenceId}`
  // const url = `http://10.0.0.164:4000/AnnotationTag/${SequenceId}`
  const headers = await getUserHeaders({ 'content-type': 'application/json' })
  let result
  try {
    const response = await axios.post(url, body, { headers })
    result = response.data
  } catch (error) {
    console.error(error)
    return { error }
  }
  return result
}

async function updateAnnotation (annotation) {
  const SequenceId = annotation.PartitionKey
  const AnnotationTag = annotation.RowKey

  const url = `${discovery.API_SEQUENCE}/AnnotationTag/${SequenceId}/${AnnotationTag}`
  // const url = `http://10.0.0.164:4000/AnnotationTag/${SequenceId}/${AnnotationTag}`
  const headers = await getUserHeaders({
    'content-type': 'application/json'
  })
  let result
  try {
    const response = await axios.post(url, annotation, { headers })
    result = response.data
  } catch (error) {
    console.error(error)
    return { error }
  }
  return result
}

async function deleteAnnotation (sequence, annotation) {
  const url = `${discovery.API_SEQUENCE}/AnnotationTagDelete/${sequence.RowKey}/${annotation.RowKey}`
  // const url = `http://localhost:4000/trainer/clip/info/${RowKey}`
  let result
  try {
    const headers = await getUserHeaders()
    result = await axios.delete(url, { headers })
    if (!result.status === 200) {
      // console.error('Delete Ann Status:', result.status)
      return { error: 'Result Could not be Deleted' }
    }
  } catch (err) {
    console.error(err)
    return { error: err }
  }
  return 200
}

export default { uploadAnnotation, updateAnnotation, getAnnotationTagsForMatch, deleteAnnotation }
