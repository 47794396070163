import React from 'react'
import PropTypes from 'prop-types'

export const ContextButton = (props) => {
  const spaceIfNeeded = props.label && props.iconName ? <React.Fragment key='space'>&nbsp;</React.Fragment> : null
  const buttonContentItems = [
    props.iconName ? <span key='icon' className={`mbsc-ic mbsc-ic-${props.iconName}`} /> : null,
    spaceIfNeeded,
    <React.Fragment key='label'>{props.label}</React.Fragment>,
  ]

  if (props.displayLabelBeforeIcon) {
    buttonContentItems.reverse()
  }

  return (
    <span
      className={`btn contextButton left ${props.disabled ? 'disabled' : props.type}`}
      // Only call event handler if enabled
      onClick={(...args) => props.disabled ? null : props.onClick(...args)}
      style={{
        cursor: props.disabled ? 'not-allowed' : undefined,
      }}
    >
      {buttonContentItems}
      {props.children}
    </span>
  )
}

ContextButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'boyzone']),
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  label: PropTypes.string,
  displayLabelBeforeIcon: PropTypes.bool,
}

ContextButton.defaultProps = {
  disabled: false,
  type: 'info',
  onClick: () => {},
  displayLabelBeforeIcon: false,
}

