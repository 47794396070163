export const isMobile = () =>
  isAndroid() ||
  navigator.userAgent.match(/webOS/i) ||
  isIPhone() ||
  isIPad() ||
  isIPadOs() ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)

export const isIOS = () => isIPad() || isIPhone()

export const isIPad = () => navigator.userAgent.match(/iPad/i) ||
  (navigator.userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)

export const isIPadOs = () => navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1

export const isIPhone = () => navigator.userAgent.match(/iPhone/i) ||
  (navigator.userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)

export const isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification))

export const isAppleMobile = () => isIPad() || isIPadOs() || isIPhone()

export const isAndroid = () => navigator.userAgent.match(/Android/i)

export const isFirefox = () => navigator.userAgent.match(/Firefox/i)

export const isIE = () => /MSIE|Trident/.test(window.navigator.userAgent)
