import React from 'react'
import { ContextButton } from './contextButton'
import { DisplayContextButtons } from './displayContextButtons'
import './contextButtons.scss'

const sequenceIsClose = (currentTime, useAbsolute = false) => (sequence) => {
  if (useAbsolute) {
    return sequence.from <= currentTime && currentTime <= sequence.to
  }
  return sequence.relativeStartTime <= currentTime && currentTime <= sequence.relativeEndTime
}

export default function ContextButtons (props) {
  const { createSequence,
    RenderToElement,
    drawMode,
    sequences,
    video,
    currentTime,
    history,
    recordingDone,
    recording,
    cancleRecording,
    redrawing,
    cancleRedraw,
    useAbsoluteTimestamps
  } = props
  const [working, setIsWorking] = React.useState(false)
  const [extended, setIsExtended] = React.useState(false)

  function toggleExtended () {
    setIsExtended(!extended)
  }

  const getSequence = (label = [], tag = false, isCustomTag = false) => {
    const match = props.match
    const timestamp = tag && tag.timestamp ? tag : Math.round(currentTime)
    const from = timestamp - 8 >= 0 ? timestamp - 8 : 0
    const to = timestamp + 8 <= video.duration ? timestamp + 8 : video.duration
    const duration = to - from
    const name = tag ? tag.name : 'Unbenannte Szene'
    if (!label.includes(name)) {
      label.push(name)
    }
    const sequence = { from,
      to,
      timestamp,
      duration,
      annotationIds: [],
      clipStream: match.userStreamAdaptive ?? match.userStream,
      name: name,
      label: label,
      matchId: match.RowKey,
      clubAName: match.clubAName,
      clubBName: match.clubBName,
      clubAId: match.clubAId,
      clubBId: match.clubBId,
      matchLeague: match.baseLeague,
      matchAgeClass: match.ageClass
    }
    if (match.vrStream) {
      sequence.vrStream = match.vrStream
    }
    //! Prop videoType DEPRECATED, but kept for Backwards Compability
    if (match.videoType === 'panorama' || match.videoType === 'quarterBoxPanorama') {
      sequence.clipStream = match.userStreamAdaptive
      sequence.vrStream = match.userStreamAdaptive
    }
    //* Use new videoProcessing going forward
    if (match.videoProcessing === 'quarterBoxPanorama') {
      sequence.clipStream = match.userStreamAdaptive
      sequence.vrStream = match.userStreamAdaptive
      sequence.isVrSequence = true
    }
    if (tag) {
      sequence.originType = isCustomTag ? 'CustomTag' : 'Tag'
      sequence.originId = tag.id
    }
    // console.log('Creating Sequence', sequence, match)
    return sequence
  }

  function trySetReferenceTime (sequence) {
    if (window.GlobalCoachingTool) {
      window.GlobalCoachingTool.timeReference = props.video.currentTime
      const sequenceTime = props.clipEditor ? props.video.currentTime - sequence.relativeStartTime
        : (props.video.currentTime - sequence.from) + sequence.relativeStartTime
      if (sequenceTime >= 0 && sequenceTime < sequence.relativeEndTime) {
        window.GlobalCoachingTool.sequenceTimeReference = sequenceTime
      }
    }
  }

  const prepareAndCreateSequence = (label = [], tag = false, isCustomTag = false, goToEdit = false) => async () => {
    if (!working) {
      const sequenceBase = getSequence(label, tag, isCustomTag)
      setIsWorking(true)
      const sequence = await createSequence(sequenceBase)
      setIsWorking(false)
      if (goToEdit && sequence) {
        trySetReferenceTime(sequence)
        history.push(`./sequence/${sequence.RowKey}/`)
      }
    }
  }

  const ensureSequenceAndEdit = (tagSequence, labels = []) => async () => {
    if (!working) {
      let sequence = tagSequence
      if (!tagSequence.RowKey) {
        tagSequence.label = labels
        setIsWorking(true)
        sequence = await createSequence(tagSequence)
        setIsWorking(false)
      }
      trySetReferenceTime(sequence)
      history.push(`./sequence/${sequence.RowKey}/`)
    }
  }

  const endRecording = async () => {
    if (!working) {
      setIsWorking(true)
      await recordingDone()
      setIsWorking(false)
    }
  }
  const _cancleRecording = () => {
    if (!working) {
      cancleRecording()
    }
  }

  const goBack = () => {
    if (!working) {
      redrawing && cancleRedraw()
      recording && cancleRecording()
      if (window.GlobalCoachingTool) {
        window.GlobalCoachingTool.goBack()
      }
    }
  }

  const getSequenceNameButton = (drawMode, sequence, sequenceCount) => {
    if (props.sequenceEditor) return null
    const timeLabel = sequence.gameTimeLabel || String(Math.round(sequence.timestamp / 60))
    const label = `${timeLabel}´ ${sequence.name}`
    const onClickHandler = drawMode ? () => {} : ensureSequenceAndEdit(sequence)
    return <ContextButton
      onClick={onClickHandler}
      key='SequenceNameBtn'
      type={'info'}
      disabled={working}
      label={label}
      iconName={drawMode ? undefined : 'material-edit'}
      displayLabelBeforeIcon
    />
  }

  let buttons = []
  const closeButton = history ? <ContextButton
    key='SimplyCloseBtn'
    type='danger'
    onClick={goBack}
    iconName='fa-times'
  /> : null
  const nearSequence = sequences.map((s, i) => { s.index = i; return s }).find(sequenceIsClose(currentTime, useAbsoluteTimestamps))
  if (props.tagMode && !nearSequence) {
    const toggleExtendButton = <ContextButton
      key='ToggleExtend'
      type='info'
      onClick={toggleExtended}
      iconName={extended ? 'fa-angle-right' : 'fa-angle-left'}
    />
    buttons.unshift(toggleExtendButton)
    if (extended) {
      props.tagDefinitions.forEach((def) => {
        const button = <ContextButton
          onClick={prepareAndCreateSequence([], def, true, false)}
          type='success'
          disabled={working}
          key={def.RowKey}
          label={def.name}
        />

        buttons.push(button)
      })
    }
  }
  if (nearSequence) {
    buttons.push(getSequenceNameButton(drawMode, nearSequence, sequences.length))
    if (recording) {
      buttons = buttons.concat([
        <ContextButton
          key='RecSaveBtn'
          type={'success'}
          disabled={working}
          onClick={endRecording}
          iconName='material-save'
        />,
        <ContextButton
          key='RecCancleBtn'
          type={'danger'}
          disabled={working}
          onClick={_cancleRecording}
          iconName='fa-times'
        />
      ])
    }
  } else if (!drawMode) {
    buttons.push(
      <ContextButton
        key='QuickEditBtn'
        disabled={working}
        type={'warning'}
        onClick={prepareAndCreateSequence([], false, false, true)}
        iconName='material-edit'
      />
    )
    buttons.push(
      <ContextButton
        key={'CreateSequenceBtn'}
        disabled={working}
        type={'success'}
        onClick={prepareAndCreateSequence()}
        iconName='plus'
        label='Szene'
      />
    )
  }
  if (!recording && history) {
    buttons.push(closeButton)
  }

  return <DisplayContextButtons
    buttons={buttons}
    container={RenderToElement}
  />
}
