import discovery from '@soccerwatch/discovery'
import listTillFinished from '../lib/helper/listTillFinished'
import { getUserHeaders } from '../components/firebase'
import { isIPhone } from '../lib/helper/deviceCatigorisation'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function getClips () {
  let clips
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_SEQUENCE}/trainer/clip/list`
    // const url = `http://localhost:4000/trainer/clip/list`
    clips = await listTillFinished(url, headers)
  } catch (error) {
    console.error(error)
    return { error }
  }
  return clips
}

async function deleteClip (RowKey) {
  const url = `${discovery.API_SEQUENCE}/trainer/clip/info/${RowKey}`
  // const url = `http://localhost:4000/trainer/clip/info/${RowKey}`
  const headers = await getUserHeaders()
  let result
  try {
    result = await axios.delete(url, { headers })
    if (!result.status === 200) {
      console.error('Delete Video Status:', result.status)
      return { error: 'Result Could not be Deleted' }
    }
  } catch (err) {
    console.error(err)
    return { error: err }
  }
  return 200
}

async function createClip (body, header) {
  const url = `${discovery.API_SEQUENCE}/trainer/clip/create/`
  const headers = await getUserHeaders({ 'content-type': 'application/json' })
  let result
  try {
    const resp = await axios.post(url, body, { headers })
    if (!resp.status === 200) {
      return { error: 'Could not Create Clip' }
    }
    result = resp.data
    return result
  } catch (err) {
    console.error(err)
    return { error: err }
  }
}

async function getClip (RowKey) {
  // const url = `http://localhost:4000/trainer/clip/share/${RowKey}`
  const url = `${discovery.API_SEQUENCE}/trainer/clip/share/${RowKey}`
  let result
  try {
    const resp = await axios.get(url)
    if (resp.status !== 200) {
      return { error: `Cloud not get Clip: ${resp.status}` }
    }
    result = resp.data
  } catch (err) {
    return { error: err }
  }
  if (!result.data || !result.data[0]) {
    return false
  }
  return result.data[0]
}

async function updateClip (body) {
  const url = `${discovery.API_SEQUENCE}/trainer/clip/update/${body.RowKey}`
  // const url = `http://localhost:4000/trainer/clip/update/${body.RowKey}`
  const headers = await getUserHeaders({ 'content-type': 'application/json' })

  let result
  try {
    const resp = await axios.post(url, body, { headers })
    if (resp.status !== 200) {
      return { error: `Could not Update Clip, Recieved ${resp.status}` }
    }
    result = resp.data
  } catch (err) {
    return { error: err }
  }
  return result
}

async function toggleClipPublicity (clip, header) {
  const body = {
    public: !clip.public,
    RowKey: clip.RowKey
  }
  const response = await updateClip(body, header)
  if (response.error) {
    return response
  }
  return true
}

function getPublicClipPath (clipId) {
  return `${discovery.API_SEQUENCE}/publicTrainerVClip/${clipId}.m3u8`
  // return `http://localhost:4000/publicTrainerVClip/${clipId}.m3u8`
}

function getIosClipPath (clipId, auth, isVr) {
  const vrSuffix = isVr ? 'VR' : ''
  auth = auth.authorization ? auth.authorization : auth
  // const authorization = headers.authorization
  return `${discovery.API_SEQUENCE}/TrainerVClip${vrSuffix}/${clipId}.m3u8?auth=${auth}`
}

async function downloadClip (clipId) {
  const url = `https://europe-west1-sw-sc-de-shared.cloudfunctions.net/convert/vclip/${clipId}/`
  let result
  try {
    const response = await axios.get(url)
    result = response.data
  } catch (err) {
    console.error(err)
  }
  const href = window.URL.createObjectURL(result)
  return href
}

export default { getClip, getClips, deleteClip, createClip, toggleClipPublicity, updateClip, getPublicClipPath, downloadClip, getIosClipPath }
