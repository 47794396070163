import discovery from '@soccerwatch/discovery'
import { getUserHeaders } from '../components/firebase'
import listTillFinished from '../lib/helper/listTillFinished'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function getSequences () {
  let sequences
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_SEQUENCE}/trainer/sequence/listFull/`
    // const url = `http://10.0.0.164:4000/trainer/sequence/listFull/`
    const response = await listTillFinished(url, headers)
    sequences = response
  } catch (error) {
    console.error(error)
    return { error }
  }
  return sequences
}

async function getSequencesForMatch (matchId) {
  let sequences
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_SEQUENCE}/trainer/sequence/listForMatch/${matchId}`
    // const url = `http://10.0.0.164:4000/trainer/sequence/listForMatch/${matchId}`
    const response = await listTillFinished(url, headers)
    sequences = response
  } catch (error) {
    console.error(error)
    return { error }
  }
  return sequences
}

async function deleteSequence (id) {
  const url = `${discovery.API_SEQUENCE}/trainer/sequence/info/${id}`
  // const url = `http://10.0.0.164:4000/trainer/sequence/info/${id}`
  let result
  try {
    const headers = await getUserHeaders()
    result = await axios.delete(url, { headers })
    if (!result.status === 200) {
      console.error('Delete Video Status:', result.status)
      return { error: 'Result Could not be Deleted' }
    }
  } catch (err) {
    console.error(err)
    return { error: err }
  }
  return 200
}

async function createSequence (data) {
  const url = `${discovery.API_SEQUENCE}/trainer/sequence/create/`
  // const url = `http://10.0.0.106:4000/trainer/sequence/create/`
  const headers = await getUserHeaders({
    'content-type': 'application/json'
  })
  let result
  try {
    const resp = await axios.post(url, data, { headers })
    if (!resp.status === 200) {
      return { error: 'Could not Create Sequence' }
    }
    result = resp.data
    return result
  } catch (err) {
    console.error(err)
    return { error: err }
  }
}

async function getSequence (id) {
  const url = `${discovery.API_SEQUENCE}/trainer/sequence/info/${id}`
  let result
  try {
    const resp = await axios.get(url)
    if (!resp.status === 200) {
      return { error: `Cloud not get Sequence: ${resp.status}` }
    }
    result = resp.data
  } catch (err) {
    return { error: err }
  }
  return result.data[0]
}

async function updateSequence (body) {
  const url = `${discovery.API_SEQUENCE}/trainer/sequence/info/${body.RowKey}`
  // const url = `http://10.0.0.57:4000/trainer/sequence/info/${body.RowKey}`
  const headers = await getUserHeaders({
    'content-type': 'application/json'
  })
  let result
  try {
    const resp = await axios.post(url, body, { headers })
    if (resp.status !== 200) {
      return { error: `Could not Update Sequence, Recieved ${resp.status}` }
    }
    result = resp.data
  } catch (err) {
    return { error: err }
  }
  return result
}

async function toggleSequencePublicity (sequence, header) {
  const body = {
    RowKey: sequence.RowKey,
    public: !sequence.public
  }
  const response = await updateSequence(body, header)
  if (response.error) {
    return response
  }
  return true
}

function getVirtualSequenceSrc (sequence, isVR = false) {
  const timestamp = sequence.from
  const matchId = sequence.matchId
  const duration = sequence.duration
  const base = isVR ? 'VirtualSequenceVR' : 'VirtualSequence'
  const url = `${discovery.API_SEQUENCE}/${base}/${matchId}/${timestamp}/${duration}/clip.m3u8`
  console.log('URL', url, isVR)
  // const url = `http://localhost:4000/VirtualSequence/${matchId}/${timestamp}/${duration}/clip.m3u8`
  return url
}

export default {
  getSequence,
  getSequences,
  getSequencesForMatch,
  deleteSequence,
  createSequence,
  toggleSequencePublicity,
  updateSequence,
  getVirtualSequenceSrc
}
