import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './sidebar.scss'

class Sidebar extends Component {
  seek = (item) => () => {
    if (this.props.video) {
      const time = this.props.useAbsoluteTimestamps ? item.from : item.relativeStartTime
      this.props.video.currentTime = time
    }
  }

  seekAnnotation = (item) => () => {
    if (this.props.video) {
      const goTo = item.relativeTimestamp - 4 < 0 ? 0 : item.relativeTimestamp - 4
      this.props.requestAnnotation(item)
      this.props.video.currentTime = goTo
    }
  }

  render () {
    if (!this.props.sidebarContent) {
      return null
    }
    const children = this.props.sidebarContent.props.children ? { ...this.props.sidebarContent.props.children } : undefined
    const contentElement = React.cloneElement(
      this.props.sidebarContent,
      { ...this.props.sidebarContent.props, seek: this.seek, seekAnnotation: this.seekAnnotation, video: this.props.video },
      children
    )
    const html = (
      <div className='sidebarContent'>
        {contentElement}
        {this.props.children}
      </div>
    )
    const container = this.props.sidebarContainer
    if (container) {
      return ReactDOM.createPortal(html, container)
    }
    return <>{html}</>
  }
}

export default Sidebar
