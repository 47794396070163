import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class FreeLine extends Component {
  onMouseDown = (evt, path) => {
    // path = new this.props.Paper.Path()
    path.add(evt.localPoint)
    return { path }
  }

  onMouseDrag = (evt, path) => {
    path.add(evt.localPoint)
    return { path }
  }

  onMouseUp = (evt, path, localEvtPoint) => {
    // const then = this.path.segments.length
    path.simplify(10)
    // const now = this.path.segments.length
    return { path }
  }

  render () {
    return (
      <ProtoTool
        ref={this.proto}
        key='Freeline'
        toolName='Freeline'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-pencil' />
      </ProtoTool>
    )
  }
}

export default FreeLine
