import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './DirectionalPad.scss'

const Arrow = ({ direction, whilePressed }) => {
  const requestedFrameRef = useRef(NaN)

  const resetAnimationFrame = () => {
    // Cancel any ongoing loop before starting a new one in case mouseup wasn't fired
    cancelAnimationFrame(requestedFrameRef.current)

    requestedFrameRef.current = requestAnimationFrame(() => {
      whilePressed()
      resetAnimationFrame()
    })
  }

  const onMouseDown = () => {
    resetAnimationFrame()
  }

  const onMouseUp = () => {
    cancelAnimationFrame(requestedFrameRef.current)
  }

  return <div className={`arrow ${direction}`}>
    <span
      className={`mbsc-ic mbsc-ic-fa-chevron-${direction}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      onTouchCancel={onMouseUp}
    />
  </div>
}

Arrow.propTypes = {
  whilePressed: PropTypes.func,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
}

export const DirectionalPad = ({
  onUp = () => {},
  onDown = () => {},
  onLeft = () => {},
  onRight = () => {},
}) => {
  return <div
    className='directional-pad'
  >
    <Arrow direction='up' whilePressed={onUp} />
    <Arrow direction='down' whilePressed={onDown} />
    <Arrow direction='left' whilePressed={onLeft} />
    <Arrow direction='right' whilePressed={onRight} />
  </div>
}

DirectionalPad.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
}
