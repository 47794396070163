import React, { Component } from 'react'
import { prompt } from '@mobiscroll/react'
import ProtoTool from './prototool'
import { createAdaptivePointText } from './AdaptiveText'

/**
 * Wraps a mobiscroll.prompt call in a Promise
 * @returns {Promise<string>}
 */
function promptText () {
  return new Promise((resolve, reject) => {
    prompt({
      title: 'TextTool',
      // message: '',
      placeholder: '',
      inputType: 'text',
      callback: resolve
    })
  })
}

class PointText extends Component {
  constructor (props) {
    super(props)
    this.count = 0
  }

  /**
   * @private
   * @type {paper.PointText}
   */
  text

  /**
   * @private
   * @type {Array<paper.PointText>}
   */
  textObjects = []

  /**
   * @private
   * @type {paper.Path.Circle}
   */
  firstPointMarker

  /**
   * @private
   * @type {boolean}
   */
  prompting = false

  /**
   * @private
   * @type {string[]}
   */
  breakKeys = ['enter', 'escape']

  /**
   * @private
   * @type {string[]}
   */
  preventBubblingOn = ['backspace']

  /**
   * @param {paper.MouseEvent} evt
   * @param {paper.Path} path
   * @param {{
   *   isUserEvt: boolean,
   *   strokeColor?: string,
   *   strokeWidth?: number,
   *   fillColor?: string,
   *   snap?: unknown,
   * }} redraw
   */
  onMouseDown = (evt, path, redraw) => {
    if (this.props.isMobile && !redraw) {
      path.remove()
      return {}
    }
    if (this.firstPointMarker) {
      this.firstPointMarker.remove()
      this.text = null
    }
    const color = new this.props.Paper.Color(redraw && redraw.strokeColor ? redraw.strokeColor : this.props.color)

    // Don't show firstPointMarker while redrawing because the user doesn't actually have any control
    if (redraw.isUserEvt) {
      this.firstPointMarker = new this.props.Paper.Path.Circle(evt.localPoint, 4)
      this.firstPointMarker.fillColor = color
    }

    // Use adaptive text in VR mode in order to make it legible, no matter its position on the field
    if (this.props.useVrPlayer) {
      // Wrap localPoint in paper.Point because redraw just gives us an {x, y} object
      this.text = createAdaptivePointText(this.props.Paper, new this.props.Paper.Point(evt.localPoint))
    } else {
      this.text = new this.props.Paper.PointText(evt.localPoint)
    }

    this.text.fillColor = color
    this.text.fontSize = 25

    this.textObjects.push(this.text)

    return { path: new this.props.Paper.Path(), isNewPath: true }
  }

  onMouseDrag = (evt, path, redraw) => {
    if (this.props.isMobile && !redraw) {
      return {}
    }
    return { path }
  }

  onMouseUp = async (evt, path, redraw) => {
    if (this.prompting) {
      return {}
    }
    if (this.props.isMobile && !redraw) {
      this.prompting = true
      const text = await promptText()
      if (!text || text === '') {
        this.prompting = false
        return {}
      }
      const time = 200
      let usePath
      if (this.props.useVrPlayer) {
        usePath = createAdaptivePointText(this.props.Paper, evt.localPoint)
      } else {
        usePath = new this.props.Paper.PointText(evt.localPoint)
      }

      usePath.fillColor = this.props.color
      usePath.fontSize = 25
      usePath.data.annotation = true

      usePath.data.mouse = [{ localPoint: evt.localPoint, time, type: 'onMouseDown' }]
      usePath.data.tool = 'PointText'
      usePath.data.annotation = true
      text.split('').forEach((char) => {
        usePath.data.mouse.push({ localPoint: { x: 0, y: 0 }, time, type: 'onKeyDown', key: '', character: char })
      })
      usePath.data.mouse.push({ localPoint: { x: 0, y: 0 }, time, type: 'onKeyDown', key: 'enter', character: '' })
      usePath.content = text
      this.prompting = false
      return { path: usePath, ignoreInput: true, endPath: true }
    }
    return {}
  }

  onKeyDown = (e, path, isRedraw) => {
    if (this.props.isMobile && !isRedraw) {
      return {}
    }
    if (this.preventBubblingOn.includes(e.key) && !isRedraw) {
      e.preventDefault()
    }
    if (this.breakKeys.includes(e.key)) {
      this.text = null
      if (this.firstPointMarker) {
        this.firstPointMarker.remove()
      }
      return { path, endPath: true }
    } else if (e.key === 'backspace') {
      this.text.content = this.text.content.slice(0, -1)
    } else if (this.text) {
      this.text.content += e.character
    }
    return { path }
  }

  onLooseFocus = () => {
    if (this.firstPointMarker) {
      this.firstPointMarker.remove()
    }
  }

  pathDone = () => {
    this.text = null
    if (this.firstPointMarker) {
      this.firstPointMarker.remove()
    }
  }

  cleanUp = (isInternal = false) => {
    if (isInternal && this.props.redrawing) { return }
    this.textObjects.forEach(textObject => textObject.remove())

    if (this.firstPointMarker) {
      this.firstPointMarker.remove()
    }
  }

  render () {
    return (
      <ProtoTool
        key='PointText'
        toolName='PointText'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        onKeyDown={this.onKeyDown}
        cleanUp={this.cleanUp}
        pathDone={this.pathDone}
        keepPath
        dontCleanOnChange
        onChange={this.onLooseFocus}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-font' />
      </ProtoTool>
    )
  }
}

export default PointText
