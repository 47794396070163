async function listTillFinished (listCmd, userHeader, list = [], nextToken = false) {
  let nextList
  let result
  try {
    const slash = listCmd[listCmd.length - 1] === '/' ? '' : '/'
    const cmd = nextToken ? `${listCmd}${slash}${nextToken}/` : listCmd
    const response = await window.fetch(cmd, { headers: userHeader })
    if (response.status !== 200) {
      // console.error(response.status, response.statusText)
      nextList = []
      result = { nextToken: false }
    } else {
      result = await response.json()
      nextList = result.data
    }
  } catch (err) {
    throw (err)
    // console.error(err)
    // nextList = { data: [] }
  }
  list = list.concat(nextList)
  if (result.nextToken) {
    const token = encodeURI(result.nextToken).replace(/\//g, '%2F')
    return listTillFinished(listCmd, userHeader, list, token)
  }
  return list
}

export default listTillFinished
