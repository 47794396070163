import { Point } from 'paper'

export class PlayerPosition {
  /**
   * @property {string} Video - ID of video the position is in
   */
  Video = null

  /**
   * @property {string} Club - ID of club the position's player plays for
   */
  Club = null

  /**
   * @property {number} PlayerNumber - ID of the position's player
   */
  PlayerNumber = NaN

  /**
   * @property {number} Timestamp - Floored timestamp (as an integer!) when the position was recorded
   */
  Timestamp = NaN

  /**
   * @property {number} PreciseTimestamp - Precise timestamp (as a float; only if available) when the position was
   * recorded
   */
  PreciseTimestamp = NaN

  /**
   * @property {paper.Point} topLeft - Bounding box' top left corner
   */
  topLeft = null

  /**
   * @property {paper.Point} bottomRight - Bounding box' top left corner
   */
  bottomRight = null
  
  /**
   * @property {paper.Point} cylinderTopLeft - Bounding box' top left corner on cylinder (if box was drawn in
   * quarterBoxPanorama)
   */
  drawingSurfaceTopLeft = null

  /**
   * @property {paper.Point} cylinderBottomLeft - Bounding box' top left corner on cylinder (if box was drawn in
   * quarterBoxPanorama)
   */
  drawingSurfaceBottomRight = null

  
  /**
   * Creates the PlayerPosition
   * @param {{
   *   Video: number | string,
   *   Club: number | string,
   *   PlayerNumber: number | string,
   *   Timestamp: number | string,
   *   topLeft?: paper.Point,
   *   bottomRight?: paper.Point,
   *   cylinderTopLeft?: paper.Point,
   *   cylinderBottomRight?: paper.Point, 
   *   PointUL: { x: number | string, y: number | string },
   *   PointDR: { x: number | string, y: number | string },
   *   DrawingSurfacePointUL: { x: number | string, y: number | string },
   *   DrawingSurfacePointDR: { x: number | string, y: number | string },
   *   interpolated?: boolean
   * }} positionObject
   * @param {boolean} interpolated - Defines whether the position is real or interpolated
   */
   constructor(positionObject, interpolated = false) {
    const { Video, Club, PlayerNumber, Timestamp, topLeft, bottomRight, PointUL, PointDR, PreciseTimestamp, drawingSurfaceTopLeft, drawingSurfaceBottomRight, DrawingSurfacePointDR, DrawingSurfacePointUL } = positionObject
    
    this.Video = '' + Video
    this.Club = '' + Club
    this.PlayerNumber = parseInt(PlayerNumber)
    this.Timestamp = parseInt(Timestamp)
    this.PreciseTimestamp = parseFloat(PreciseTimestamp || Timestamp)
    this.topLeft = topLeft || new Point(PointUL)
    this.bottomRight = bottomRight || new Point(PointDR)
    if (drawingSurfaceTopLeft || DrawingSurfacePointUL) {
      this.drawingSurfaceTopLeft = drawingSurfaceTopLeft || new Point(DrawingSurfacePointUL)
    }
    if (drawingSurfaceBottomRight || DrawingSurfacePointDR) {
      this.drawingSurfaceBottomRight = drawingSurfaceBottomRight || new Point(DrawingSurfacePointDR)
    }
    this.interpolated = Boolean(interpolated || positionObject.interpolated)
  }

  get paperPoint () {
    return new Point(this.X, this.Y)
  }
}
