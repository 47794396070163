import React, { Component } from 'react'
import SequenceList from '../sequences/sequenceList'
import VortexSpinner from '../spinner/vortex'
// import { Link } from 'react-router-dom'
import i18n from 'i18next'
// import Collapsible from 'react-collapsible'

class CreateClipUi extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // name: '',
      // requestPending: false,
      extended: true,
      isCollapsed: true
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.sequences.length === 0 && this.props.sequences.length > 0) {
      this.setState({ extended: true })
    }
    if (this.props.sequences.length === 0 && prevProps.sequences.length > 0) {
      this.setState({ extended: false })
    }
  }

  setName = (e) => {
    const name = e.target.value
    this.props.setClipSequenceName(name)
  }

  removeSequence = (item) => () => {
    const property = item.RowKey ? 'RowKey' : 'originId'
    const index = this.props.sequences.findIndex((i) => i[property] === item[property])
    this.props.removeSequence(index)
  }

  uploadClip = async () => {
    // this.setState({ requestPending: true })
    this.props.setClipUiWorking(true)
    const sequences = await this.ensureSequences()
    const name = this.props.clipSequenceName
    if (!name) {
      return
    }
    const sequenceIds = sequences.map((s) => s.RowKey)
    const clip = {
      public: false,
      sequenceIds,
      name
    }
    const createFN = this.props.createClip ? this.props.createClip : this.props.saveAs
    await createFN(clip)
    this.props.setClipUiWorking(false)
    this.props.setClipSequenceName('')
  }

  updateClip = async () => {
    this.props.setClipUiWorking(true)
    if (this.props.clipSequenceName !== this.props.clip.name) {
      return this.uploadClip()
    }
    const copyClip = JSON.parse(JSON.stringify(this.props.clip))
    copyClip.sequences = await this.ensureSequences()
    copyClip.sequenceIds = copyClip.sequences.map((s) => s.RowKey)
    await this.props.updateClip(copyClip)
    this.props.setClipUiWorking(false)
  }

  ensureSequences = async () => {
    const sequenceList = [...this.props.sequences]
    if (!sequenceList || !sequenceList.length) {
      return
    }
    for (const index in sequenceList) {
      const seq = sequenceList[index]
      if (!seq.RowKey) {
        const sequence = await this.props.createSequence(seq)
        if (!sequence) {
          console.error('<Create Clip> Something went terrible Wrong. Could not Create Sequence')
          return
        }
        sequenceList[index] = sequence
      }
    }
    return sequenceList
  }

  toggleExtended = () => {
    const extended = !this.state.extended
    this.setState({ extended })
  }

  cancle = (goToClip) => () => {
    this.props.setClipContext(false)
    goToClip && this.props.history.push(`/clips/${this.props.clip.RowKey}`)
  }

  setIsCollapsed = (isCollapsed) => {
    this.setState({ isCollapsed })
  }

  render () {
    const disabled = this.clipUiWorking || !this.props.sequences || !this.props.sequences.length ? { disabled: true, placeholder: i18n.t('createClipUi.addSequences') } : { placeholder: i18n.t('createClipUi.nameClip') }
    // const [isCollapsed, setIsCollapsed] = useState(true)
    // const isCollapsed = this.state.isCollapsed
    return (
      // Set action to prevent page from reloading on submit
      // eslint-disable-next-line no-script-url
      <form action='javascript:void(0);' className='clipBox'>
        {!this.props.disableHeader && <div className='headerBox'>
          {
            this.props.createClip &&
            <h3 className='headerLabel'>{i18n.t('createClipUi.create')}</h3>
          }
          {this.props.updateClip && <h3 className='headerLabel'>{i18n.t('createClipUi.edit')}</h3>}
        </div>
        }
        {!!this.props.children &&
          <div className='children'>
            {this.props.children}
          </div>
        }
        {/* {this.props.showAddSequenceLink && <Link to='/matches/' className='btn_fluid info'>Szenen hinzufügen</Link>}
        {this.props.showPreviewSequenceLink && this.props.clip && <Link to={`/clips/${this.props.clip.RowKey}/edit/`} className='btn_fluid info'>Szenen hinzufügen</Link>} */}
        {this.props.updateClip &&
          <>
            <div className='updateClipUi'>
              <input {...disabled} onChange={this.setName} value={this.props.clipSequenceName} />
              {!this.props.clipUiWorking && this.props.clipSequenceName !== '' && !!this.props.sequences.length && <button type='submit' className='btn success saveForAction' onClick={this.updateClip}><span className='mbsc-ic mbsc-ic-material-save' /></button>}
              {!!this.props.sequences.length && !this.props.clipUiWorking && <span className='btn danger' onClick={this.cancle(true)}><span className='mbsc-ic mbsc-ic-fa-times' /></span>}
            </div>
            {/* <div className='helpcollapse'>
              <Collapsible triggerClassName='collapsible_area' trigger={<><small>Hilfe für Clip Bearbeiten <span className='mbsc-ic mbsc-ic-ion-help' /></small> {isCollapsed ? <span className='mbsc-ic mbsc-ic-fa-chevron-down' /> : <span className='mbsc-ic mbsc-ic-fa-chevron-up' />}</>} onOpen={() => this.setIsCollapsed(false)} onClose={() => this.setIsCollapsed(true)} transitionTime={600} easing='cubic-bezier(0.68, -0.55, 0.265, 1.55)'>
                test
              </Collapsible>
            </div> */}
          </>
        }
        {!this.props.updateClip && this.props.createClip &&
          <>
            <div className='createClipUi'>
              {this.props.sequences.length === 0 && <p className='infoTextForAction'>{i18n.t('createClipUi.info')}</p>}
              {!!this.props.sequences.length && <input {...disabled} onChange={this.setName} value={this.props.clipSequenceName} />}
              {!this.props.clipUiWorking && this.props.clipSequenceName !== '' && !!this.props.sequences.length && <button type='submit' className='btn success saveForAction' onClick={this.uploadClip}><span className='mbsc-ic mbsc-ic-material-save' /></button>}
              {!!this.props.sequences.length && !this.props.clipUiWorking && <button className='btn danger saveForAction' onClick={this.cancle(false)}><span className='mbsc-ic mbsc-ic-fa-times' /></button>}
            </div>
            {/* <div className='helpcollapse'>
              <Collapsible triggerClassName='collapsible_area' trigger={<><small>Hilfe für Clip erstellen <span className='mbsc-ic mbsc-ic-ion-help' /></small>{isCollapsed ? <span className='mbsc-ic mbsc-ic-fa-chevron-down' /> : <span className='mbsc-ic mbsc-ic-fa-chevron-up' />}</>} onOpen={() => this.setIsCollapsed(false)} onClose={() => this.setIsCollapsed(true)} transitionTime={600} easing='cubic-bezier(0.68, -0.55, 0.265, 1.55)'>
                test
              </Collapsible>
            </div> */}
          </>
        }

        <div className='clipBoxContent'>
          {this.props.clipUiWorking ? <VortexSpinner />
            : <SequenceList
              matches={this.props.matches}
              className='singleSequences'
              sequences={this.props.sequences}
              clipSequence={this.props.sequences}
              clipActions={this.props.clipActions}
              onSequenceSort={this.props.onSequenceSort}
              disableAutoSort
              showClubIconsInBaseLabel
              disableButtonLabel
              // editSequence
              sortable
              showMatchLink
              needsConfirm={false}
            />
          }
        </div>
      </form>
    )
  }
}

export default CreateClipUi
