import discovery from '@soccerwatch/discovery'
import axios from 'axios'
import * as rax from 'retry-axios'
import { getUserHeaders } from '../components/firebase'
rax.attach()

// import listTillFinished from '../lib/helper/listTillFinished'

async function getEnabler () {
  let enabler
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_ADDITIONAL}/generic`
    const response = await axios.get(url, { headers })
    enabler = response.data
  } catch (error) {
    console.error(error)
    return { error }
  }
  return (enabler.data || []).filter((e) => {
    if (!e.enabledClubs) {
      return false
    }
    const clubIds = (e.enabledClubs || []).filter((c) => !!c)
    return clubIds.length
  })
}

export default { getEnabler }
