import discovery from '@soccerwatch/discovery'
import listTillFinished from '../lib/helper/listTillFinished'
import { getUserHeaders } from '../components/firebase'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()
// const USE_STRICT_CHECK = false

const formateDateTime = (timeString) => {
  const timeObj = new Date(timeString)
  const date = `${timeObj.getDate()}.${('0' + (timeObj.getMonth() + 1)).slice(-2)}.${timeObj.getFullYear()}`
  const time = `${timeObj.toISOString().split('T')[1].split(':').splice(0, 2).join(':')} Uhr`
  return { date, time }
}

async function login (username, pw) {
  const loginData = await validateLogin(username, pw)
  return loginData
}

async function validateLogin (user, pw, retries = 0) {
  const url = `${discovery.API_USER}/login`
  try {
    const response = await axios.post(url, { user, pw }, { headers: { 'Content-Type': 'application/json' } })
    if (response.status !== 200) {
      return { error: `Login Error ${response.status}` }
    }
    const res = response.data
    return res
  } catch (err) {
    return { error: err }
  }

  // try {
  //   loginData.subscriptions = await checkSubscriptions(headers)
  //   loginData.subscriptions = loginData.subscriptions.map((s) => {
  //     if (USE_STRICT_CHECK && !s.data.coachingTool) {
  //       return null
  //     }
  //     const formated = formateDateTime(s.validUntil)
  //     s.validDate = formated.date
  //     s.validTime = formated.time
  //     if (s.activeUntil) {
  //       const formatedActive = formateDateTime(s.activeUntil)
  //       s.activeDate = formatedActive.date
  //       s.activeTime = formatedActive.time
  //     }
  //     return s
  //   })
  // } catch (err) {
  //   console.error(err)
  //   loginData.subscriptions = []
  // }
  // if (USE_STRICT_CHECK) {
  //   loginData.subscriptions = loginData.subscriptions.filter((s) => !!s)
  // }
  // loginData.trainerSubscriptions = []
  // loginData.subTrainerSubscriptions = {}
  // loginData.subscriptions.forEach((sub) => {
  //   if (sub.type === 'TrainerSubscription') {
  //     loginData.trainerSubscriptions.push(sub)
  //   } else if (sub.type === 'SubTrainerSubscription') {
  //     if (!loginData.subTrainerSubscriptions[sub.data.mainUser]) {
  //       loginData.subTrainerSubscriptions[sub.data.mainUser] = []
  //     }
  //     loginData.subTrainerSubscriptions[sub.data.mainUser].push(sub)
  //   }
  // })
  // loginData.clubList = loginData.trainerSubscriptions
  //   .sort((a, b) => { return parseInt(a.data.club) - parseInt(b.data.club) })
  //   .reduce((string, sub, index) => {
  //     const slash = index === 0 ? '' : ' - '
  //     return string + slash + sub.data.club
  //   }, '')
  // return { user: loginData, auth: headers.authorization }
}

function formatDate (s) {
  if (s.validUntil) {
    if (s.validUntil === '*') {
      s.validDate = '/'
      s.validTime = '/'
    } else {
      const formated = formateDateTime(s.validUntil)
      s.validDate = formated.date
      s.validTime = formated.time
    }
  }
  if (s.activeUntil) {
    if (s.validUntil === '*') {
      s.validDate = '/'
      s.validTime = '/'
    } else {
      const formatedActive = formateDateTime(s.activeUntil)
      s.activeDate = formatedActive.date
      s.activeTime = formatedActive.time
    }
  }
  return s
}

async function getLicense (type) {
  const headers = await getUserHeaders()
  const url = `${discovery.API_USER}/licensesByType/${type}`
  try {
    const res = await axios.get(url, { headers })
    const licenses = res.data
    if (licenses.status === 'INVALID_API_KEY') {
      return []
    }
    return licenses.data
  } catch (err) {
    console.error(err)
    return []
  }
}

async function getSubscriptions () {
  const subscriptions = await Promise.all([getLicense('TrainerSubscription'), getLicense('SubTrainerSubscription')])

  return { Trainer: subscriptions[0].map(formatDate), SubTrainer: subscriptions[1].map(formatDate) }
}

async function resetPassword (body) {
  const url = `${discovery.API_USER}/password`
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  try {
    const response = await axios.post(url, body, options)
    if (!response.status === 200) {
      return { error: `Reset Error ${response.status}` }
    }
    return true
  } catch (err) {
    return { error: err }
  }
}

async function register (body, type) {
  const url = `${discovery.API_USER}/register/`
  // const url = 'http://localhost:3001/register/'
  body.Place = `web-coachingtool_${type}`
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  try {
    const res = await axios.post(url, body, options)
    if (res.status === 409) {
      const data = res.data
      // data.status === 'mail exists' or 'UserName exists' or 'Missing Required Properties'
      return { error: data }
    }
    return res.status
  } catch (err) {
    return { error: err }
  }
}

// async function getPublicClubList () {
//   const url = `${discovery.API_CLUB}/clubs/`
//   try {
//     const response = listTillFinished(url)
//     return response
//   } catch (err) {
//     console.error(err)
//     return { error: err }
//   }
// }

async function checkSubscriptions () {
  const url = `${discovery.API_SUBSCRIPTION}/checkSubscription/`
  // const url = `http://localhost:4000/checkSubscription/`
  let subs = []
  try {
    const headers = await getUserHeaders()
    const Response = await axios.get(url, { headers })
    const json = Response.data
    subs = json.validSubscriptions
  } catch (err) {
    console.error(err)
  }
  return subs
}

async function getSubscriptionToken (subscriptionId) {
  // const subId = window.btoa(subscriptionId)
  const url = `${discovery.API_USER}/getSubToken/${subscriptionId}`
  // const url = `http://localhost:4000/getSubToken/${subId}`
  let token
  try {
    const headers = await getUserHeaders()
    const response = await axios.get(url, { headers })
    token = response.data
  } catch (err) {
    return { error: err }
  }
  return token
}

async function applySubToken (tokenId) {
  const url = `${discovery.API_USER}/licenseToken/redeem/${tokenId}/`
  // const url = `http://localhost:3200/licenseToken/redeem/${tokenId}/`
  const headers = await getUserHeaders({ 'Content-Type': 'application/json' })
  try {
    const response = await axios.post(url, {}, { headers })
    return { status: response.status }
  } catch (err) {
    return { error: err }
  }
}

async function sendSubToken (data) {
  const url = `${discovery.API_USER}/subTrainerToken/issue/`
  // const url = `http://localhost:3200/subTrainerToken/issue/`
  const headers = await getUserHeaders({ 'Content-Type': 'application/json' })
  try {
    const response = await axios.post(url, data, { headers })
    return { status: response.status }
  } catch (err) {
    return { error: err }
  }
}

async function redeemTrainerToken (token) {
  const url = `${discovery.API_SUBSCRIPTION}/trainerToken/redeem/${token}/`
  // const url = `http://localhost:4000/trainerToken/redeem/${token}/`
  const headers = await getUserHeaders({ 'Content-Type': 'application/json' })
  try {
    const response = await axios.post(url, { headers })
    if (response.status !== 200) {
      return { status: response.status }
    }
    return response.json()
  } catch (err) {
    return { error: err }
  }
}

async function getTrainerLicenses () {
  const url = `${discovery.API_USER}/licensesByType/TrainerSubscription/`
  try {
    const headers = await getUserHeaders()
    const response = await listTillFinished(url, headers)
    return response.data
  } catch (err) {
    return { error: err }
  }
}

async function getSubTrainerLicenses () {
  const url = `${discovery.API_USER}/licensesByType/SubTrainerSubscription`
  try {
    const headers = await getUserHeaders()
    const response = await listTillFinished(url, headers)
    return response.data
  } catch (err) {
    return { error: err }
  }
}

export default { login, validateLogin, resetPassword, register, getSubscriptionToken, applySubToken, sendSubToken, redeemTrainerToken, getTrainerLicenses, getSubTrainerLicenses, getSubscriptions }
