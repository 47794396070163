import React, { Component } from 'react'
import ReactDom from 'react-dom'
import ToolManager from './toolManager'
// import mobiscroll from '@mobiscroll/react'
import ColorButton from '../../../Editor/colorButton'
import StrokeButton from '../../../Editor/strokeButton'
import ToolbarActions from './toolbarActions'
import PropTypes from 'prop-types'

// const { Input, FormGroup } = mobiscroll

class Toolbar extends Component {

  // These PropTypes are INCOMPLETE!
  static propTypes = {
    undo: PropTypes.func,
    redo: PropTypes.func,
    clear: PropTypes.func,
    actionsContainer: PropTypes.any,
    quickbarContainer: PropTypes.any,
    show: PropTypes.bool,
    toolbarContainer: PropTypes.any,
    recording: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    color: PropTypes.any,
    setColor: PropTypes.func,
    useVrPlayer: PropTypes.bool,
    metersPerPixel: PropTypes.number,
  }

  static defaultProps = {
    useVrPlayer: false,
  }

  constructor (props) {
    super(props)
    this.state = {
      currentTool: 'Hand',
      stroke: 1,
      snap: 0
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.redrawing !== prevProps.redrawing) {
      this.setState({ currentTool: 'Hand' })
    }
  }

  activate = (currentTool) => {
    this.setState({ currentTool })
  }

  // setStroke = (e) => {
  //   const stroke = e.target.value
  //   this.setState({ stroke })
  // }

  setSnap = (e) => {
    const snap = e.target.value
    this.setState({ snap })
  }

  onRedrawDone = () => {
    this.setState({ currentTool: 'Hand' })
  }

  render () {
    const { undo, redo, clear, actionsContainer, quickbarContainer, show, toolbarContainer, recording, canUndo, canRedo, color, setColor, stroke, setStroke, useVrPlayer } = this.props
    const sharedProps = {
      Paper: this.props.Paper,
      color,
      useClass: 'mbsc-col-12',
      snap: this.state.snap,
      stroke,
      activate: this.activate,
      currentTool: this.state.currentTool,
      videoBuffering: this.props.videoBuffering,
      recording: this.props.recording,
      record: this.props.record,
      redrawing: this.props.redrawing,
      drawMode: this.props.drawMode,
      drawingLayer: this.props.drawingLayer,
      videoParent: this.props.videoParent,
      onRedrawDone: this.props.onRedrawDone,
      updateWorkState: this.props.updateWorkState,
      isMobile: this.props.isMobile,
      useVrPlayer,
    }
    const isHidden = show ? '' : 'hide'
    const ToolbarHTML = (
      <div className={`Toolbar msbc-row ${isHidden}`} style={this.props.drawMode ? {} : { display: 'none' }}>
        {!quickbarContainer && <ToolManager
          sharedProps={sharedProps}
          metersPerPixel={this.props.metersPerPixel}
        >
          <ColorButton color={color} setColor={setColor} />
          <StrokeButton stroke={stroke} setStroke={setStroke} />
        </ToolManager>
        }
        <ToolbarActions container={actionsContainer} recording={recording} undo={undo} redo={redo} clear={clear} canUndo={canUndo} canRedo={canRedo} />
        {/* <FormGroup>
          <Input type='number' value={this.state.snap} onChange={this.setSnap}>Distance/Snap</Input>
        </FormGroup>
        <FormGroup>
          <Input type='number' value={this.state.stroke} onChange={this.setStroke}>Stroke</Input>
        </FormGroup> */}
        {/* <button
          className='btn mbsc-btn action'
          onClick={this.props.recording ? this.props.recordingDone : this.props.record}>
          {this.props.recording ? 'Done(Save)' : 'Record'}
        </button> */}
        {/* <button className='btn mbsc-btn action' onClick={this.props.cancleRecording}>Cancle</button> */}
        {quickbarContainer && <ToolManager
          sharedProps={sharedProps}
          container={quickbarContainer}
          disableName
          activateTouch={this.props.activateTouch}
          deactivateTouch={this.props.deactivateTouch}
          metersPerPixel={this.props.metersPerPixel}
        >
          <ColorButton color={color} setColor={setColor} />
          <StrokeButton stroke={stroke} setStroke={setStroke} />
        </ToolManager> }

      </div>
    )
    if (toolbarContainer) {
      return ReactDom.createPortal(ToolbarHTML, toolbarContainer)
    }
    return ToolbarHTML
  }
}

export default Toolbar
