import React, { Component } from 'react'
import { Date, Input } from '@mobiscroll/react'

class DateFilter extends Component {

  change = (isFrom) => (txt, inst) => {
    const value = inst.getVal()
    this.props.change(isFrom, value)
  }

  render () {
    const { from, till } = this.props
    return <div className='row'>
      <div className='col-xs-6 date'><Date dateFormat='dd.mm.yy' lang='de' onSet={this.change(true)} returnFormat='iso8601' display='bubble' ><Input value={from} inputStyle='box'>Von:</Input></Date></div>
      <div className='col-xs-6 date'><Date dateFormat='dd.mm.yy' lang='de' onSet={this.change(false)} returnFormat='iso8601' display='bubble' ><Input value={till} inputStyle='box'>Bis:</Input></Date></div>
    </div>
  }
}

export default DateFilter
