import React from 'react'
import i18n from 'i18next'
const fType = {
  homeGoal: (tag) => {
    return tag.originType === 'Tag' && tag.originData.eventType === 0
  },
  goal: (tag) => {
    return tag.originType === 'Tag' && (tag.originData.eventType === 1 || tag.originData.eventType === 0)
  },
  highlight: (tag) => {
    return tag.originType === 'Tag' && (tag.originData.level > 0)
  },
  fouls: (tag) => {
    return tag.originType === 'Tag' && (tag.originData.eventType === 4 || tag.originData.eventType === 5)
  },
  escalation: (tag) => {
    return tag.originType === 'Tag' && (tag.originData.eventType === 23)
  }
}
export default function FastClipButtons (props) {
  const { sequenceMap, matchList, addToClip, isSelectedMatch } = props

  const quickClip = (filter) => async () => {
    if (!matchList) {
      return
    }
    const sequences = Array.isArray(matchList)
      ? matchList.map((match) => (sequenceMap[match.RowKey] || []).filter(filter)).flat()
      : sequenceMap[matchList.RowKey].filter(filter)
    for (const i in sequences) {
      const sequence = sequences[i]
      await addToClip(sequence)()
    }
  }

  const label = Array.isArray(matchList) ? 'fastClipButtons.filtered' : isSelectedMatch ? 'fastClipButtons.this' : 'fastClipButtons.newest'
  const labelAdd = i18n.t(label)
  return (
    <div className='FastClipButtons'>
      <small>
        <div className='clipBtnText'>{i18n.t('fastClipButtons.head')} :</div>
        <span onClick={quickClip(fType.highlight)} className={`btn ${matchList ? 'info_border' : 'disabled'}`}>{i18n.t('fastClipButtons.highlights')} {labelAdd}</span>
        <span onClick={quickClip(fType.goal)} className={`btn ${matchList ? 'info_border' : 'disabled'}`}>{i18n.t('fastClipButtons.goals')} {labelAdd}</span>
        {props.showHomeGoalsBtn && <span onClick={quickClip(fType.homeGoal)} className={`btn ${matchList ? 'info_border' : 'disabled'}`}>Heimtore {labelAdd}</span>}
        {props.isHighlightAdmin && <span onClick={quickClip(fType.fouls)} className={`btn ${matchList ? 'info_border' : 'disabled'}`}>Fouls {labelAdd}</span>}
        {props.isHighlightAdmin && <span onClick={quickClip(fType.escalation)} className={`btn ${matchList ? 'info_border' : 'disabled'}`}>Eskalationen {labelAdd}</span>}
      </small>
    </div>
  )
}
