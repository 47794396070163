import React from 'react'
import { Link } from 'react-router-dom'
import SequenceList from '../sequences/sequenceList'
import VortexSpinner from '../spinner/vortex'
import { Animate } from 'react-simple-animate'
import i18n from 'i18next'
import * as VC from '@soccerwatch/videocanvas'
import BigPlayButton from '../../plugins/BigPlayButton/bigPlayButton'
import * as DeviceCatigorisation from '../../../lib/helper/deviceCatigorisation'

const VideoCanvas = VC.Video

function getLogoSrc (id) {
  if (!id || id === undefined || id === '') {
    return 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
  }
  return `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${id}.png`
}

function deliverPlugins (videoParent, videoState, video, paper, drawingLayer) {
  if (!video) {
    return null
  }
  return <BigPlayButton show={!videoParent.props.videoController.isPlaying} play={videoParent.playVideo} pause={videoParent.pauseVideo} />
}

const isFirefoxAndroid = DeviceCatigorisation.isFirefox() && DeviceCatigorisation.isAndroid()
const unsuportedBrowser = ((!DeviceCatigorisation.isMobile() && !DeviceCatigorisation.isIOS() && DeviceCatigorisation.isSafari()) || isFirefoxAndroid)
const browserNotice = DeviceCatigorisation.isMobile() ? 'Bitte verwenden sie die aktuelle Version des Chrome Browsers' : 'Bitte verwenden sie die aktuelle Version von Chrome oder Firefox.'

export default function MatchItem (props) {
  const { history, match, sequences, sequencesLoaded,
    extendable = true, showSequences, showTags, linkToDetails, showPreview, seek,
    seekAnnotation, showSequenceCount
    // showCombinedCount, sequenceMap
  } = props
  const sequencesOpenByDefault = !extendable && showSequences
  const [sequencesOpen
    // setSequencesOpen
  ] = React.useState(sequencesOpenByDefault)
  const _sequences = sequences || []
  const sequenceLabel = sequencesLoaded && match.tagsLoaded ? i18n.t('matchItem.sequencesLoaded', { count: _sequences.length }) : i18n.t('matchItem.sequencesLoading')
  //! Prop videoType DEPRECATED, but kept for Backwards Compability
  const videoTypeIsPanorama = match.videoType === 'panorama' || match.videoType === 'quarterBoxPanorama'
  //* Use new videoProcessing going forward
  const videoProcessingIsPanorama = match.videoProcessing === 'panorama' || match.videoProcessing === 'quarterBoxPanorama'
  const src = match && ((videoTypeIsPanorama || videoProcessingIsPanorama) || match.userStreamAdaptive) ? match.userStreamAdaptive : match.userStream
  return (
    <div onClick={() => {
      if (linkToDetails) {
        history.push(`/matches/${match.RowKey}/`)
      }
    }} key={match.RowKey} className='matchCard col-xs-12'>
      {showPreview && unsuportedBrowser &&
      <div className='VideoPreviewContainer'>
        <div className='useOtherBrowserNotice row'>
          <p className='navItemBack col-xs-2' onClick={() => {
            if (window.GlobalCoachingTool) {
              window.GlobalCoachingTool.goBack()
            }
          }}>
            <span className='navIcon mbsc-ic mbsc-ic-fa-chevron-left' />
           Zurück
          </p>
          <h3 className='col-xs-10'>
               Der Coachingtool Videoeditor ist in ihrem aktuellen Browser leider nicht lauffähig.
            { browserNotice }
          </h3>
        </div>
      </div>
      }
      {showPreview && !unsuportedBrowser
        ? (match && <div className='VideoPreviewContainer'>
          <VideoCanvas
            disablePrecissionSeek
            controls
            // publishPaperScope={this.recievePaperScope}
            playPauseOn={VideoCanvas.playPauseOnOptions.spaceButtonPress}
            // dragCanvasKey={this.getTouchBehaviour()}
            src={src}
            // forceHLS
            disableSkipButtons
            keyboardShortcuts={{}}
            disableDefaultShortcuts
            evaluatePlugins={deliverPlugins}
            activateTouchControls
            // FullScreenTarget={this.FullScreenTarget}
            // tryForceFullscreen={this.tryForceFullscreen}
            // {...authData}
          ><Link style={{ zIndex: 20 }} className='previewGameLink' to='./edit/' ><span className='btn info previewGameLinkLabel'>{i18n.t('matchItem.watch')}</span></Link></VideoCanvas>
        </div>)
        : null}
      {props.showClubIcons &&
      <div className='clubIcons row'>
        <div className='clubLogoContainer col-xs clubA' ><img src={getLogoSrc(match.clubAId)} className='image clubLogo clubALogo' alt='Club A Logo' /></div>
        <div className='clubLogoContainer col-xs clubB' ><img src={getLogoSrc(match.clubBId)} className='image clubLogo clubBLogo' alt='Club B logo' /></div>
      </div>
      }
      {props.isHighlightAdmin &&
      <div>
        <Link to='./playerPositions/' className='btn info'>Spielerpositionen Erfassen</Link>
      </div>
      }
      {(!sequencesLoaded)
        ? <VortexSpinner />
        : <Animate
          play={sequencesLoaded}// set play true to start the animation
          duration={0.4} // how long is the animation duration
          start={{ opacity: 0, display: 'flex', flex: '1 1 auto', flexWrap: 'wrap', borderRadius: '0.25em', overflow: 'hidden' }}
          end={{ opacity: 1, display: 'flex', flex: '1 1 auto', flexWrap: 'wrap', borderRadius: '0.25em', overflow: 'hidden' }}
          easeType='cubic-bezier(0.445, 0.05, 0.55, 0.95)'
        >
          <div className='innerData'>
            {showSequenceCount &&
              <small className='Label'>{sequenceLabel}&nbsp;{i18n.t('matchItem.from')}&nbsp;{match.formatedDate}&nbsp;{i18n.t('matchItem.at')}&nbsp;{match.formatedTime}</small>
            }
            <h5 className='Club'>
              {match.clubAName}&nbsp;{<span className='clubScores'>{match.scoreTeamA}&nbsp;:&nbsp;{match.scoreTeamB}</span>}&nbsp;{match.clubBName}
            </h5>
            <small className='League'>{match.ageClass ? match.ageClass + ' |' : ''} {match.baseLeague}</small>
          </div>
          {linkToDetails &&
            <div className='pageChanger'>
              <Link className='btn_fluid boyzone' to={`/${match.RowKey}/`}>
                <span className='mbsc-ic mbsc-ic-ion-ios7-arrow-forward' />
              </Link>
            </div>
          }
          {Boolean(props.children) &&
            <div className='childs'>
              {props.children}
              {showSequences &&
                <>
                  {sequencesOpen &&
                    <SequenceList
                      updateSequence={props.updateSequence}
                      sequences={_sequences}
                      clipActions={props.clipActions}
                      createSequence={props.createSequence}
                      deleteSequence={props.deleteSequence}
                      clipSequence={props.clipSequence}
                      tagList={match.sequenceTagsFiltered || match.sequenceTags}
                      showTags={showTags}
                      history={props.history}
                      // tagToSequence={props.tagToSequence}
                      knownSequenceLabel={props.knownSequenceLabel}
                      editSequence={props.editSequence}
                      showAnnotations={props.showAnnotations}
                      showLabel={props.showLabel}
                      onSequenceClick={seek || props.onSequenceClick}
                      onMinuteClick={seek || props.onMinuteClick}
                      onAnnotationClick={seekAnnotation}
                      useColor={props.useColor}
                      afterSeek={props.afterSeek}
                      needsConfirm={props.needsConfirm}
                      updateAnnotation={props.updateAnnotation}
                      disableTagCreate={props.disableTagCreate}
                      disableLabelShowMore={props.disableLabelShowMore}
                      filterAnnotations={props.filterAnnotations}
                    />
                  }
                </>
              }
            </div>
          }
        </Animate>
      }
    </div>
  )
}
