import React from 'react'
import PropTypes from 'prop-types'
import ProtoTool from './prototool'
import './PlayerPositionTool.scss'

const wasClickInsideOfVideo = (evt) => evt.videoCanvasEvent && evt.videoCanvasEvent.positionInVideo &&
  (evt.videoCanvasEvent.positionInVideo.x !== 0 || evt.videoCanvasEvent.positionInVideo.y !== 0)

/*
TODO
- route: matches/id/playerPositions
 */
class PlayerPositionTool extends React.Component {
  static toolName = `PlayerPosition`
  static propTypes = {
    ...ProtoTool.propTypes,
    dashArray: PropTypes.arrayOf(PropTypes.number.isRequired),
    onVideoPositionChange: PropTypes.func,
  }

  min
  max
  minVideoPoint
  maxVideoPoint

  onMouseDown = (evt, path) => {
// Ignore event if the click was outside of the video
   if (wasClickInsideOfVideo(evt)) {
    this.min = evt.localPoint
    this.minVideoPoint = evt.videoCanvasEvent.positionInVideo
    this.maxVideoPoint = evt.videoCanvasEvent.positionInVideo
    this.props.onVideoPositionChange({ min: this.minVideoPoint, max: this.maxVideoPoint })
  
    path = new this.props.Paper.Path.Rectangle(this.min, this.min)
      if (this.props.dashArray) {
        path.style.dashArray = this.props.dashArray
      }
    }

    return { path }
  }

  onMouseDrag = (evt, path) => {
    // There must have been a mouseDown event on the video before a mouseDrag is processed!
    if (wasClickInsideOfVideo(evt) && this.min) {
      this.max = evt.localPoint

      this.maxVideoPoint = evt.videoCanvasEvent.positionInVideo
      this.props.onVideoPositionChange({ min: this.minVideoPoint, max: this.maxVideoPoint })

      const Point = this.props.Paper.Point
      path.segments[0].point.set(this.max)
      path.segments[1].point.set(new Point(this.max.x, this.min.y))
      path.segments[2].point.set(this.min)
      path.segments[3].point.set(new Point(this.min.x, this.max.y))
    }
    
    return { path }
  }

  /**
   * @param {paper.MouseEvent} evt
   * @param {paper.Path} path
   */
  onMouseUp = (evt, path) => {
    path.remove()

    this.min = undefined
    this.max = undefined
    this.minVideoPoint = undefined
    this.maxVideoPoint = undefined

    return { path }
  }

  render() {
    return (
      <ProtoTool
        key={PlayerPositionTool.toolName}
        toolName={PlayerPositionTool.toolName}
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        dontCleanOnChange
        {...this.props}
      />
    )
  }
}

export default PlayerPositionTool
