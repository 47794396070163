import React from 'react'
import { Link } from 'react-router-dom'
import i18n from 'i18next'

const pathMap = {
  matches: { idOptional: true, labelSingle: 'Spiel', labelId: 'Spiel Details', editRef: 'Spiel' },
  clips: { idOptional: true, labelSingle: 'Clips', labelId: 'Clip Details', editRef: 'Clip' },
  sequence: { idOptional: false, labelId: 'Szene Editieren' },
  user: { idOptional: true, labelSingle: 'Benutzer Verwaltung', noId: true },
  subscription: { idOptional: false, labelId: 'Subscription' },
  newSubscription: { idOptional: true, labelSingle: 'New Subscription', PathName: 'newSubscription' },
  edit: { isEditor: true },
  playerPositions: { labelSingle: 'Spielerpositionen erfassen', PathName: 'playerPositions', idOptional: true },
  404: { }
}
const UNKNOWN = { labelSingle: '???', idOptional: true, editRef: '???', labelId: '???', unknown: true }

function getPathItems (path) {
  let lastBackLink = '/'
  let currentPathLink = '/'
  let lastEditRef = ''
  let currentSite = ''
  let lastSite = 'Dashboard'
  const pathName = (path.split('/').filter((n) => n && n !== ''))[0]
  const parts = path.split('/').filter(p => p !== '')
  const PathElements = parts.map((part, i) => {
    const conf = pathMap[part] || { isId: true }
    const usePath = conf.PathName || pathName
    if (conf.editRef) { lastEditRef = i18n.t(`pathMap.${usePath}.editRef`) }
    currentPathLink += part + '/'
    let label = conf.isEditor ? i18n.t('pathMap.editor', { ref: lastEditRef }) : i18n.t(`pathMap.${usePath}.single`) // conf.labelSingle
    const link = currentPathLink
    if (conf.isId) {
      const basePath = parts[i - 1]
      const baseConf = pathMap[parts[i - 1]] || UNKNOWN
      const basePathName = (basePath.split('/').filter((n) => n && n !== ''))[0]
      label = basePath && !baseConf.unknown ? i18n.t(`pathMap.${basePathName}.id`) : baseConf.labelId
    } else if (!conf.isEditor && !conf.idOptional) {
      return null
    }
    if (i !== parts.length - 1) {
      lastBackLink = currentPathLink
    }
    if (currentSite !== '') {
      lastSite = currentSite
    }
    currentSite = label
    return <Link key={part} to={link}><h3 className='label'>{label}</h3></Link>
  })
  return { PathElements, lastBackLink, lastSite, currentSite }
}

const globaLGoBack = (link, history) => () => {
  history.push(link)
}

export default function PathMap (props) {
  const { history } = props
  if (!history || !history.location) {
    return null
  }
  const path = history.location.pathname
  const { lastBackLink, lastSite, currentSite } = getPathItems(path)
  if (window.GlobalCoachingTool) {
    window.GlobalCoachingTool.goBack = globaLGoBack(lastBackLink, history)
    // console.log(lastBackLink)
  }
  return lastSite
    ? (
      <li>
        {props.history && path !== '/' &&
        <div style={{ marginBottom: '0', marginTop: '0' }} className='navItemBack' onClick={() => { history.push(lastBackLink) }}>
          <span className='navIcon mbsc-ic mbsc-ic-fa-chevron-left' />
          <span>{lastSite} </span>
          <span> / </span>
          <span>{currentSite}</span>

        </div>}

      </li>
    )
    : null
}
