import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class Hand extends Component {
  onMouseDown = (evt, path) => {

  }

  onMouseDrag = (evt, path) => {

  }

  onMouseUp = (evt, path) => {

  }

  render () {
    return (
      <ProtoTool
        key='Hand'
        toolName='Hand'
        overwriteMouseDown={this.onMouseDown}
        overwriteMouseDrag={this.onMouseDrag}
        overwriteMouseUp={this.onMouseUp}
        {...this.props}
        ignoreToolEvents
      >
        <span className='mbsc-ic mbsc-ic-fa-hand-paper-o' />
      </ProtoTool>
    )
  }
}

export default Hand
