import React from 'react'
import PropTypes from 'prop-types'
import { DisplayLengthAboveLine } from './DisplayLengthAboveLine'
import paper from 'paper'

export class ToolWithLineLength extends React.Component {
  static propTypes = {
    showLineLength: PropTypes.bool,
    useVr: PropTypes.bool,
    metersPerPixel: PropTypes.number,
  }

  static defaultProps = {
    showLineLength: false,
  }

  /**
   * @protected
   * @type {Array<DisplayLengthAboveLine>}
   */
  _lineLengthIndicators = []

  componentDidUpdate = (oldProps) => {
    if (this.props.useVrPlayer !== oldProps.useVrPlayer || this.props.showLineLength !== oldProps.showLineLength) {
      this._lineLengthIndicators.forEach(indicator => indicator.setVisibility(this.props.useVrPlayer && this.props.showLineLength))
    }

    if (this.props.metersPerPixel !== oldProps.metersPerPixel) {
      this._lineLengthIndicators.forEach(indicator => indicator.metersPerPixel = this.props.metersPerPixel)
    }
  }

  /**
   * @protected
   */
  clearIndicators = () => {
    this._lineLengthIndicators.forEach(indicator => indicator.clear())
  }

  /**
   * @protected
   */
  getCurrentIndicator = () => this._lineLengthIndicators[this._lineLengthIndicators.length - 1]

  /**
   * @protected
   * @param {paper.Point} point
   * @param {paper.Color} color
   */
  generateLineLengthIndicator = (point, color) => {
    const paperPoint = point instanceof paper.Point ? point : new paper.Point(point.x, point.y)

    const indicator = new DisplayLengthAboveLine(
      this.props.metersPerPixel,
      this.props.Paper,
      paperPoint,
      color,
      this.props.useVrPlayer && this.props.showLineLength
    )

    this._lineLengthIndicators.push(indicator)

    return indicator
  }

  /**
   * @protected
   */
  generateNameSuffix = () => this.props.showLineLength ? 'withLineLength' : 'widhoutLineLength'
}
