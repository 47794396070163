import React from 'react'
import i18n from 'i18next'
import copy from 'copy-to-clipboard'
import { Link } from 'react-router-dom'
import mobiscroll from '@mobiscroll/react'
import * as VC from '@soccerwatch/videocanvas'
import SequenceList from '../../utility/sequences/sequenceList'
import BigPlayButton from '../../plugins/BigPlayButton/bigPlayButton'
import * as DeviceCatigorisation from '../../../lib/helper/deviceCatigorisation'
import { getAuthorization } from '../../firebase'
// import VortexSpinner from '../spinner/vortex'

const VideoCanvas = VC.Video

function formateDateTime (timeString) {
  const timeObj = new Date(timeString)
  const date = `${timeObj.getDate()}.${('0' + (timeObj.getMonth() + 1)).slice(-2)}.${timeObj.getFullYear()}`
  const time = `${timeObj.toISOString().split('T')[1].split(':').splice(0, 2).join(':')} Uhr`
  return { date, time }
}

const showConfirm = (callback) => () => {
  return mobiscroll.confirm({
    title: 'Sind sie Sicher?',
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    callback
  })
}

const prompt = (callback, value = '') => () => {
  return mobiscroll.prompt({
    placeholder: 'Clip Umbenennen',
    inputType: 'text',
    value,
    callback
  })
}
function deliverPlugins (videoParent, videoState, video, paper, drawingLayer) {
  if (!video) {
    return null
  }
  return <BigPlayButton show={!videoParent.props.videoController.isPlaying} play={videoParent.playVideo} pause={videoParent.pauseVideo} />
}

const isIOS = DeviceCatigorisation.isIOS()
const isIPadOs = DeviceCatigorisation.isIPadOs()
const appleMobile = DeviceCatigorisation.isAppleMobile()
const isFirefoxAndroid = DeviceCatigorisation.isFirefox() && DeviceCatigorisation.isAndroid()
const unsuportedBrowser = ((!DeviceCatigorisation.isMobile() && !DeviceCatigorisation.isIOS() && DeviceCatigorisation.isSafari()) || isFirefoxAndroid)
const browserNotice = DeviceCatigorisation.isMobile() ? 'Bitte verwenden sie die aktuelle Version des Chrome Browsers' : 'Bitte verwenden sie die aktuelle Version von Chrome oder Firefox.'
export default function ClipDetails (props) {
  const { clip, index, deleteClip, publishClip, showEditorLink,
    //  isList, editable,
    showSequences, seek, seekAnnotation, editClip, disableControls, getIOSClipPath } = props
  const [iosSrc, setIosSrc] = React.useState('')

  React.useEffect(() => {
    if (showEditorLink && appleMobile) {
      const setIosSrcAsync = async () => {
        const headers = await getAuthorization()
        const src = getIOSClipPath(clip.RowKey, headers, false)
        setIosSrc(src)
      }
      setIosSrcAsync()
    }
  }, [showEditorLink, clip.RowKey, getIOSClipPath, getAuthorization])

  const _deleteClip = (clip, index) => async (confirmed) => {
    if (confirmed && (index || index === 0)) {
      deleteClip(clip, index)
    }
  }
  const _publishClip = (clip) => () => {
    publishClip && publishClip(clip)
  }

  const changeClipName = async (clipName) => {
    if (!clipName || clipName === '' || clipName === clip.name) {
      return
    }
    const clipUpdate = { ...clip, name: clipName }
    if (props.updateClip) {
      await props.updateClip(clipUpdate)
    }
  }
  const date = formateDateTime(props.clip.created)

  const copyToClipBoard = (link, clip) => (e) => {
    e.stopPropagation()
    copy(window.location.href + link)
    mobiscroll.toast({
      message: i18n.t('clipDetails.urlInClipboard'),
      color: 'success'
    })
    const clubSequence = clip.sequence.reduce((string, seq, index) => {
      const slash = index === 0 ? '' : ' - '
      return string + slash + seq.clubAId + ' - ' + seq.clubBId
    }, '')
    window.gtag && window.gtag('event', 'clip shared', {
      env: String(process.env.REACT_APP_Environment),
      clubSequence,
      event_category: 'clip',
      event_label: 'User Copied a ClipLink to Clipboard'
      // 'value': ''
    })
  }
  const isPublicClass = clip.public ? 'remove' : 'add'
  const isPublicContent = clip.public
    ? <span className='mbsc-ic mbsc-ic-foundation-unlink'> {i18n.t('clipDetails.unPublish')}</span>
    : <span className='mbsc-ic mbsc-ic-material-insert-link'> {i18n.t('clipDetails.publish')}</span>
  const renameFunction = props.updateClip ? prompt(changeClipName, clip.name) : () => {}
  const sequence = clip.sequence.map((sequence) => {
    if (!sequence.annotations) {
      return sequence
    }
    sequence.annotations = sequence.annotations.map((anno) => {
      anno.relativeTimestamp = sequence.relativeTimestamp + (anno.timestamp - sequence.timestamp)
      return anno
    })
    return sequence
  })
  const getAuthorizationString = () => {
    return props.authHeader
  }
  const authData = {
    needAuthorization: true,
    getAuthorization: getAuthorizationString
  }
  const src = isIOS || isIPadOs ? iosSrc : clip.clipStream
  return (
    <>
      <div className='clipCard col-xs' key={clip.RowKey}>
        {unsuportedBrowser &&
        <div className='VideoPreviewContainer'>
          <div className='useOtherBrowserNotice row'>
            <p className='navItemBack col-xs-2' onClick={() => {
              if (window.GlobalCoachingTool) {
                window.GlobalCoachingTool.goBack()
              }
            }}>
              <span className='navIcon mbsc-ic mbsc-ic-fa-chevron-left' />
           Zurück
            </p>
            <h3 className='col-xs-10'>
               Der Coachingtool Videoeditor ist in ihrem aktuellen Browser leider nicht lauffähig.
              { browserNotice }
            </h3>
          </div>
        </div>
        }
        {showEditorLink && !unsuportedBrowser ? (<div className=''>
          {props.authHeader && 
          <div className='VideoPreviewContainer'>
            <VideoCanvas
              disablePrecissionSeek
              controls
              // publishPaperScope={this.recievePaperScope}
              playPauseOn={VideoCanvas.playPauseOnOptions.spaceButtonPress}
              // dragCanvasKey={this.getTouchBehaviour()}
              src={src}
              evaluatePlugins={deliverPlugins}
              disableSkipButtons
              keyboardShortcuts={{}}
              disableDefaultShortcuts
              // evaluatePlugins={this.deliverPlugins}
              activateTouchControls={false}
              // FullScreenTarget={this.FullScreenTarget}
              {...authData}
            ><Link style={{ zIndex: 20 }} className='previewGameLink' to='./edit/' ><span className='btn info previewGameLinkLabel'>{i18n.t('clipDetails.watch')}</span></Link></VideoCanvas>
          </div>
          }
        </div>)
          : null}
        <div className='innerData'>
          <small className='Label'>{clip.sequenceIds.length} {i18n.t('clipDetails.sceneCount', { date: date.date })}</small>
          <h5 onClick={renameFunction} className='clipName'>{clip.name}{props.updateClip && <span className='mbsc-ic mbsc-ic-material-edit' />}</h5>
          {!disableControls && <div className='buttonArea'>
            <small>
              <span className={`${isPublicClass}Btn`} onClick={_publishClip(clip)} >
                {isPublicContent}
              </span>
              {clip.public ? <span className='infoBtn' onClick={copyToClipBoard(`public/`, clip)}><span className='mbsc-ic mbsc-ic-share' /> {i18n.t('clipDetails.copyLink')}</span> : null}
              {props.isHighlightAdmin && clip.public && <a rel='noopener noreferrer' onClick={(e) => { e.stopPropagation() }} href={`https://europe-west1-sw-sc-de-shared.cloudfunctions.net/convert/vclip/${clip.RowKey}/`} target='_blank' className='infoBtn'><span className='mbsc-ic mbsc-ic-fa-download' /> Download</a>}
              {showEditorLink && <Link className='infoBtn' to='./edit/' onClick={(e) => { e.stopPropagation() }} ><span className='mbsc-ic mbsc-ic-fa-video-camera' /> {i18n.t('clipDetails.watchShort')}</Link>}
              <Link className='editBtn' onClick={editClip || (() => { })} to={editClip ? '#' : `/clips/${clip.RowKey}/edit/`}><span className='mbsc-ic mbsc-ic-material-edit' /> {i18n.t('clipDetails.edit')}</Link>
              {!!deleteClip && <span className='removeBtn' onClick={showConfirm(_deleteClip(clip, index))} ><span className='mbsc-ic mbsc-ic-material-delete' /> {i18n.t('clipDetails.delete')}</span>}
            </small>
          </div>
          }
        </div>
        <div className='childs'>
          {showSequences &&
            <SequenceList
              showClubIcons={props.showClubIcons}
              sequences={sequence}
              clipActions={props.clipActions}
              // deleteSequence={props.deleteSequence}
              // needsConfirm={props.needsConfirm}
              clipSequence={props.clipSequence}
              // editSequence={props.editSequence}
              updateSequence={props.updateSequence}
              showAnnotations={props.showAnnotations}
              showLabel={props.showLabel}
              disableRename={props.disableRename}
              onSequenceClick={seek}
              onMinuteClick={props.onMinuteClick}
              onAnnotationClick={props.onAnnotationClick || seekAnnotation}
              afterSeek={props.afterSeek}
              showFullAnnotationTime
              useRelativeTimestamp
              disableAutoSort
              useColor={props.useColor}
              history={props.history}
              editSequence={props.editSequence}
              disableLabelShowMore={props.disableLabelShowMore}
              updateAnnotation={props.updateAnnotation}
              showClubIconsInBaseLabel
              knownSequenceLabel={props.knownSequenceLabel}
              filterAnnotations={props.filterAnnotations}
            />}
          {
            props.children
          }
        </div>
      </div>
    </>
  )
}
