import React, { useState } from 'react'
import materialColor from 'material-color-hash'
import mobiscroll from '@mobiscroll/react'
import { Link } from 'react-router-dom'
import LabelList from './labelList'
import AnnotationList from '../annotations/annotationList'
import { sortableElement, sortableHandle } from 'react-sortable-hoc'
import Collapsible from 'react-collapsible'
import i18n from 'i18next'
import './sequenceItem.scss'

// const DragHandle = sortableHandle(({ number, onClick, name }) => <div className='draggableHeader'><span onClick={onClick} className='sequenceName'>{name}</span></div>)
const DragHandleNumber = sortableHandle(({ number, onClick, style, hasAnnotationClass }) => {
  return <span style={style} className={`minuteLabel draggableHeader ${hasAnnotationClass}`}>
    <span onClick={onClick} className='btn boyzone drag'>{number}</span>
  </span>
})
const SortableElement = sortableElement(({ children }) => (
  <div>{children}</div>
))

const showConfirm = (callback) => () => {
  return mobiscroll.confirm({
    title: i18n.t('sequenceItem.deleteHead'),
    message: i18n.t('sequenceItem.deleteMsg'),
    okText: i18n.t('sequenceItem.deleteOk'),
    cancelText: i18n.t('sequenceItem.deleteCancle'),
    callback
  })
}

const prompt = (callback, value = '') => (e) => {
  e.stopPropagation()
  return mobiscroll.prompt({
    placeholder: i18n.t('sequenceItem.rename'),
    inputType: 'text',
    value,
    callback
  })
}

function getLogoSrc (id) {
  if (!id || id === undefined || id === '') {
    return 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
  }
  return `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${id}.png`
}

export default function SequenceItem (props) {
  const {
    sequence, clipActions, clipIndex, editSequence,
    deleteSequence, needsConfirm, updateSequence,
    sortable, index,
    //  disableRename,
    disableButtonLabel } = props
  const buttons = []
  const ensureSequence = async (_sequence) => {
    let seq = _sequence
    if (!seq.RowKey) {
      seq = await props.createSequence(seq)
    }
    if (!seq) {
      console.error('<SequenceItem:EnsureSequence> Something went wrong')
      return
    }
    return seq
  }

  const onEdit = async (e) => {
    e.stopPropagation()
    const _sequence = await ensureSequence(props.sequence)
    if (!_sequence) { return }
    if (typeof editSequence !== 'function') {
      props.history.push(`./sequence/${_sequence.RowKey}/`)
      return
    }
    editSequence(_sequence)
  }

  if (editSequence) {
    let button = null
    // if (typeof editSequence === 'boolean') {
    button = <span
      className='actionlistBtn'
      key='SeqEditBtn'
      onClick={onEdit}>
      <span className='mbsc-ic mbsc-ic-fa-edit'>
        <span className='edit' />
        {!disableButtonLabel && (' ' + i18n.t('sequenceItem.edit'))}
      </span>
    </span>
    // } else {
    // button = <span className='btn warning' key='SeqEditBtn' onClick={props.editSequence(sequence)}><span className='mbsc-ic mbsc-ic-fa-edit'><span className='edit' /></span></span>
    // }
    buttons.push(button)
  }
  if (clipActions) {
    const button = clipIndex || clipIndex === 0
      ? <span className='removeBtn' key='SeqClipBtn'
        onClick={(e) => { e.stopPropagation(); clipActions.removeFromClip(clipIndex) }}>
        <span className={`mbsc-ic mbsc-ic-fa-${disableButtonLabel ? 'times' : 'minus'}`}>
          <span className='remove' />{!disableButtonLabel && (' ' + i18n.t('sequenceItem.removeFromClip'))}</span>
      </span>
      : <span className='actionlistBtn' key='SeqClipBtn'
        onClick={clipActions.addToClip(sequence)}>
        <span className='mbsc-ic mbsc-ic-fa-plus'>
          <span className='add' />{!disableButtonLabel && (' ' + i18n.t('sequenceItem.addToClip'))}</span>
      </span>
    buttons.push(button)
  }
  if (deleteSequence && sequence.originType !== 'Tag' && sequence.RowKey) {
    const deleteFn = needsConfirm ? showConfirm(deleteSequence(sequence)) : deleteSequence(sequence)
    const button = <span className='actionlistBtn' key='SeqDeleteBtn' onClick={deleteFn}>
      <span className='mbsc-ic mbsc-ic-fa-minus'>
        <span className='remove' />
        &nbsp;{i18n.t('sequenceItem.delete')}
      </span>
    </span>
    buttons.push(button)
  }
  const _updateAnnotation = async (annotation) => {
    const _sequence = await ensureSequence(props.sequence)
    if (!_sequence) { return }
    props.updateAnnotation(annotation, _sequence)
  }
  const updateAnnotation = props.updateAnnotation ? _updateAnnotation : undefined
  async function updateSequenceLabel (label) {
    const _sequence = await ensureSequence(props.sequence)
    if (!_sequence) { return }
    _sequence.label = label
    return updateSequence(_sequence)
  }
  const changeSequenceName = async (name) => {
    if (!name || name === '' || name === sequence.name) {
      return
    }
    const _sequence = await ensureSequence(props.sequence)
    if (!_sequence) { return }
    const oldName = sequence.name
    if (_sequence.label.includes(oldName)) {
      const nIndex = _sequence.label.findIndex((l) => l === oldName)
      if (nIndex > -1) {
        _sequence.label[nIndex] = name
      }
    } else if (!_sequence.label.includes(name)) {
      _sequence.label.push(name)
    }
    const updatedSequence = { ..._sequence, name }
    updateSequence(updatedSequence)
  }
  const handleElementClick = (e) => {
    e.stopPropagation()
    if (props.onClick) {
      props.onClick(sequence)
      if (props.afterSeek) {
        props.afterSeek()
      }
    }
  }
  
  const renameFunction = updateSequence ? prompt(changeSequenceName, sequence.name) : () => { }
  const sequenceColor = props.useColor ? materialColor((sequence.originData || {}).eventType + '') : undefined
  const matchColor = props.matchData ? materialColor(props.matchData.RowKey) : undefined
  const BGColor = '#03A9F4' || sequenceColor || matchColor || {}
  // const matchLinkColor = materialColor(sequence.matchId)
  let minuteLabel = sequence.gameTimeLabel ? `${sequence.gameTimeLabel}´` : `${Math.round(sequence.timestamp / 60)}´`
  if (props.useRelativeTimestamp) {
    const minute = Math.floor(sequence.relativeStartTime / 60) + ''
    const sec = Math.floor(sequence.relativeStartTime % 60) + ''
    minuteLabel = `${minute.length === 1 ? '0' + minute : minute}:${sec.length === 1 ? '0' + sec : sec}`
  }
  const useRelativeAnnotationTime = props.useRelativeTimestampForAnnotations || props.useRelativeTimestamp
  const [isCollapsed, setIsCollapsed] = useState(true)
  const deleteAnnotation = props.deleteAnnotation ? props.deleteAnnotation(sequence) : undefined
  const hasAnnotationClass = sequence.annotations && sequence.annotations.length ? 'annotated' : ''
  const buttonClass = disableButtonLabel ? 'noList' : 'list'
  const annotationCount = sequence.annotations ? props.filterAnnotations
    ? (sequence.annotations.filter((anno) => {
      if (!props.filterAnnotations) { return true }
      return anno.type === props.filterAnnotations
    })).length : sequence.annotations.length : 0
  const content = (
    <div className='sequenceItem' key={sequence.RowKey}>
      <div className='sequenceItemContent'>
        <div className='basicData' onClick={handleElementClick}>
          {sortable ? (
            <DragHandleNumber
              hasAnnotationClass={hasAnnotationClass}
              style={{ marginBottom: '0', marginTop: '0', borderLeft: `5px solid ${BGColor.backgroundColor}` }}
              number={minuteLabel}
              onClick={props.onClick ? props.onClick : () => { }}
            />)
            : (<p style={{ marginBottom: '0', marginTop: '0', borderLeft: `5px solid ${BGColor.backgroundColor}` }} className={`minuteLabel ${hasAnnotationClass}`}>
              <span onClick={props.onMinuteClick ? props.onMinuteClick : props.onClick ? props.onClick : () => { }} className='btn boyzone'>{minuteLabel}</span>
            </p>
            )}
          <div className={`sequenceData ${buttonClass}`} onClick={props.onClick ? () => { } : renameFunction}>
            {/* {sortable
              ? <DragHandle number={minuteLabel} onClick={props.onClick ? renameFunction : () => { }} name={sequence.name} />
              : <>
                <p style={{ marginBottom: '0.5em', marginTop: '0' }} onClick={props.onClick && !disableRename ? renameFunction : () => { }} className='sequenceName'>
                  {sequence.name}&nbsp;{props.onClick && !disableRename && <span className='mbsc-ic mbsc-ic-fa-edit' />}
                </p>
              </>
            } */}
            <LabelList
              knownSequenceLabel={props.knownSequenceLabel}
              onChange={updateSequenceLabel}
              editable={!!updateSequence}
              baseLabel={sequence.name}
              label={sequence.label}
              updateBaseLabel={changeSequenceName}
              showClubIconsInBaseLabel={props.showClubIconsInBaseLabel}
              clubAId={sequence.clubAId}
              clubBId={sequence.clubBId}
              disableShowMore={props.disableLabelShowMore}
              sequenceFromTag={sequence.originType === 'Tag'}
              disableTagCreate={props.disableTagCreate}
            />
            <div className={`callToAction ${buttonClass}`}>
              <small>{buttons}</small>
            </div>
          </div>
        </div>
      </div>
      <small>
        {props.showClubIcons &&
          <div className='clubIcons row'>
            <div className='clubLogoContainer col-xs clubA' ><img src={getLogoSrc(sequence.clubAId)} className='image clubLogo clubALogo' alt='Club A Logo' /></div>
            <div className='clubLogoContainer col-xs clubB' ><img src={getLogoSrc(sequence.clubBId)} className='image clubLogo clubBLogo' alt='Club B logo' /></div>
          </div>
        }
        {props.showMatchLink &&
        <Collapsible triggerClassName='collapsible_area' trigger={<>{i18n.t('sequenceItem.gameInfo')} {isCollapsed ? <span className='mbsc-ic mbsc-ic-fa-chevron-down' /> : <span className='mbsc-ic mbsc-ic-fa-chevron-up' />}</>} onOpen={() => setIsCollapsed(false)} onClose={() => setIsCollapsed(true)} transitionTime={600} easing='cubic-bezier(0.68, -0.55, 0.265, 1.55)'>
          { props.matchData &&
            <div style={{ marginBottom: '1em' }} className='matchData'>
              <small className='Label'>{i18n.t('matchItem.from')}&nbsp;{props.matchData.formatedDate}&nbsp;{i18n.t('matchItem.at')}&nbsp;{props.matchData.formatedTime}</small>
              <h5 className='Club'>
                {props.matchData.clubAName}&nbsp;{<span className='clubScores'>{props.matchData.scoreTeamA}&nbsp;:&nbsp;{props.matchData.scoreTeamB}</span>}&nbsp;{props.matchData.clubBName}
              </h5>
              <small className='League'>{props.matchData.ageClass ? props.matchData.ageClass + ' |' : ''} {props.matchData.baseLeague}</small>
            </div>
          }
          <Link className='btn_fluid info' to={`/matches/${sequence.matchId}/edit`}>Zum Spiel <span className='mbsc-ic mbsc-ic-material-chevron-right' /></Link>
        </Collapsible>
        }
        {(props.showAnnotations && sequence.annotations && sequence.annotations.length)
          ? <Collapsible triggerClassName='collapsible_area' trigger={<>{annotationCount} {i18n.t('sequenceItem.annotations')} {isCollapsed ? <span className='mbsc-ic mbsc-ic-fa-chevron-down' /> : <span className='mbsc-ic mbsc-ic-fa-chevron-up' />}</>} onOpen={() => setIsCollapsed(false)} onClose={() => setIsCollapsed(true)} transitionTime={600} easing='cubic-bezier(0.68, -0.55, 0.265, 1.55)'>
            {props.showAnnotations && sequence.annotations &&
            <AnnotationList
              disableRename={props.disableRename}
              useColor={props.useAnnotationColor}
              useRelativeTime={useRelativeAnnotationTime}
              showFullTime={props.showFullAnnotationTime}
              additionalClassName='annotation'
              annotations={sequence.annotations}
              updateAnnotation={updateAnnotation}
              onClick={props.onAnnotationClick}
              afterSeek={props.afterSeek}
              deleteAnnotation={deleteAnnotation}
              filterAnnotations={props.filterAnnotations}
            />

            }
          </Collapsible>
          : null
        }
      </small>
    </div>
  )

  return sortable ? <SortableElement index={index}>{content}</SortableElement> : content
}
