
import React from 'react'
import i18n from 'i18next'
// import './I18NButtons.scss'

export const I18NButtons = (props) => {
  let changeLanguage = () => { }
  if (window.GlobalCoachingTool && window.GlobalCoachingTool.changeLanguage) {
    changeLanguage = window.GlobalCoachingTool.changeLanguage
  }
  if (props.changeLanguage) {
    changeLanguage = props.changeLanguage
  }

  return (
    <div id='Languages' className={`${props.absolute ? 'absolute' : ''}`}>
      {/* <div className='col-xs-7'>
        {props.children}
      </div> */}
      {/* <div className='col-xs'> */}
      <button className={`btn ${i18n.language === 'de' ? 'active info' : ''}`} onClick={changeLanguage('de')}>DE</button>
      <button className={`btn ${i18n.language === 'en' ? 'active info' : ''}`} onClick={changeLanguage('en')}>EN</button>
      <button className={`btn ${i18n.language === 'fr' ? 'active info' : ''}`} onClick={changeLanguage('fr')}>FR</button>
      {/* </div> */}
    </div>
  )
}

export default I18NButtons
