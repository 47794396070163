import { createAdaptivePointText } from './AdaptiveText'

export class DisplayLengthAboveLine {
  /**
   * @type {number}
   * @private
   */
  _metersPerPixel

  /**
   * @private
   * @type {paper.PointText}
   */
  _text

  /**
   * @private
   * @type {paper.PaperScope}
   */
  _paper

  /**
   * @private
   * @type {number}
   */
  _distance

  /**
   * @private
   * @type {Array<paper.Point>}
   */
  _points

  /**
   * @param {number} metersPerPixel How many meters are in a pixel. Should be well below 1, but above 0
   * @param {paper.PaperScope} paper
   * @param {paper.Point} point
   * @param {unknown} color
   * @param {boolean} shouldBeVisible
   */
  constructor(metersPerPixel, paper, point, color, shouldBeVisible = false) {
    this._metersPerPixel = metersPerPixel
    this._paper = paper
    this._text = createAdaptivePointText(paper, point)
    this._text.fillColor = color
    this._text.fontSize = 25
    this._text.visible = shouldBeVisible
  }

  /**
   * @private
   * @param {number} n 
   * @param {number} digits 
   */
  roundToNDigits = (n, digits = 1) => Math.round(n * (10 ** digits)) / (10 ** digits)

  /**
   * @private
   * @param {paper.Point} vector 
   */
  lengthOfLine(vector) {
    return vector
      .abs()
      .multiply(this.metersPerPixel)
      .length
  }

  getCounterRotationForPerspectiveRotationalEffect = (targetPosition) => {
    const viewSize = this._paper.project.view.viewSize
    const bottomCenter = new this._paper.Point(viewSize.width / 2, viewSize.height)
    return -bottomCenter.subtract(targetPosition).rotate(-90).angle
  }

  /**
   * @param {paper.Point} pointA 
   * @param {paper.Point} pointB 
   * @param {number?} distance
   */
  setPoints = (pointA, pointB, distance) => {
    this._points = [pointA, pointB]
    const vector = pointB.subtract(pointA)
    const middleOfLine = pointA.add(vector.divide(2))
    this.distance = distance ? distance * this.metersPerPixel : this.lengthOfLine(vector)

    /*
     * angle values:
     * 
     *      0 - right
     *   - 90 - top
     * +- 180 - left
     *     90 - bottom
     */
    let angle = vector.angle

    if (Math.abs(angle + this.getCounterRotationForPerspectiveRotationalEffect(middleOfLine)) > 90) {
      angle += Math.sign(angle) * 180
    }

    this._text.rotation = angle

    this._text.setCenter(middleOfLine)
  }

  clear = () => {
    this._text.remove()
  }

  setVisibility = (shouldBeVisible) => {
    this._text.visible = shouldBeVisible
  }

  get metersPerPixel() {
    return this._metersPerPixel
  }

  set metersPerPixel(newMetersPerDistance) {
    this._metersPerPixel = newMetersPerDistance
    this.distance = this.lengthOfLine(this._points[1].subtract(this._points[0]))
  }

  get distance() {
    return this._distance
  }

  set distance(newDistance) {
    this._distance = this.roundToNDigits(Number.isNaN(newDistance) ? 0 : newDistance)

    this._text.content = `${this._distance} m`
  }
}