import React, { Component } from 'react'
import './knownPositionIntervals.scss'
import PropTypes from 'prop-types'
import PlayerPositionService from '../PositionService/PlayerPositionService'
import { AlternateProgressbar } from '../../intervalMarker/alternateProgressbar'

/**
 * @typedef {[number, number]} Interval
 * @typedef {Array<Interval>} Intervals
 * @typedef {{duration: number, onClick: Function, startTime: number}} AlternateProgressbarInterval
 */

/**
 * Computes overlapping intervals of two intervals
 * @param {Intervals} intervalsA
 * @param {Intervals} intervalsB
 * @returns {Intervals}
 */
const getOverlaps = (intervalsA, intervalsB) => {
  let indexA = 0, indexB = 0
  const result = []

  while (indexA < intervalsA.length && indexB < intervalsB.length) {
    const intervalA = intervalsA[indexA]
    const intervalB = intervalsB[indexB]

    if (intervalA[1] < intervalB[0]) {
      // A ends before B --> no overlap; increment indexA
      indexA++
    } else if (intervalB[1] < intervalA[0]) {
      // B ends before A --> no overlap; increment indexA
      indexB++
    } else {
      // Intervals overlap --> Add overlapping interval and increment index of interval that ends earlier
      result.push([
        Math.max(intervalA[0], intervalB[0]),
        Math.min(intervalA[1], intervalB[1]),
      ])

      if (intervalA[1] < intervalB[1]) {
        indexA++
      } else {
        indexB++
      }
    }
  }

  return result
}

class KnownPlayerPositionIntervals extends Component {
  static propTypes = {
    totalDuration: PropTypes.number.isRequired,
    playerPositionService: PropTypes.instanceOf(PlayerPositionService),
    currentPlayer: PropTypes.any,
    video: PropTypes.instanceOf(HTMLVideoElement).isRequired,
    gameTimeIntervals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired)).isRequired,
  }

  isUnmounted = false
  subscriptionName = 'knownPlayerPositionIntervals'

  state = {
    /**
     * @type {Array<AlternateProgressbarInterval>}
     */
    intervals: [],
  }

  componentDidMount() {
    if (this.props.playerPositionService) {
      this.subscribeToPlayerPositionServiceUpdates(this.props.playerPositionService)
    }
  }

  componentWillUnmount = () => {
    this.isUnmounted = true
  }

  subscribeToPlayerPositionServiceUpdates = (playerPositionService) => {
    if (playerPositionService) {
      playerPositionService.subscribeToChange(this.subscriptionName, this.computeIntervals)
    }
  }

  /**
   * @param {PlayerPositionService} playerPositionService
   */
  unsubscribeFromPlayerPositionServiceUpdates = (playerPositionService) => {
    if (playerPositionService) {
      playerPositionService.unsubscribeFromChange(this.subscriptionName)
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.playerPositionService !== this.props.playerPositionService) {
      this.unsubscribeFromPlayerPositionServiceUpdates(prevProps.playerPositionService)

      if (this.props.playerPositionService) {
        this.subscribeToPlayerPositionServiceUpdates(this.props.playerPositionService)
      }
    }

    if (prevProps.playerPositionService !== this.props.playerPositionService || prevProps.currentPlayer !== this.props.currentPlayer) {
      this.computeIntervals()
        .catch(console.error)
    }
  }

 /**
  * Creates maps an interval's start and end time to an object that AlternateProgressbar can interpret and use
  * @param {number} startTime
  * @param {number} endTime
  * @returns AlternateProgressbarInterval
  */

  createProgressbarIntervalFromStartAndEndTime = (startTime, endTime) => ({
    startTime: startTime,
    // Borders include first and last needed times, so for n needed timestamps
    // (from k to k + n - 1, because both are included) duration would be n - 1
    duration: endTime - startTime + 1,
    onClick: () => { this.props.video.currentTime = startTime },
  })

  computeIntervals = async () => {
    if (this.props.playerPositionService && this.props.currentPlayer) {
      try {
        const positions = await this.props.playerPositionService.getByPlayer(this.props.currentPlayer.teamId, this.props.currentPlayer.playerNumber)

        const startTimestamp = 0
        const endTimestamp = this.props.totalDuration

        const positionTimes = positions
          .map(position => position.Timestamp)

        // Add positions at edge of video to track missing positions all the way to the edge
        positionTimes.unshift(startTimestamp - 1)
        positionTimes.push(endTimestamp + 1)
        

        const requiredPositionBorders = []

        positionTimes.reduce((prevPosition, currPosition) => {
          if (prevPosition + 1 < currPosition) {
            // there was a gap
            requiredPositionBorders.push([prevPosition + 1, currPosition - 1])
          }

          return currPosition
        }, -1)

        const merged = getOverlaps(requiredPositionBorders, this.props.gameTimeIntervals)
        const intervals = this.createMissingPositionIntervalsFromBorders(merged)
        
        if (!this.isUnmounted) {
          this.setState({ intervals })
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

/**
 * Maps intervals to the data type required by AlternateProgressBar
 * @param {Intervals} borders
 * @returns {Array<AlternateProgressbarInterval>}
 */

  createMissingPositionIntervalsFromBorders = (borders) => {
    return borders.reduce((allIntervals, borders) => {
      allIntervals.push(this.createProgressbarIntervalFromStartAndEndTime(...borders))
      return allIntervals
    }, [])
  }

  render() {
    return <AlternateProgressbar
      intervals={this.state.intervals}
      totalDuration={this.props.totalDuration}
    />
  }
}

export default KnownPlayerPositionIntervals
