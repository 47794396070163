
import React, { useRef } from 'react'
import { Color } from '@mobiscroll/react'
import './colorButton.scss'
export default function ColorButton (props) {
  const { color, setColor } = props
  const colorElement = useRef(null)
  function selectColor () {
    if (!colorElement.current || !colorElement.current.instance) {
      console.error('Something went Wrong! No Color Element in ColorButton!')
      return
    }
    colorElement.current.instance.element.click()
  }
  const videoElement = document.querySelector('.video-player')
  return (
    <>
      <div className='Tool colorButton' style={{ backgroundColor: color }} onClick={selectColor} >
        <span className='mbsc-ic mbsc-ic-material-invert-colors' />
      </div>
      <div className='mobiElement'>
        <Color context={videoElement} ref={colorElement} cssClass='forceFixedPosition' value={color} onSet={setColor} onItemTap={setColor} display='center' mode='refine' />
      </div>
    </>
  )
}
