import React, { Component } from 'react'
import i18n from 'i18next'
import BG from '../../../img/background.png'
import Firebase from '../../firebase'
import I18NButtons from '../../utility/I18NButtons'
import VortexSpinner from '../../utility/spinner/vortex'
import { Form, Input } from '@mobiscroll/react'
import './login.scss'
class LoginSite extends Component {
  constructor (props) {
    super(props)
    this.state = {
      working: false,
      email: '',
      password: '',
      type: 'Login'
    }
  }
  
  changeType = (type) => {
    this.setState({ type })
  }

  changeProp = (prop) => (e) => {
      this.setState({ [prop]: e.currentTarget.value })
  }

  submitLogin = (e) => {
    e.preventDefault()
    this.props.login(this.state.email, this.state.password)
  }

  render () {
    const formStyle = this.state.working ? { visibility: 'hidden' } : {}
    const { email, password, working, type } = this.state
    const { changeType, changeProp, submitLogin } = this
    // console.log(i18n)
    return (
        <div className='loginContainer col-xs-12' style={{ backgroundImage: `url("${BG}")` }}>
          <div className='loginWindow row'>
            <aisw-user />
            <I18NButtons language={i18n.language}/>
           {/* 
           { working &&
            <div className='col-xs-12 center' >
              <VortexSpinner />
              <h4 className='loginText'>{i18n.t('login.loggingIn')}</h4>
            </div>
            }
            <div style={formStyle} className='form col-xs-12 col-sm-8 col-md-6 col-lg-4' >
              <Form>
                {type === 'Login' &&
                <>
                  <Input tabIndex='1' key='Mail' value={email} onChange={changeProp('email')}>Mail</Input>
                  <Input tabIndex='2' key='PW' value={password} type='password' onChange={changeProp('password')}>{i18n.t('login.password')}</Input>
                  <button tabIndex='3' onClick={submitLogin}>{i18n.t('login.login')}</button>
                </>
                }
              
              </Form>
            </div> */}
          </div>
        </div>
      )
  }
}

export default LoginSite
