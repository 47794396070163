import React, { Component } from 'react'
import currentlyGlobalPaper from 'paper'
import PropTypes from 'prop-types'

// const { Button, Radio } = mobiscroll
const allowPinch = false
class ProtoTool extends Component {
  static propTypes = {
    onActivateTouch: PropTypes.func,
    onDeactivateTouch: PropTypes.func,
    onChange: PropTypes.func,
    currentTool: PropTypes.string,
    Paper: PropTypes.object,
    drawingLayer: PropTypes.object,
    ignoreToolEvents: PropTypes.bool,
    activateTouch: PropTypes.func,
    dontCleanOnChange: PropTypes.bool,
    deactivateTouch: PropTypes.func,
    toolName: PropTypes.string,
    cleanUp: PropTypes.func,
    drawMode: PropTypes.bool,
    redrawing: PropTypes.bool,
    videoBuffering: PropTypes.bool,
    recording: PropTypes.bool,
    record: PropTypes.func,
    keepPath: PropTypes.bool,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseDrag: PropTypes.func,
    snap: PropTypes.number,
    color: PropTypes.oneOfType([PropTypes.instanceOf(currentlyGlobalPaper.Color), PropTypes.string]),
    stroke: PropTypes.number,
    updateWorkState: PropTypes.func,
    pathDone: PropTypes.func,
    onRedrawDone: PropTypes.func,
  }

  static defaultProps = {
    record: () => {},
  }

  /**
   * @type {currentlyGlobalPaper.Tool}
   */
  tool

  componentDidMount () {
    if (this.props.Paper) {
      this.setup()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.Paper && !prevProps.Paper) {
      this.setup()
    }

    // Active Tool changed
    if (prevProps.currentTool !== this.props.currentTool) {
      // Tool was Just Activated
      if (this.props.currentTool === this.toolName) {
        if (!this.props.ignoreToolEvents) {
          this.setUpTouchEvents()
        }
        if (this.props.activateTouch) {
          this.props.activateTouch()
        }
      } else if (prevProps.currentTool === this.toolName) {
        // Tool Was Just Deactivated
        if (!this.props.ignoreToolEvents) {
          this.removeTouchEvents()
        }

        if (typeof this.props.onChange === 'function') {
          this.props.onChange()
        }

        if (!this.props.dontCleanOnChange) {
          this.cleanUp(true)
        }
        if (this.props.deactivateTouch) {
          this.props.deactivateTouch()
        }
      }
    }
  }

  componentWillUnmount = () => {
    if (!this.props.ignoreToolEvents) {
      this.removeTouchEvents()
    }
    if (!this.props.dontCleanOnChange) {
      this.cleanUp()
    }

    if (this.tool) {
      this.tool.remove()
    }
  }

  setup = () => {
    let oldActiveScope, oldActiveLayer

    if (currentlyGlobalPaper !== this.props.Paper) {
      oldActiveScope = currentlyGlobalPaper
      oldActiveLayer = currentlyGlobalPaper.project ? currentlyGlobalPaper.project.activeLayer : { activate: () => {} }
      this.props.Paper.activate()
    }

    this.tool = new this.props.Paper.Tool()

    if (oldActiveLayer) {
      oldActiveScope.activate()
      oldActiveLayer.activate()
    }

    this.toolName = this.tool.name = this.props.toolName
    this.path = null
    // this.tool.minDistance = this.props.minDistance || 20
    this.tool.onMouseDown = this.props.overwriteMouseDown || this.onMouseDown
    this.tool.onMouseDrag = this.props.overwriteMouseDrag || this.onMouseDrag
    this.tool.onMouseUp = this.props.overwriteMouseUp || this.onMouseUp
    this.tool.on('touchstart', this.onTouchStart)
    this.tool.on('touchmove', this.onTouchMove)
    this.tool.on('touchend', this.onTouchEnd)
    this.tool.onKeyDown = this.props.overwriteKeyDown || this.onKeyDown
    this.tool.onKeyUp = this.props.overwriteKeyUp || this.onKeyUp
    this.tool.cleanUp = this.cleanUp || (() => {})
    if (this.props.currentTool === this.toolName) {
      this.tool.activate()
    }
  }

  cleanUp = (onChangeInternal = false) => {
    this.props.cleanUp && this.props.cleanUp(onChangeInternal)
    this.painting = false
    this.path = null
    this.resetTime = false
    // this.lastTime = 0
  }

  // Get the position of a touch relative to the canvas
  getTouchPos (canvasDom, touchEvent) {
    var rect = canvasDom.getBoundingClientRect()
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top
    }
  }

  // Get the position of a touch relative to the canvas
  getTouchPosForPoint (canvasDom, point) {
    const rect = canvasDom.getBoundingClientRect()
    return {
      x: point.clientX - rect.left,
      y: point.clientY - rect.top
    }
  }

  /**
   * @param {currentlyGlobalPaper.MouseEvent} evt
   * @param {{ isUserEvt: boolean, strokeColor?: string, strokeWidth?: number, fillColor?: string, snap?: number }} redrawOptions
   */
  onMouseDown = (evt, redrawOptions = { isUserEvt: true }) => {
    if (
      evt.event.button !== 0 ||
      (redrawOptions.isUserEvt && (!this.props.drawMode || this.props.redrawing || this.props.videoBuffering))
    ) {
      return
    }

    if (!this.props.recording && !this.props.redrawing) {
      this.props.record()
    }
    this.reactivateLayer = this.props.Paper.project.activeLayer
    this.props.drawingLayer.activate()
    this.painting = true
    // Basic Setup
    const createNewPath = !this.props.keepPath || (this.props.keepPath && !this.path)
    if (createNewPath) {
      this.path = new this.props.Paper.Path()
    }
    if (!evt.localPoint) {
      const layer = this.props.Paper.project.activeLayer
      evt.localPoint = layer.globalToLocal(evt.point).round()
    } else if (Array.isArray(evt.localPoint) && evt.localPoint[0] === 'Point') {
      evt.localPoint = new this.props.Paper.Point(evt.localPoint[1], evt.localPoint[2])
    }
    const { path, isNewPath = true } = this.props.onMouseDown(evt, this.path, redrawOptions)
    if (!path) { return }
    if (isNewPath) {
      this.path = path
      this.path.data.annotation = true
      this.tool.minDistance = this.path.data.toolMinDist = redrawOptions.snap || this.props.snap
      this.path.data.mouse = []
      this.path.data.tool = this.toolName
      this.path.strokeColor = redrawOptions.strokeColor || this.props.color
      this.path.strokeWidth = redrawOptions.strokeWidth || this.props.stroke
      if (redrawOptions.fillColor) {
        this.path.fillColor = redrawOptions.fillColor
      }
    }
    // Tool Specifix

    // PersistData
    this.props.updateWorkState && this.props.updateWorkState()
    const now = new Date().getTime()
    const time = isNewPath || this.resetTime ? 0 : now - this.lastTime
    if (this.resetTime) {
      this.resetTime = false
    }
    this.lastTime = now
    const { x, y } = evt.localPoint
    this.path.data.mouse.push({ localPoint: { x, y }, time, type: 'onMouseDown' })
    this.startTime = new Date().getTime()
  }

  onTouchStart = (wrapped) => {
    if ((!this.props.drawMode || this.props.redrawing || this.props.videoBuffering)) {
      return
    }
    const evt = wrapped.event
    if (this.trackedTouchId || this.trackedTouchId === 0) {
      return
    }
    if (allowPinch) {
      if (evt.touches.length > 1) {
        return
      }
    }
    evt.event = { button: 0 }
    this.trackedTouchId = evt.changedTouches[0].identifier
    const tPoint = evt.changedTouches[0]
    const mouseDown = this.props.overwriteMouseDown || this.onMouseDown
    mouseDown({ event: { button: 0 },
      point: tPoint.positionInVideo
    })
  }

  /**
   * @param {currentlyGlobalPaper.MouseEvent} evt
   * @param {boolean} redraw
   */
  onMouseDrag = (evt, redraw) => {
    if (!this.painting) {
      return
    }
    if (!evt.localPoint || !redraw) {
      if (this.props.redrawing) {
        return
      }
      const layer = this.props.Paper.project.activeLayer
      evt.localPoint = layer.globalToLocal(evt.point).round()
    }
    const { path } = this.props.onMouseDrag(evt, this.path, redraw)
    if (!path) {
      return
    }
    this.path = path
    // PersistData
    const now = new Date().getTime()
    const time = now - this.lastTime
    this.lastTime = now
    const { x, y } = evt.localPoint
    this.path.data.mouse.push({ localPoint: { x, y }, time, type: 'onMouseDrag' })
  }

  onTouchMove = (wrapped) => {
    const evt = wrapped.event
    if (allowPinch) {
      if (evt.touches.length > 1) {
        evt.stopPropagation()
        evt.preventDefault()
        return
      }
    }
    const trackedTouchPoint = Array.from(evt.changedTouches).find((t) => {
      return t.identifier === this.trackedTouchId
    })
    if (!trackedTouchPoint) {
      return
    }
    const mouseDrag = this.props.overwriteMouseDrag || this.onMouseDrag
    mouseDrag({
      point: trackedTouchPoint.positionInVideo
    })
  }

  /**
   * @param {React.MouseEvent} evt
   * @param {boolean} redraw
   */
  onMouseUp = async (evt, redraw) => {
    if (!this.painting) {
      return
    }
    if (this.props.redrawing && !redraw) {
      return
    }
    if (!evt.localPoint) {
      const layer = this.props.Paper.project.activeLayer
      evt.localPoint = layer.globalToLocal(evt.point).round()
    }

    const { path, endPath, resetTime, ignoreInput } = await this.props.onMouseUp(evt, this.path, redraw)

    if (!path) {
      return
    }
    if (resetTime) {
      this.resetTime = true
    }
    this.painting = false
    this.path = path
    if (!ignoreInput) {
      const now = new Date().getTime()
      const time = now - this.lastTime
      this.lastTime = now
      const { x, y } = evt.localPoint
      this.path.data.mouse.push({ localPoint: { x, y }, time, type: 'onMouseUp' })
    }
    this.reactivateLayer.activate()
    this.props.updateWorkState && this.props.updateWorkState()
    if (endPath) {
      this.path = false
    }
    if (this.props.pathDone) {
      this.props.pathDone(this.path)
    }
    if (redraw && this.props.onRedrawDone) {
      this.props.onRedrawDone()
    }
  }

  onTouchEnd = (wrapped) => {
    const evt = wrapped.event
    const trackedTouchPoint = Array.from(evt.changedTouches).find((t) => {
      return t.identifier === this.trackedTouchId
    })
    if (!trackedTouchPoint) {
      return
    }
    this.trackedTouchId = null
    const mouseUp = this.props.overwriteMouseUp || this.onMouseUp
    mouseUp({
      point: trackedTouchPoint.positionInVideo
    })
  }

  onKeyDown = (e, redraw = false) => {
    if (!this.props.onKeyDown) {
      return
    }
    if (!this.painting) {
      return
    }
    if (this.props.redrawing && !redraw) {
      return
    }
    if (!e.localPoint) {
      const layer = this.props.Paper.project.activeLayer
      e.localPoint = layer.globalToLocal(e.point).round()
    }
    const { path, endPath } = this.props.onKeyDown(e, this.path, redraw)
    if (!path) {
      return
    }

    this.path = path
    const now = new Date().getTime()
    const time = now - this.lastTime
    this.lastTime = now
    this.path.data.mouse.push({ localPoint: { x: 0, y: 0 }, time, type: 'onKeyDown', key: e.key, character: e.character })
    if (endPath) {
      this.painting = false
      this.reactivateLayer.activate()
      this.props.updateWorkState && this.props.updateWorkState()
      if (endPath) {
        this.path = false
      }
      if (this.props.pathDone) {
        this.props.pathDone(this.path)
      }
      if (redraw && this.props.onRedrawDone) {
        this.props.onRedrawDone()
      }
    }
  }

  onKeyUp = (e) => {
    this.props.onKeyUp && this.props.onKeyUp(e)
  }

  activate = () => {
    this.tool.activate()
    this.props.activate(this.toolName)
    if (this.props.onActivation) {
      this.props.onActivation(this.props.toolName)
    }
  }

  setUpTouchEvents = () => {
    // const canvas = this.props.videoParent.canvas
    // if (canvas) {
    //   canvas.addEventListener('touchstart', this.onTouchStart)
    //   canvas.addEventListener('touchmove', this.onTouchMove)
    //   canvas.addEventListener('touchend', this.onTouchEnd)
    // }
  }

  removeTouchEvents = () => {
    // const canvas = this.props.videoParent.canvas
    // if (canvas) {
    //   canvas.removeEventListener('touchstart', this.onTouchStart)
    //   canvas.removeEventListener('touchmove', this.onTouchMove)
    //   canvas.removeEventListener('touchend', this.onTouchEnd)
    // }
  }

  render () {
    const selected = this.props.currentTool === this.toolName ? 'selected' : ''
    const name = this.props.disableName ? '' : this.props.toolName
    const onClick = this.props.redrawing ? () => {} : this.activate
    return (
      <div className={`Tool ${this.props.toolName}Button ${selected} ${this.props.className}`} name='Tool' onClick={onClick}>{this.props.children}{name}</div>
    )
  }
}

export default ProtoTool
