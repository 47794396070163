import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLineSelector } from './LineSelector'
import { LineEditor } from './LineEditor'
import ReactDOM from 'react-dom'
import {
  getFieldCalibrationFromLocalStorage,
  getPersistedMetersPerPixel,
  persistFieldCalibration, saveFieldCalibrationToLocalStorage,
} from './PersistCalibration'
  

export const FieldCalibrationPlugin = (props) => {
  const onDone = (lines) => {
    // Remove incomplete lines from localStorage before computing total metersPerPixel
    persistFieldCalibration(
      props.fieldID,
      props.getAuthorization,
      lines.filter(line => line.distance !== undefined && line.pointA && line.pointB),
      false,
    )
      .then(() => getPersistedMetersPerPixel(props.fieldID, props.getAuthorization))
      .then(props.onDone)
  }

  const { lineIndex, lineSelector, setLineIndex, isNewLine } = useLineSelector({
    ...props,
    onDone,
    fieldID: props.fieldID,
    getAuthorization: props.getAuthorization,
  })

  const returnToLineSelector = useCallback(() => setLineIndex(undefined), [setLineIndex])

  const onLineEditAbort = useCallback(() => {
    if (isNewLine) {
      const lines = getFieldCalibrationFromLocalStorage(props.fieldID)
      lines.pop()
      saveFieldCalibrationToLocalStorage(props.fieldID, lines)
    }

    returnToLineSelector()
  }, [isNewLine, props.fieldID, returnToLineSelector])


  const result = lineIndex === undefined ? lineSelector : <LineEditor
    {...props}
    lineIndex={lineIndex}
    onDone={returnToLineSelector}
    onAbort={onLineEditAbort}
    isNewLine={isNewLine}
  />

  return props.sidebarContainer ? ReactDOM.createPortal(result, props.sidebarContainer) : result
}

FieldCalibrationPlugin.propTypes = {
  videoParent: PropTypes.any,
  videoState: PropTypes.any,
  video: PropTypes.any,
  paper: PropTypes.any,
  drawingLayer: PropTypes.any,
  onAbort: PropTypes.func,
  onDone: PropTypes.func,
  ContextButtonsContainer: PropTypes.instanceOf(Element),
  // TODO: should actually be an element, but for some reason elements are obejcts ¯\_(ツ)_/¯
  sidebarContainer: PropTypes.object,
  lineIndex: PropTypes.number,
  fieldID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getAuthorization: PropTypes.func,
}

FieldCalibrationPlugin.defaultProps = {
  onAbort: () => {},
  onDone: () => {},
}
