// const Config = {
//   Clips: [{ url: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4', label: 'Clips Anlegen' }],
//   Editieren: { url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4', label: 'Editieren' }
// }

const BasicsConf = [
  { label: 'Bezeichnungen', type: 'Link', url: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4' },
  { label: 'Spiele', type: 'Link', url: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4' },
  { label: 'Subscriptions', type: 'Link', url: 'http://clips.vorwaerts-gmbh.de/VfE_html5.mp4' }
]

const EditorBasics = [
  { label: 'Navigieren', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' },
  { label: 'Editieren', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' },
  { label: 'Zeichnen', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' }
]

const EditorTools = [
  { label: 'Hand', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' },
  { label: 'Line', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' },
  { label: 'Rect', type: 'Link', url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4' }
]

const EditorCategories = [
  { label: 'Basics', type: 'SubList', config: EditorBasics },
  { label: 'DrawingTools', type: 'SubList', config: EditorTools }
]

const Config = [
  { label: 'Grundlagen', type: 'SubList', config: BasicsConf },
  { label: 'Der Editor', type: 'SubList', config: EditorCategories }
]

export default Config
