export default {
  default: {
    button: {
      close: 'fermer',
    },
  },
  dashboard: {
    welcome: 'Staige',
    toClips: 'Vers les clips',
    toGames: 'Aux jeux',
    logout: 'annuler'
  },
  sidebar: {
    subs: 'SOUSCRIPTIONS',
    help: 'AIDE'
  },
  pathMap: {
    matches: {
      single: 'Jeux',
      id: 'Détails du jeu',
      editRef: 'Jeux'
    },
    clips: {
      single: 'Clips',
      id: 'Détails du clip',
      editRef: 'Clip'
    },
    sequence: {
      id: 'Montage de scène'
    },
    user: {
      single: 'Abonnements'
    },
    subscription: {
      id: 'Abonnements pour invités'
    },
    newSubscription: {
      single: 'Nouvel abonnement'
    },
    editor: '{{ref}} éditer',
    playerPositions: {
      single: 'Positions des tourne-disques',
    },
    404: {
      single: '???'
    }
  },
  createClipUi: {
    create: 'Créer un clip',
    edit: 'Editez le clip',
    info: 'Pour créer un clip, ajoutez des séquences provenant de la sélection rapide ou de jeux.',
    addSequences: 'Ajout de séquences',
    nameClip: 'Clip de nom à sauvegarder'
  },
  fastClipButtons: {
    head: 'Sélection rapide de scènes',
    standards: 'Normes',
    highlights: 'Faits marquants',
    goals: 'Tir de but',
    this: 'ce jeu',
    newest: 'du dernier jeu',
    filtered: 'les jeux filtrés'
  },
  clipDetails: {
    watch: 'dans l\'éditeur Voir',
    watchShort: 'Editor',
    edit: 'Edit',
    publish: 'Publier',
    unPublish: 'En privé',
    copyLink: 'Copie du lien',
    delete: 'Supprimer',
    urlInClipboard: 'Url copié dans le presse-papiers',
    sceneCount: 'Scènes de {{date}}',
    questionDelete: 'Vous voulez vraiment supprimer le clip ?',
    cancle: 'Annuler'
  },
  clipList: {
    header: 'Tous les clips'
  },
  matchList: {
    header: 'Tous les jeux'
  },
  matchItem: {
    watch: 'dans l\'éditeur Voir',
    sequencesLoading: 'Charger des scènes',
    sequencesLoaded: '{{count}} Scènes',
    from: 'à partir de',
    at: 'at'
  },
  sequenceItem: {
    edit: 'Edit',
    addToClip: 'Ajouter au clip',
    removeFromClip: 'Supprimer',
    rename: 'Renommer la scène',
    gameInfo: 'Informations sur les jeux',
    annotations: 'Notes',
    delete: 'Supprimer',
    deleteHead: 'Scène Supprimer?',
    deleteMsg: 'Attention : cela supprimera également la scène de tous les clips dans lesquels elle a été utilisée !',
    deleteOk: 'Supprimer',
    deleteCancle: 'Annuler'
  },
  labelList: {
    new: 'Nouveau label',
    more: 'En savoir plus',
    namePrompt: 'Label de nom'
  },
  omniFilter: {
    search: 'Recherchez...'
  },
  user: {
    from: 'à partir de',
    guestSubs: 'Votre accès invité',
    guestSubFrom: 'aux clubs de {{userName}}',
    subscription: 'Abonnement',
    invalidSubscriptions: 'Vous n\'avez pas d\'abonnements valables',
    clubNameLoading: 'Le nom du club est chargé',
    validUntiL: 'Payé jusqu\'à',
    activeUntil: 'Valable jusqu\'au',
    manageAccess: 'Gérer les formateurs',
    newSub: 'Nouvel abonnement'
  },
  login: {
    loginFailed: 'Échec de la connexion',
    unknownMail: 'Courriel et/ou mot de passe inconnu',
    inputIncomplete: 'Veuillez remplir tous les champs !',
    pwNoMatch: 'Les mots de passe saisis ne correspondent pas !',
    invalidEmail: 'Veuillez entrer une adresse électronique valide.',
    resetPwError: 'Le mot de passe n\'a pas pu être réinitialisé avec succès.',
    shareSubs: 'veut partager son abonnement avec vous.',
    noAccount: 'Pas encore de compte ?',
    password: 'Mot de passe',
    forgotPassword: 'Vous avez oublié votre mot de passe ?',
    login: 'Connexion',
    userName: 'Nom d\'utilisateur',
    gotAccount: 'J\'ai déjà un compte',
    repeatPassword: 'Répéter le mot de passe',
    register: 'S\'inscrire',
    registerSuccess: 'Votre inscription a été réussie. Veuillez vérifier votre boîte e-mail.',
    send: 'Soumettre',
    pwResetComplete: 'Votre mot de passe a été réinitialisé avec succès. Veuillez vérifier votre boîte aux lettres électronique.',
    backToLogin: 'Retour à la demande',
    loggingIn: 'Vous serez enregistré',
    TrainerToken: 'Enregistrez-vous ou connectez-vous avec un compte existant pour accéder à votre nouvel abonnement.'
  },
  subscriptionEdit: {
    inviteUser: 'Inviter les utilisateurs',
    notSet: 'Non attribué',
    guestSub: 'Les abonnements des invités pour',
    recieverMail: 'E-mail du destinataire',
    send: 'Envoyer',
    back: 'Retour',
    explanationP1: `Ici, vous pouvez donner aux entraîneurs de votre club l'accès à l'outil d'entraînement soccerwatch.
    Chaque entraîneur a accès aux matchs enregistrés de votre club pendant la durée de votre outil d'entraînement soccerwatch.
    Pour ajouter un utilisateur, il suffit de cliquer sur l'un des créneaux et d'entrer l'adresse électronique du destinataire.
    `,
    explanationP2: 'L\'entraîneur recevra alors un e-mail avec votre invitation à utiliser l\'outil d\'entraînement de soccerwatch en tant q\'entraîneur du club {{{CLUB}}.',
    explanationP3: ``
  },
  subscriptionCreate: {
    newSubscription: 'Nouvel abonnement',
    working: 'Votre commande sera traitée...',
    choosenClub: 'Club sélectionné :',
    noClub: 'Aucun club sélectionné',
    badError: 'Une erreur inattendue s\'est produite. Veuillez noter le numéro suivant afin que nous puissions affecter votre virement Paypal à votre commande.',
    retry: 'Essayer à nouveau',
    headerSuccess: 'Accès au club Activé',
    textSuccess: 'Votre inscription au club {{{club}} a été créée avec succès.',
    back: 'Retour à la vue d\'ensemble',
    chooseClub: 'Choisissez le club pour lequel vous souhaitez souscrire un abonnement'
  },
  tags: {
    0: 'Club d\'accueil Goal',
    1: 'Opposant au but',
    2: 'Marquer des points pour l\'équipe locale',
    3: 'L\'équipe de la chance au but',
    4: 'Foul Heimmannschaft',
    5: 'Foul Auswärtsmannschaft',
    6: '11.Meter Heimmannschaft',
    7: '11.Meter Auswärtsmannschaft',
    8: 'Gelbe Karte Heimmannschaft',
    9: 'Gelbe Karte Auswärtsmannschaft',
    10: 'Rote Karte Heimmannschaft',
    11: 'Rote Karte Auswärtsmannschaft',
    12: 'Anpfiff 1. Halbzeit',
    13: 'Ende 1. Halbzeit',
    14: 'Anpfiff 2. Halbzeit',
    15: 'Ende 2. Halbzeit',
    16: 'Tricks',
    17: 'Trick Pass',
    18: 'Trick Schuss',
    19: 'Flitzer',
    25: 'Kopfball',
    26: 'Schwalbe',
    27: 'Verlängerung',
    28: 'Ende',
    33: 'Abseits Heimmannschaft',
    34: 'Abseits Auswärtsmannschaft',
    35: 'Ecke Heimmannschaft',
    36: 'Ecke Auswärtsmannschaft',
    37: 'Freistoß Heimmannschaft',
    38: 'Freistoß Auswärtsmannschaft',
    39: 'Auswechslung Heimmannschaft',
    40: 'Auswechslung Auswärtsmannschaft',
  },
  playerPositionTracker: {
    metadataFormTitle: 'Metadaten des zu markierenden Objekts',
    placeholder: 'Bitte eingeben...',
    playerNumber: 'Spielernummer',
    teamId: 'Team-ID',
    trackBall: 'Ball markieren',
    link: {
      title: 'Positionen markieren',
      placeholder: 'Spiel-ID',
      linkLabel: 'Los',
    },
    teamPositionsTitle: 'Team {{teamId}} - Bereits {{numberOfTrackedPlayers}} Spieler erfasst',
  },
  shortcutInfoPopup: {
    modalTitle: 'Raccourcis clavier',
    intro: 'Le lecteur vidéo dispose de quelques raccourcis clavier. Cependant, ces derniers ne fonctionnent que lorsque le lecteur vidéo est au point.',
    howToFocusVideoPlayer: 'En cliquant sur la barre de contrôle noire en bas du lecteur vidéo, le lecteur peut être mis au point sans marquer une position !',
    proTip: 'Pro-Tip',
    space: 'Leertaste',
    ctrl: 'Strg',
    shift: 'Umschalt',
    alt: 'Alt',
    playPause: 'Das Video fortsetzen / pausieren',
    fullscreen: 'Vollbildmodus aktivieren / deaktivieren',
    seconds: '{{count}} Sekunde',
    seconds_plural: '{{count}} Sekunden',
    direction_forward: 'vorne',
    direction_backward: 'hinten',
    skip: `$t(shortcutInfoPopup.seconds, { 'count': {{seconds}} }) nach $t(shortcutInfoPopup.direction, { 'context': '{{direction}}' }) springen`,
    animateAhead: `$t(shortcutInfoPopup.seconds, { 'count': {{seconds}} }) des Videos mit erhöhter Geschwindigkeit abspielen`,
    showAgain: 'Übersicht nächstes Mal wieder anzeigen',
    dontShowAgain: 'Übersicht nicht nochmal anzeigen',
    explenation: '{{shiftSplitter}} fügt also, so zu sagen, eine Animation zu einem Tastaturbefehl hinzu, während {{altSplitter}} bzw. {{ctrlSplitter}} die zu überspringende Zeit auf 5 bzw. 20 Sekunden setzt.',
    changePlayerNumber_increment: 'Spielernummer um 1 erhöhen',
    changePlayerNumber_decrement: 'Spielernummer um 1 verringern',
  },
}
