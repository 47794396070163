import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import mobiscroll from '@mobiscroll/react'
import { ContextButton } from '../annotationEditor/sub/contextButton'
import './CalibrationControls.scss'
import { DisplayContextButtons } from '../annotationEditor/sub/displayContextButtons'

const PointController = ({
  isBeingEdited = false,
  onEdit = () => {},
  onSave = () => {},
  onAbort = () => {},
  disabled = false,
  title,
  required = false,
  isSet = false,
}) => {
  return <mobiscroll.FormGroup className={'point-controller'}>
    <span className={`point-label ${required ? 'required' : ''}`}>
      {title}
      {isSet ? <span className={'mbsc-ic mbsc-ic-fa-check check'} /> : null}
    </span>
    {isBeingEdited ? <div>
      <ContextButton
        type={'success'}
        iconName={'material-save'}
        onClick={onSave}
        disabled={disabled}
      />
      <ContextButton
        type={'danger'}
        iconName={'fa-times'}
        onClick={onAbort}
        disabled={disabled}
      />
    </div> : <div>
      <ContextButton
        type={'warning'}
        iconName={'material-edit'}
        disabled={disabled}
        onClick={onEdit}
      />
    </div>}
  </mobiscroll.FormGroup>
}

export const CalibrationControls = ({

  onSave = () => {},
  onAbort = () => {},
  canSave = true,
  onSavePoint: pointSaveCallback = () => {},
  onAbortPointEdit: pointAbortionCallback = () => {},
  savedPoints = [],
  ContextButtonsContainer,
  distance,
  onDistanceChange,
  lineName, onLineNameChange,
  editingPointA, setEditingPointA,
  editingPointB, setEditingPointB,
}) => {

  const onPointAbort = () => {
    setEditingPointA(false)
    setEditingPointB(false)
    pointAbortionCallback()
  }

  const onPointSave = () => {
    setEditingPointA(false)
    setEditingPointB(false)
    pointSaveCallback()
  }

  const contextButtons = <DisplayContextButtons
    buttons={<>
      <ContextButton
        onClick={onPointSave}
        type='success'
        iconName='material-save'
      />
      <ContextButton
        onClick={onPointAbort}
        type='danger'
        iconName='fa-times'
      />
    </>}
    container={ContextButtonsContainer}
  />

  const createOnEditHandler = (setEditingPoint) => () => {
    setEditingPoint(true)

    if (window.GlobalCoachingTool && window.GlobalCoachingTool.setSidebarOpen) {
      window.GlobalCoachingTool.setSidebarOpen(false)
    }
  }

  return <>
    {editingPointA || editingPointB ? contextButtons : null}

    <mobiscroll.Page
      theme={'ios'}
      className={'calibration-controls'}
    >
      <PointController
        title={`${i18next.t('fieldCalibration.point')} A`}
        onEdit={createOnEditHandler(setEditingPointA)}
        onAbort={onPointAbort}
        onSave={onPointSave}
        isBeingEdited={editingPointA}
        disabled={editingPointB}
        required
        isSet={Boolean(savedPoints[0])}
      />
      <PointController
        title={`${i18next.t('fieldCalibration.point')} B`}
        onEdit={createOnEditHandler(setEditingPointB)}
        onAbort={onPointAbort}
        onSave={onPointSave}
        isBeingEdited={editingPointB}
        disabled={editingPointA}
        required
        isSet={Boolean(savedPoints[1])}
      />
      <mobiscroll.FormGroup
        className={'name-input required'}
      >
        <mobiscroll.Input
          type={'text'}
          value={lineName}
          onChange={onLineNameChange}
          valid={lineName !== ''}
        >
          {i18next.t('fieldCalibration.name')}
        </mobiscroll.Input>
      </mobiscroll.FormGroup>
      <mobiscroll.FormGroup
        className={'distance-input required'}
      >
        <mobiscroll.Input
          placeholder={`(${i18next.t('fieldCalibration.inMeters')})`}
          type={'number'}
          value={distance}
          onChange={onDistanceChange}
          valid={distance !== ''}
        >
          {i18next.t('fieldCalibration.distance')}
        </mobiscroll.Input>
      </mobiscroll.FormGroup>
      <mobiscroll.FormGroup className={'buttons'}>
        <button
          className={'btn success'}
          onClick={() => onSave()}
          disabled={!canSave || typeof distance === 'string'}
        >
          {i18next.t('default.button.save')}
        </button>
        <button
          className={'btn danger'}

          onClick={onAbort}
        >
          {i18next.t('default.button.cancel')}
        </button>
      </mobiscroll.FormGroup>
    </mobiscroll.Page>
  </>
}

CalibrationControls.propTypes = {
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  onAbort: PropTypes.func,
  onSavePoint: PropTypes.func,
  onAbortPointEdit: PropTypes.func,
  ContextButtonsContainer: PropTypes.instanceOf(Element),
  savedPoints: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })),
  distance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onDistanceChange: PropTypes.func,
  lineName: PropTypes.string,
  onLineNameChange: PropTypes.func,
  editingPointA: PropTypes.bool,
  setEditingPointA: PropTypes.func,
  editingPointB: PropTypes.bool,
  setEditingPointB: PropTypes.func,
}
