import React, { Component } from 'react'
import Config from './config'
import './helpModal.scss'

// function SideNav ({ currentSite, config, setCurrentSite }) {
//   const NavPoints = Object.keys(config).map((key) => {
//     const data = config[key]
//     return <li key={key} onClick={setCurrentSite(key)} className={`navPoint ${currentSite === key ? 'active' : ''}`}>{data.label}</li>
//   })
//   return <ul className='sideNav'>{NavPoints}</ul>
// }

const toComponent = (setLink, passedIndex = [], level = 0, currentIndex, parrentActive) => (config, i) => {
  const index = [...passedIndex]
  index.push(i)
  return config.type === 'SubList'
    ? <NavPoint parrentActive={parrentActive} level={level} currentIndex={currentIndex} index={index} config={config} setLink={setLink} />
    : <NavLink parrentActive={parrentActive} level={level} currentIndex={currentIndex} index={index} config={config} setLink={setLink} />
}

function NavLink ({ config, setLink, index, level, currentIndex, parrentActive }) {
  const selected = parrentActive && currentIndex[level] === index[level]
  return <div>
    <li className={`${selected ? 'active' : ''}`} onClick={setLink(config, index)}><span className='label'>{config.label}</span></li>
  </div>
}

function NavPoint ({ config, setLink, index, level, currentIndex, parrentActive }) {
  const [open, setIsOpen] = React.useState(false)
  const toggle = (e) => {
    e.stopPropagation()
    setIsOpen(!open)
  }
  const selected = parrentActive && currentIndex[level] === index[level]

  return <> <li className={`${selected ? 'active' : ''}`} onClick={toggle}>
    <span className='label' >{config.label}</span>
    <span className={`mbsc-ic mbsc-ic-${open ? 'material-keyboard-arrow-up' : 'material-keyboard-arrow-down'}`} />
  </li>
    { open && config.config && <SideNav config={config.config} setLink={setLink} level={level + 1} index={index} currentIndex={currentIndex} parrentActive={selected} />}
  </>
}

function SideNav ({ config, setLink, index, level = 0, currentIndex, parrentActive = true }) {
  return <ol className='sideNav'>{config.map(toComponent(setLink, index, level, currentIndex, parrentActive))}</ol>
}

class HelpModal extends Component {
  constructor (props) {
    super(props)
    const currentSite = props.currentSite || Object.keys(Config)[0]
    const open = props.open
    this.state = {
      error: '',
      currentSite,
      url: '',
      selectedIndex: [0, 0, 0, 0, 0, 0, 0, 0],
      open
    }
  }

  componentDidMount = () => {
    window.GlobalCoachingTool = window.GlobalCoachingTool || {}
    window.GlobalCoachingTool.setHelpModalOpen = this.setOpen
  }

  componentDidUpdate = (lastProps) => {
    if (lastProps.currentSite !== this.props.currentSite) {
      this.setState({ currentSite: this.props.currentSite })
    }
    if (lastProps.open !== this.props.open) {
      this.setState({ open: this.props.open })
    }
  }

  setOpen = (open) => {
    this.setState({ open })
  }

  toggleOpen = () => {
    const open = !this.state.open
    this.setState({ open })
  }

  setCurrentSite = (currentSite) => () => {
    this.setState({ currentSite })
  }

  setLink = (config, selectedIndex) => (e) => {
    e.stopPropagation()
    const url = config.url
    this.setState({ url, selectedIndex })
  }

  render () {
    if (!this.state.open) {
      return null
    }
    return (
      <div className='ModalContainer'>
        <div id='CLOSABLESHADOW' className='modal' onClick={(e) => {
          if (e.target.id === 'CLOSABLESHADOW') {
            this.setOpen(false)
          }
        }}>
          <div className='modalContent container-fluid'>
            <div className='row'>
              <div className='col-sm-8 col-md-9 videoContent'>
                <video key='Video' controls src={this.state.url} alt={`VideoTutorial`} />
              </div>
              <div className='col-sm-4 col-md-3 noCenter'>
                <SideNav config={Config} setLink={this.setLink} currentIndex={this.state.selectedIndex} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HelpModal
