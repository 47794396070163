import { get, set } from 'lodash'
import listTillFinished from './listTillFinished'
import { PlayerPosition } from './PlayerPosition'
import { BaseService } from './BaseService'
// import {PositionInterpolator} from './positionInterpolator'
// import {PositionCache} from './positionCache'
import discovery from '@soccerwatch/discovery'

/**
 * Manages {@link PlayerPosition}s by fetching them from
 * the server and caching them.
 */
export class PlayerPositionService extends BaseService {
  static ballMetadata = {
    Club: -1,
    PlayerNumber: -1
  }

  positionsObject = {}
  alreadyFetched = {
    teams: new Set(),
    players: new Set(),
    timestamps: new Set()
  }

  /**
   * @private
   * @type {number | string}
   */
  _video = 10000

  /**
   * @private
   * @type {string}
   */
  _baseURL = discovery.API_STATISTIC

  /**
   * @param {number | string} matchId - ID of match to manage {@link PlayerPosition}s of
   * @param {function?} onFetchingUpdate
   */
  constructor (matchId, onFetchingUpdate, getAuthorization) {
    super(onFetchingUpdate)
    this.matchId = matchId
    this.getAuthorization = getAuthorization
  }

  /**
   * @deprecated Please use PlayerPositionService.positionsObject instead
   * @returns {PlayerPosition[]} Array of all PlayerPositions
   */
  get positions () {
    console.error('PlayerPositionService.positions is deprecated. Please use PlayerPositionService.positionsObject instead')
    return Object.values(this.positionsObject).reduce((clubPositions, positionsByTeam) =>
      [
        ...clubPositions,
        ...Object.values(positionsByTeam)
          .reduce((teamPositions, positionsByPlayer) =>
            [
              ...teamPositions,
              ...Object.values(positionsByPlayer)
                .reduce((playerPositions, positionsByTimestamp) =>
                  [
                    ...playerPositions,
                    ...Object.values(positionsByTimestamp)
                  ]
                , [])
            ]
          , [])
      ]
    , [])
  }

  /**
   * Adds position to object of positions
   * @param {object | PlayerPosition} position Position to add
   * @returns {PlayerPosition}
   */
  addPosition = (position) => {
    if (!(position instanceof PlayerPosition)) {
      position = new PlayerPosition(position, false)
    }

    set(this.positionsObject, [position.Club, position.PlayerNumber, position.Timestamp], position)

    return position
  }

  getPosition = (team, position, timestamp) => {
    return get(this.positionsObject, [team, position, timestamp], undefined)
  }

  generatePlayerAndTeamId ({ playerId, teamId }) {
    return `${playerId}:${teamId}`
  }

  startedFetching (url, promise) {
    return super.startedFetching(url, promise, this._fetchPositionsFromRelativeUrl)
  }

  /**
   * Fetch all positions from a url, add them
   * to PlayerPositionService.positionsObject
   * and notify subscriptions
   * @fires PlayerPositionService#change
   * @param {string} url Relative URL to fetch positions from
   * @param {string?} message - message to be printed before
   * and after request
   * @returns {Promise.<PlayerPosition[]>} Positions retrieved from server
   */
  _fetchPositionsFromRelativeUrl = (url, message) => {
    if (!this.currentlyFetching[url]) {
      if (message) {
        console.info(message)
      }

      const listTillFinishedPromise = this.getAuthorization().then((Authorization) => {
        const headers = { Authorization }
        return listTillFinished(`${this._baseURL}/${url}`, headers)
      }).then((positions) => {
        // .map because this.addPosition also wraps positions in PlayerPosition objects
        positions = positions.map(position => this.addPosition(position))

        this.onChange()

        if (message) {
          console.info(`Finished ${message}`)
        }

        return positions
      }).catch((err) => {
        console.error(`Error while ${message || `fetching ${url}`}.`, err.stack)
        // this.positionCache.checkInternetConnectionAndFireEvents()
        throw err
      })

      this.startedFetching(url, listTillFinishedPromise)
    }

    return this.currentlyFetching[url]
  }

  /**
   * Fetch all positions at a certain time from the database and add them
   * to PlayerPositionService.positionsObject
   * @fires PlayerPositionService#change
   * @param {number | string} teamId Team ID that must match
   * the position's team ID
   * @returns {Promise.<PlayerPosition[]>} Positions retrieved from server
   */
  fetchPositionsOfTeam = async (teamId) => {
    const result = await this._fetchPositionsFromRelativeUrl(
      `playerPositions/byClub/${this.matchId}/${teamId}/`,
      `Fetching positions of team ${teamId}.`
    )
    this.alreadyFetched.teams.add(teamId)
    return result
  }

  fetchPositionsAtTimestamp = async (timestamp) => {
    const result = await this._fetchPositionsFromRelativeUrl(
      `playerPositionAt/${this.matchId}/${timestamp}/`,
      `Fetching positions at timestamp ${timestamp}.`
    )
    this.alreadyFetched.timestamps.add(timestamp)
    return result
  }

  fetchPositionsOfPlayer = async (teamId, playerId) => {
    const result = await this._fetchPositionsFromRelativeUrl(
      `playerPositions/byPlayer/${this.matchId}/${teamId}/${playerId}/`,
      `Fetching positions of player ${playerId} in team ${teamId}.`
    )
    this.alreadyFetched.players.add(this.generatePlayerAndTeamId({ playerId, teamId }))
    return result
  }

  /**
   * Filter all positions by exact timestamp
   * @param {number | string} timestamp
   * @param {Boolean} forceFetch - Determines, wether the
   * positions may be retrieved from cache or not
   * @returns {Promise<PlayerPosition[]>} Positions with exact timestamp
   * provided as first argument
   */
  getByTimestamp = async (timestamp, forceFetch = false) => {
    let result = []
    const timestampInSeconds = Math.floor(timestamp)

    if (forceFetch || !this.alreadyFetched.timestamps.has(timestampInSeconds)) {
      result = await this.fetchPositionsAtTimestamp(timestampInSeconds)
    } else {
      Object.values(this.positionsObject).forEach((positionsByClub) =>
        Object.values(positionsByClub).forEach(positionsByPlayer => {
          if (positionsByPlayer[timestampInSeconds]) {
            result.push(positionsByPlayer[timestampInSeconds])
          }
        })
      )
    }

    return result
  }

  /**
   * Get positions of a player in a team up to 5 seconds before a certain time
   * @param {string} teamId
   * @param {string} playerId
   * @param {boolean} forceFetch - Determines, wether the data may be cached or not
   * @returns {Promise.<PlayerPosition[]>}
   */
  getByPlayer = async (teamId, playerId, forceFetch = false) => {
    let result = {}

    if (
      forceFetch ||
      (
        !this.alreadyFetched.teams.has(teamId) &&
        !this.alreadyFetched.players.has(this.generatePlayerAndTeamId({ teamId, playerId }))
      )
    ) {
      await this.fetchPositionsOfPlayer(teamId, playerId)
    }

    if (this.positionsObject[teamId] && this.positionsObject[teamId][playerId]) {
      result = this.positionsObject[teamId][playerId]
    }

    return Object.values(result)
  }

  /**
   * Get positions of a player in a team up to 5 seconds before a certain time
   * @param {string} teamId
   * @param {string} playerId
   * @param {number} timestamp
   * @param {boolean} forceFetch - Determines, wether the data may be cached or not
   * @returns {Promise.<PlayerPosition[]>}
   */
  getByPlayerAndTimestamp = async (teamId, playerId, timestamp, forceFetch = false) => {
    let result = []
    const roundedTimestamp = Math.round(timestamp)

    const numberOfPositionsToBeShown = 5
    // const paddingForInterpolation = 5

    await this.getByPlayer(teamId, playerId, forceFetch)

    // Show last five seconds and look five seconds back / ahead
    // this.positionInterpolator.getInterpolatedPositionsOfPlayerBetweenTimestamps(
    //   teamId,
    //   playerId,
    //   roundedTimestamp - numberOfPositionsToBeShown - paddingForInterpolation,
    //   roundedTimestamp + paddingForInterpolation,
    //   this.positionsObject,
    // )

    for (let i = numberOfPositionsToBeShown - 1; i >= 0; i--) {
      if (this.positionsObject[teamId] && this.positionsObject[teamId][playerId] && this.positionsObject[teamId][playerId][roundedTimestamp - i]) {
        result.push(this.positionsObject[teamId][playerId][roundedTimestamp - i])
        // } else if (this.positionInterpolator.interpolatedPositions[teamId] &&
        // this.positionInterpolator.interpolatedPositions[teamId][playerId]) {
        // result.push(this.positionInterpolator.interpolatedPositions[teamId][playerId][roundedTimestamp - i])
      }
    }

    // remove undefined values
    return result.filter(Boolean)
  }

  getByAllPositionsForTracking = async (timestamp, forceFetch = false) => {
    let result = {}

    await Promise.all(Object.keys(this.positionsObject).map(async (teamId) => {
      result[teamId] = {}
      await Promise.all(Object.keys(this.positionsObject[teamId]).map(async (playerId) => {
        result[teamId][playerId] = await this.getByPlayer(teamId, playerId)
        result[teamId][playerId] = result[teamId][playerId].sort((a, b) => a.Timestamp - b.Timestamp)
      }))
    }))

    return result
  }

  /**
   * Get positions of a team up to 5 seconds before a certain time
   * @param {string} teamId
   * @param {boolean} forceFetch - Determines, wether the data may be cached or not
   * @returns {Promise.<PlayerPosition[]>}
   */
  getByClub = async (teamId, forceFetch = false) => {
    // fetch positions of team if not there yet
    if (forceFetch || !this.alreadyFetched.teams.has(teamId)) {
      try {
        await this.fetchPositionsOfTeam(teamId)
      } catch (e) {
        console.error(e)
      }
    }
    return this.positionsObject[teamId]
  }

  /**
   * Get positions of a team up to 5 seconds before a certain time
   * @param {string} teamId
   * @param {number} timestamp All positions will be returned that
   *  are not older than four seconds before timestamp
   * @param {boolean} forceFetch - Determines, wether the data may be cached or not
   * @returns {Promise.<PlayerPosition[]>}
   */
  getByClubAndTimestamp = async (teamId, timestamp, forceFetch = false) => {
    const result = []
    const roundedTimestamp = Math.round(timestamp)

    await this.getByClub(teamId, forceFetch)

    const paddingForInterpolation = 5

    const teamPositions = this.positionsObject[teamId]
    if (teamPositions) {
      Object.keys(teamPositions).forEach(playerId => {
        const positionsOfPlayer = teamPositions[playerId]
        if (positionsOfPlayer[roundedTimestamp]) {
          result.push(positionsOfPlayer[roundedTimestamp])
        } else {
          const allInterpolatedPositions = this.positionInterpolator.getInterpolatedPositionsOfPlayerBetweenTimestamps(
            teamId, playerId, roundedTimestamp - paddingForInterpolation, roundedTimestamp + paddingForInterpolation, this.positionsObject
          )
          const interpolatedPositionsAtTimestamp = allInterpolatedPositions.filter(position => position.Timestamp === roundedTimestamp)
          result.push(...interpolatedPositionsAtTimestamp)
        }
      })
    }

    // remove undefined values
    return result.filter(Boolean)
  }

  /**
   * Saves a players position
   * @param {paper.Point} drawingSurfaceTopLeft
   * @param {paper.Point} drawingSurfaceBottomRight
   * @param {number | string} timestamp
   * @param {number | string} teamId
   * @param {number | string} playerNumber
   * @param {number | string} frameTime
   * @param {paper.Point | undefined} topLeft - Top left corner in video
   * @param {paper.Point | undefined} bottomRight - Bottom right corner in video
   */
  createPosition = async (
    drawingSurfaceTopLeft,
    drawingSurfaceBottomRight,
    timestamp,
    teamId,
    playerNumber,
    frameTime,
    topLeft,
    bottomRight,
  ) => {
    const PreciseTimestamp = frameTime > -1 ? frameTime : timestamp
    let position = new PlayerPosition({
      bottomRight,
      topLeft,
      Video: this._video,
      Club: teamId,
      PlayerNumber: playerNumber,
      Timestamp: timestamp,
      PreciseTimestamp,
      drawingSurfaceTopLeft,
      drawingSurfaceBottomRight
    })

    const url = `${this._baseURL}/playerPosition/${this.matchId}/${position.Club}/${position.PlayerNumber}/${position.Timestamp}/`
    const Authorization = await this.getAuthorization()

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      Authorization
    }
    try {
      const paperPointToJSONableObject = point => ({ x: point.x, y: point.y })
      const result = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify({
          PointUL: paperPointToJSONableObject(position.topLeft),
          PointDR: paperPointToJSONableObject(position.bottomRight),
          DrawingSurfacePointUL: paperPointToJSONableObject(position.drawingSurfaceTopLeft),
          DrawingSurfacePointDR: paperPointToJSONableObject(position.drawingSurfaceBottomRight),
          PreciseTimestamp: position.PreciseTimestamp,
        }),
      })

      window.dispatchEvent(new Event('online'))

      position = await result.json()
    } catch (err) {
      console.warn('Could not save position to server! The position will be cached in order to be saved at a later time.')
      // this.positionCache.cacheUnsavedPosition(position)
      // this.positionCache.checkInternetConnectionAndFireEvents()
    } finally {
      this.addPosition(position)

      this.onChange()
    }
  }

  /**
   * Delete {@link PlayerPosition} from database
   * and local records
   * @fires PlayerPositionService#change
   * @param {number | string} teamId - TeamID of the
   * position that is supposed to be deleted
   * @param {number | string} playerNumber - Playernumber
   * of the position that is supposed to be deleted
   * @param {number | string} timestamp - Timestamp of
   * the position that is supposed to be deleted
   * @returns {Promise}
   */
  deletePosition = async (teamId, playerNumber, timestamp) => {
    const url = `${this._baseURL}/playerPosition/${this.matchId}/${teamId}/${playerNumber}/${timestamp}`
    const Authorization = await this.getAuthorization()
    const headers = { Authorization } 
    await window.fetch(url, {
      method: 'DELETE',
      headers
    })

    if (this.positionsObject[teamId] && this.positionsObject[teamId][playerNumber] && this.positionsObject[teamId][playerNumber][timestamp]) {
      delete this.positionsObject[teamId][playerNumber][timestamp]
      this.onChange()
    }
  }
}

export default PlayerPositionService
