import React, { Component } from 'react'
import materialColor from 'material-color-hash'
// import discovery from '@soccerwatch/discovery'
import AnnotationEditor from '../plugins/annotationEditor/annotationEditor'
import SequenceBar from '../plugins/intervalMarker/alternateProgressbar'
import * as VC from '@soccerwatch/videocanvas'
import './editor.scss'
import Content from '../layout/content'
import MatchItem from '../utility/matches/matchItem'
import { applyOmniFilter, OmniFilterUi } from '../utility/omniFilter/omniFilter'
import Sidebar from '../layout/sidebar'
import { Link } from 'react-router-dom'
import ClipDetails from '../utility/clips/clipDetails'
import SequenceList from '../utility/sequences/sequenceList'
import * as DeviceCatigorisation from '../../lib/helper/deviceCatigorisation'
import Recorder from '../plugins/recorder/recorder'
import i18next from 'i18next'
import { getPersistedMetersPerPixel } from '../plugins/fieldCalibration/PersistCalibration'
import { FieldCalibrationPlugin } from '../plugins/fieldCalibration/FieldCalibrationPlugin'
import FallbackCorners from '../utility/FallbackCorners'
import { getAuthorization } from '../firebase'
import VortexSpinner from '../utility/spinner/vortex'
// import Collapsible from 'react-collapsible'
const SHOW_DEV_RECORDER = false
const VideoCanvas = VC.Video

class Editor extends Component {
  /**
   * @type {HTMLVideoElement}
   */
  videoRef = null

  constructor(props) {
    super(props)
    // console.log('Editor Props', props)
    this.isMobile = DeviceCatigorisation.isMobile()
    this.isSafari = DeviceCatigorisation.isSafari()
    this.isAndroidFirefox = DeviceCatigorisation.isAndroid() && DeviceCatigorisation.isFirefox()
    this.isIOS = DeviceCatigorisation.isIOS()
    this.isAppleMobile = DeviceCatigorisation.isAppleMobile()
    this.unsuportedBrowser = ((!this.isMobile && !this.isIOS && this.isSafari) || this.isAndroidFirefox)
    this.browserNotice = this.isMobile ? 'Bitte verwenden sie die aktuelle Version des Chrome Browsers' : 'Bitte verwenden sie die aktuelle Version von Chrome oder Firefox.'
    this.proposeFullScreen = (window.innerWidth < 776)
    this.videoTimeSubscribers = {}
    const innerHeight = window.innerHeight
    this.state = {
      trashcan: [],
      controls: true,
      recordedPath: [],
      recording: false,
      Paper: false,
      drawMode: true,
      tagMode: false,
      sidebar: false,
      toolbar: false,
      color: 'red',
      stroke: 1,
      touchEnabled: true,
      isCollapsed: true,

      iPhoneOverwriteSrc: '',
      iPhoneOverwriteSrcVr: '',

      innerHeight,

      editClipMode: false,
      localClipSequence: [],
      sidebarOpen: false,
      vrMode: props.forceVr,
      vrSrc: false,
      cornerPoints: null,
      fieldCalibrationActive: false,
      metersPerPixel: 0.024866266730804387,
      authHeader: null
    }
    this.props.setCurrentSite()
    this.sidebarRef = React.createRef()
    this.getCurrentAuthHeader()

  }

  getCurrentAuthHeader = async () => {
    const authHeader = await getAuthorization()
    this.setState({ authHeader })
  }

  componentDidUpdate(prevProps) {
    if (this.isAppleMobile && this.props.clipEditor && ((prevProps.src !== this.props.src) || (prevProps.vrSrc !== this.props.vrSrc))) {
      this.getIOsOverrideSrc(this.props.src)
    }
    if (!prevProps.authReady && this.props.authReady) {
      this.getCurrentAuthHeader()
    }
    if (this.props.clipEditor) {
      if (!prevProps.sequencesLoaded && this.props.sequencesLoaded) {
        this.props.setClipContext(this.props.clip)
      }
    }
    if (!prevProps.forceVr && this.props.forceVr) {
      this.setState({ vrMode: true })
    }
    if ((this.props.matchEditor && !prevProps.match && this.props.match) ||
      (this.props.sequenceEditor && !prevProps.sequence && this.props.sequence)) {
      this.getCornerPoints()
    }

    if (!prevProps.match && this.props.match) {
      getPersistedMetersPerPixel(this.props.match.swcsID, this.props.getAuthorization)
        .then(metersPerPixel => {
          if (metersPerPixel) {
            this.setState({ metersPerPixel })
          }
        })
        .catch(console.error)
    }
    console.log('src', this.state.src, 'vrSrc', this.state.vrSrc)
  }

  componentDidMount() {
    if (this.props.src && this.props.src !== '' && this.props.clipEditor && this.isAppleMobile) {
      this.getIOsOverrideSrc(this.props.src)
    }
    window.addEventListener('resize', this.updateInnerHeight)
    if ((this.props.matchEditor && this.props.match) || (this.props.sequenceEditor && this.props.sequence)) {
      this.getCornerPoints()
    }
  }

  getCornerPoints = async () => {
    let matchId
    if (this.props.matchEditor || this.props.sequenceEditor) {
      matchId = this.props.match?.RowKey
    } else {
      console.warn('Using FallBack MatchId to getCornerPoints')
      this.setState({ cornerPoints: FallbackCorners })
      return
    }
    const cornerPoints = await this.props.getMatchCornerPoints(matchId)

    if (!cornerPoints || !cornerPoints.panorama_corners) {
      this.setState({ cornerPoints: FallbackCorners })
      return
    }

    // Video calculation used to have a bug resulting in the corner points being off by a factor of 1.111. This bug
    // was fixed by June 2020. If the match was created (or took place) before June 2020, apply this fix manually.
    const matchDate = this.props.match && this.props.match.startTime
      ? new Date(this.props.match.startTime)
      : new Date(0)
    const bugFixDate = new Date(2020, 6, 1)
    const applyVideoFormatBugQuickfix = matchDate < bugFixDate
    if (applyVideoFormatBugQuickfix) {
      cornerPoints.panorama_corners = cornerPoints.panorama_corners.map((p, i) => i === 3 || i === 5 ? p * 1.111 : p)
    }

    // console.log(cornerPoints)

    this.setState({ cornerPoints })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInnerHeight)
  }

  toggleVrMode = () => {
    const vrMode = !this.state.vrMode
    // touch enabled - implicitly switch to hand mode (tool switch is currently "forced" because tool is re-instanciated)
    this.setState({ vrMode, touchEnabled: true })
  }

  updateInnerHeight = () => {
    const innerHeight = window.innerHeight
    this.setState({ innerHeight })
  }

  getIOsOverrideSrc = async (src) => {
    const auth = await this.props.getAuthorization()
    const iPhoneOverwriteSrc = this.props.getIOSClipPath(this.props.clip.RowKey, auth, false)
    const iPhoneOverwriteSrcVr = this.props.getIOSClipPath(this.props.clip.RowKey, auth, true)
    this.setState({ iPhoneOverwriteSrc, iPhoneOverwriteSrcVr })
  }

  setColor = (e) => {
    const color = e.valueText
    this.setState({ color })
  }

  setStroke = (e) => {
    const stroke = e.value || parseInt(e.valueText, 10)
    if (!stroke || isNaN(stroke)) {
      console.error('<Editor>: Could not set Stroke; Event:', e)
      return
    }
    this.setState({ stroke })
  }

  activateTouch = () => {
    this.setState({ touchEnabled: true })
  }

  deactivateTouch = () => {
    this.setState({ touchEnabled: false })
  }

  addAnnotationToSequence = async (annotation) => {
    console.log('New Annotation:', annotation)
  }

  /**
   * @param {VC.VideoCanvasController} videoParent
   * @param {VC.VideoCanvasControllerStateType} videoState
   * @param {HTMLVideoElement} video
   * @param {paper.PaperScope} paper
   * @param {paper.Layer} drawingLayer
   */
  deliverPlugins = (videoParent, videoState, video, paper, drawingLayer) => {
    const plugins = []

    if (video && ((!this.videoRef) || (this.videoRef.src !== video.src))) {
      this.videoRef = video
      if (!this.props.sequenceEditor && window.GlobalCoachingTool.timeReference) {
        this.seekTo(window.GlobalCoachingTool.timeReference)
        window.GlobalCoachingTool.timeReference = 0
      }
      if (this.props.sequenceEditor && window.GlobalCoachingTool.sequenceTimeReference) {
        this.seekTo(window.GlobalCoachingTool.sequenceTimeReference)
        window.GlobalCoachingTool.sequenceTimeReference = 0
      }
    }

    if (this.state.fieldCalibrationActive && this.state.vrMode) {
      plugins.push(this._deliverCalibrateFieldPlugin(videoParent, videoState, video, paper, drawingLayer))
    } else if (this.props.isVideoView) {
      plugins.push(this.pluginsForVideoViewMode(videoParent, videoState, video, paper, drawingLayer))
    } else {
      plugins.push(this.pluginsForClipEditMode(videoParent, videoState, video, paper, drawingLayer))
    }

    return plugins
  }

  goToSequenceEdit = (sequence) => {
    if (this.videoRef) {
      window.GlobalCoachingTool.timeReference = this.videoRef.currentTime
      const sequenceTime = this.props.clipEditor ? this.videoRef.currentTime - sequence.relativeStartTime
        : (this.videoRef.currentTime - sequence.from) + sequence.relativeStartTime
      if (sequenceTime >= 0 && sequenceTime < sequence.relativeEndTime) {
        window.GlobalCoachingTool.sequenceTimeReference = sequenceTime
      }
    }
    this.props.history.push(`./sequence/${sequence.RowKey}/`)
  }

  closeSidebar = () => {
    if (window.GlobalCoachingTool && window.GlobalCoachingTool.setSidebarOpen) {
      window.GlobalCoachingTool.setSidebarOpen(false)
    }
    // const sidebar = this.sidebarRef.current
    // if (sidebar) {
    //   sidebar.setIsOpen(false)
    // }
  }

  getVideoSidebarContentAndFilteredSequences() {
    const { filterStateSequence, knownSequenceLabel, addToClip, removeFromClip } = this.props

    const omniFilterConfig = [
      { filterType: 'sequence', label: 'Szene Name', property: 'name', type: 'text' },
      { filterType: 'sequence', label: 'Label', property: 'label', type: 'option', options: knownSequenceLabel, singleValue: false },
      { filterType: 'sequence', label: 'Szene Datum', property: 'date', type: 'date' }
    ]
    const sequences = this.props.sequences ? applyOmniFilter(this.props.sequences, omniFilterConfig, filterStateSequence) : []
    const match = { ...this.props.match }
    const sequencesHidden = this.props.sequences ? this.props.sequences.length - sequences.length : 0
    const type = this.state.vrMode ? 'Vr' : 'Basic'
    const sidebarContent = this.props.match && this.props.sequences ? <MatchItem
      match={match}
      sequences={sequences}
      sequencesLoaded={this.props.sequences}
      extendable={false}
      showSequences
      showAnnotations
      useColor
      knownSequenceLabel={this.props.knownSequenceLabel}
      afterSeek={this.closeSidebar}
      history={this.props.history}
      createSequence={this.props.createSequence}
      editSequence={this.goToSequenceEdit}
      clipSequence={this.props.clipSequence}
      clipActions={{ addToClip, removeFromClip }}
      updateSequence={this.props.updateSequence}
      updateAnnotation={this.props.updateAnnotation}
      filterAnnotations={type}
      deleteSequence={(seq) => (confirm) => {
        if (confirm) {
          this.props.deleteSequence(seq)
        }
      }}
      needsConfirm
    >
      <>
        <OmniFilterUi
          onFilterRemove={this.props.omniFilterRemove}
          onFilterAdd={this.props.omniFilterAdd}
          config={omniFilterConfig}
          filterPrefix='filter'
          filters={filterStateSequence}
        />
        {Boolean(sequencesHidden) && <div style={{ color: 'white' }}><span>{sequencesHidden} Szenen Ausgeblendet</span></div>}
      </>
    </MatchItem> : null
    return { sidebarContent, filteredSequences: sequences, filteredMatch: match }
  }

  /**
   * @param {VC.VideoCanvasController} videoParent
   * @param {VC.VideoCanvasControllerStateType} videoState
   * @param {HTMLVideoElement} video
   * @param {paper.PaperScope} paper
   * @param {paper.Layer} drawingLayer
   */
  pluginsForVideoViewMode = (videoParent, videoState, video, paper, drawingLayer) => {
    if (!this.props.match) {
      return
    }

    const { sequenceMap, match } = this.props
    const sequences = (sequenceMap && sequenceMap[match.RowKey] ? sequenceMap[match.RowKey] : [])
      .sort((a, b) => a.timestamp - b.timestamp)

    const annotationType = this.state.vrMode ? 'Vr' : 'Basic'
    const annotations = this.getFlatAnnotations(sequences, false, annotationType)
    const SidebarContainer = document.getElementById('Sidebar')
    const ContextButtonsContainer = document.getElementById('ContextButtons')
    const { sidebarContent, filteredSequences, filteredMatch } = this.getVideoSidebarContentAndFilteredSequences()
    const intervals = filteredSequences.map((seq) => {
      return {
        startTime: seq.from,
        duration: seq.to - seq.from,
        color: (materialColor(seq.timestamp + '')).backgroundColor,
        onClick: () => {
          this.seekTo(seq.from)
        }
      }
    })
    const useAnnotations = DeviceCatigorisation.isIOS() ? [] : annotations
    return (<React.Fragment key={'videoModePlugins'}>
      <AnnotationEditor
        createSequence={this.props.createSequence}
        paper={paper}
        video={video}
        videoState={videoState}
        videoParent={videoParent}
        videoController={videoParent && videoParent.props ? videoParent.props.videoController : null}
        match={filteredMatch || this.props.match}
        matchId={this.props.match.RowKey}
        sequences={filteredSequences || sequences}
        annotations={useAnnotations}
        annotationsLoading={this.props.sequencesLoading}
        disableControls={this.disableControls}
        enableControls={this.enableControls}
        drawingLayer={drawingLayer}
        sidebarContainer={SidebarContainer}
        sidebarContent={sidebarContent}
        ContextButtonsContainer={ContextButtonsContainer}
        // color={this.state.color}
        uploadAnnotation={this.props.uploadAnnotation}
        updateAnnotation={this.props.updateAnnotation}
        updateSequence={this.props.updateSequence}
        history={this.props.history}
        clipEditor={this.props.clipEditor}
        matchEditor={this.props.matchEditor}
        tagDefinitions={this.props.tagDefinitions}
        sequenceEditor={this.props.sequenceEditor}
        useAbsoluteTimestamps
        noToggle
        useVrPlayer={this.state.vrMode}
        subscribeToVideoTime={this.subscribeToVideoTime}
        unsubscribeFromVideoTime={this.unsubscribeFromVideoTime}
        metersPerPixel={this.state.metersPerPixel}
      />
      {
        video && <SequenceBar intervals={intervals} totalDuration={video.duration} />
      }
      {SHOW_DEV_RECORDER && !this.isMobile && <Recorder video={video} paper={paper} videoParent={videoParent} />}
    </React.Fragment>
    )
  }

  /**
   * @private
   * @param {number} metersPerPixel
   */
  _onFieldCalibrationFinished = (metersPerPixel) => {
    this.setState({ fieldCalibrationActive: false, metersPerPixel })
  }

  /**
   * @private
   */
  _onFieldCalibrationAborted = () => {
    console.warn('Field calibration aborted!')
    this.setState({ fieldCalibrationActive: false })
  }

  /**
   * @private
   * @param {VC.VideoCanvasController} videoParent
   * @param {VC.VideoCanvasControllerStateType} videoState
   * @param {HTMLVideoElement} video
   * @param {paper.PaperScope} paper
   * @param {paper.Layer} drawingLayer
   */
  _deliverCalibrateFieldPlugin = (videoParent, videoState, video, paper, drawingLayer) => {
    const sidebarContainer = document.getElementById('Sidebar')
    const ContextButtonsContainer = document.getElementById('ContextButtons')
    return <FieldCalibrationPlugin
      key={'fieldCalibrationPlugin'}
      {...{ videoParent, videoState, video, paper, drawingLayer, ContextButtonsContainer }}
      onDone={this._onFieldCalibrationFinished}
      onAbort={this._onFieldCalibrationAborted}
      activateTouch={this.activateTouch}
      deactivateTouch={this.deactivateTouch}
      sidebarContainer={sidebarContainer}
      fieldID={this.props.match.swcsID}
      getAuthorization={this.props.getAuthorization}
    />
  }

  updateClip = (isCreate) => async (clip) => {
    const result = isCreate ? await this.props.createClip(clip) : await this.props.updateClip(clip)
    if (!result) {
      // ERROR CASE
      return
    }
    const URL = `/clips/${result.RowKey}/`
    this.props.history.push(URL)
  }

  getClipSidebarContent = () => {
    const type = this.state.vrMode ? 'Vr' : 'Basic'
    if (this.props.clipEditor) {
      return <ClipDetails
        clip={this.props.clip}
        updateSequence={this.props.updateSequence}
        updateClip={this.props.updateClip}
        editSequence={this.goToSequenceEdit}
        afterSeek={this.closeSidebar}
        publishClip={this.props.toggleClipPublicity}
        knownSequenceLabel={this.props.knownSequenceLabel}
        updateAnnotation={this.props.updateAnnotation}
        editClip={this.setClipContext}
        showAnnotations
        showLabel
        useColor
        disableControls
        showSequences
        filterAnnotations={type}
        deleteSequence={(seq) => (confirm) => {
          if (confirm) {
            this.props.deleteSequence(seq)
          }
        }}
        needsConfirm
      />
    } else {
      if (!this.props.sequence) {
        return null
      }
      const sequences = [this.props.sequence]
      return <SequenceList
        afterSeek={this.closeSidebar}
        knownSequenceLabel={this.props.knownSequenceLabel}
        sequences={sequences}
        useAnnotationColor
        updateSequence={this.props.updateSequence}
        updateAnnotation={this.props.updateAnnotation}
        deleteAnnotation={this.props.deleteAnnotation}
        disableLabelShowMore
        useRelativeTimestampForAnnotations
        showFullAnnotationTime
        // useRelativeTimestamp
        mockClipCardContainer
        showAnnotations
        showLabel
        filterAnnotations={type}
      />
    }
  }

  /**
   * @param {VC.VideoCanvasController} videoParent
   * @param {VC.VideoCanvasControllerStateType} videoState
   * @param {HTMLVideoElement} video
   * @param {paper.PaperScope} paper
   * @param {paper.Layer} drawingLayer
   */
  pluginsForClipEditMode = (videoParent, videoState, video, paper, drawingLayer) => {
    if (!this.props.clip && !this.props.sequence) {
      return
    }
    const ToolbarContainer = document.getElementById('Toolbar')
    const SidebarContainer = document.getElementById('Sidebar')
    const ContextButtonsContainer = document.getElementById('ContextButtons')
    const sequences = this.props.clip ? this.props.clip.sequence : [this.props.sequence]
    const annotationType = this.state.vrMode ? 'Vr' : 'Basic'
    const annotations = this.getFlatAnnotations(sequences, true, annotationType)
    const intervals = this.props.clipEditor ? sequences.map((seq) => {
      return {
        startTime: seq.relativeStartTime,
        duration: seq.relativeEndTime - seq.relativeStartTime,
        color: (materialColor(seq.timestamp + '')).backgroundColor,
        onClick: () => {
          this.seekTo(seq.relativeStartTime)
        }
      }
    }) : this.props.sequence && annotations ? annotations.map((Anno) => {
      const start = Anno.relativeTimestamp - 1 >= 0 ? Anno.relativeTimestamp - 1 : 0
      return {
        startTime: start,
        duration: 2,
        color: (materialColor(Anno.relativeTimestamp + '')).backgroundColor,
        onClick: () => {
          this.seekTo(Anno.relativeTimestamp - 2)
        }
      }
    }) : []
    const ActionsContainer = this.props.sequenceEditor ? document.getElementById('ActionsContainer') : undefined
    const QuickbarContainer = this.props.sequenceEditor ? document.getElementById('QuickbarContainer') : undefined
    const sidebarContent = this.getClipSidebarContent()
    const useAnnotations = DeviceCatigorisation.isIOS() ? [] : annotations
    return <React.Fragment key={'clipEditModePlugins'}>
      <AnnotationEditor
        createSequence={this.props.createSequence}
        activateTouch={this.activateTouch}
        deactivateTouch={this.deactivateTouch}
        paper={paper}
        video={video}
        videoState={videoState}
        videoParent={videoParent}
        videoController={videoParent && videoParent.props ? videoParent.props.videoController : null}
        newAnnotation={this.addAnnotationToSequence}
        match={this.props.match}
        sequences={sequences}
        annotations={useAnnotations}
        annotationsLoading={false}
        disableControls={this.disableControls}
        enableControls={this.enableControls}
        drawingLayer={drawingLayer}
        sidebarContainer={SidebarContainer}
        sidebarContent={sidebarContent}
        actionsContainer={ActionsContainer}
        toolbarContainer={ToolbarContainer}
        quickbarContainer={QuickbarContainer}
        ContextButtonsContainer={ContextButtonsContainer}
        color={this.state.color}
        setColor={this.setColor}
        stroke={this.state.stroke}
        setStroke={this.setStroke}
        uploadAnnotation={this.props.uploadAnnotation}
        updateAnnotation={this.props.updateAnnotation}
        updateSequence={this.props.updateSequence}
        editSingleSequence={this.props.sequenceEditor}
        drawMode={this.props.sequenceEditor}
        history={this.props.history}
        clipEditor={this.props.clipEditor}
        videoEditor={this.props.videoEditor}
        sequenceEditor={this.props.sequenceEditor}
        noToggle
        useVrPlayer={this.state.vrMode}
        subscribeToVideoTime={this.subscribeToVideoTime}
        unsubscribeFromVideoTime={this.unsubscribeFromVideoTime}
        metersPerPixel={this.state.metersPerPixel}
      />
      {
        video && <SequenceBar intervals={intervals} totalDuration={video.duration} />
      }
      {SHOW_DEV_RECORDER && !this.isMobile && <Recorder video={video} paper={paper} videoParent={videoParent} />}
    </React.Fragment>
  }

  getFlatAnnotations = (sequences, inClip = false, type = 'Basic') => {
    return sequences.map((seq) => {
      if (!seq.annotations) {
        return []
      }
      return seq.annotations.map((anno) => {
        if (inClip) {
          anno.relativeTimestamp = seq.relativeTimestamp + (anno.timestamp - seq.timestamp)
        } else {
          anno.relativeTimestamp = anno.timestamp// - seq.offset
        }
        return anno
      })
    }).flat()
      .filter((anno) => anno.type === type)
      .sort((a, b) => a.relativeTimestamp - b.relativeTimestamp)
  }

  disableControls = () => {
    this.setState({ controls: false })
  }

  enableControls = () => {
    this.setState({ controls: true })
  }

  doPauseOnClick = () => {
    return this.state.touchEnabled ? VideoCanvas.playPauseOnOptions.space : VideoCanvas.playPauseOnOptions.none
  }

  getTouchBehaviour = () => {
    return this.state.touchEnabled && !(this.state.fieldCalibrationActive && this.state.vrMode) ? VideoCanvas.dragCanvasKey.leftMouseButton : VideoCanvas.dragCanvasKey.rightMouseButton
  }

  seekTo = (time) => {
    if (this.videoRef) {
      this.videoRef.currentTime = time
    }
  }

  omniFilterAdd = (prop, value) => {
    const current = [...this.state[prop]]
    if (current.includes(value)) {
      return
    }
    current.push(value)
    this.setState({ [prop]: current })
  }

  omniFilterRemove = (prop, value) => {
    const current = [...this.state[prop]]
    const index = current.findIndex((v) => v === value)
    if (index > -1) {
      current.splice(index, 1)
      this.setState({ [prop]: current })
    }
  }

  toggleClipEdit = (next = null) => {
    const editClipMode = !this.state.editClipMode
    const localClipSequence = editClipMode ? [...this.props.clip.sequence] : []
    this.setState({ editClipMode, localClipSequence, matchListOverlayOpen: editClipMode }, () => {
      if (typeof next !== 'function') {
        return
      }
      return next()
    })
  }

  removeFromClip = (index) => {
    if (index < 0) {
      return
    }
    const localClipSequence = JSON.parse(JSON.stringify(this.state.localClipSequence))
    localClipSequence.splice(index, 1)
    this.setState({ localClipSequence })
  }

  createClip = async (clip) => {
    const newClip = await this.props.createClip(clip)
    this.props.history.push(`/clips/${newClip.RowKey}/`)
  }

  setClipContext = () => {
    const clip = this.props.clip
    this.props.setClipContext(clip)
    this.props.history.push('/matches/')
  }

  clickForFullscreen = () => {
    const fsBtn = document.querySelector('.controls-button.fullscreen')
    if (fsBtn) {
      fsBtn.click()
    }
  }

  setIsCollapsed = (isCollapsed) => {
    this.setState({ isCollapsed })
  }

  subscribeToVideoTime = (name, fn) => {
    this.videoTimeSubscribers[name] = fn
  }

  unsubscribeFromVideoTime = (name) => {
    if (this.videoTimeSubscribers[name]) {
      delete this.videoTimeSubscribers[name]
    }
  }

  notifyVideoProgress = (currentTime) => {
    Object.keys(this.videoTimeSubscribers).forEach((id) => {
      const fn = this.videoTimeSubscribers[id]
      fn(currentTime)
    })
  }

  getAuthorizationString = () => {
    return this.state.authHeader
  }

  render() {
    const { matchEditor, clipEditor
      // ,sequenceEditor
    } = this.props

    const authData = this.props.isVideoView ? {} : {
      needAuthorization: true,
      getAuthorization: this.getAuthorizationString
    }
    let src = this.isAppleMobile && this.props.clipEditor ? this.state.iPhoneOverwriteSrc : this.props.src
    if (this.state.updateSrc || !src) {
      src = ''
    }
    let vrSrc = this.isAppleMobile && this.props.clipEditor ? this.state.iPhoneOverwriteSrcVr : this.props.vrSrc
    if (this.state.updateSrc || !vrSrc) {
      vrSrc = ''
    }
    if (!window.logged && this.props.src) {
      window.logged = true
    }
    if (this.props.clipEditor && !this.state.authHeader) {
      return <div className='editorSite'>
        <VortexSpinner />
      </div>
    }
    return (
      <div className='editorSite'>
        {this.unsuportedBrowser &&
          <div className='useOtherBrowserNotice row'>
            <p className='navItemBack col-xs-2' onClick={() => {
              if (window.GlobalCoachingTool) {
                window.GlobalCoachingTool.goBack()
              }
            }}>
              <span className='navIcon mbsc-ic mbsc-ic-fa-chevron-left' />
              Zurück
            </p>
            <h3 className='col-xs-10'>
              Der Coachingtool Videoeditor ist in ihrem aktuellen Browser leider nicht lauffähig.
              {this.browserNotice}
            </h3>
          </div>
        }
        <Content disablePathMap history={this.props.history}>
          <div className='VideoContainer' style={{ height: this.state.innerHeight }}>
            <VideoCanvas
              disablePrecissionSeek
              controls={this.state.controls}
              playPauseOn={VideoCanvas.playPauseOnOptions.spaceButtonPress}
              dragCanvasKey={this.getTouchBehaviour()}
              src={src}
              vrSrc={vrSrc}                             //! videoType is Deprecated                                       // Use videoProcessing going forward
              isQuarterBoxPanorama={(this.props.match && (this.props.match.videoType === 'quarterBoxPanorama' || this.props.match.videoProcessing === 'quarterBoxPanorama')) || (this.props.sequenceEditor && this.props.sequence.isVrSequence)}
              // forceHLS={this.state.vrMode}
              disableSkipButtons
              keyboardShortcuts={{}}
              disableDefaultShortcuts
              evaluatePlugins={this.deliverPlugins}
              activateTouchControls={this.state.touchEnabled}
              FullScreenTarget={this.FullScreenTarget}
              useVrPlayer={this.state.vrMode}
              vrGridOpacity={0}
              vrGridCorners={this.state.cornerPoints}
              onProgress={this.notifyVideoProgress}
              // tryForceFullscreen={this.tryForceFullscreen}
              drawOnField
              showPlaybackRateSetter
              playbackRateSteps={[0.25, 0.5, 1, 1.5, 2, 4, 8]}
              {...authData}
            >
              <Sidebar
                noNav
                ribbon
                inlineHeader
                ignoreBreakpoint
                matchCount={this.props.matchCount}
                clipCount={this.props.clipCount}
                history={this.props.history}
                user={this.props.user}
                ribbonLabel={<span className='mbsc-ic mbsc-ic-material-menu' />}
              >
                {matchEditor && !!this.props.clipSequence.length &&
                  <Link style={{ marginLeft: '1em', marginRight: '1em' }} className='toClip btn_fluid success' to={`/matches/`}>
                    Zum Clip ({this.props.clipSequence ? this.props.clipSequence.length : '0'} Situationen)
                  </Link>}
                {clipEditor &&
                  <Link to='#' style={{ marginLeft: '1em', marginRight: '1em' }} onClick={this.setClipContext} className='editClip btn_fluid success'>
                    Clip Bearbeiten
                  </Link>}
                <div className='col-xs sidebarButtonContainer'>
                  {!!this.props.vrSrc && !this.props.forceVr && <button className='btn info' onClick={this.toggleVrMode}>{this.state.vrMode ? 'Standard Mode' : 'Vr Mode'}</button>}

                  {/* Offer user to calibrate field when in VR mode */}
                  {this.state.vrMode
                    ? <button
                      className={'btn info'}
                      onClick={() => this.setState({ fieldCalibrationActive: true })}
                    >
                      {i18next.t('fieldCalibration.startCalibration')}
                    </button>
                    : null
                  }

                </div>
                {DeviceCatigorisation.isIOS() &&
                  <div className='IOS_WARNING'>
                    {i18next.t('IOS_WARNING')}
                  </div>
                }
                <div key='Sidebar' id='Sidebar' className={`SidebarContainer`} />
              </Sidebar>
              <div key='ContextButtons' id='ContextButtons' className='ContextButtonsContainer' />
              <div key='ActionsContainer' id='ActionsContainer' className='ActionsContainer' />
              <div key='QuickbarContainer' id='QuickbarContainer'>
                {/* {this.props.sequenceEditor &&
                <ColorButton color={this.state.color} setColor={this.setColor} />
                } */}
              </div>
            </VideoCanvas>
          </div>
        </Content>
      </div>
    )
  }
}

export default Editor
