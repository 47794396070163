
export const UserList = [
  '28', '23', '19', '20', '29', '30', '24', '31',
  '32', '22', '21', '17', '18', '16', '27', '48', '47'
]
export const HLAdminList = [
  '28', '19', '20', '29', '30', '24', '31',
  '32', '22', '21', '17', '18', '16', '27', '48', '47'
]
export default {
  UserList, HLAdminList
}
