import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CornerMarkerTool } from './CornerMarkerTool'
import { DisplayContextButtons } from '../annotationEditor/sub/displayContextButtons'
import { DirectionalPad } from './DirectionalPad'

/**
 * @param {CornerMarkerTool} cornerMarkerTool
 */
const useTouchControls = (cornerMarkerTool) => {
  const createMoveHandler = (direction) => (delta) => {
    if (cornerMarkerTool) {
      cornerMarkerTool.move(direction, delta)
    }
  }

  return <DirectionalPad
    onUp={createMoveHandler('up')}
    onDown={createMoveHandler('down')}
    onLeft={createMoveHandler('left')}
    onRight={createMoveHandler('right')}
  />
}

export const PointSelector = (props) => {
  /**
   * @type {[CornerMarkerTool?, React.Dispatch<React.SetStateAction<CornerMarkerTool?>>]}
   */
  const cornerMarkerToolRef = useRef()
  const [point, setPoint] = useState(props.startingPoint)
  const clearToolRef = useRef()

  const clearCornerMarkerTool = () => {
    if (cornerMarkerToolRef.current && cornerMarkerToolRef.current.clear) {
      cornerMarkerToolRef.current.clear()
    }
    cornerMarkerToolRef.current = undefined
  }
  clearToolRef.current = clearCornerMarkerTool

  const replaceCornerMarkerTool = (newTool) => {
    clearCornerMarkerTool()
    cornerMarkerToolRef.current = newTool
  }

  const reInstantiateCornerMarkerTool = (startingPoint = point) => {
    if (props.paper && props.onChange) {
      const onPointUpdate = (newPoint, eventType) => {
        setPoint(newPoint)
        props.onChange(newPoint, eventType)
      }

      const newCornerMarkerTool = new CornerMarkerTool(props.paper, onPointUpdate, startingPoint, props.activateTouch, props.deactivateTouch)
      replaceCornerMarkerTool(newCornerMarkerTool)
      
      newCornerMarkerTool.activate()
    }  
  }

  useEffect(() => {
    reInstantiateCornerMarkerTool()
    
    // Don't re-instantiate CornerMarkerTool when point changes. Point is only passed to constructor to not
    // loose the old position.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paper, props.activateTouch, props.deactivateTouch, props.onChange, props.pointIdentifier])
  
  // Set current point to new default iff point identifier has changed (meaning, a different point
  // is selected instead of just the point's coordinates having changed)
  useEffect(() => {
    setPoint(props.startingPoint)
    reInstantiateCornerMarkerTool(props.startingPoint)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pointIdentifier])

  useEffect(() => {
    // Clear CornerMarkerTool on unmount
    return clearToolRef.current
  }, [])

  const touchControls = useTouchControls(cornerMarkerToolRef.current)

  return (
    <DisplayContextButtons
      container={props.ContextButtonsContainer}
    >
      {touchControls}
    </DisplayContextButtons>
  )
}

PointSelector.propTypes = {
  paper: PropTypes.any,
  ContextButtonsContainer: PropTypes.instanceOf(Element),
  activateTouch: PropTypes.func,
  deactivateTouch: PropTypes.func,
  onChange: PropTypes.func,
  pointIdentifier: PropTypes.any,
}

PointSelector.defaultProps = {}
