import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import BG from '../../../img/staigeBackgroundOrange.png'
import I18NButtons from '../../utility/I18NButtons'
import LogoutButton from '../../utility/logoutButton'
class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  render () {
    // const { recentMatchesLoaded, recentMatches, clipsLoaded, recentClips } = this.props
    return (
      // <div className='col-xs dashboard' style={{ backgroundImage: `url("${BG}")` }}>
      <div className='col-xs dashboard' >

        <div className='intro'>

          <div className='logoArea'>
            <Link to='/'>
              <img alt='background' src='https://storage.googleapis.com/aisw-assets/logo/staige/staigeOrange.svg' className='coachingtoolLogo' />
            </Link>
          </div>
          <div className='welcome'>
            <h2>Coach</h2>
          </div>
          <div className='entry'>
            <Link className='btn boyzone' to='/matches/' >
              {i18n.t('dashboard.toGames')}
            </Link>
            <Link className='btn boyzone' to='/clips/' >
              {i18n.t('dashboard.toClips')}
            </Link>
          </div>
          <LogoutButton />
      
          <div className='col-xs-'>
            <Link className='btn boyzone subButton' to='/user/'>
              {i18n.t('sidebar.subs')}
            </Link>
            <I18NButtons />
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
