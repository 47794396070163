import React from 'react'
import mobiscroll from '@mobiscroll/react'
import materialColor from 'material-color-hash'
import './labelList.scss'
import i18n from 'i18next'
import AutoComplete from '../autocomplete'

function materialColorStaige(label){

  let hashCode = function(label) {
    var hash = 0,
      i, chr;
    if (label.length === 0) return hash;
    for (i = 0; i < label.length; i++) {
      chr = label.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  let colors = ['#345995','#E6AF2E','#FF3517','#43281C ','#034C3C']
  console.log(hashCode(label))
  return {
    backgroundColor: colors[hashCode(label)%colors.length]+'ff',
    color: '#ffffff',
    materialColorName: 'staige-'+label
  }
}


const prompt = (callback) => (e) => {
  e.stopPropagation()
  const videoElement = document.querySelector('.video-player') || document.body
  return mobiscroll.prompt({
    placeholder: i18n.t('labelList.namePrompt'),
    inputType: 'text',
    context: videoElement,
    callback
  })
}
function Label ({ label, color, handleClick, editable, deleteLabel, i, className = '', rename }) {
  const addClass = handleClick || editable ? '' : 'noClick'
  return <small key={label} className='labelTagOuter'>
    <div className={`btn label ${addClass} ${className}`} style={color}>
      <span className='labelTagInner' onClick={(handleClick || (() => {}))}>{label}&nbsp;
        { rename && <span className='mbsc-ic mbsc-ic-material-edit' />}
        {editable &&
        <span onClick={deleteLabel(i)} className='mbsc-ic mbsc-ic-fa-times' />
        }</span>
    </div>
  </small>
}

function getLogoSrc (id) {
  if (!id || id === undefined || id === '') {
    return 'https://storage.googleapis.com/sw-sc-de-assets/assets/assets_clubq.png'
  }
  return `https://storage.googleapis.com/sw-sc-de-assets/clubLogo/club-${id}.png`
}

export default function LabelList (props) {
  const {
    disableShowMore,
    baseLabel,
    label = [],
    onChange,
    editable,
    disableTagCreate,
    knownSequenceLabel,
    onClick, showClubIconsInBaseLabel } = props
  const [modalOpen, setModalOpen] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)
  const [clubAId, setClubAId] = React.useState(props.clubAId)
  const [clubBId, setClubBId] = React.useState(props.clubBId)
  const onError = (isClubA) => (e) => {
    isClubA ? setClubAId(false) : setClubBId(false)
  }

  const toggleShowMore = (e) => {
    e && e.stopPropagation()
    setShowMore(!showMore)
  }

  const deleteLabel = (index) => (e) => {
    e.stopPropagation()
    const changedLabel = label.filter((l, i) => i !== index)
    onChange(changedLabel)
  }
  const addLabel = async (name) => {
    if (!name || !name.length) {
      return
    }
    if (label.map((l) => l.toLowerCase()).includes(name.toLowerCase())) {
      return
    }
    if (!showMore) {
      toggleShowMore()
    }
    const changedLabel = [...label, name]
    onChange(changedLabel)
  }
  const changeLabel = (index) => (newName) => {
    if (!newName || !newName.length) {
      return
    }
    if (label.map((l) => l.toLowerCase()).includes(newName.toLowerCase())) {
      return
    }
    const changedLabel = [...label]
    changedLabel[index] = newName
    onChange(changedLabel)
  }
  // const toggleModal = () => {
  //   setModalOpen(!modalOpen)
  // }
  const itemList = knownSequenceLabel ? knownSequenceLabel.filter((l) => !label.includes(l)) : []
  const labelLeft = label.filter((l) => l !== baseLabel)
  const labelList = (showMore || disableShowMore) ? labelLeft.map((label, i) => {
    const handleClick = editable ? prompt(changeLabel(i)) : onClick ? onClick(label) : undefined
    const color = materialColorStaige(label)
    const labelList = <Label key={label} label={label} color={color} editable={editable} handleClick={handleClick} deleteLabel={deleteLabel} i={i} />
    return labelList
  }).filter((i) => !!i) : []
  let baseLabelElement = null
  if (baseLabel) {
    const handleClick = props.sequenceFromTag ? undefined : prompt(props.updateBaseLabel)
    const color = materialColorStaige(baseLabel)
    console.log(color)
    baseLabelElement = <Label key='%%BASELABEL%%' label={baseLabel} color={color} handleClick={handleClick} rename={!props.sequenceFromTag} />
    labelList.unshift(baseLabelElement)
  }
  if (showClubIconsInBaseLabel) {
    labelList.unshift(<div key='%%CLUBB%%' className='clubLogoContainer labelTag clubB' ><img onError={onError(false)} src={getLogoSrc(clubBId)} className='image clubLogo clubBLogo' alt='Club B logo' /></div>)
    labelList.unshift(<div key='%%CLUBA%%' className='clubLogoContainer labelTag clubA' ><img onError={onError(true)} src={getLogoSrc(clubAId)} className='image clubLogo clubALogo' alt='Club A Logo' /></div>)
  }
  if (editable && !disableTagCreate) {
    const AddButton = <Label key='%%ADDBUTTON%%' className='info' label={<>{i18n.t('labelList.new')} <span className='mbsc-ic mbsc-ic-fa-plus' /></>}
    // handleClick={prompt(addLabel)}
      handleClick={() => { setModalOpen(true) }}
    />
    labelList.push(AddButton)
  }
  return <div className='LabelList'>
    <AutoComplete
      open={modalOpen}
      onSelect={addLabel}
      itemList={itemList}
      close={() => { setModalOpen(false) }}
    />
    <div className='content' style={{ display: 'flex', flexWrap: 'wrap' }}>
      {labelList}
      {!disableShowMore && !!labelLeft.length && <small><span className='btn info' onClick={toggleShowMore}>{showMore ? <span className='mbsc-ic mbsc-ic-fa-times' /> : i18n.t('labelList.more')}</span></small>}
    </div>
  </div>
}
