import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class Arrow extends Component {
  onMouseDown = (evt, path, redraw) => {
    this.min = evt.localPoint
    path = new this.props.Paper.Path.Line(this.min, this.min)
    const color = redraw && redraw.strokeColor ? redraw.strokeColor : this.props.color
    path.fillColor = color
    return { path }
  }

  onMouseDrag = (evt, path) => {
    this.max = new this.props.Paper.Point(evt.localPoint)
    path.segments[1].point.set(this.max)
    const lineVector = this.max.subtract(this.min)
    const norm = lineVector.normalize(10)
    const normSmall = lineVector.normalize(7.5)
    const POnLine = this.max.subtract(norm)
    const OOnLine = this.max.subtract(normSmall)
    path.segments[1].point.set(OOnLine)
    const left = POnLine.rotate(25, this.max)
    const right = POnLine.rotate(-25, this.max)
    if (!path.segments[2]) {
      path.add(left)
    } else {
      path.segments[2].point.set(left)
    }
    if (!path.segments[3]) {
      path.add(right)
    } else {
      path.segments[3].point.set(this.max)
    }
    if (!path.segments[4]) {
      path.add(this.max)
    } else {
      path.segments[4].point.set(right)
    }
    if (!path.segments[5]) {
      path.add(this.max)
    } else {
      path.segments[5].point.set(OOnLine)
    }
    return { path }
  }

  onMouseUp = (evt, path) => {
    return { path }
  }

  render () {
    return (
      <ProtoTool
        key='Arrow'
        toolName='Arrow'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-arrow-up-right2' />
      </ProtoTool>
    )
  }
}

export default Arrow
