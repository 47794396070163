import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'
import { PlayerPositionService } from './PositionService/PlayerPositionService'

export const MetadataForm = (props) => {
  const onNumberInputChange = useCallback((setValue) => (event) => {
    const newValue = parseInt(event.target.value)
    setValue(Number.isNaN(newValue) ? '' : newValue)
  }, [])

  return <mobiscroll.Form theme={'ios'}>
    <mobiscroll.FormGroup>
      <mobiscroll.FormGroupTitle>{i18n.t('playerPositionTracker.metadataFormTitle')}</mobiscroll.FormGroupTitle>
      <mobiscroll.Input
        type="number"
        placeholder={i18n.t('playerPositionTracker.placeholder')}
        onChange={onNumberInputChange(props.setPlayerNumber)}
        value={props.playerNumber === undefined ? '' : props.playerNumber}
      >
        {i18n.t('playerPositionTracker.playerNumber')}
      </mobiscroll.Input>
      <mobiscroll.Input
        type="number"
        placeholder={i18n.t('playerPositionTracker.placeholder')}
        onChange={onNumberInputChange(props.setTeamId)}
        value={props.teamId === undefined ? '' : props.teamId}
      >
        {i18n.t('playerPositionTracker.teamId')}
      </mobiscroll.Input>
      <mobiscroll.Checkbox checked={props.userIsTrackingBall} onChange={props.toggleBallTracking}>
        {i18n.t('playerPositionTracker.trackBall')}
      </mobiscroll.Checkbox>
    </mobiscroll.FormGroup>
  </mobiscroll.Form>
}

MetadataForm.propTypes = {
  playerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  teamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setPlayerNumber: PropTypes.func.isRequired,
  setTeamId: PropTypes.func.isRequired,
  userIsTrackingBall: PropTypes.bool.isRequired,
  toggleBallTracking: PropTypes.func.isRequired,
}

export const useMetadataForm = () => {
  const [playerNumber, setPlayerNumber] = useState(undefined)
  const [oldPlayerNumber, setOldPlayerNumber] = useState(undefined)
  const [teamId, setTeamId] = useState(undefined)
  const [oldTeamId, setOldTeamId] = useState(undefined)
  const [userIsTrackingBall, setUserIsTrackingBall] = useState(false)

  // Create reference to player number so increment- and decrementPlayerNumber work even when cached
  const playerNumberReference = useRef(playerNumber)

  const onPlayerNumberChange = (newPlayerNumber) => {
    // Set playerNumber to undefined if input is deleted (newPlayerNumber === '')
    setPlayerNumber(typeof newPlayerNumber === 'number' ? newPlayerNumber : undefined)
    playerNumberReference.current = newPlayerNumber
    setUserIsTrackingBall(false)
  }

  const onTeamIdChange = (newTeamId) => {
    // Set teamId to undefined if input is deleted (newTeamId === '')
    setTeamId(typeof newTeamId === 'number' ? newTeamId : undefined)
    setUserIsTrackingBall(false)
  }

  const toggleBallTracking = () => {
    if (userIsTrackingBall) {
      setTeamId(oldTeamId)
      setPlayerNumber(oldPlayerNumber)
    } else {
      setOldTeamId(teamId)
      setOldPlayerNumber(playerNumber)

      setTeamId(PlayerPositionService.ballMetadata.Club)
      setPlayerNumber(PlayerPositionService.ballMetadata.PlayerNumber)
      playerNumberReference.current = PlayerPositionService.ballMetadata.PlayerNumber
    }

    setUserIsTrackingBall(!userIsTrackingBall)
  }

  const incrementPlayerNumber = () => onPlayerNumberChange((playerNumberReference.current || 0) + 1)
  const decrementPlayerNumber = () => onPlayerNumberChange((playerNumberReference.current || 0) - 1)

  const metadataFormElement = <MetadataForm
    playerNumber={playerNumber}
    teamId={teamId}
    setPlayerNumber={onPlayerNumberChange}
    setTeamId={onTeamIdChange}
    userIsTrackingBall={userIsTrackingBall}
    toggleBallTracking={toggleBallTracking}
  />

  return {
    playerNumber,
    teamId,
    metadataFormElement,
    incrementPlayerNumber,
    decrementPlayerNumber,
  }
}
