import React, { Component } from 'react'
import Matches from '../../utility/matches/matchList'
import mobiscroll from '@mobiscroll/react'
import ClipBox from '../../utility/clips/createClipUi'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import i18n from 'i18next'
import { OmniFilterUi, applyOmniFilter } from '../../utility/omniFilter/omniFilter'
import FastClipButtons from '../../utility/clips/fastClipButtons'
import VortexSpinner from '../../utility/spinner/vortex'
import DateFilter from '../../utility/omniFilter/dateFilter'
import { ForwardToPlayerTracking } from '../../ForwardToPlayerTracking/ForwardToPlayerTracking'
const { Popup } = mobiscroll

class MatchSite extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''

    }
    this.error = React.createRef()
    this.previewVid = React.createRef()
    this.props.setCurrentSite()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filterStateSequence && prevProps.filterStateSequence.sequence_name !== this.props.filterStateSequence.sequence_name) {
      const list = Array.from(document.querySelectorAll('.SequenceCountLabel.single'))
      if (list) {
        list.forEach((itm) => {
          itm.classList.add('highlight')
        })
        setTimeout(() => {
          if (list) {
            list.forEach((itm) => {
              itm.classList.remove('highlight')
            })
          }
        }, 500)
      }
    }
  }

  componentDidMount () {
    const el = document.querySelector('body')
    if (el.scrollTo) {
      el.scrollTo(0, 0)
    } else if (el.scroll) {
      el.scroll(0, 0)
    }
    window.scrollTo(0, 0)
  }

  onFilterChange = (prop, value) => {
    this.setState({ [prop]: value })
  }

  // tagToSequence = (match) => (item) => () => {
  //   const from = item.timestamp - 8 >= 0 ? item.timestamp - 8 : 0
  //   const to = item.timestamp + 8
  //   const duration = to - from
  //   const sequence = {
  //     from,
  //     to,
  //     timestamp: item.timestamp,
  //     duration,
  //     annotationIds: [],
  //     clipStream: match.userStream,
  //     name: item.name,
  //     label: [item.name],
  //     matchId: match.RowKey,
  //     clubAName: match.clubAName,
  //     clubBName: match.clubBName,
  //     matchLeague: match.baseLeague,
  //     matchAgeClass: match.ageClass,
  //     originType: 'Tag',
  //     originId: item.id
  //   }
  //   if (!this.props.createSequence) {
  //     console.error('Missing createSequence Method in deails')
  //     return
  //   }
  //   this.props.createSequence(sequence)
  // }

  deleteSequence = (item) => (confirm) => {
    this.props.deleteSequence(item)
  }

  updateClip = (isCreate) => async (clip) => {
    const result = isCreate ? await this.props.createClip(clip) : await this.props.updateClip(clip)
    if (!result) {
      // ERROR CASE
      return
    }
    const URL = `/clips/${result.RowKey}/`
    this.props.history.push(URL)
  }

  HighlightAdminFilter ({ enabler, cities, clubs, change, selected, selectedType, loaded }) {
    if (!loaded) {
      return <VortexSpinner />
    }

    const enablerLabel = enabler.map((e) => e.name)
    const cityLabel = cities.map((c) => c.name)
    const clubLabel = clubs.map(c => c.name)

    const omniFilterConfig = [
      { filterType: 'enabler', label: 'Enabler', property: 'enabler', type: 'option', options: enablerLabel },
      { filterType: 'cities', label: 'Stadt', property: 'city', type: 'option', options: cityLabel },
      { filterType: 'club', label: 'Club', property: 'club', type: 'option', options: clubLabel }
    ]

    const omniFilterAdd = (prop, value) => {
      const type = prop.includes('enabler') ? 'enabler' : prop.includes('city') ? 'city' : 'club'
      change(type, value)
    }
    const omniFilterRemove = (prop, value) => {
      change(null, null)
    }
    const state = { enabler_enabler: null, cities_city: null, club_club: null }
    switch (selectedType) {
      case 'enabler': state['enabler_enabler'] = selected
        break
      case 'city': state['cities_city'] = selected
        break
      case 'club': state['club_club'] = selected
        break
      default:
        break
    }

    return <OmniFilterUi
      onFilterRemove={omniFilterRemove}
      onFilterAdd={omniFilterAdd}
      config={omniFilterConfig}
      filterPrefix=''
      filters={state}
    />
  }

  render () {
    const { knownSequenceLabel, knownMatchLeagues, knownMatchClubs, knownMatchAgeClasses, addToClip, removeFromClip, isHighlightAdmin, HLAdminDataLoaded, enabler, cities } = this.props
    const { filterStateMatch, filterStateSequence } = this.props
    const omniFilterState = { ...filterStateMatch, ...filterStateSequence }
    const omniFilterConfig = [
      { filterType: 'match', label: 'Match Datum', property: 'formatedDate', type: 'text', requirement: 'date' },
      { filterType: 'match', label: 'Alterklasse', property: 'ageClass', type: 'option', options: knownMatchAgeClasses, singleValue: true },
      { filterType: 'match', label: 'Liga', property: 'baseLeague', type: 'option', options: knownMatchLeagues, singleValue: true },
      { filterType: 'match', label: 'Club', property: 'clubs', type: 'option', options: knownMatchClubs, singleValue: false },
      // { filterType: 'sequence', label: 'Szene Name', property: 'name', type: 'text' },
      { filterType: 'sequence', label: 'Label', property: 'label', type: 'option', options: knownSequenceLabel, singleValue: false }
      // { filterType: 'sequence', label: 'Szene Datum', property: 'date', type: 'date' }
    ]
    let matches = this.props.matches ? applyOmniFilter(this.props.matches, omniFilterConfig, filterStateMatch).map((match) => {
      return match
    }) : []
    const sequenceMap = matches.reduce((map, match) => {
      const sequence = this.props.sequenceMap[match.RowKey]
      if (sequence) {
        map[match.RowKey] = applyOmniFilter(sequence, omniFilterConfig, filterStateSequence)
      }
      return map
    }, {})
    if (this.props.sequencesLoaded && (filterStateSequence.sequence_name.length || filterStateSequence.sequence_label.length)) {
      matches = matches.filter((match) => {
        return sequenceMap[match.RowKey] && sequenceMap[match.RowKey].length > 0
      })
    }
    const matchesHidden = this.props.matches ? this.props.matches.length - matches.length : 0
    let matchList = matchesHidden > 0 ? matches : this.props.recentMatches[0]

    if (isHighlightAdmin && matches.length) {
      const from = new Date(this.props.matchDateFrom).getTime()
      const till = new Date(this.props.matchDateTill).getTime()
      matches = matches.filter((match, i) => {
        const matchTime = new Date(match.startTime).getTime()
        return from <= matchTime && matchTime <= till
      })
      matchList = matches
    }

    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          ribbonLabel={this.props.clipSequence.length}
          user={this.props.user}
          ribbon
        >
          <ClipBox
            key='CreateClipUi'
            matches={this.props.matches}
            sequences={this.props.clipSequence}
            removeSequence={this.props.removeFromClip}
            clip={this.props.clipContext}
            history={this.props.history}
            onSequenceSort={this.props.onClipSequenceSort}
            createSequence={this.props.createSequence}
            createClip={!this.props.clipContext ? this.updateClip(true) : undefined}
            updateClip={this.props.clipContext ? this.updateClip(false) : undefined}
            saveAs={this.updateClip(true)}
            clipActions={{ addToClip, removeFromClip }}
            setClipContext={this.props.setClipContext}
            clipSequenceName={this.props.clipSequenceName}
            setClipSequenceName={this.props.setClipSequenceName}
            clipUiWorking={this.props.clipUiWorking}
            setClipUiWorking={this.props.setClipUiWorking}
            collapsible
          >
            <FastClipButtons isHighlightAdmin={this.props.isHighlightAdmin} showHomeGoalsBtn={isHighlightAdmin} sequenceMap={this.props.sequenceMap} matchList={matchList} addToClip={this.props.addToClip} />
          </ClipBox>
          { this.props.isHighlightAdmin &&
            <ForwardToPlayerTracking />
          }
        </Sidebar>
        <Content history={this.props.history} className='matchSite'>
          <h1 className='siteHeader'>{i18n.t('matchList.header')}</h1>
          {isHighlightAdmin &&
          <>
            <this.HighlightAdminFilter
              loaded={HLAdminDataLoaded}
              enabler={enabler}
              cities={cities}
              clubs={this.props.clubs}
              selected={this.props.HASelection}
              selectedType={this.props.HASelectedType}
              change={this.props.changeHighlightAdminSelection}
            />
            <DateFilter change={this.props.setMatchDate} from={this.props.matchDateFrom} till={this.props.matchDateTill} />
          </>
          }
          <OmniFilterUi
            onFilterRemove={this.props.omniFilterRemove}
            onFilterAdd={this.props.omniFilterAdd}
            config={omniFilterConfig}
            filterPrefix='filter'
            filters={omniFilterState}
          />
          <Matches
            matches={matches}
            loading={!this.props.matchesLoaded}
            sequencesLoaded={this.props.sequencesLoaded}
            tagsLoaded={this.props.tagsLoaded}
            sequenceMap={sequenceMap}
            deleteSequence={this.deleteSequence}
            clipActions={{ addToClip, removeFromClip }}
            clipSequence={this.props.clipSequence}
            // tagToSequence={this.tagToSequence}
            showSequenceCount
            linkToDetails
            // showClubIcons
            history={this.props.history}
          />
          { Boolean(matchesHidden) && <div className='row centerContent'><span>{matchesHidden} Spiele Ausgeblendet</span></div>}
        </Content>
        <Popup display='center' layout='liquid' ref='previewVid' buttons={['cancel']} >
          <video src={this.props.src} />
        </Popup>
      </>
    )
  }
}

export default MatchSite
