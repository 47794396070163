import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'

class Arcline extends Component {
  onMouseDown = (evt, path) => {
    path.add(evt.localPoint)
    return { path }
  }

  /**
   * @param {paper.Event} evt
   * @param {paper.Path} path
   * @returns {{path: paper.Path}}
   */
  onMouseDrag = (evt, path) => {
    path.arcTo(evt.localPoint)
    return { path }
  }

  onMouseUp = (evt, path) => {
    return { path }
  }

  render () {
    return (
      <ProtoTool
        key='Arcline'
        toolName='Arcline'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-fa-skyatlas' />
      </ProtoTool>
    )
  }
}

export default Arcline
