import React from 'react'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import RouterCache from './components/RouterCache'
import './Main.scss'
import './i18n/i18n'
import { withTranslation } from 'react-i18next'
mobiscroll.settings = { theme: 'mobiscroll', lang: 'de' }

const Cache = props => <RouterCache {...props} />

// class App extends Component {
//   render () {
//     return withTranslation()(Cache)
//   }
// }

export default withTranslation()(Cache)
