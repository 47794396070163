import React, { Component } from 'react'
import MatchItem from './matchItem'
import VortexSpinner from '../spinner/vortex'
class Matches extends Component {

  render () {
    if (this.props.loading) {
      return <VortexSpinner />
    }
    if (!this.props.matches || !this.props.matches.length) {
      return null // <h4>Placeholder(Matches Missing or no Filter Result)</h4>
    }
    const matches = this.props.matches.map((match, index) => {
      // const tagToSequence = this.props.tagToSequence ? this.props.tagToSequence(match) : () => {}
      const sequences = this.props.sequenceMap[match.RowKey] || []
      return (
        <MatchItem
          key={match.RowKey}
          match={match}
          sequences={sequences}
          showClubIcons={this.props.showClubIcons}
          sequencesLoaded={this.props.sequencesLoaded}
          clipActions={this.props.clipActions}
          createSequence={this.props.createSequence}
          clipSequence={this.props.clipSequence}
          deleteSequence={this.props.deleteSequence}
          showSequences={this.props.showSequences}
          showSequenceCount={this.props.showSequenceCount}
          // tagToSequence={tagToSequence}
          updateSequence={this.props.updateSequence}
          linkToDetails={this.props.linkToDetails}
          editSequence={this.props.editSequence}
          noDetails={this.props.noDetails}
          fullWidth={this.props.fullWidth}
          showAnnotations={this.props.showAnnotations}
          showLabel={this.props.showLabel}
          history={this.props.history}
          useColor={this.props.useColor}
        />
      )
    })

    return (
      <>
        {matches}
      </>
    )
  }
}

export default Matches
