import React, { Component } from 'react'

import VortexSpinner from '../../utility/spinner/vortex'
import i18n from 'i18next'
import firebase from '../../firebase'
import './token.scss'
import './subscriptionCreate.scss'

class TokenSite extends Component {
  constructor (props) {
    super(props)
    const token = this.getQueryToken(props.location.search)
    this.state = {
      token,
      working: false,
      error: undefined
    }
  }

  getQueryToken (query) {
      let token = ''
      if (query !== '') {
        query.replace(/\?/g, '')
        const parts = query.split('&')
        const tokenString = parts.find(p => p.includes('token='))
        token = tokenString.split('=')[1]
        token = token.replace('/', '')

      }
      return token
  }

  onTokenInput = (e) => {
    const token = e.currentTarget.value
    this.setState({ token })
  }

  applyToken = async () => {
    const token = this.state.token
    if (!token) {
      return
    }  
    this.setState({ working: true })
    const header = await this.props.getAuthorization()
    const result = await this.props.applySubToken(token.trim(), header)

    this.setState({ working: false, error: result.error })
    if (result.status === 200) {
      await firebase.auth().currentUser.getIdToken(true)
      this.props.history.push('/user/')
    }
  }

  render () {
    return (
      <>
        <div className='DataView row'>
          <div className='col-xs'>
            <h3 onClick={this.props.cancle} className='siteHeader clickable'>&lt; Zurück</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
           
          </div>
          <div className='col-xs-6'>
            { this.state.working || !this.props.user
              ? <VortexSpinner />
              : ( 
              <>
               <label className='codeBoxLabel' htmlFor='codeBox'> Token-Code:</label>
               <input className='codeBox' name='codeBox' type='text' value={this.state.token} onChange={this.onTokenInput} />
               <button className='codeBoxButton btn info' onClick={this.applyToken} disabled={!this.state.token}>Überprüfen</button>
              </>
              )
            }
          </div>         
        </div>
      </>
    )
  }
}
export default TokenSite
