import React, { Component } from 'react'
import Clips from '../../utility/clips/clipList'
import mobiscroll from '@mobiscroll/react'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import i18n from 'i18next'
import { OmniFilterUi, applyOmniFilter } from '../../utility/omniFilter/omniFilter'
const { Popup } = mobiscroll

class ClipSite extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',

      // Omnifilter
      filter_name: [],
      filter_date: [],
      filter_league: [],
      filter_label: [],
      filter_club: [],
      filter_public: []
    }
    this.showClipModal = React.createRef()
    this.props.setCurrentSite()
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  // shouldComponentUpdate (nextProps) {
  //   return (this.props.clipsLoaded !== nextProps.clipsLoaded) ||
  //   (this.props.sequencesLoaded !== nextProps.sequencesLoaded)
  // }

  onFilterChange = (prop, value) => {
    this.setState({ [prop]: value })
  }

  omniFilterAdd = (prop, value) => {
    const current = [...this.state[prop]]
    if (current.includes(value)) {
      return
    }
    current.push(value)
    this.setState({ [prop]: current })
  }

  omniFilterRemove = (prop, value) => {
    const current = [...this.state[prop]]
    const index = current.findIndex((v) => v === value)
    if (index > -1) {
      current.splice(index, 1)
      this.setState({ [prop]: current })
    }
  }

  setClipContext = (clip) => (e) => {
    e.stopPropagation()
    this.props.setClipContext(clip)
    this.props.history.push('/matches/')
  }

  render () {
    const { knownSequenceClubs, knownSequenceLabel, knownSequenceLeagues } = this.props
    const { filter_name, filter_date, filter_league, filter_label, filter_club, filter_public } = this.state // eslint-disable-line
    const omniFilterState = { filter_name, filter_date, filter_league, filter_label, filter_club, filter_public }
    const omniFilterConfig = [
      { label: 'Name', property: 'name', type: 'text', sortDistance: 1 },
      { label: 'Datum', property: 'date', type: 'date' },
      { label: 'Label', property: 'label', type: 'option', options: knownSequenceLabel, singleValue: false },
      { label: 'Liga', property: 'league', type: 'option', options: knownSequenceLeagues, singleValue: false },
      { label: 'Club', property: 'club', type: 'option', options: knownSequenceClubs, singleValue: false },
      { label: 'Öffentlich?', property: 'public', type: 'bool', keyWords: ['Öffentlich'] }
    ]
    const useClips = applyOmniFilter(this.props.clips, omniFilterConfig, omniFilterState, 'filter').sort((a, b) => b.lastModified - a.lastModified)
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        >
          <div className='newClipButton'>
            {/* <Link className='btn_fluid success' to='/matches'>Neuer Clip</Link> */}
          </div>
        </Sidebar>
        <Content history={this.props.history} className='clipSite'>
          <h1 className='siteHeader'>{i18n.t('clipList.header')}</h1>
          <OmniFilterUi
            onFilterRemove={this.omniFilterRemove}
            onFilterAdd={this.omniFilterAdd}
            config={omniFilterConfig}
            filterPrefix='filter'
            filters={omniFilterState}
          />
          <Clips
            clips={useClips}
            loading={!this.props.clipsLoaded}
            deleteClip={this.props.deleteClip}
            toggleClipPublicity={this.props.toggleClipPublicity}
            editClip={this.setClipContext}
            history={this.props.history}
            isHighlightAdmin={this.props.isHighlightAdmin}
            downloadClip={this.props.downloadClip}
          />
        </Content>
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
    )
  }
}

export default ClipSite
