import React from 'react'
import { Link } from 'react-router-dom'
import { Animate } from 'react-simple-animate'
import copy from 'copy-to-clipboard'
import mobiscroll from '@mobiscroll/react'
import i18n from 'i18next'

function formateDateTime (timeString) {
  const timeObj = new Date(timeString)
  const date = `${timeObj.getDate()}.${('0' + (timeObj.getMonth() + 1)).slice(-2)}.${timeObj.getFullYear()}`
  const time = `${timeObj.toISOString().split('T')[1].split(':').splice(0, 2).join(':')} Uhr`
  return { date, time }
}

// const showConfirm = (callback) => (e) => {
//   e.stopPropagation()
//   return mobiscroll.confirm({
//     title: i18n.t('clipDetails.questionDelete'),
//     okText: i18n.t('clipDetails.delete'),
//     cancelText: i18n.t('clipDetails.cancle'),
//     callback
//   })
// }
const copyToClipBoard = (link, clip) => (e) => {
  e.stopPropagation()
  copy(window.location.href + link)
  mobiscroll.toast({
    message: i18n.t('clipDetails.urlInClipboard'),
    color: 'success'
  })
  const clubSequence = clip.sequence.reduce((string, seq, index) => {
    const slash = index === 0 ? '' : ' - '
    return string + slash + seq.clubAId + ' - ' + seq.clubBId
  }, '')
  window.gtag && window.gtag('event', 'clip shared', {
    env: String(process.env.REACT_APP_Environment),
    clubSequence,
    event_category: 'clip',
    event_label: 'User Copied a ClipLink to Clipboard'
    // 'value': ''
  })
}

export default function ClipItem (props) {
  const { clip, publishClip, history
    // editClip, index, deleteClip,
  } = props
  // const _deleteClip = (clip, index) => async (confirmed) => {
  //   if (confirmed) {
  //     deleteClip(clip, index)
  //   }
  // }

  // const [downloadBusy, setDownloadBusy] = React.useState(false)

  // const download = async (clip) => {
  //   setDownloadBusy(true)
  //   const id = clip.RowKey
  //   const href = await props.downloadClip(id)
  //   var a = document.createElement('a')
  //   a.style = 'display: none'
  //   document.body.appendChild(a)
  //   a.href = href
  //   a.setAttribute('target', '_blank')
  //   a.setAttribute('rel', 'noopener noreferrer')
  //   a.setAttribute('download', `${clip.name}.mp4`)
  //   a.click()
  //   window.URL.revokeObjectURL(href)
  //   a.remove()
  //   // window.location.assign(href)
  //   setDownloadBusy(false)
  // }

  const _publishClip = (clip) => (e) => {
    e.stopPropagation()
    publishClip(clip)
  }
  const isPublicClass = clip.public ? 'remove' : 'add'
  const isPublicContent = clip.public
    ? <span className='mbsc-ic mbsc-ic-foundation-unlink'> {i18n.t('clipDetails.unPublish')}</span>
    : <span className='mbsc-ic mbsc-ic-material-insert-link'> {i18n.t('clipDetails.publish')}</span>
  const date = formateDateTime(props.clip.created)
  return (
    <div className='clipCard col-xs' key={clip.RowKey}>
      <div onClick={() => { history.push(`/clips/${clip.RowKey}/`) }}>
        <Animate
          play // set play true to start the animation
          duration={0.4} // how long is the animation duration
          start={{ opacity: 0, display: 'flex', flex: '1 1 auto', flexWrap: 'wrap', borderRadius: '0.25em', overflow: 'hidden' }}
          end={{ opacity: 1, display: 'flex', flex: '1 1 auto', flexWrap: 'wrap', borderRadius: '0.25em', overflow: 'hidden' }}
          easeType='cubic-bezier(0.445, 0.05, 0.55, 0.95)'
        >
          <div className='innerData'>
            <small className='Label'>{clip.sequenceIds.length}&nbsp;Szenen&nbsp;vom&nbsp;{date.date}</small>
            <h5>
              {clip.name}
            </h5>
            <div className='buttonArea'>
              <span className={`${isPublicClass}Btn`} onClick={_publishClip(clip)} >
                {isPublicContent}
              </span>
              {clip.public ? <span className='infoBtn' onClick={copyToClipBoard(`${clip.RowKey}/public/`, clip)}><span className='mbsc-ic mbsc-ic-share' /> {i18n.t('clipDetails.copyLink')}</span> : null}
              {props.isHighlightAdmin && clip.public && <a rel='noopener noreferrer' onClick={(e) => { e.stopPropagation() }} href={`https://europe-west1-sw-sc-de-shared.cloudfunctions.net/convert/vclip/${clip.RowKey}`} target='_blank' className='infoBtn'><span className='mbsc-ic mbsc-ic-fa-download' /> Download</a>}
              {/* {props.isHighlightAdmin && clip.public && <>
                <span onClick={(e) => { e.stopPropagation(); if (!downloadBusy) { download(clip) } }} className='infoBtn'>{downloadBusy ? <VortexSpinner style={{ width: '20px', height: '20px' }} innerStyle={{ color: 'teal' }} /> : <span className='mbsc-ic mbsc-ic-fa-download' />}
                &nbsp;Download</span>
              </> */}
              {/* <Link className='infoBtn' onClick={(e) => { e.stopPropagation() }} to={`/clips/${clip.RowKey}/edit/`} ><span className='mbsc-ic mbsc-ic-fa-video-camera' /> {i18n.t('clipDetails.watchShort')}</Link> */}
              {/* <Link className='editBtn' onClick={editClip || (() => { })} to={editClip ? '#' : `/clips/${clip.RowKey}/`}><span className='mbsc-ic mbsc-ic-material-edit' /> {i18n.t('clipDetails.edit')}</Link> */}
              {/* <span className='removeBtn' onClick={showConfirm(_deleteClip(clip, index))} ><span className='mbsc-ic mbsc-ic-material-delete' /> {i18n.t('clipDetails.delete')}</span> */}
            </div>
          </div>
          <div className='pageChanger'>
            <Link className='btn boyzone' to={'/clips/${clip.RowKey/'}><span className='mbsc-ic mbsc-ic-ion-ios7-arrow-forward' /></Link>
          </div>
        </Animate>
      </div>
    </div>
  )
}
