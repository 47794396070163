import React, { useCallback } from 'react'
import ReactDom from 'react-dom'
import Line from '../tools/Line'
import Freeline from '../tools/Freeline'
import Arcline from '../tools/Arcline'
import Rectangle from '../tools/Rectangle'
import Circle from '../tools/Circle'
import Hand from '../tools/Hand'
import Poly4 from '../tools/Poly4'
import Focus from '../tools/Focus'
import PointText from '../tools/PointText'
import Arrow from '../tools/Arrow'
// import DistanceCalibrator from '../tools/DistanceCalibrator'

export default function ToolManager (props) {
  const { sharedProps, disableName, container } = props
  const defaultOpen = window.innerWidth > 778
  const [isOpen, setIsOpen] = React.useState(defaultOpen)
  sharedProps.className = isOpen ? '' : 'hidden'

  // useful default value for dev purposes: 0.032130769326311606
  // const [metersPerPixel, setMetersPerPixel] = useState(props.metersPerPixel)

  const openToolList = useCallback(() => { setIsOpen(true) }, [setIsOpen])
  const closeToolList = useCallback(() => { setIsOpen(false) }, [setIsOpen])

  const html = (
    <div className='Toolslist open'>
      {
        isOpen ? <span onClick={closeToolList} className='Tool mbsc-ic mbsc-ic-fa-times' />
          : <div className='Toolslist'> <span onClick={openToolList} className='Tool mbsc-ic mbsc-ic-foundation-wrench' /></div>
      }
      <Hand
        {...sharedProps}
        disableName={disableName}
        activateTouch={props.activateTouch}
        deactivateTouch={props.deactivateTouch}
      />
      {/* {sharedProps.useVrPlayer ? <DistanceCalibrator
        {...sharedProps}
        disableName={disableName}
        setMetersPerPixel={setMetersPerPixel}
      /> : null} */}
      <Line
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      {sharedProps.useVrPlayer ? <Line
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
        showLineLength
      /> : null}
      <Arrow
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      <Freeline
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      <Arcline
        {...sharedProps}
        disableName={disableName}
      />
      <Rectangle
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      {sharedProps.useVrPlayer ? <Rectangle
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
        showLineLength
      /> : null}
      <Circle
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      <Poly4
        {...sharedProps}
        disableName={disableName}
        metersPerPixel={props.metersPerPixel}
      />
      <Focus
        {...sharedProps}
        disableName={disableName}
      />
      <PointText
        {...sharedProps}
        disableName={disableName}
      />
      {props.children}
    </div>
  )
  if (container) {
    return ReactDom.createPortal(html, container)
  } else {
    return html
  }
}
