import React from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
import { ToolWithLineLength } from './ToolWithLineLength'

class Line extends ToolWithLineLength {
  /**
   * @param {MouseEvent} evt 
   * @param {paper.Path.Line} path 
   */
  onMouseDown = (evt, path, redraw) => {
    this.min = evt.localPoint
    path = new this.props.Paper.Path.Line(this.min, this.min)

    this.generateLineLengthIndicator(evt.localPoint, redraw && redraw.strokeColor ? redraw.strokeColor : this.props.color)

    return { path }
  }

  /**
   * @param {MouseEvent} evt 
   * @param {paper.Path} path 
   */
  onMouseDrag = (evt, path) => {
    this.max = evt.localPoint
    path.segments[1].point.set(this.max)

    this.getCurrentIndicator().setPoints(path.segments[0].point, path.segments[1].point)

    return { path }
  }

  /**
   * @param {MouseEvent} evt 
   * @param {paper.Path} path 
   */
  onMouseUp = (evt, path) => {
    return { path }
  }

  componentWillUnmount = this.clearIndicators

  render () {
    return (
      <ProtoTool
        key={`Line ${this.generateNameSuffix()}`}
        toolName={`Line ${this.generateNameSuffix()}`}
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        cleanUp={this.clearIndicators}
        dontCleanOnChange
        {...this.props}
      >
        <span className={`mbsc-ic mbsc-ic-${
          this.props.showLineLength ? 'fa-expand' : 'material-linear-scale'
        }`} />
      </ProtoTool>
    )
  }
}

export default Line
