import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import discovery from '@soccerwatch/discovery'
import mobiscroll from '@mobiscroll/react'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import scriptLoader from 'react-async-script-loader'
import VortexSpinner from '../../utility/spinner/vortex'
import i18n from 'i18next'
import './subscriptionCreate.scss'

function OptionList ({ options }) {
  return options.map((o, i) => {
    return <option key={o.RowKey} data-index={i} value={o.RowKey}>{o.name}</option>
  })
}

function ClubSelect ({ onChange, selectedClub, clubs, filter, setFilter }) {
  return (
    <div className='row clubSelect'>
      <div className='col-xs-12 center'>
        {i18n.t('subscriptionCreate.chooseClub')}
      </div>
      <div className='col-xs-12 noCenter'>
        <input className='clubFilter' placeholder='Clubfilter' value={filter} onChange={(e) => { setFilter(e.currentTarget.value) }} />
      </div>
      <div className='col-xs-12 center'>
        <select size={12} value={selectedClub} onChange={onChange}>
          <OptionList options={clubs} />
        </select>
      </div>
    </div>
  )
}

const { Popup } = mobiscroll

class PaypalSite extends Component {
  constructor (props) {
    super(props)
    const excludeClubList = props.user.trainerSubscriptions.map((sub) => {
      return sub.data.club
    })
    this.state = {
      showButton: false,
      working: false,
      payPalDone: false,
      badErrorCase: false,
      success: false,
      subData: {},
      selectedClub: '',
      // selectedClubData: {},
      errorCount: 0,
      excludeClubList,
      filter: ''
    }
    window.React = React
    window.ReactDOM = ReactDOM
    this.paypalActions = null
  }

  componentDidMount () {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true })
      this.setupPPButton()
    }
    !this.props.clubsLoaded && this.props.getClubs && this.props.getClubs()
  }

  componentWillReceiveProps (nextProps) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = nextProps
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton &&
      !this.props.isScriptLoaded &&
      isScriptLoaded
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true })
        this.setupPPButton()
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.paypalActions) {
      if (this.state.selectedClub === '' && prevState.selectedClub !== '') {
        this.paypalActions.enable()
      }
      if (this.state.selectedClub !== '' && prevState.selectedClub === '') {
        this.paypalActions.disable()
      }
    }
    if (this.state.filter !== prevState.filter || (!prevProps.clubs.length && this.props.clubs.length)) {
      const filtered = this.filterClubs()
      const _selectedClub = this.state.selectedClub
      if (filtered.includes(c => c.RowKey === _selectedClub)) {
        return
      }
      const firstClub = filtered[0]
      const selectedClub = firstClub ? firstClub.RowKey : ''
      this.setState({ selectedClub })
    }
  }

  PaypalButtonMounted = (data, actions) => {
    this.paypalActions = actions
    if (this.state.selectedClub === '') {
      actions.disable()
    }
  }

  setupPPButton () {
    if (!window.paypal) {
      console.error('Could Not Properly Load Paypal SDK')
      return
    }
    window.paypal.Buttons({
      createSubscription: this.createSubscription,
      onApprove: this.onApprove,
      // notify_url: `${discovery.API_PAYMENT}/notifications/`,
      onInit: this.PaypalButtonMounted
    }).render('#PayPalButton')
  }

  onApprove = async (data, actions) => {
    this.setState({ payPalDone: true, working: true, badErrorCase: false })
    const body = {
      subscriptionID: data.subscriptionID,
      user: this.props.user.sub,
      origin: this.props.user.email,
      clubId: this.state.selectedClub
    }
    const response = await this.props.createSubscription(body)
    if (response !== 200) {
      console.error(response)
      if (this.state.errorCount >= 5) {
        this.setState({ badErrorCase: true, working: false })
        return
      }
      const errorCount = this.state.errorCount + 1
      this.setState({ errorCount, subData: data })
      setTimeout(() => {
        this.onApprove(data, actions)
      }, 300)
      return
    }
    // TODO: Change to UpdateSubscriptions
    this.props.updateUserData && await this.props.updateUserData()
    window.gtag && window.gtag('event', 'new subscription', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.props.user.clubList,
      event_category: 'subscription',
      event_label: 'User bought a Subscription via Paypal'
    })
    this.setState({ working: false, success: true })
  }

  createSubscription = (data, actions) => {
    this.setState({ working: true })
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PID
    })
  }

  setClub = (e) => {
    if (!e) {
      this.setState({ clubData: {}, club: '' })
      return
    }
    const selectedClub = e.target.value
    this.setState({ selectedClub })
  }

  setFilter = (value) => {
    const filter = value || ''
    this.setState({ filter })
  }

  filterClubs () {
    if (!this.props.clubs) {
      return []
    }
    const { filter, excludeClubList } = this.state
    return this.props.clubs.map((club, i) => {
      if ((filter === '' || (club.name.toLowerCase().includes(filter.toLowerCase()))) && !excludeClubList.includes(club.RowKey)) {
        return club
      } return null
    }).filter(c => c && !c.subscriptionExclusive)
  }

  render () {
    const { filter } = this.state
    let filteredClubs = this.filterClubs()
    const _selectedClubData = this.props.clubs.find(c => c.RowKey === this.state.selectedClub)
    const selectedClubData = _selectedClubData || ''
    return (
      <>
        <div className='DataView row'>
          <div className='col-xs'>
            <h3 onClick={this.props.cancle} className='siteHeader clickable'>&lt; Paypal</h3>
          </div>
        </div>
        {this.state.working && this.state.payPalDone &&
        <div className='row'>
          <div className='col-xs-12'>
            <h1>{i18n.t('subscriptionCreate.working')}</h1>
          </div>
          <div className='col-xs-12'>
            <VortexSpinner />
          </div>
        </div>
        }
        {!this.state.badErrorCase && !this.state.success && !this.state.payPalDone &&
            <>
              <div className='row'>
                <div className='col-xs center'>
                  {this.props.clubsLoaded
                    ? <ClubSelect onChange={this.setClub} selectedClub={this.state.selectedClub} clubs={filteredClubs} setFilter={this.setFilter} filter={filter} />
                    : <VortexSpinner />
                  }
                </div>
              </div>
              <div className='row'>
                <div className='col-xs center'>
                  {i18n.t('subscriptionCreate.choosenClub')} {selectedClubData.name || i18n.t('subscriptionCreate.noClub')}
                </div>
              </div>
              <div className='row'>
                <div className='col-xs center'>
                  {!this.state.showButton && <VortexSpinner />}
                  {!this.state.payPalDone && <div id='PayPalButton' />}
                </div>
              </div>
            </>
        }
        { this.state.badErrorCase &&
        <div className='row'>
          <div className='col-xs center'>
            <h1>Error</h1>
            <p>{i18n.t('subscriptionCreate.badError')}</p>
            <h4>{this.state.subData.subscriptionID}</h4>
          </div>
          <div className='col-xs-12 center'>
            <button onClick={() => { this.onApprove(this.state.subData, {}) }} className='btn warning'>{i18n.t('subscriptionCreate.retry')}</button>
          </div>
        </div>
        }
        { this.state.success &&
        <div className='row'>
          <div className='col-xs center'>
            <h1>{i18n.t('subscriptionCreate.headerSuccess')}</h1>
            <p>{i18n.t('subscriptionCreate.textSuccess', { club: selectedClubData.name })}</p>
            <button className='btn success' onClick={() => {
              window.GlobalCoachingTool && window.GlobalCoachingTool.goBack && window.GlobalCoachingTool.goBack()
            }}>{i18n.t('subscriptionCreate.back')}</button>
          </div>
        </div>
        }
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
    )
  }
}
export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_CID}&vault=true`)(PaypalSite)
