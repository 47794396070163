import React from 'react'
import i18n from 'i18next'

function logout () {
  if (window.GlobalCoachingTool) {
    window.GlobalCoachingTool.logout()
  }
}
export default function LogoutButton () {
  return <div onClick={logout} className='logout btn info'><small>{i18n.t('dashboard.logout')} <span className='mbsc-ic mbsc-ic-enter' /></small></div>
}
