import { Component } from 'react'

class RouteListener extends Component {
  componentDidMount () {
    this.props.onLocationChange && this.props.onLocationChange(this.props.location)
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onLocationChange && this.props.onLocationChange(this.props.location)
    }
  }

  render () {
    return null
  }
}

export default RouteListener
