import discovery from '@soccerwatch/discovery'
import { getUserHeaders } from '../components/firebase'
import listTillFinished from '../lib/helper/listTillFinished'

async function getCities () {
  let cities
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_CITY}/cities/`
    cities = await listTillFinished(url, headers)
  } catch (error) {
    console.error(error)
    return { error }
  }
  return cities
}

export default { getCities }
