/**
 *
 * @param {paper.PaperScope} paper
 * @param {paper.Point} point
 */
export const createAdaptivePointText = (paper, point, ...args) => {
  class AdaptivePointText extends paper.PointText {
    /**
     * @private
     */
    _fontSizeMultiplier = 1

    /**
     * @param {paper.Point} point
     * @param  {...any} args
     */
    constructor(point, ...args) {
      super(point, ...args)
      this._updateFont(point)
    }

    /**
     * @private
     */
    _calculateBottomCenterOfPaper = () => {
      const size = paper.project.view.size
      return new paper.Point(size.width / 2, size.height)
    }

    /**
     * @private
     * @param {paper.Point} position
     */
    _updateFont = (position) => {
      const oldFontSize = this.fontSize

      const distance = position.getDistance(this._calculateBottomCenterOfPaper())
      this._fontSizeMultiplier = 1.3 + distance / 450

      this.fontSize = oldFontSize
    }

    get position() {
      return super.position
    }

    /**
     * @type {paper.Point}
     */
    set position(value) {
      this._updateFont(value)
      super.position = value
    }

    /**
     * @private
     */
    _getOffsetToLine = () => {
      const oldAngle = this.rotation
      this.rotation = 0

      const height = this.bounds.height

      this.rotation = oldAngle

      // negative height as y direction goes "downwards"
      return new paper.Point(0, -0.75 * height).rotate(oldAngle)
    }

    /**
     *
     * @param {paper.Point} center
     */
    setCenter = (center) => {
     this._updateFont(center)

      super.position = center.add(this._getOffsetToLine())
    }

    get fontSize() {
      return super.fontSize / this._fontSizeMultiplier
    }

    set fontSize(newFontSize) {
      super.fontSize = newFontSize * this._fontSizeMultiplier
    }
  }

  return new AdaptivePointText(point, ...args)
}
