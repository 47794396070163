import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './ForwardToPlayerTracking.scss'
import i18n from 'i18next'

export const ForwardToPlayerTracking = (props) => {
  const [matchId, setMatchId] = useState(props.currentMatchId)
  const [touched, setTouched] = useState(false)
  const onChange = (evt) => {
    setTouched(false)
    setMatchId(evt.target.value)
  }

  useEffect(() => {
    if (!touched) {
      setMatchId(props.currentMatchId)
    }
  }, [touched, setMatchId, props.currentMatchId])

  return <div className={'forward-to-player-tracking'}>
    <h3>
      {i18n.t('playerPositionTracker.link.title')}
    </h3>
    <form>
      <input
        value={matchId === undefined ? '' : matchId}
        onChange={onChange}
        placeholder={i18n.t('playerPositionTracker.link.placeholder')}
      />
      <Link to={`/matches/${matchId}/playerPositions/`}>
        {i18n.t('playerPositionTracker.link.linkLabel')}
      </Link>
    </form>
  </div>
}

ForwardToPlayerTracking.propTypes = {
  currentMatchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
ForwardToPlayerTracking.defaultProps = {}
