import discovery from '@soccerwatch/discovery'
import { getUserHeaders } from '../components/firebase'
import fallBackCorners from '../components/utility/FallbackCorners'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function getMatches () {
  let matches
  const headers = await getUserHeaders()
  try {
    const response = await axios.get(`${discovery.API_VIDEO}/trainerVideos/`, { headers })
    matches = response.data
  } catch (error) {
    console.error(error)
    return { error }
  }
  if (matches.status === 'error') {
    return []
  }
  
  // ! I Added this to statify a customer that uses a K2M to record all kinds of clubs.
  // ! Here we simply get all Contract Matches and then filter for unique values in the second filter down below.
  // ! Remove if stuff unexpectadly goes wrong
  const cMatches = await getContractMatches(headers)
  matches = matches.concat(cMatches)
  // const errors = matches.filter(m => !!m && ((!m.userStream && !m.userStreamAdaptive) || m.error))
  const filtered = matches
    .filter(m => Boolean(m) && (m.userStream || m.userStreamAdaptive) )
    .filter((m, i, a) => { return a.findIndex((e => m.RowKey === e.RowKey)) === i })
  // console.log('FILTER', matches.length, filtered.length, errors)
  return filtered
}

async function getContractMatches(headers) {
  const contracts = (await axios.get(`${discovery.API_USER}/Contracts/me/`, { headers })).data
  let videos = []
  for(let c of contracts) {
    try {
      const contractVids = (await axios.get(`${discovery.API_VIDEO}/metaBy/contract/${c.RowKey}`, { headers })).data.data
      videos = videos.concat(contractVids)
    } catch(err) {
      console.warn('Could not get Videos for Contact', c.RowKey, err)
      continue
    }
  }
  return videos
}

async function getMachtesForClubs (clubs) {
  const headers = await getUserHeaders({
    'Content-Type': 'application/json'
  })
  const body = JSON.stringify({ clubs })
  const url = `${discovery.API_VIDEO}/videosByClub/`
  // const url = `http://localhost:5001/videosByClub/`
  let matches
  try {
    const response = await axios.post(url, body, { headers })
    matches = response.data
  } catch (err) {
    console.error(err)
    return { error: err }
  }
  return matches
}

async function getMatchById (matchId) {
  const headers = await getUserHeaders()
  const url = `${discovery.API_VIDEO}/meta/${matchId}`
  let match
  try {
    const response = await axios.get(url, { headers })
    match = response.data
  } catch (err) {
    console.error(err)
    match = { error: err }
  }
  return match
}

async function getTrialMatches () {
  const url = `${discovery.API_VIDEO}/trialVideos/`
  let matches
  try {
    const headers = await getUserHeaders()
    const response = await axios.get(url, { headers })
    matches = response.data
  } catch (error) {
    console.error(error)
    matches = { error }
  }
  return matches
}

async function getCornerPointsFrom (fromVideo = true, id, isPanorama = false) {
  const url = fromVideo ? `${discovery.API_VIDEO}/cornerPoints/${id}` : `${discovery.API_CAMERA}/info/cornerPoints/${id}`
  let cornerPoints
  try {
    const headers = await getUserHeaders()
    const repsonse = await axios.get(url, { headers })
    const json = repsonse.data
    if (json.status === 200 || json.status === '200') {
      cornerPoints = json.Corners
    } else {
      console.warn('Had to load Backup Cornerpoints from Camera')
      const type = fromVideo ? 'Video' : 'Camera'
      console.error('Getting CornerPoints for', type, ':', id, 'Failed with Status', json.status)
      // console.warn('Using Fallback CornerPoints')
      // cornerPoints = fallBackCorners
      cornerPoints = undefined
    }
  } catch (error) {
    console.error(error)
    cornerPoints = undefined
  }
  if (cornerPoints) {
    const mapped = {
      corners: cornerPoints.Corners.corners
    }
    if (cornerPoints['Panorama Corners']) {
      mapped.panorama_corners = cornerPoints['Panorama Corners'].panorama
      mapped.quarterBoxPanorama = cornerPoints['Panorama Corners'].quarterBoxPanorama
    } else if (cornerPoints.Corners.panorama_corners) {
      mapped.panorama_corners = cornerPoints.Corners.panorama_corners
    }
    cornerPoints = mapped
  } else {
    console.warn('Using Fallback CornerPoints')
    cornerPoints = fallBackCorners
  }
  return cornerPoints
}

async function getCameraIdFromVideo (video) {
  const headers = await getUserHeaders()
  const match = await getMatchById(video, headers)
  return match.swcsID
}

async function getCornerPoints (video, isPanorama = false) {
  const headers = await getUserHeaders()
  let cornerPoints = await getCornerPointsFrom(true, video, isPanorama)
  if (!cornerPoints) {
    const camera = await getCameraIdFromVideo(video, headers)
    cornerPoints = await getCornerPointsFrom(false, camera)
  }
  return cornerPoints
}

export default { getMatches, getContractMatches, getMachtesForClubs, getMatchById, getTrialMatches, getCornerPoints }
