import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import BaseNav from './baseNav'
import PathMap from './PathMap'
import './sidebar.scss'

// import HelpModal from '../utility/helpModal/helpModal'
const breakpoint = 989
window.GlobalCoachingTool = window.GlobalCoachingTool || {}
export default function Sidebar (props) {
  const [isOpen, setIsOpen] = useState(!!props.openByDefault)
  const [screenWidth, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (window.GlobalCoachingTool) {
      window.GlobalCoachingTool.setSidebarOpen = (state) => {
        setIsOpen(state)
        notifyContent(state)
      }
    }
  })

  function notifyContent (newVal) {
    const content = document.getElementById('Content')
    if (content) {
      const addClass = newVal ? 'isOpen' : 'isClosed'
      const removeClass = newVal ? 'isClosed' : 'isOpen'
      content.classList.add(addClass)
      content.classList.remove(removeClass)
    }
  }

  const toggleHighlightMode = window.GlobalCoachingTool.toggleHighlightMode || function () { }

  useEffect(() => {
    notifyContent(isOpen)
    function handleResize () {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen])

  function toggleOpen () {
    const newVal = !isOpen
    setIsOpen(newVal)
    notifyContent(newVal)
  }

  const toggleIcon = screenWidth > breakpoint && !props.ignoreBreakpoint ? 'fa-bars' : isOpen ? 'material-close' : 'fa-bars'
  return <>
    <div className={`gateOpener ${isOpen ? 'isOpen' : 'isClosed'}`}>
      <span onClick={toggleOpen} className={`mbsc-ic mbsc-ic-${toggleIcon}`} />
      <div className='greyOutArea' onClick={toggleOpen} />
    </div>
    <div className={`sidebar ${isOpen ? 'isOpen' : 'isClosed'}`}>
      {props.childrenBeforeLogo && props.children}
      <div className='logoArea'>
        <Link to={props.loggedOff ? '/login' : '/'}>
        <img alt='Logo' src='https://storage.googleapis.com/aisw-assets/logo/staige/staigeOrange.svg' className='coachingtoolLogo' />
          {/* <img alt='Logo' src='https://storage.googleapis.com/sw-sc-de-assets/assets/AISW%20Logo%20redesign%20050220%20RGB%20Silber%20L.png' className='coachingtoolLogo' /> */}
        </Link>
      </div>
      {/* <I18NButtons>
        { props.user && <DropDownButton user={props.user}>
          <div className='col-xs-12'>
            <Link className='userCenterButton' to='/user/'>
              <button className='btn boyzone'>{i18n.t('sidebar.subs')}</button>
            </Link>
          </div>
          { props.user && props.user.Role.includes('admin') &&
            <div className='col-xs-12'>
              <button className='btn boyzone' onClick={toggleHighlightMode}>Toggle Mode</button>
            </div>
          }
          <div className='col-xs-12'>
            <LogoutButton />
          </div>
        </DropDownButton> }
        <button className='btn warning' onClick={() => {
          window.GlobalCoachingTool && window.GlobalCoachingTool.setHelpModalOpen && window.GlobalCoachingTool.setHelpModalOpen(true)
        }}>{i18n.t('sidebar.help')}</button>
      </I18NButtons> */}
      <div className='pathMap'>
        <ul>
          <PathMap history={props.history} />
        </ul>
      </div>
      {(props.user?.role?.includes('admin') || props.user?.role?.includes('highlightadmin')) &&
        <div className='col-xs-12'>
          <button className='btn boyzone' onClick={toggleHighlightMode}>Toggle Mode</button>
        </div>
      }
      {!props.childrenBeforeLogo && props.children}

    </div>

    {props.ribbon &&
      <div onClick={toggleOpen} className={`ribbon ${isOpen ? 'isOpen' : 'isClosed'}`}>
        <small>{props.ribbonLabel} Sequenzen ausgewählt</small>
      </div>
    }

  </>
}
