import React, { Component } from 'react'
import ClipItem from './clipItem'
import VortexSpinner from '../spinner/vortex'

class Clips extends Component {
  render () {
    if (this.props.loading) {
      return <VortexSpinner /> // <h4>Placeholder(Loading)</h4>
    }
    if (!this.props.clips || !this.props.clips.length) {
      return null // <h4>Placeholder(Clips Missing)</h4>
    }
    const editClip = this.props.editClip || (() => { return undefined })
    return this.props.clips.map((clip, index) => {
      return (
        <ClipItem
          key={clip.RowKey}
          clip={clip}
          index={index}
          history={this.props.history}
          previewClip={this.props.previewClip}
          deleteClip={this.props.deleteClip}
          publishClip={this.props.toggleClipPublicity}
          isHighlightAdmin={this.props.isHighlightAdmin}
          downloadClip={this.props.downloadClip}
          editClip={editClip(clip)}
          isList
        />
      )
    })
  }
}

export default Clips
