
import React from 'react'
import './vortex.scss'

export const VortexSpinner = (props) => {
  return (
    <div style={props.style} className='loader'>
      <div style={props.innerStyle} className='inner one' />
      <div style={props.innerStyle} className='inner two' />
      <div style={props.innerStyle} className='inner three' />
    </div>
  )
}

export default VortexSpinner
