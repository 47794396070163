import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePersistedLines } from './PersistCalibration'
import mobiscroll from '@mobiscroll/react'
import { ContextButton } from '../annotationEditor/sub/contextButton'
import i18n from 'i18next'
import './LineSelector.scss'
import { LineVisualizer } from './LineVisualizer'
import materialColor from 'material-color-hash'

export const ListItem = ({ item }) => {
  const { id: index, onClick, onDelete, name } = item
  return <li
    data-index={index}
    key={index}
    onClick={onClick}
  >
    <div className={'left'}>
      <div
        className={'color-preview'}
        style={{
          backgroundColor: materialColor(name).backgroundColor,
        }}
      />
      {name}
    </div>

    <ContextButton
      onClick={(e) => {
        e.stopPropagation()
        onDelete()
      }}
      type='danger'
      iconName='fa-trash'
    />
  </li>
}

export const LineSelector = (props) => {
  const { setLineIndex, fieldID, getAuthorization, setIsNewLine } = props
  const [lines, setLines] = usePersistedLines(fieldID, getAuthorization, true)

  useEffect(() => {
    if (props.paper && lines.length) {
      const lineGroups = lines.map((line, index) => {
        const colorString = materialColor(line.name).backgroundColor
        const color = new props.paper.Color(colorString)
        return LineVisualizer(props.paper, line, color)
      })

      return () => {
        lineGroups.forEach(group => group.remove())
      }
    }
  }, [lines, props.paper])
  

  const addLine = () => {
    setLines([
      ...lines,
      {
        name: i18n.t('fieldCalibration.newLine'),
        // Empty string because empty inputs require an empty string as their value
        distance: '',
      },
    ])
    setLineIndex(lines.length)
    setIsNewLine(true)
  }

  const removeLine = useCallback((index) => {
    setLines([
      ...lines.slice(0, index),
      ...lines.slice(index + 1, lines.length),
    ])
  }, [lines, setLines])

  const data = useMemo(() => lines.map((line, index) => ({
    ...line,
    id: index,
    onClick: () => {
      setLineIndex(index)
      setIsNewLine(false)
    },
    onDelete: () => removeLine(index),
  })), [lines, removeLine, setIsNewLine, setLineIndex])

  const onDone = () => props.onDone(lines)

  return <mobiscroll.Page theme={'ios'} className={'line-selector'}>
    <mobiscroll.Listview
      itemType={ListItem}
      data={data}
      theme={'ios'}
      actions={[
        {
          icon: 'edit',
          action: console.log,
        },
      ]}
    />

    <mobiscroll.FormGroup className={'control-buttons'}>
      <ContextButton
        onClick={addLine}
        type={'boyzone'}
        iconName='fa-plus'
        label={i18n.t('fieldCalibration.createNewLine')}
      />
      <ContextButton
        onClick={onDone}
        type='success'
        iconName='fa-check'
        label={i18n.t('default.button.save')}
      />
      <ContextButton
        onClick={props.onAbort}
        type='danger'
        iconName='fa-times'
        label={i18n.t('default.button.cancel')}
      />
    </mobiscroll.FormGroup>
  </mobiscroll.Page>
}

export const useLineSelector = (props) => {
  const [lineIndex, setLineIndex] = useState(undefined)
  const [isNewLine, setIsNewLine] = useState(false)

  const lineSelector = <LineSelector
    {...props}
    lineIndex={lineIndex}
    setLineIndex={setLineIndex}
    setIsNewLine={setIsNewLine}
  />

  return { lineIndex, setLineIndex, lineSelector, isNewLine }
}
