import React from 'react'
import ReactDom from 'react-dom'

export default function ToolbarActions (props) {
  const { undo, redo, clear, container, recording, canUndo, canRedo, showLabel } = props
  const html = recording ? (
    <div className='Actions'>
      <div className={`action btn ${canUndo ? 'warning' : 'disabled'} `} onClick={() => { canUndo && undo() }}>{showLabel ? 'Undo' : ''}<span className='mbsc-ic mbsc-ic-material-undo' /></div>
      <div className={`action btn ${canRedo ? 'success' : 'disabled'} `} onClick={() => { canRedo && redo() }}>{showLabel ? 'Redo' : ''}<span className='mbsc-ic mbsc-ic-material-redo' /></div>
      <div className={`action btn ${canUndo ? 'danger' : 'disabled'} `} onClick={() => { canUndo && clear() }}>{showLabel ? 'Clear' : ''}<span className='mbsc-ic mbsc-ic-material-layers-clear' /></div>
    </div>
  ) : null
  if (container) {
    return ReactDom.createPortal(html, container)
  }
  return html
}
