
import React, { useRef } from 'react'
import { Scroller } from '@mobiscroll/react'
import './strokeButton.scss'
const strokeRange = [[{
  circular: true,
  data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
}]]

export default function StrokeButton (props) {
  const { stroke, setStroke } = props
  const mobiElement = useRef(null)
  function selectStroke () {
    if (!mobiElement.current || !mobiElement.current.instance) {
      console.error('Something went Wrong! No Color Element in strokeButton!')
      return
    }
    mobiElement.current.instance.element.click()
  }
  const videoElement = document.querySelector('.video-player')
  return (
    <>
      <div className='Tool strokeButton' onClick={selectStroke} >
        <span className='mbsc-ic'>{ stroke }</span>
      </div>
      <div className='mobiElement'>
        <Scroller wheels={strokeRange} context={videoElement} ref={mobiElement} onSet={setStroke} onItemTap={setStroke} display='center' />
      </div>
    </>
  )
}
