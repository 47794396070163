import React from 'react'
import VortexSpinner from '../spinner/vortex'
import AnnotationItem from './annotationItem'
import '../item.scss'

export default function AnnotationList (props) {
  if (props.annotations === null || props.annotations === undefined) {
    return (<div className='mbsc-row'>
      <VortexSpinner />
    </div>
    )
  }
  if (!props.annotations.length) {
    return null
  }
  const { annotations, updateAnnotation, filterAnnotations } = props

  const AnnotationElements = annotations.sort((a, b) => a.relativeTimestamp - b.relativeTimestamp)
    .filter((anno) => {
      if (!filterAnnotations) { return true }
      return anno.type === filterAnnotations
    }).map((anno) => {
      const onClick = props.onClick ? props.onClick(anno) : () => {}
      return <AnnotationItem
        useColor={props.useColor}
        key={anno.RowKey}
        annotation={anno}
        updateAnnotation={updateAnnotation}
        onClick={onClick}
        afterSeek={props.afterSeek}
        showFullTime={props.showFullTime}
        useRelativeTime={props.useRelativeTime}
        deleteAnnotation={props.deleteAnnotation}
        disableRename={props.disableRename}
      // onRename={onRename(anno)}
      />
    })
  return <div className={`Annotations ${props.additionalClass}`}>
    {AnnotationElements}
  </div>
}
