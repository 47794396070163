// import discovery from '@soccerwatch/discovery'

import Tag from './tag'
import Clip from './clip'
import Login from './user'
import Club from './clubs'
import Match from './match'
import Sequence from './sequence'
import Annotation from './annotation'
import Payment from './payment'
import Enabler from './enabler'
import City from './city'
const API = { ...Tag, ...Clip, ...Match, ...Annotation, ...Login, ...Sequence, ...Club, ...Payment, ...Enabler, ...City }
export default API
