
import React from 'react'
import ReactDom from 'react-dom'
import './autocomplete.scss'
import LabelList from './sequences/labelList'

export const AutoComplete = (props) => {
  const { itemList, onSelect, close } = props
  const [value, setValue] = React.useState('')
  const [selectedVal, setSelectedVal] = React.useState('')
  const onValueChange = (e) => {
    const text = e.target.value
    setValue(text)
  }
  const doAutocomplete = (val) => () => {
    if (val && val !== '') {
      setSelectedVal(val)
      setValue(val)
    }
  }
  const _close = () => {
    setValue('')
    setSelectedVal('')
    close()
  }
  const selectValue = () => {
    onSelect(value)
    close()
    setValue('')
    setSelectedVal('')
  }
  const options = itemList.filter((itm) => itm.toLowerCase().includes(value.toLowerCase()))
  // .map((itm) => {
  //   return <div className='labelTag' onClick={doAutocomplete(itm)}>{itm}</div>
  // })
  if (!props.open) {
    return null
  }
  const html = (
    <div className='autocomplete modal'>
      <div className='modalContent container-fluid'>
        <div className='row'>
          <div className='col-xs'>
            {/* <p className='callToAction'>Label anlegen:</p> */}
            <input placeholder='Neues Label hier eintragen...' className='filterInput' value={value} onChange={onValueChange} />
            {value !== '' && selectedVal !== value && (
              <div className='fake Collapsible'>
                <p className='suggest'>Bereits bestehende Labels:</p>
                <LabelList label={options} onClick={doAutocomplete} disableShowMore />
              </div>)
            }
            <div className='row'>
              <div className='col-xs-6'>
                <button className='btn_fluid danger' onClick={_close}>Abbrechen</button>
              </div>
              <div className='col-xs-6'>
                <button className='btn_fluid boyzone' onClick={selectValue}>Label hinzufügen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const ModalContainer = document.getElementById('ModalContainer')
  if (ModalContainer) {
    return ReactDom.createPortal(html, ModalContainer)
  }
  return html
}

export default AutoComplete
