export default {
  IOS_WARNING: "Annotations are currently not supported on iOS-Devices. We are working to find a permanent solution.",
  default: {
    button: {
      close: 'Close',
      cancel: 'Abort',
      save: 'Save',
      ok: 'ok',
    },
  },
  dashboard: {
    welcome: 'Staige',
    toClips: 'Go to Clips',
    toGames: 'Go to Games',
    logout: 'Logout',
  },
  sidebar: {
    subs: 'SUBSCRIPTIONS',
    help: 'HELP',
  },
  pathMap: {
    matches: {
      single: 'Games',
      id: 'Game Details',
      editRef: 'Game',
    },
    clips: {
      single: 'Clips',
      id: 'Clip Details',
      editRef: 'Clip',
    },
    sequence: {
      id: 'Edit Scene',
    },
    user: {
      single: 'User Managment',
    },
    subscription: {
      id: 'Subscription',
    },
    newSubscription: {
      single: 'New Subscription',
    },
    editor: 'Edit {{ref}}',
    playerPositions: {
      single: 'Track player positions',
    },
    404: {
      single: '???',
    },
  },
  createClipUi: {
    create: 'Create Clip',
    edit: 'Edit Clip',
    info: 'To create a clip, add szenes via quickselection or from games',
    addSequences: 'Add Scenes',
    nameClip: 'Name Clip before saving',
  },
  fastClipButtons: {
    head: 'Scene Quickselect',
    standards: 'Standards',
    highlights: 'Highlights',
    goals: 'Goals',
    this: 'of this game',
    newest: 'of newest game',
    filterd: 'of filtered games',
  },
  clipDetails: {
    watch: 'Watch in Editor',
    watchShort: 'Editor',
    edit: 'Edit',
    publish: 'Publish',
    unPublish: 'Set Privat',
    copyLink: 'Copy Link',
    delete: 'Delete',
    urlInClipboard: 'Url copied to Clipboard',
    sceneCount: 'Scenes from {{date}}',
    questionDelete: 'Are you sure, you want to delete this Clip?',
    cancle: 'Cancle',
  },
  clipList: {
    header: 'All Clips',
  },
  matchList: {
    header: 'All Matches',
  },
  matchItem: {
    watch: 'Watch in Editor',
    sequencesLoading: 'Loading Scenes',
    sequencesLoaded: '{{count}} Scenes',
    from: 'from',
    at: 'at',
  },
  sequenceItem: {
    edit: 'Edit',
    addToClip: 'Add to Clip',
    removeFromClip: 'Remove',
    rename: 'Rename Scene',
    gameInfo: 'Game Information',
    annotations: 'Annotations',
    delete: 'Delete',
    deleteHead: 'Delete Scene?',
    deleteMsg: 'Warning: The Scene will also be removed from all clips, that use it.',
    deleteOk: 'Delete',
    deleteCancle: 'Cancle',
  },
  labelList: {
    new: 'New Label',
    more: 'More',
    namePrompt: 'Name Label',
  },
  omniFilter: {
    search: 'Search...',
  },
  user: {
    from: 'from',
    guestSubs: 'Your Guestsubscriptions',
    guestSubFrom: 'to {{userName}}´s Clubs',
    subscription: 'Subscriptions',
    invalidSubscriptions: 'You do not own valid subscriptions',
    clubNameLoading: '- Clubname still loading',
    validUntiL: 'Payed until',
    activeUntil: 'Valid until',
    manageAccess: 'Manage Trainer',
    newSub: 'New Subscription',
  },
  subscriptionEdit: {
    inviteUser: 'Invite User',
    notSet: 'Not Set',
    guestSub: 'Guest Subscriptions for',
    recieverMail: 'Reciever E-Mail',
    send: 'Send',
    back: 'Back',
    explanationP1: `On this page you can invite other users as trainers to your subscription.
    Each trainer receives access to the game recordings of your subscribed club for the duration of your subscription.
    To invite a user simply click on one of the slots and enter the email address of the recipient.
    `,
    explanationP2: 'If the recipient does not have a soccerwatch account, he will receive an email with a registration link',
    explanationP3: `If he is already the owner of a soccerwatch account, the access will be activated immediately and the mail will only be used to inform him.`,
  },
  login: {
    loginFailed: 'Login Failed',
    unknownMail: 'Email and/or Password unknown',
    inputIncomplete: 'Please fill in all fields!',
    pwNoMatch: 'The entered passwords do not match!',
    invalidEmail: 'Please enter a valid Email',
    resetPwError: 'The password could not be reset successfully.',
    shareSubs: 'want to share a subscription with you.',
    noAccount: 'No account yet?',
    password: 'Password',
    forgotPassword: 'Forgot your Password?',
    login: 'Login',
    userName: 'Username',
    gotAccount: 'I already have an account',
    repeatPassword: 'Repeat Password',
    register: 'Register',
    registerSuccess: 'Your registration was successful. Please check your e-mail inbox.',
    send: 'Send',
    pwResetComplete: 'Your password has been reset successfully. Please check your e-mail inbox.',
    backToLogin: 'Back to Login',
    loggingIn: 'Signing on',
    TrainerToken: 'Register or log in with an existing account to gain access to your new subscription',
  },
  subscriptionCreate: {
    newSubscription: 'New Subscription',
    working: 'Your order is being processed ...',
    choosenClub: 'Selected Club:',
    noClub: 'No Club Selected',
    badError: 'An unexpected error has occurred. Please write following number down so that we may match your Paypal transfer to your order.',
    retry: 'Retry',
    headerSuccess: 'Club-access unlocked',
    textSuccess: 'Your Subscription to Club {{club}} has successfully been completed.',
    back: 'Back to overview',
    chooseClub: 'Select the club you want to subscribe to',
  },
  tags: {
    0: 'Goal Home',
    1: 'Goal Away',
    2: 'Goalchance Home',
    3: 'Goalchance Away',
    4: 'Foul Home',
    5: 'Foul Away',
    6: 'Penalty-Kick Home',
    7: 'Penalty-Kick Away',
    8: 'Yellow Card Home',
    9: 'Yellow Card Away',
    10: 'Red Card Home',
    11: 'Red Card Away',
    12: 'Kickoff 1. Half',
    13: 'End 1. Half',
    14: 'Kickoff 2. Half',
    15: 'End 2. Half',
    16: 'Tricks',
    17: 'Trick Pass',
    18: 'Trick Shot',
    19: 'Streaker',
    25: 'Header',
    26: 'Dive',
    27: 'Verlängerung',
    28: 'End',
    33: 'Offside Home',
    34: 'Offside Away',
    35: 'Corner Home',
    36: 'Corner Away',
    37: 'Free Kick Home',
    38: 'Free Kick Away',
    39: 'Auswechslung Home',
    40: 'Auswechslung Away',
  },
  playerPositionTracker: {
    metadataFormTitle: 'Metadata of the tracked object',
    placeholder: 'Please enter...',
    playerNumber: 'Player number',
    teamId: 'Team ID',
    trackBall: 'Track ball',
    link: {
      title: 'Mark positions',
      placeholder: 'Match-ID',
      linkLabel: 'Go',
    },
    players: '{{count}} player',
    players_plural: '{{count}} players',
    teamPositionsTitle: `Team {{teamId}} - $t(playerPositionTracker.players, { 'count': {{numberOfTrackedPlayers}} }) tracked`,
  },
  shortcutInfoPopup: {
    modalTitle: 'Keyboard shortcuts',
    intro: 'The video player actually has some shortcuts. They only work when the player is in focus, though.',
    howToFocusVideoPlayer: 'Click on the black control bar at the bottom of the screen to focus on the video player without marking a position!',
    proTip: 'Pro tip',
    space: 'Space',
    ctrl: 'Ctrl',
    shift: 'Shift',
    alt: 'Alt',
    playPause: 'Play / Pause the video',
    fullscreen: 'Toggle full screen mode',
    seconds: '{{count}} second',
    seconds_plural: '{{count}} seconds',
    direction_forward: 'ahead',
    direction_backward: 'back',
    skip: `Skip $t(shortcutInfoPopup.direction, { 'context': '{{direction}}' }) by $t(shortcutInfoPopup.seconds, { 'count': {{seconds}} })`,
    animateAhead: `Play $t(shortcutInfoPopup.seconds, { 'count': {{seconds}} }) of the video at a faster speed`,
    showAgain: 'Show me again',
    dontShowAgain: `Don't show again`,
    explenation: 'You can think of {{shiftSplitter}} as adding animation to a shortcut while' +
      ' {{altSplitter}} and {{ctrlSplitter}} set the skipped time to 5 and 20 seconds, respecively.',
    changePlayerNumber_increment: 'Increment player number by 1',
    changePlayerNumber_decrement: 'Decrement player number by 1',
  },
  fieldCalibration: {
    startCalibration: 'Calibrate field',
    newLine: 'New line',
    createNewLine: 'Add new line',
    point: 'Point',
    inMeters: 'in meters',
    name: 'Name',
    distance: 'Distance',
  },
}
