import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import PlayerPositionTool from '../annotationEditor/tools/PlayerPositionTool'
import mobiscroll from '@mobiscroll/react'
import PlayerPositionService from './PositionService/PlayerPositionService'
import globalPaper from 'paper'
import { useMetadataForm } from './MetadataForm'
import { createAdaptivePointText } from '../annotationEditor/tools/AdaptiveText'
import { TrackedPositionsVisualizer } from './TrackedPositionsVisualizer'
import KnownPlayerPositionIntervals from './knownPositionIntervals/knownPositionIntervals'

import tagDefinitions from '../../../lib/helper/tagDefinitions.json'

const tagDefinitionsByEventType = tagDefinitions.reduce((defs, itm) => {
  defs[itm.eventType] = itm
  return defs
}, {})


const strokeWidth = 3

const positionStrokeColors = {
  ball: new globalPaper.Color('darkgreen'),

  // dark orange
  teamA: new globalPaper.Color([201, 111, 0].map(n => n / 255)),
  // light orange
  teamAGoalie: new globalPaper.Color([255, 161, 46].map(n => n / 255)),
  // dark blue
  teamB: new globalPaper.Color([0, 16, 186].map(n => n / 255)),
  // light blue
  teamBGoalie: new globalPaper.Color([115, 152, 255].map(n => n / 255)),
  // red
  referee: new globalPaper.Color([244, 0, 0].map(n => n / 255))
}
const teamIdConventions = {
  0: 'teamA',
  1: 'teamB',
  2: 'teamAGoalie',
  3: 'teamBGoalie',
  4: 'referee'
}

const getPositionStrokeColor = (playerNumber, teamId) => {
  if (teamIdConventions[teamId]) {
    return positionStrokeColors[teamIdConventions[teamId]]
  }

  if (
    teamId === PlayerPositionService.ballMetadata.Club &&
    playerNumber === PlayerPositionService.ballMetadata.PlayerNumber
  ) {
    return positionStrokeColors.ball
  }
}

const useGetCurrentVideoTime = (videoController) => {
  return useCallback(() => videoController ? videoController.currentTime : -1, [videoController])
}

const usePlayerPositionService = (match, getAuthorization) => {
  const matchId = match ? match.RowKey : -1
  const [playerPositionService, setPlayerPositionService] = useState(new PlayerPositionService(matchId, undefined, getAuthorization))

  useEffect(() => {
    setPlayerPositionService(new PlayerPositionService(
      matchId, undefined, getAuthorization
    ))
  }, [matchId, setPlayerPositionService, getAuthorization])

  return playerPositionService
}

export const useOnTimeUpdate = (video, callback) => useEffect(() => {
  if (video) {
    video.addEventListener('timeupdate', callback)

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', callback)
      }
    }
  }
}, [video, callback])

const parsePositionData = (position) => ({
  ...position,
  PlayerNumber: parseInt(position.PlayerNumber),
  Club: parseInt(position.Club)
})

const createPlayerPositionBoundingBox = ({
  paper, position, videoSize, currentPlayerNumber, currentTeamId
}) => {
  const Rectangle = paper ? paper.Path.Rectangle : () => {}

  const topLeft = position.drawingSurfaceTopLeft || position.topLeft
  const bottomRight = position.drawingSurfaceBottomRight || position.bottomRight
 
  const coordinatesAreNormalized = [topLeft.x, topLeft.y, bottomRight.x, bottomRight.y]
    .reduce(
      (previousCoordsAreNormalized, coord) => previousCoordsAreNormalized && 0 <= coord && coord <= 1,
      true,
    )
 
  let rectangle = new Rectangle(topLeft, bottomRight)
  if (coordinatesAreNormalized) {
    // Map normalized coordinates to UV coordinates
    rectangle = new Rectangle(
      topLeft.multiply(videoSize), bottomRight.multiply(videoSize),
    )
  }
  
  rectangle.strokeColor = getPositionStrokeColor(
    position.PlayerNumber, position.Club,
    currentPlayerNumber, currentTeamId
  )
  rectangle.strokeWidth = strokeWidth

  // Make rectangle around non-current players dashed
  if (position.PlayerNumber !== currentPlayerNumber || position.Club !== currentTeamId) {
    rectangle.style.dashArray = [8, 8]
  }

  return rectangle
}

const createPlayerPositionLabel = ({ paper, rectangle, position }) => {
  let text = createAdaptivePointText(
    paper,
    rectangle.bounds.topCenter.subtract(new paper.Point(0, 5))
  )
  text.content = position.PlayerNumber.toString()
  text.fillColor = rectangle.strokeColor
  text.strokeWidth = strokeWidth

  return text
}

const useDrawBoxes = ({
  drawingLayer,
  paper,
  playerPositionService,
  getCurrentVideoTime,
  videoSize,
  currentPlayerNumber,
  currentTeamId
}) => {
  const rectangles = useRef([])
  const noop = useCallback(() => {}, [])
  const Group = paper ? paper.Group : noop

  return useCallback((forceFetch = false) => {
    /**
     * @type {paper.Layer}
     */
    const layer = drawingLayer

    rectangles.current.forEach(rect => rect.remove())
    rectangles.current = []

    playerPositionService.getByTimestamp(getCurrentVideoTime(), forceFetch)
      // Ensure positions is an array (of positions)
      .then(positions => Array.isArray(positions) ? positions : [])
      // Convert some string data to integers
      .then(positions => positions.map(parsePositionData))
      .then(positions => {
        positions.forEach((position) => {
          const group = new Group()
          const rectangle = createPlayerPositionBoundingBox({
            position, currentTeamId, currentPlayerNumber, paper, videoSize
          })
          const text = createPlayerPositionLabel({ rectangle, paper, position })

          // Remove group elements from view, but then add group to the view
          rectangle.remove()
          group.addChild(rectangle)
          text.remove()
          group.addChild(text)
          layer.addChild(group)

          rectangles.current.push(group)
        })
      })
  }, [drawingLayer, playerPositionService, getCurrentVideoTime, Group, currentTeamId, currentPlayerNumber, paper, videoSize])
}

/**
 * Returns frameTime if video.requestVideoFrameCallback exists and should be used
 * @param {HTMLVideoElement | null} video
 * @returns {number} frameTime; seems to often be video.currentTime - 0.04
 */
const useFrameTime = (video) => {
  const shouldUseRequestVideoFrameCallback = false

  const time = video ? video.currentTime : 1
  const [frameTime, setFrameTime] = useState(-1)

  if (shouldUseRequestVideoFrameCallback) {
    // shouldUseRequestVideoFrameCallback is a constant feature flag, so it will never change at runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      video && video.requestVideoFrameCallback && video.requestVideoFrameCallback((now, meta) => {
        setFrameTime(meta.mediaTime)
      })
    }, [time, video])
  }

  return frameTime
}

const useTags = (getTagsForMatch, matchID, headers) => {
  const [tags, setTags] = useState([])

  useEffect(() => {
    getTagsForMatch(matchID, tagDefinitionsByEventType, headers)
      .then(newTags => {
        setTags(newTags)
      })
  }, [matchID, getTagsForMatch, headers])

  return tags
}
/**
 *
 * @param {Function} getTagsForMatch
 * @param {string | number} matchID
 * @param {object} headers
 * @returns {Array<[number, number]>}
 */
const useGameIntervals = (getTagsForMatch, matchID, headers) => {
  const tags = useTags(getTagsForMatch, matchID, headers)

  return useMemo(() => {
    const getTimeByEventType = eventType => {
      const tag = tags.find(tag => tag.eventType === eventType)
      return tag === undefined ? tag : tag.timestamp
    }
    const firstHalfStart = getTimeByEventType(12)
    const firstHalfEnd = getTimeByEventType(13)
    const secondHalfStart = getTimeByEventType(14)
    const secondHalfEnd = getTimeByEventType(15)

    const intervals = []

    if (firstHalfStart && firstHalfEnd) {
      intervals.push([firstHalfStart, firstHalfEnd])
    }

    if (secondHalfStart && secondHalfEnd) {
      intervals.push([secondHalfStart, secondHalfEnd])
    }

    return intervals
  }, [tags])
}

  

/**
 * A Videocanvas plugin that allows the user to mark the position of
 * any player or the ball.
 * @param {{
 *   videoParent: Videocanvas,
 *   videoState: object,
 *   video: HTMLVideoElement,
 *   paper: paper.PaperScope,
 *   drawingLayer: paper.Layer,
 *   sidebarContainer: HTMLElement,
 *   match: {
 *     RowKey: number | string,
 *   },
 *   getTagsForMatch: Function,
 *   headers: {},
 * }} props
 * @returns {*}
 * @constructor
 */
export const PositionEditorPlugin = (props) => {
  // Empty number inputs must have the value '', not undefined, NaN or null
  const {
    teamId: currentTeamId,
    playerNumber: currentPlayerNumber,
    metadataFormElement,
    decrementPlayerNumber,
    incrementPlayerNumber
  } = useMetadataForm()
  useEffect(() => {
    props.addKeyboardShortcuts({
      'incrementPlayerNumber': {
        code: 'KeyW',
        handler: incrementPlayerNumber
      },
      'decrementPlayerNumber': {
        code: 'KeyS',
        handler: decrementPlayerNumber
      }
    })

    // because useEffect is used as componentDidMount here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const playerPositionService = usePlayerPositionService(props.match, props.getAuthorization)

  // Cache video size
  const paperSize = props.paper ? props.paper.view.size : { width: 0, height: 0 }
  const videoSize = useMemo(
    () => new globalPaper.Point(paperSize.width, paperSize.height),
    [paperSize.height, paperSize.width]
  )

  const getCurrentVideoTime = useGetCurrentVideoTime(props.videoParent.props.videoController)

  const drawBoxes = useDrawBoxes({
    playerPositionService,
    videoSize,
    drawingLayer: props.drawingLayer,
    paper: props.paper,
    currentPlayerNumber,
    currentTeamId,
    getCurrentVideoTime
  })

  // Force redraw when current player number or team ID change in order to update box colors
  useEffect(() => drawBoxes(true), [drawBoxes, currentPlayerNumber, currentTeamId])

  const frameTime = useFrameTime(props.video)

  useOnTimeUpdate(props.video, drawBoxes)

  useEffect(() => {
    const subscriptionName = 'PlayerPositionPlugin'

    playerPositionService.subscribeToChange(subscriptionName, drawBoxes)
    return () => playerPositionService.unsubscribeFromChange(subscriptionName)
  }, [drawBoxes, playerPositionService])

  useEffect(() => {
    // TODO save and restore active tool?
  }, [props.paper])

  const gameTimeIntervals = useGameIntervals(props.getTagsForMatch, props.match.RowKey, props.headers)

  // Save positions in video as onNewPosition is only called with position on drawing surface
  const [videoPositions, setVideoPositions] = useState({})
  const onVideoPositionChange = setVideoPositions
  

  /**
   * Handles new Positions
   * @param {paper.Path} path - The path marking the new position. Usually a rectangle
   */
  const onNewPosition = async (path) => {
    // Only save positions with min area in order to ignore accidental clicks
    const minArea = 10
    const boundingBox = path.bounds

    if (currentTeamId !== undefined && currentPlayerNumber !== undefined && boundingBox.area >= minArea) {
      playerPositionService.createPosition(
        boundingBox.topLeft,
        boundingBox.bottomRight,
        getCurrentVideoTime(),
        currentTeamId,
        currentPlayerNumber,
        frameTime,
        // Normalize UV coordinates to fit range [0, 1]
        props.paper.Point.min(videoPositions.min, videoPositions.max).divide(videoSize),
        props.paper.Point.max(videoPositions.min, videoPositions.max).divide(videoSize),
      ).catch(console.error)
    } else if (currentTeamId === undefined || currentPlayerNumber === undefined) {
      mobiscroll.toast({
        display: 'top',
        message: 'Please enter a team and player ID!',
        color: 'danger'
      })
    }
  }
  const duration = props.video ? props.video.duration : 0
  const sidebarControls = <div className={`position-metadata-input`}>
    {metadataFormElement}
    <TrackedPositionsVisualizer
      playerPositionService={playerPositionService}
      getCurrentVideoTime={getCurrentVideoTime}
      video={props.video}
    />

    <div style={{ display: 'none' }}>
      <PlayerPositionTool
        currentTool={PlayerPositionTool.toolName}
        videoParent={props.videoParent}
        Paper={props.paper}
        drawingLayer={props.drawingLayer}
        pathDone={onNewPosition}
        onVideoPositionChange={onVideoPositionChange}
        drawMode
        redrawing={false}
        color={getPositionStrokeColor(currentPlayerNumber, currentTeamId, currentPlayerNumber, currentTeamId)}
        stroke={strokeWidth}
        // dashArray={[8, 8]}
      />
    </div>
    {
      props.video ? <KnownPlayerPositionIntervals
        totalDuration={duration}
        currentPlayer={
          (currentPlayerNumber !== undefined && currentTeamId !== undefined)
            ? { playerNumber: currentPlayerNumber, teamId: currentTeamId }
            : null
        }
        playerPositionService={playerPositionService}
        video={props.video}
        gameTimeIntervals={gameTimeIntervals}
      /> : null
    }
  </div>

  return props.sidebarContainer
    ? ReactDOM.createPortal(sidebarControls, props.sidebarContainer)
    : sidebarControls
}

PositionEditorPlugin.propTypes = {
  videoParent: PropTypes.any,
  videoState: PropTypes.any,
  video: PropTypes.any,
  paper: PropTypes.any,
  drawingLayer: PropTypes.any,
  sidebarContainer: PropTypes.any,
  match: PropTypes.shape({
    RowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  addKeyboardShortcuts: PropTypes.func,
  getAuthorization: PropTypes.func,
  getTagsForMatch: PropTypes.func,
  headers: PropTypes.object
}
