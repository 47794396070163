import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class Poly4 extends Component {
  constructor (props) {
    super(props)
    this.count = 0
  }

  onMouseDown = (evt, path) => {
    path.add(evt.localPoint)
    if (this.count === 0) {
      this.firstPointMarker = new this.props.Paper.Path.Circle(evt.localPoint, 4)
      this.firstPointMarker.fillColor = new this.props.Paper.Color(this.props.color)
      path.data.skipAnimatedRedraw = true
    } else if (this.firstPointMarker) {
      this.firstPointMarker.remove()
      this.firstPointMarker = undefined
    }
    this.count++
    return { path, isNewPath: this.count === 1 }
  }

  onMouseDrag = (evt, path) => {
    // this.max = evt.localPoint
    // path.segments[1].point.set(this.max)
    return false
  }

  onMouseUp = (evt, path) => {
    if (this.count === 4) {
      path.closed = true
      path.fillColor = path.strokeColor.clone()
      path.fillColor.alpha = 0.2
      this.count = 0
      return { path, endPath: true }
    }
    return false
  }

  cleanUp = () => {
    this.count = 0
    if (this.firstPointMarker) {
      this.firstPointMarker.remove()
      this.firstPointMarker = undefined
    }
  }

  render () {
    return (
      <ProtoTool
        key='Poly4'
        toolName='Poly4'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        cleanUp={this.cleanUp}
        keepPath
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-material-texture' />
      </ProtoTool>
    )
  }
}

export default Poly4
