import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import ClipDetails from '../../utility/clips/clipDetails'
import VortexSpinner from '../../utility/spinner/vortex'
import { getAuthorization } from '../../firebase'
// import { Link } from 'react-router-dom'
const { Popup } = mobiscroll

class ClipDetailsSite extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      working: false,
      authHeader: null
    }
    this.props.setCurrentSite()
    this.getCurrentAuthHeader()
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  getCurrentAuthHeader = async () => {
    const authHeader = await getAuthorization()
    this.setState({ authHeader })
  }

  componentDidUpdate(prevPros) {
    if (!prevPros.authReady && this.props.authReady) {
      this.getCurrentAuthHeader()
    }
  }


  // shouldComponentUpdate (nextProps) {
  //   return (this.props.clipsLoaded !== nextProps.clipsLoaded) ||
  //   (this.props.sequencesLoaded !== nextProps.sequencesLoaded)
  // }

  deleteSequence = (sequence) => () => {
    if (this.state.working) {
      return
    }
    this.setState({ working: true }, async () => {
      const clipUpdate = { ...this.props.clip }
      const sequenceIndex = clipUpdate.sequenceIds.findIndex((id) => id === sequence.RowKey)
      clipUpdate.sequenceIds.splice(sequenceIndex, 1)
      if (this.props.updateClip) {
        await this.props.updateClip(clipUpdate)
      }
      this.setState({ working: false })
    })
  }

  setClipContext = () => {
    const clip = this.props.clip
    this.props.setClipContext(clip)
    this.props.history.push('/matches/')
  }

  deleteClip = async () => {
    await this.props.deleteClip(this.props.clip, this.props.clipIndex)
    window.GlobalCoachingTool.goBack && window.GlobalCoachingTool.goBack()
  }

  goToClipEditSequenceTime = (sequence) => () => {
    window.GlobalCoachingTool.timeReference = sequence.relativeStartTime
    this.props.history.push('./edit/')
  }

  goToEditAnnotationTime = (anno) => () => {
    const time = anno.relativeTimestamp - 4
    window.GlobalCoachingTool.timeReference = time > 0 ? time : 0
    this.props.history.push('./edit/')
  }

  render () {
    const { clip, clipsLoaded, sequencesLoaded, toggleClipPublicity } = this.props
    return (
      <>
        <Sidebar
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          history={this.props.history}
          user={this.props.user}
          childrenBeforeLogo
        >
          <div className='newClipButton'>
            {/* <span onClick={this.setClipContext} className='btn_fluid success'> Clip Bearbeiten </span> */}
            {/* <Link className='btn_fluid success' to='/matches'>Neuer Clip</Link> */}
          </div>
        </Sidebar>
        <Content className='clipDetails'>
          {clipsLoaded && sequencesLoaded
            ? <ClipDetails
              clip={clip}
              authHeader={this.state.authHeader}
              index={this.props.clipIndex}
              publishClip={toggleClipPublicity}
              updateClip={this.props.updateClip}
              deleteClip={this.deleteClip}
              editClip={this.setClipContext}
              history={this.props.history}
              onMinuteClick={this.goToClipEditSequenceTime}
              onAnnotationClick={this.goToEditAnnotationTime}
              getAuthorization={this.props.getAuthorization}
              currentAuthHeader={this.props.currentAuthHeader}
              isHighlightAdmin={this.props.isHighlightAdmin}
              downloadClip={this.props.downloadClip}
              getIOSClipPath={this.props.getIOSClipPath}
              disableLabelShowMore
              editSequence
              showEditorLink
              showSequences
              showAnnotations
              showLabel
            />
            : <VortexSpinner />
          }
        </Content>
        <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
          <span>{this.state.error}</span>
        </Popup>
      </>
    )
  }
}

export default ClipDetailsSite
