import { useEffect } from 'react'
import materialColor from 'material-color-hash'

const strokeWidth = 25

/**
 * @typedef {{ x: number, y: number }} Point
 */

/**
 * @typedef {{ pointA: Point, pointB: Point }} Line
 */

/**
 *
 * @param {paper.PaperScope} paperScope
 * @param {Line} line
 * @param {paper.Color} color
 * @returns {paper.Path.Line}
 */
const createLine = (paperScope, line, color) => {
  const pointA = new paperScope.Point(line.pointA)
  const pointB = new paperScope.Point(line.pointB)
  const paperLine = new paperScope.Path.Line(pointA, pointB)

  paperLine.strokeWidth = strokeWidth
  paperLine.strokeColor = color
  return paperLine
}

/**
 * Paints calibration line on playing field
 * @param {paper.PaperScope} paperScope
 * @param {paper.Color} color
 * @param {Line} line
 * @returns {paper.Group} The Group containing the calibration line
 */
export const LineVisualizer = (paperScope, line, color) => {
  const activeLayer = paperScope.project.activeLayer

  const group = new paperScope.Group()

  if (
    line.pointA &&
    line.pointB &&
    !Number.isNaN(line.pointA.x + line.pointB.x + line.pointA.y + line.pointB.y)
  ) {
    const paperLine = createLine(paperScope, line, color)
    paperLine.remove()
    group.addChild(paperLine)
  }

  group.remove()
  activeLayer.addChild(group)

  return group
}

/**
 * React hook to paint a calibration line on the field
 * @param {paper.PaperScope} paperScope
 * @param {string} lineName
 * @param {Array<Point>} pointDrafts
 */
export const useLineVisualizer = (paperScope, lineName, pointDrafts) => {
  useEffect(() => {
    if (paperScope && lineName) {
      const color = new paperScope.Color(materialColor(lineName).backgroundColor)
      const lineGroup = LineVisualizer(paperScope, {
        pointA: pointDrafts[0],
        pointB: pointDrafts[1],
      }, color)

      return () => {
        lineGroup.remove()
      }
    }
  }, [lineName, pointDrafts, paperScope])
}
