import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export const DisplayContextButtons = (props) => {
  const html = (
    <div className='ContextButtonWrapper'>
      <div className='ContextButtonPositioner'>
        {props.children}
        {props.buttons}
      </div>
    </div>
  )
  if (props.container) {
    return ReactDOM.createPortal(html, props.container)
  }
  return html
}

DisplayContextButtons.propTypes = {
  buttons: PropTypes.node,
  container: PropTypes.instanceOf(Element),
}
