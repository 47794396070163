import React, { Component } from 'react'
import mobiscroll from '@mobiscroll/react'
import jwtDecode from 'jwt-decode'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import API from '../api/index'
import MatchSite from './sites/matches/matchSite'
import ClipSite from './sites/clips/clipSite'
import Site404 from './sites/404/404.js'
import View from './sites/publicView/view'
import Login from './sites/login/login'
import Details from './sites/matchDetails/details'
import ClipDetails from './sites/ClipDetailsSite/ClipDetailsSite'
import Editor from './Editor/editor'
import Dashboard from './sites/dashboard/dashboard'
import User from './sites/user/user'
import NoSubscription from './sites/noSubscriptions/noSubscriptions'
import arrayMove from 'array-move'
import SubscriptionEdit from './sites/user/subscriptionEdit'
import SubscriptionCreate from './sites/user/subscriptionCreate'
import RouteListener from './RouteListener'
import HelpModal from './utility/helpModal/helpModal'
import UnsuportedBrowser from './sites/unsuportedBrowser/unsuportedBrowser'
import { isIE } from '../lib/helper/deviceCatigorisation'
import TAGDEFINITION from '../lib/helper/tagDefinitions.json'
import firebase, { getAuthorization } from './firebase'

const TAGDEFINITION_OBJ = TAGDEFINITION.reduce((defs, itm) => {
  defs[itm.eventType] = itm
  return defs
}, {})

function formateDateTime (timeString) {
  const timeObj = new Date(timeString)
  const date = `${('0' + timeObj.getDate()).slice(-2)}.${('0' + (timeObj.getMonth() + 1)).slice(-2)}.${timeObj.getFullYear()}`
  const time = `${timeObj.toISOString().split('T')[1].split(':').splice(0, 2).join(':')} Uhr`
  return { date, time }
}

function Wait (time) {
  return new Promise((resolve) => {
    return setTimeout(resolve, time)
  })
}

const { Popup } = mobiscroll
const unique = (itm, i, arr) => i === arr.indexOf(itm)
class App extends Component {
  constructor (props) {
    super(props)
    this.checkIfStillLoggedIn()
    const curr = window.location.href.split('/')
    // const userJson = window.localStorage.getItem('user')
    // const userData = userJson && JSON.parse(userJson)
    // const tokenJson = window.localStorage.getItem('tokenSet')
    // const tokenSet = tokenJson && JSON.parse(tokenJson)
    // const authorization = tokenSet ? 'Bearer ' + tokenSet.token : null
    const userOptedOut = window.localStorage.getItem('userOptedOut')
    if (userOptedOut) {
      window['ga-disable-UA-101371445-7'] = true
    }
    // const noSubscriptions = userData && userData.subscriptions?.length === 0
    this.deferredUpdate = false
    const lastWeek = new Date()
    lastWeek.setTime(new Date().getTime() - 604800000)
    // let isHighlightAdmin = userData && userData.role && userData.role.includes('highlightadmin')
    let isHighlightAdmin = false
    const adminPref = window.localStorage.getItem('highlightMode')
    if (adminPref !== null) {
      isHighlightAdmin = adminPref === 'true'
    }
    this.NavSites = ['matches', 'videos']
    this.specialSiteFirstLogout = false

    this.state = {
      currentSite: curr[curr.length - 1],
      loadingMatches: false,
      matchesLoaded: false,
      matches: null,
      matchMap: {},
      matchesNextToken: null,

      recentMatches: [],
      recentMatchesLoaded: false,

      loadingTags: false,
      tagsLoaded: false,

      // TODO: REMOVE
      // loadingDefinitions: false,
      // tagDefinitionsLoaded: false,
      // tagDefinitions: null,

      loadingAnnotationTags: false,
      annotationTagsLoaded: false,
      annotationTags: null,

      sequences: {},
      sequenceMap: {},
      sequencesLoading: false,
      sequencesLoaded: false,

      recentSequences: [],

      loggedIn: '', // Boolean(userData && authorization),
      userData: undefined,
      noSubscriptions: false,

      loadingclips: false,
      clipsLoaded: false,
      clips: [],

      recentClips: [],
      clipSequence: [],
      clipContext: null,
      clipSequenceName: '',
      clipUiWorking: false,

      clubs: {},
      clubArray: [],
      clubsLoaded: false,

      knownMatchAgeClasses: [],
      knownMatchLeagues: [],
      knownMatchClubs: [],

      knownSequenceAgeClasses: [],
      knownSequenceLeagues: [],
      knownSequenceClubs: [],
      knownSequenceLabel: [],

      match_formatedDate: [],
      match_baseLeague: [],
      match_ageClass: [],
      match_clubs: [],

      sequence_name: [],
      sequence_date: [],
      sequence_label: [],

      loginTrackSend: false,
      returnTrackSend: false,

      deferrerSwitch: false,
      userOptedOut,

      isHighlightAdmin: isHighlightAdmin,
      enabler: [],
      cities: [],
      enablerLoaded: false,
      citiesLoaded: false,
      HASelectedType: null,
      HASelection: null,

      matchDateFrom: lastWeek.toISOString(),
      matchDateTill: new Date().toISOString(),

      currentEnabler: null,
      currentCity: null,

      authReady: false,
      autoLoginTimePassed: false
    }
  }

  checkIfStillLoggedIn = async () => {
    if (!window.location.pathname.includes('/login') && !window.location.pathname.includes('/public') && !window.location.pathname.includes('/publicView')) {
      await Wait(1200)
      if (!this.state.loggedIn && !this.checkingLogin) {
        window.location = '/login/'
        return
      }
      // console.log('Logged In')
    }
  }

  setupGlobalObject = () => {
    window.GlobalCoachingTool = window.GlobalCoachingTool || {}
    window.GlobalCoachingTool.logout = this.logout
    window.GlobalCoachingTool.toggleHighlightMode = this.toggleHighlightMode
    window.GlobalCoachingTool.changeLanguage = this.changeLanguage
  }

  componentDidMount = async () => {
    // window.alert('RC Mount')
    if (isIE() && window.location.pathname !== '/unsuportedBrowser/') {
      window.location = '/unsuportedBrowser/'
      return
    }
    this.setupGlobalObject()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.handleLogin(user)
      }
    })
    // let returnTrackSend = this.state.returnTrackSend
    // if (!this.state.loginTrackSend) {
    //   window.gtag && window.gtag('event', 'Login', {
    //     event_category: 'Returned',
    //     event_label: 'User Returned to or Reloaded the Page',
    //     value: `${user.Mail}::${process.env.REACT_APP_Environment}`
    //   })
    //   returnTrackSend = true
    // }
  }

  toggleHighlightMode = () => {
    if (!this.state.userData || (!this.state.userData.role.includes('admin') && !this.state.userData.role.includes('highlightadmin'))) {
      return
    }
    const isHighlightAdmin = !this.state.isHighlightAdmin
    window.localStorage.setItem('highlightMode', isHighlightAdmin)
    this.setState({ isHighlightAdmin, matchesLoaded: false, loadingMatches: true }, () => {
      this.getUserData()
    })
  }

  getUserData = async () => {
    // await this.getTagDefinitions()
    if (this.state.isHighlightAdmin) {
      this.getClips()
      this.getAllSequences()
      !this.state.clubsLoaded && await this.getClubs()
      const cities = await API.getCities()
      const enabler = await API.getEnabler()
      this.setState({ cities, enabler, citiesLoaded: true, enablerLoaded: true, matches: [], matchesLoaded: true, loadingMatches: false })
    } else {
      const matches = await this.getMatches(this.state.noSubscriptions)
      const sortedMatches = (JSON.parse(JSON.stringify(matches))).sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
      await this.getClips()
      await this.getAllSequences()
      await this.getTagsForAllMatches(sortedMatches, true)
      await !this.state.clubsLoaded && this.getClubs()
    }
  }

  shallowCompare (newObj, prevObj) {
    for (const key in newObj) {
      if (newObj[key] !== prevObj[key]) return true
    }
    return false
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (this.deferredUpdate) {
      if ((this.state.deferrerSwitch !== nextState.deferrerSwitch) && (!this.state.matches || (nextState.matches.length === this.state.matches.length))) {
        return false
      }
    }
    return true
  }

  get filterStateMatch () {
    const { match_formatedDate, match_baseLeague, match_ageClass, match_clubs } = this.state //eslint-disable-line
    return { match_formatedDate, match_baseLeague, match_ageClass, match_clubs }
  }

  get filterStateSequence () {
    const { sequence_name, sequence_date, sequence_label } = this.state //eslint-disable-line
    return { sequence_name, sequence_date, sequence_label }
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  calculateknownMatchMeta = () => {
    const matches = this.state.matches
    let knownMatchClubs = {}
    let knownMatchLeagues = {}
    let knownMatchAgeClasses = {}
    matches.forEach((video) => {
      // Keep Track of Possible Values for different Filters
      knownMatchClubs[video.clubAName] = true
      knownMatchClubs[video.clubBName] = true
      if (video.ageClass && video.ageClass !== 'Unbekannt') {
        knownMatchAgeClasses[video.ageClass] = true
      }
      knownMatchLeagues[video.baseLeague] = true
    })

    knownMatchClubs = Object.keys(knownMatchClubs).sort()
    knownMatchLeagues = Object.keys(knownMatchLeagues).sort()
    knownMatchAgeClasses = Object.keys(knownMatchAgeClasses).sort()
    this.setState({ knownMatchClubs, knownMatchLeagues, knownMatchAgeClasses })
  }

  getClubs = async () => {
    const unsorted = await API.getClubs()
    if (unsorted.error) {
      console.error(unsorted.error)
      this.setState({ clubsLoaded: true })
      return
    }
    const realClubs = []
    const doubles = []
    unsorted.map(c => c.name).forEach((n, i, s) => {
      const c = unsorted[i]
      if (unique(n, i, s)) {
        realClubs.push(c)
      } else {
        doubles.push(c)
      }
    })
    doubles.forEach(d => {
      const index = realClubs.findIndex((c) => c.name === d.name)
      realClubs[index].additionalRowKey = d.RowKey
    })
    const clubArray = realClubs.sort((a, b) => (
      a.name.toLowerCase() < b.name.toLowerCase() ? -1
        : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0))
    const clubs = realClubs.reduce((clubs, c) => {
      clubs[c.RowKey] = c
      return clubs
    }, {})
    this.setState({ clubs, clubArray, clubsLoaded: true }, () => {
      this.mapSubscriptionIdsToClubNames()
    })
  }

  mapSubscriptionIdsToClubNames = () => {
    const userData = { ...this.state.userData }
    userData.subscriptions = userData.subscriptions?.map((sub) => {
      const club = this.state.clubs[sub.data.club] || { name: 'Unbekannt' }
      sub.data.clubName = club.name
      return sub
    })
    this.setState(userData)
  }

  getMatches = async (trial = false) => {
    const matches = trial ? await API.getTrialMatches() : await API.getMatches()
    if (matches.error) {
      console.error(matches.error)
      this.setState({ error: matches.error.toString(), loadingMatches: false }, () => {
        this.refs.error.instance.show()
      })
      return []
    }

    return this.updateMatchState(matches)
  }

  getMatchCornerPoints = async (videoId) => {
    const cornerPoints = await API.getCornerPoints(videoId)
    return cornerPoints
  }

  updateMatchState = (data) => {
    const matches = data.map((match) => {
      const formated = formateDateTime(match.startTime)
      match.formatedTime = formated.time
      match.formatedDate = formated.date
      if (!match.league) {
        match.league = 'N/A'
      }
      const leagueAndAgeClass = match.league.split(' | ')
      if (leagueAndAgeClass.length > 1) {
        match.ageClass = leagueAndAgeClass[0]
        match.baseLeague = leagueAndAgeClass[1]
      } else {
        match.baseLeague = match.league
        match.ageClass = ''
      }
      match.clubs = [match.clubAName, match.clubBName]
      return match
    }).sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
    const matchMap = matches.reduce((map, match) => {
      map[match.RowKey] = match
      return map
    }, {})
    this.setState({ matches, matchMap, loadingMatches: false, matchesLoaded: true }, () => {
      this.calculateknownMatchMeta()
    })
    return matches
  }

  getMatchesForEnabler = async (enabler) => {
    this.setState({ loadingMatches: true, matchesLoaded: false })
    const clubList = enabler.enabledClubs
    const matchesUnorderd = await API.getMachtesForClubs(clubList)
    const matches = (JSON.parse(JSON.stringify(matchesUnorderd))).filter((a) => !!a).sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
    return this.updateMatchState(matches)
    // this.setState({ matches, matchesLoaded: true, matchesLoading: false })
  }

  getMatchesForCity = async (city) => {
    // const city = this.state.currentCity
    this.setState({ loadingMatches: true, matchesLoaded: false })
    const cityName = city.name
    const clubs = await API.getClubsByCity(cityName)
    const clubIds = clubs.map((c) => c.RowKey)
    const matchesUnorderd = await API.getMachtesForClubs(clubIds)
    const matches = (JSON.parse(JSON.stringify(matchesUnorderd))).filter((a) => !!a).sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
    return this.updateMatchState(matches)
    // this.setState({ matches, matchesLoaded: true, matchesLoading: false })
  }

  getMatchesForClub = async (club) => {
    this.setState({ loadingMatches: true, matchesLoaded: false })
    const clubIds = [club.RowKey]
    if (club.additionalRowKey) {
      clubIds.push(club.additionalRowKey)
    }
    const matchesUnorderd = await API.getMachtesForClubs(clubIds)
    const matches = (JSON.parse(JSON.stringify(matchesUnorderd))).filter((a) => !!a).sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())
    return this.updateMatchState(matches)
  }

  calculateGameTimesFromTags = (tags) => {
    const times = tags.reduce((times, tag) => {
      switch (tag.eventType) {
        case 12: times.startFirstHalf = tag.timestamp - 1; break
        case 13: times.endFirstHalf = tag.timestamp; break
        case 14: times.startSecondHalf = tag.timestamp - 1; break
        case 15: times.endSecondHalf = tag.timestamp; break
        default: break
      }
      return times
    }, {})
    if (Object.keys(times).length !== 4) {
      return null
    }
    return times
  }

  calculateGameTimestampForTagsOrSeq = (tag, times) => {
    if (!times || !Object.keys(times).length === 4) {
      return tag
    }
    const isFirstHalf = tag.timestamp <= times.endFirstHalf
    const min45 = 2700
    const min90 = 5400
    let relativeTime = 0
    let gameTimeLabel = ''
    if (isFirstHalf) {
      relativeTime = tag.timestamp - times.startFirstHalf
      if (relativeTime > min45) {
        gameTimeLabel = '45 + ' + Math.ceil((relativeTime % min45) / 60)
      } else {
        gameTimeLabel = '' + Math.ceil((relativeTime / 60))
      }
    } else {
      relativeTime = min45 + tag.timestamp - times.startSecondHalf
      if (relativeTime > min90) {
        gameTimeLabel = '90 + ' + Math.ceil((relativeTime % min90) / 60)
      } else {
        gameTimeLabel = '' + Math.ceil((relativeTime / 60))
      }
    }
    if (gameTimeLabel.length === 1) {
      gameTimeLabel = '0' + gameTimeLabel
    }
    relativeTime = Math.ceil(relativeTime / 60)
    tag.gameTimeLabel = gameTimeLabel
    tag.gameTime = relativeTime
    return tag
  }

  calculateGameTimestampForSequences = (matchId, times) => {
    const sequenceMap = JSON.parse(JSON.stringify(this.state.sequenceMap))
    const sequences = JSON.parse(JSON.stringify(this.state.sequences))
    if (sequenceMap[matchId]) {
      sequenceMap[matchId] = sequenceMap[matchId].map((seq) => {
        const sequence = this.calculateGameTimestampForTagsOrSeq(seq, times)
        sequences[sequence.RowKey] = sequence
        return sequence
      })
    }
    const deferrerSwitch = !this.state.deferrerSwitch
    this.setState({ sequenceMap, sequences, deferrerSwitch })
  }

  getTagsForMatch = async (matchId) => {
    const defs = TAGDEFINITION_OBJ
    const tags = await API.getTagsForMatch(matchId, defs, this.state.isHighlightAdmin)
    if (tags.error) {
      return this.setState({ error: tags.error.toString() }, () => {
        this.refs.error.instance.show()
      })
    }
    let times = null
    let matchRef = null
    const sequenceMap = { ...this.state.sequenceMap }
    const matches = this.state.matches.map((match) => {
      if (match.RowKey === matchId) {
        match.gameTime = this.calculateGameTimesFromTags(tags)
        times = match.gameTime
        matchRef = matchId
        match.tagsLoaded = true
        // match.tags = tags.map((tag) => { return this.calculateGameTimestampForTagsOrSeq(tag, match.gameTime) })
        if (!sequenceMap[match.RowKey]) {
          sequenceMap[match.RowKey] = []
        }
        const sequences = sequenceMap[match.RowKey]
        const sequenceTagIds = sequences.filter(s => s.originType === 'Tag').map(s => s.originId)
        tags.forEach((tag) => {
          if (!sequenceTagIds.includes(tag.id)) {
            sequences.push(this.tagToMockSequence(tag, match))
          }
        })
        sequenceMap[match.RowKey] = sequences
        match.scoreTeamA = 0
        match.scoreTeamB = 0
        tags.forEach((tag) => {
          if (tag.eventType === 0) {
            match.scoreTeamA++
          } else if (tag.eventType === 1) {
            match.scoreTeamB++
          }
        })
      }
      return match
    })
    const deferrerSwitch = !this.state.deferrerSwitch
    this.setState({ matches, sequenceMap, deferrerSwitch }, () => {
      this.calculateGameTimestampForSequences(matchRef, times)
      this.calculateknownSequenceMeta()
    })
    return tags
  }

  getTagsForAllMatches = async (matches, initial = false, chunkSize = 5) => {
    if (!initial && !this.deferredUpdate) {
      this.deferredUpdate = true
    }
    const matchChunk = matches.splice(0, chunkSize)
    const tagPromises = []
    for (var itt in matchChunk) {
      const video = matchChunk[itt]
      tagPromises[itt] = this.getTagsForMatch(video.RowKey, initial)
    }
    await Promise.all(tagPromises)
    if (initial) {
      const recentMatches = this.state.matches.slice(0, 5)
      this.setState({ recentMatches, recentMatchesLoaded: true })
    }
    if (matches.length) {
      return this.getTagsForAllMatches(matches, false, chunkSize)
    }
    this.deferredUpdate = false
    return this.setState({ tagsLoaded: true, loadingTags: false })
  }

  tagToMockSequence = (tag, match, calculateGameTime = false) => {
    const from = tag.timestamp - 8 >= 0 ? tag.timestamp - 8 : 0
    const to = tag.timestamp + 8
    const duration = to - from
    const seqTag = {
      from,
      to,
      timestamp: tag.timestamp,
      duration,
      annotationIds: [],
      clipStream: match.userStream,
      name: tag.name,
      label: [tag.name],
      matchId: match.RowKey,
      clubAName: match.clubAName,
      clubBName: match.clubBName,
      clubAId: match.clubAId,
      clubBId: match.clubBId,
      gameTime: tag.gameTime,
      gameTimeLabel: tag.gameTimeLabel,
      matchLeague: match.baseLeague,
      matchAgeClass: match.ageClass,
      originType: 'Tag',
      originId: tag.id,
      originData: {
        eventType: tag.eventType,
        level: tag.level
      }
    }
    if (match.vrStream) {
      seqTag.vrStream = match.vrStream
    }
    //! Prop videoType DEPRECATED, but kept for Backwards Compability
    if (match.videoType === 'panorama' || match.videoType === 'quarterBoxPanorama') {
      seqTag.clipStream = match.userStreamAdaptive
      seqTag.vrStream = match.userStreamAdaptive
    }
    //* Use new videoProcessing going forward
    if (match.videoProcessing === 'quarterBoxPanorama') {
      seqTag.clipStream = match.userStreamAdaptive
      seqTag.vrStream = match.userStreamAdaptive
      seqTag.isVrSequence = true
    }
    if (calculateGameTime) {
      return this.calculateGameTimestampForTagsOrSeq(seqTag, match.gameTime)
    }
    return seqTag
  }

  getAllSequences = async () => {
    this.setState({ sequencesLoading: true })
    const sequenceResult = await API.getSequences()
    if (sequenceResult.error) {
      console.error(sequenceResult)
      this.setState({ sequencesLoaded: true, sequencesLoading: false })
      return
    }
    const sequenceMap = {}
    const sequences = {}
    sequenceResult.forEach((seq) => {
      // Add Key to SequenceMap if nonExistant
      if (!sequenceMap[seq.matchId]) {
        sequenceMap[seq.matchId] = []
      }
      const match = this.state.matchMap[seq.matchId] || undefined
      if (match && (!seq.clubAId || !seq.clubBId)) {
        seq.clubAId = match.clubAId
        seq.clubBId = match.clubBId
      }
      // Add Sequence to VideoIdMap and SequenceIdMap
      sequenceMap[seq.matchId].push(seq)
      sequences[seq.RowKey] = seq
    })
    this.setState({ sequences, sequenceMap, sequencesLoaded: true, sequencesLoading: false }, () => {
      this.calculateknownSequenceMeta()
    })
  }

  calculateknownSequenceMeta = () => {
    const sequences = Object.keys(this.state.sequenceMap).reduce((map, key) => {
      return map.concat(this.state.sequenceMap[key])
    }, [])
    let knownSequenceClubs = {}
    let knownSequenceLabel = {}
    let knownSequenceLeagues = {}
    let knownSequenceAgeClasses = {}
    sequences.forEach((seq) => {
      // const seq = this.state.sequences[key]
      // Keep Track of Possible Values for different Filters
      knownSequenceClubs[seq.clubAName] = true
      knownSequenceClubs[seq.clubBName] = true
      knownSequenceLeagues[seq.matchLeague] = true
      if (seq.ageClass) {
        knownSequenceAgeClasses[seq.ageClass] = true
      }
      seq.label.forEach((label) => {
        knownSequenceLabel[label.toLowerCase()] = label
      })
    })

    knownSequenceClubs = Object.keys(knownSequenceClubs).sort()
    knownSequenceLabel = Object.keys(knownSequenceLabel).map((key) => knownSequenceLabel[key]).sort()
    knownSequenceLeagues = Object.keys(knownSequenceLeagues).sort()
    knownSequenceAgeClasses = Object.keys(knownSequenceAgeClasses).sort()
    this.setState({ knownSequenceClubs, knownSequenceLabel, knownSequenceLeagues, knownSequenceAgeClasses })
  }

  createSequence = async (sequence) => {
    const result = await API.createSequence(sequence)
    if (result.error || result.status) {
      const error = result.error || result.status
      console.error(error)
      return
    }
    const update = { ...sequence, ...result }
    const match = this.state.matches.find((m) => m.RowKey === update.matchId)
    if (match) {
      this.calculateGameTimestampForTagsOrSeq(update, match.gameTime)
    }
    const sequences = JSON.parse(JSON.stringify(this.state.sequences))
    const sequenceMap = JSON.parse(JSON.stringify(this.state.sequenceMap))
    sequences[update.RowKey] = update
    if (!sequenceMap[update.matchId]) {
      sequenceMap[update.matchId] = []
    }
    sequenceMap[update.matchId].push(update)
    if (!sequence.RowKey && sequence.originType === 'Tag') {
      // TagSequences are always Referenced With MatchID
      // SO: Inside sequences there needend be any SequenceTags!
      // const seqIndex = sequences.find((s) => s.RowKey && (s.originId === sequence.originId))
      const seqMapIndex = sequenceMap[update.matchId].findIndex((s) => !s.RowKey && (s.originId === sequence.originId))
      if (seqMapIndex) { sequenceMap[update.matchId].splice(seqMapIndex, 1) }
    }
    this.setState({ sequences, sequenceMap }, () => {
      this.calculateknownSequenceMeta()
    })
    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'sequence created', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: update.matchId,
      clubHome: update.clubAName,
      clubAway: update.clubBName,
      event_category: 'sequence',
      event_label: 'Created new Sequence'
    })
    return result
  }

  updateSequence = async (sequence) => {
    const result = await API.updateSequence(sequence)
    if (result.error || result.status) {
      const error = result.error || result.status
      console.error(error)
      return
    }
    const update = { ...sequence, ...result }
    const match = this.state.matches.find((m) => m.RowKey === update.matchId)

    if (match) {
      this.calculateGameTimestampForTagsOrSeq(update, match.gameTime)
    }
    const sequences = JSON.parse(JSON.stringify(this.state.sequences))
    const sequenceMap = JSON.parse(JSON.stringify(this.state.sequenceMap))
    const updatedSequence = { ...sequence, ...update }
    sequences[update.RowKey] = updatedSequence
    const mapIndex = sequenceMap[update.matchId].findIndex((seq) => seq.RowKey === update.RowKey)
    sequenceMap[sequence.matchId][mapIndex] = updatedSequence
    this.setState({ sequences, sequenceMap }, () => {
      this.calculateknownSequenceMeta()
    })

    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'sequence modified', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: updatedSequence.matchId,
      clubHome: updatedSequence.clubAName,
      clubAway: updatedSequence.clubBName,
      event_category: 'sequence',
      event_label: 'Renamed or modified Sequence'
    })
  }

  removeSequenceFromAllClips = async (seqId) => {
    for (const i in this.state.clips) {
      const clip = this.state.clips[i]
      const index = clip.sequenceIds.indexOf(seqId)
      if (index > -1) {
        const removeClip = { ...clip }
        removeClip.sequenceIds.splice(index, 1)
        await this.updateClip(removeClip)
      }
    }
  }

  deleteSequence = async (sequence) => {
    await this.removeSequenceFromAllClips(sequence.RowKey)
    const result = await API.deleteSequence(sequence.RowKey)
    if (result.error) {
      console.error(result.error)
      return
    }
    const sequences = JSON.parse(JSON.stringify(this.state.sequences))
    const sequenceMap = JSON.parse(JSON.stringify(this.state.sequenceMap))
    delete sequences[sequence.RowKey]
    const seqMapIndex = sequenceMap[sequence.matchId].findIndex((s) => s.RowKey === sequence.RowKey)
    sequenceMap[sequence.matchId].splice(seqMapIndex, 1)
    this.setState({ sequences, sequenceMap }, () => {
      this.calculateknownSequenceMeta()
    })
    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'sequence delete', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: sequence.matchId,
      clubHome: sequence.clubAName,
      clubAway: sequence.clubBName,
      event_category: 'sequence',
      event_label: 'Deleted a Sequence'
    })
  }

  getAnnotationTagsForMatch = async (matchId) => {
    const annotations = await API.getAnnotationTagsForMatch(matchId)
    if (annotations.error) {
      this.setState({ error: annotations.error.toString(), loadingAnnotationTags: false }, () => {
        this.refs.error.instance.show()
      })
      return
    }
    const matches = this.state.matches.map((match) => {
      if (match.RowKey === matchId) {
        match.annotations = annotations.sort((a, b) => a.timestamp - b.timestamp)
      }
      return match
    })
    this.setState({ matches })
    return annotations
  }

  getAnnotationTagsForAllMatches = async (matches, chunkSize = 5) => {
    const matchChunk = matches.splice(0, chunkSize)
    const annoPromises = []
    for (var itt in matchChunk) {
      const video = matchChunk[itt]
      annoPromises[itt] = this.getAnnotationTagsForMatch(video.RowKey)
    }

    await Promise.all(annoPromises)
    if (matches.length) {
      return this.getAnnotationTagsForAllMatches(matches, chunkSize)
    }
    return this.setState({ annotationTagsLoaded: true })
  }

  uploadAnnotation = async (sequenceId, body) => {
    const result = await API.uploadAnnotation(sequenceId, body)
    const toolList = body.drawings.reduce((tools, part) => {
      tools[part.tool] = true
      return tools
    }, {})
    // Google Analytics
    const sequence = this.state.sequences[sequenceId]
    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'annotation created', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: sequence.matchId,
      clubHome: sequence.clubAName,
      clubAway: sequence.clubBName,
      event_category: 'annotation',
      event_label: 'Created new Annotation'
    })
    Object.keys(toolList).forEach((toolName) => {
      window.gtag && window.gtag('event', `tool: ${toolName}`, {
        env: String(process.env.REACT_APP_Environment),
        clubList: this.state.userData.clubList,
        clubSequence,
        matchId: sequence.matchId,
        clubHome: sequence.clubAName,
        clubAway: sequence.clubBName,
        event_category: 'annotation',
        event_label: 'Uploaded Annotation Included Specific Tool',
        value: toolName
      })
    })
    return result
  }

  updateAnnotation = async (annotation, seq) => {
    const result = await API.updateAnnotation(annotation)
    if (result.error || result.status) {
      const error = result.error || result.status
      console.error(error)
      return
    }

    const sequences = JSON.parse(JSON.stringify(this.state.sequences))
    const sequenceMap = JSON.parse(JSON.stringify(this.state.sequenceMap))
    const sequence = { ...sequences[seq.RowKey] }
    sequence.annotations = sequence.annotations.map((anno) => {
      if (anno.RowKey === result.RowKey) {
        return result
      }
      return anno
    })
    sequences[seq.RowKey] = sequence
    const mapIndex = sequenceMap[seq.matchId].findIndex((s) => s.RowKey === seq.RowKey)
    sequenceMap[seq.matchId][mapIndex] = sequence
    this.setState({ sequences, sequenceMap })
    // Google Analytics
    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'annotation modified', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: sequence.matchId,
      clubHome: sequence.clubAName,
      clubAway: sequence.clubBName,
      event_category: 'annotation',
      event_label: 'Renamed or modified an Annotation'
    })
    return result
  }

  deleteAnnotation = (_sequence) => async (annotation) => {
    const index = _sequence.annotationIds.findIndex((id) => id === annotation.RowKey)
    if (!index && index !== 0) {
      console.error(`<RC:DeleteAnnotation>: Could not find ${annotation} in ${_sequence}`)
      return
    }
    const sequence = { ..._sequence }
    sequence.annotationIds.splice(0, 1)
    sequence.annotations = sequence.annotations.filter((anno) => {
      return anno.RowKey !== annotation.RowKey
    })
    await API.deleteAnnotation(_sequence, annotation)
    // Google Analytics
    const clubSequence = `${sequence.clubAId} - ${sequence.clubBId}`
    window.gtag && window.gtag('event', 'annotation deleted', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      matchId: sequence.matchId,
      clubHome: sequence.clubAName,
      clubAway: sequence.clubBName,
      event_category: 'annotation',
      event_label: 'Deleted an Annotation'
    })
    return this.updateSequence(sequence)
  }

  getClips = async () => {
    let clips = await API.getClips()
    if (clips.error) {
      console.error(clips.error)
      this.setState({ loadingclips: false, clipsLoaded: true })
      return
    }

    clips = clips.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
    const clipCopy = JSON.parse(JSON.stringify(clips))
    clipCopy.sort((a, b) => b.lastModified - a.lastModified)
    const recentClips = clipCopy.slice(0, 5)
    this.setState({ clips, loadingclips: false, clipsLoaded: true, recentClips })
    return clips
  }

  createClip = async (body) => {
    if (body.sequences) {
      body.hasVrSequence = body.sequences.some((seq) => !!seq.vrStream)
    }
    const clip = await API.createClip(body)
    const error = clip.error || clip.status
    if (error) {
      console.error(error)
      return
    }
    const clips = [...this.state.clips]
    clips.push(clip)
    this.setState({ clips, clipSequence: [] })

    // Google Analytics
    const clubSequenceObj = clip.sequenceIds.reduce((obj, seqId, index) => {
      const sequence = this.state.sequences[seqId]
      obj[sequence.clubAId] = true
      obj[sequence.clubBId] = true
      return obj
    }, {})
    const clubSequence = Object.keys(clubSequenceObj).join(' - ')
    window.gtag && window.gtag('event', 'clip created', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      event_category: 'clip',
      event_label: 'User Created Clip'
      // 'value': ''
    })
    return clip
  }

  getAuthorization = async (headers = {}) => {
    const auth = await getAuthorization(headers)
    return auth
  }

  linkSequencesToClip = (clip, sequences) => {
    let currentOffset = 0
    clip.label = []
    clip.leagues = []
    clip.clubNames = []
    clip.sequence = clip.sequenceIds.map((id) => {
      const sequence = { ...sequences[id] }
      if (!sequences[id]) {
        console.error('sequence', id, 'unavailable')
        return {
          RowKey: id,
          name: 'Sequence Unavailable'
        }
      }
      const relativeDuration = sequence.relativeEndTime - sequence.relativeStartTime
      sequence.relativeStartTime += currentOffset
      sequence.relativeEndTime += currentOffset
      sequence.relativeOffset = currentOffset
      sequence.relativeTimestamp = sequence.relativeTimestamp + currentOffset
      currentOffset += relativeDuration

      clip.label = clip.label.concat(sequence.label)
      clip.leagues.push(sequence.matchLeague)
      clip.clubNames.push(sequence.clubAName)
      clip.clubNames.push(sequence.clubBName)
      return sequence
    })
    clip.label = clip.label.filter(unique).sort()
    clip.leagues = clip.leagues.filter(unique).sort()
    clip.clubNames = clip.clubNames.filter(unique).sort()

    return clip
  }

  updateClip = async (clip) => {
    if (clip.sequences) {
      clip.hasVrSequence = clip.sequences.some((seq) => !!seq.vrStream)
    }
    const result = await API.updateClip(clip)
    if (result.error) {
      console.error(result.error)
      return
    }
    const clips = this.state.clips.map((c) => {
      if (c.RowKey === result.RowKey) {
        return { ...c, ...result }
      }
      return c
    })
    this.setState({ clips })
    // Google Analytics
    const clubSequence = clip.sequence.reduce((string, seq, index) => {
      const slash = index === 0 ? '' : ' - '
      return string + slash + seq.clubAId + ' - ' + seq.clubBId
    }, '')
    window.gtag && window.gtag('event', 'clip updated', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      event_category: 'clip',
      event_label: 'User changed Clip'
      // 'value': ''
    })
    return clip
  }

  updateClipWithClipSequence = async (clip) => {
    // this.state.clipSequence.map((seq) => seq.RowKey)
    const update = await this.updateClip(clip)
    if (!update) {
      return
    }
    this.setState({ clipContext: null, clipSequence: [] })
    return update
  }

  toggleClipPublicity = async (clip) => {
    const result = await API.toggleClipPublicity(clip)
    if (result.error) {
      window.alert(result.error)
      return
    }
    const clips = this.state.clips.map((c) => {
      if (c.RowKey === clip.RowKey) {
        c.public = !c.public
      }
      return c
    })
    this.setState({ clips })
    return true
  }

  deleteClip = async (clip, clipIndex) => {
    const success = await API.deleteClip(clip.RowKey)
    if (success.error) {
      this.setState({ error: success.error.toString() }, () => {
        // this.refs.error.instance.show()
        console.error(success.error)
      })
      return
    }
    const clips = JSON.parse(JSON.stringify(this.state.clips))
    const index = clipIndex || clips.findIndex((c) => c.RowKey === clip.RowKey)
    if (!index && index !== 0) {
      console.error('<RC:DeleteClip> Cant find index of Clip')
      return
    }
    clips.splice(index, 1)
    this.setState({ clips })
    // Google Analytics
    const clubSequence = clip.sequence.reduce((string, seq, index) => {
      const slash = index === 0 ? '' : ' - '
      return string + slash + seq.clubAId + ' - ' + seq.clubBId
    }, '')
    window.gtag && window.gtag('event', 'clip deleted', {
      env: String(process.env.REACT_APP_Environment),
      clubList: this.state.userData.clubList,
      clubSequence,
      event_category: 'clip',
      event_label: 'User deleted Clip'
      // 'value': ''
    })
  }

  setClipSequenceName = (clipSequenceName) => {
    this.setState({ clipSequenceName })
  }

 setClipUiWorking = (state) => {
   const clipUiWorking = state || !this.state.clipUiWorking
   this.setState({ clipUiWorking })
 }

  addToClip = (sequence) => async (e) => {
    e && e.stopPropagation()
    const id = sequence.RowKey || sequence.originId
    const allreadyAdded = this.state.clipSequence.some((i) => i.RowKey === id || i.originId === id)
    if (allreadyAdded) {
      return
    }
    const clipSequence = JSON.parse(JSON.stringify(this.state.clipSequence))
    clipSequence.push(sequence)
    await this.setStateAsync({ clipSequence })
  }

  removeFromClip = (index) => {
    if (index < 0) {
      return
    }
    const clipSequence = JSON.parse(JSON.stringify(this.state.clipSequence))
    clipSequence.splice(index, 1)
    this.setState({ clipSequence })
  }

  onClipSequenceSort = ({ oldIndex, newIndex }) => {
    const clipSequence = arrayMove(this.state.clipSequence, oldIndex, newIndex)
    this.setState({ clipSequence })
  }

  setClipContext = (clip) => {
    if (!clip) {
      this.setState({ clipSequence: [], clipContext: false, clipSequenceName: '' })
      return
    }
    const clipContext = { ...clip }
    const clipSequence = clip.sequence
    const clipSequenceName = clip.name
    this.setState({ clipSequence, clipContext, clipSequenceName })
  }

  setEnabler = (currentEnabler) => {
    this.setState({ currentEnabler, currentCity: null })
  }

  setCity = (currentCity) => {
    this.setState({ currentEnabler: null, currentCity })
  }

  getHAMatchData = async (type, selection) => {
    if (selection === null) {
      return
    }
    if (type === 'enabler') {
      const enabler = this.state.enabler.find((e) => e.name === selection)
      await this.getMatchesForEnabler(enabler)
    } else if (type === 'city') {
      const city = this.state.cities.find((c) => c.name === selection)
      await this.getMatchesForCity(city)
    } else {
      const club = this.state.clubArray.find((c) => c.name === selection)
      await this.getMatchesForClub(club)
    }
    this.setState({ deferrerSwitch: true })
    const matches = JSON.parse(JSON.stringify(this.state.matches))
    await this.getTagsForAllMatches(matches, true)
    this.setState({ deferrerSwitch: false })
  }

  changeHighlightAdminSelection = (HASelectedType, HASelection) => {
    this.getHAMatchData(HASelectedType, HASelection)
    this.setState({ HASelectedType, HASelection })
  }

  changeLanguage = (lang) => () => {
    this.props.i18n.changeLanguage(lang)
  }

  setOptOut = (userOptedOut) => () => {
    if (userOptedOut) {
      window['ga-disable-UA-101371445-7'] = true
    }
    window.localStorage.setItem('userOptedOut', userOptedOut)
    this.setState({ userOptedOut })
  }

  logout = (redirect = true) => {
    firebase.auth().signOut()
    this.setState({ loggedIn: false, userData: null, isHighlightAdmin: false }, () => {
      if (this.keycloak && this.keycloak.token) {
        this.keycloak.logout()
      }
      if (redirect && window.location.pathname !== '/login/' && window.location.pathname !== '/keyCloak/' && !window.location.pathname.includes('/public')) {
        window.location = '/login/'
      }
    })
  }

  login = async (name, pw, redirect = true, autoLoadUserData = true, retry = false) => {
    // this.headers = { authorization: auth }
    // window.gtag && window.gtag('event', 'Login', {
    //   event_category: 'Initial',
    //   event_label: 'User Logged in via LoginPage',
    //   value: `${user.Mail}::${process.env.REACT_APP_Environment}`
    // })
    // window.gtag && window.gtag('event', 'loggedIn_dimension', { loggedIn: true })
    try {
      const login = await firebase.auth().signInWithEmailAndPassword(name, pw)
      this.handleLogin(login.user, redirect, autoLoadUserData)
    } catch (err) {
      console.error(err)
      if (!retry) {
        const migrated = await API.login(name, pw)
        if (migrated.newMigrated) {
          const loggedIn = await this.checkLogin(name, pw, true)
          return loggedIn
        }
      }
      console.error('Login Failed')
      return false
    }
    return true
  }

  handleLogin = async (gUser, redirect = false, autoLoadUserData = true) => {
    // const diff = new Date().getTime() - this.startTime
    this.checkingLogin = true
    const token = await gUser.getIdToken()
    const user = jwtDecode(token)
    const userSubs = await API.getSubscriptions()
    user.trainerSubscriptions = userSubs.Trainer
    user.subTrainerSubscriptions = userSubs.SubTrainer.reduce((subs, current) => {
      if (!subs[current.data.mainUser]) {
        subs[current.data.mainUser] = []
      }
      subs[current.data.mainUser].push(current)
      return subs
    }, {})
    user.subscriptions = [...userSubs.Trainer, ...userSubs.SubTrainer]
    const noSubscriptions = user.subscriptions && user.subscriptions.length === 0
    // console.log('SUBS', user.subscription?.length, noSubscriptions)
    let isHighlightAdmin = user.role?.includes('highlightadmin')
    const adminPref = window.localStorage.getItem('highlightMode')
    if (adminPref !== null) {
      isHighlightAdmin = adminPref === 'true'
    }
    this.setState({ authReady: true, loggedIn: true, userData: user, noSubscriptions, isHighlightAdmin }, () => {
      if (autoLoadUserData) {
        this.getUserData()
      }
    })
    if (redirect) {
      window.location = '/'
    }
  }

  updateUserData = async () => {
    // TODO: REFRESH USER TOKEN

    // if (this.keycloak && this.keycloak.token) {
    //   const data = await this.getUser()
    //   window.localStorage.setItem('user', JSON.stringify(data))
    //   this.setState({ userData: data }, () => {
    //     this.mapSubscriptionIdsToClubNames()
    //   })
    //   return data
    // }
  }

  // register = async (body, additional) => {
  //   return API.register(body, 'direct')
  // }

  asyncGTag = (tokenData) => {
    return new Promise((resolve) => {
      return window.gtag && window.gtag('event', 'TrainerToken redeemedByRegister', {
        env: String(process.env.REACT_APP_Environment),
        // clubList: this.state.userData.clubList,
        // clubSequence,
        event_category: 'TrainerToken',
        event_label: 'User Registered and Claimed a Subscription',
        value: JSON.stringify(tokenData.grants),
        event_callback: resolve
        // () => {
        // window.location = '/'
        // return true
        // }
      })
    })
  }

  omniFilterAdd = (prop, value, isBool, subType) => {
    const current = [...this.state[prop]]
    if (subType) {
      value = subType + '=' + value
    }
    if (current.includes(value)) {
      return
    }
    current.push(value)
    this.setState({ [prop]: current })
  }

  omniFilterRemove = (prop, value) => {
    const current = [...this.state[prop]]
    const index = current.findIndex((v) => v === value)
    if (index > -1) {
      current.splice(index, 1)
      this.setState({ [prop]: current })
    }
  }

  setMatchDate = (isFrom, value) => {
    const newState = isFrom ? { matchDateFrom: value } : { matchDateTill: value }
    this.setState(newState)
  }

  setCurrentSite = (currentSite) => () => {
    this.setState({ currentSite })
  }

  checkLoginThen = (next, redirectLink = '/login/', needsHighlightAdmin = false) => {
    return next()
  }

  goToUnsuportedBrowser = () => {
    return <UnsuportedBrowser />
  }

  goToNoSubscriptions = () => {
    return <NoSubscription logout={this.logout} />
  }

  goToDashboard = (props) => () => {
    return <Dashboard
      {...props}
      recentMatches={this.state.recentMatches}
      recentMatchesLoaded={this.state.recentMatchesLoaded}
      matchesLoaded={this.state.matchesLoaded}
      tagsLoaded={this.state.tagsLoaded}
      sequenceMap={this.state.sequenceMap}
      sequencesLoaded={this.state.sequencesLoaded}
      recentClips={this.state.recentClips}
      clipsLoaded={this.state.clipsLoaded}
    />
  }

  goToUserArea = (props) => () => {
    return <User
      {...props}
      user={this.state.userData}
      setCurrentSite={this.setCurrentSite}
      updateUserData={this.updateUserData}
      clubsLoaded={this.state.clubsLoaded}
      getClubs={this.getClubs}
      clubs={this.state.clubs}
    />
  }

  goToSubscriptionEdit = (props) => () => {
    const subscriptionId = props.match.params.subId
    const subscription = this.state.userData.subscriptions?.find((sub) => {
      return sub.RowKey === subscriptionId
    })
    return <SubscriptionEdit
      {...props}
      user={this.state.userData}
      subscription={subscription}
      getSubscriptionToken={API.getSubscriptionToken}
      sendSubToken={API.sendSubToken}
      getAuthorization={this.getAuthorization}
      updateUserData={this.updateUserData}
      clubs={this.state.clubs}
    />
  }

  goToSubscriptionCreate = (props) => () => {
    return <SubscriptionCreate
      {...props}
      user={this.state.userData}
      clubsLoaded={this.state.clubsLoaded}
      clubs={this.state.clubArray}
      createSubscription={API.createSubscription}
      applySubToken={API.applySubToken}
      getAuthorization={this.getAuthorization}
      updateUserData={this.updateUserData}
      getClubs={this.getClubs}
    />
  }

  goToMatchList = (props) => () => {
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'
    return <MatchSite
      {...props}
      user={this.state.userData}
      clipCount={clipCount}
      matchCount={matchCount}
      setCurrentSite={this.setCurrentSite('matches')}
      matches={this.state.matches}
      matchesLoaded={this.state.matchesLoaded}
      recentMatches={this.state.recentMatches}
      tagsLoaded={this.state.tagsLoaded}
      tagDefinitions={TAGDEFINITION}
      // getTagDefinitions={this.getTagDefinitions}
      tagDefinitionsLoaded
      sequences={this.state.sequences}
      sequenceMap={this.state.sequenceMap}
      sequencesLoaded={this.state.sequencesLoaded}
      sequencesLoading={this.state.sequencesLoading}
      clipSequence={this.state.clipSequence}
      clipSequenceName={this.state.clipSequenceName}
      setClipSequenceName={this.setClipSequenceName}
      clipUiWorking={this.state.clipUiWorking}
      setClipUiWorking={this.setClipUiWorking}
      onClipSequenceSort={this.onClipSequenceSort}
      addToClip={this.addToClip}
      removeFromClip={this.removeFromClip}
      createClip={this.createClip}
      clipContext={this.state.clipContext}
      updateClip={this.updateClipWithClipSequence}
      deleteSequence={this.deleteSequence}
      createSequence={this.createSequence}
      knownMatchLeagues={this.state.knownMatchLeagues}
      knownMatchClubs={this.state.knownMatchClubs}
      knownMatchAgeClasses={this.state.knownMatchAgeClasses}
      knownSequenceClubs={this.state.knownSequenceClubs}
      knownSequenceLabel={this.state.knownSequenceLabel}
      knownSequenceLeagues={this.state.knownSequenceLeagues}
      knownSequenceAgeClasses={this.state.knownSequenceAgeClasses}
      setClipContext={this.setClipContext}
      cities={this.state.cities}
      enabler={this.state.enabler}
      HASelectedType={this.state.HASelectedType}
      HASelection={this.state.HASelection}
      isHighlightAdmin={this.state.isHighlightAdmin}
      HLAdminDataLoaded={this.state.citiesLoaded && this.state.enablerLoaded && this.state.clubsLoaded}
      changeHighlightAdminSelection={this.changeHighlightAdminSelection}
      matchDateFrom={this.state.matchDateFrom}
      matchDateTill={this.state.matchDateTill}
      setMatchDate={this.setMatchDate}
      filterStateMatch={this.filterStateMatch}
      filterStateSequence={this.filterStateSequence}
      omniFilterAdd={this.omniFilterAdd}
      omniFilterRemove={this.omniFilterRemove}
      clubs={this.state.clubArray}
      clubsLoaded={this.state.clubsLoaded}
    />
  }

  gotoMatchDetails = (props) => () => {
    let match = false
    if (this.state.matchesLoaded) {
      match = this.state.matches.find((m) => {
        return m.RowKey === props.match.params.matchId
      })
    }
    const sequences = this.state.sequencesLoaded ? this.state.sequenceMap[match.RowKey] : []
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'
    return <Details
      {...props}
      clipCount={clipCount}
      matchCount={matchCount}
      match={match}
      user={this.state.userData}
      matches={this.state.matches}
      matchesLoaded={this.state.matchesLoaded}
      updateClip={this.updateClipWithClipSequence}
      clipSequence={this.state.clipSequence}
      clipSequenceName={this.state.clipSequenceName}
      setClipSequenceName={this.setClipSequenceName}
      clipUiWorking={this.state.clipUiWorking}
      setClipUiWorking={this.setClipUiWorking}
      clipContext={this.state.clipContext}
      addToClip={this.addToClip}
      onClipSequenceSort={this.onClipSequenceSort}
      removeFromClip={this.removeFromClip}
      deleteSequence={this.deleteSequence}
      createClip={this.createClip}
      sequences={sequences}
      sequenceMap={this.state.sequenceMap}
      knownSequenceLabel={this.state.knownSequenceLabel}
      createSequence={this.createSequence}
      updateSequence={this.updateSequence}
      setClipContext={this.setClipContext}
      isHighlightAdmin={this.state.isHighlightAdmin}
      filterStateSequence={this.filterStateSequence}
      omniFilterAdd={this.omniFilterAdd}
      omniFilterRemove={this.omniFilterRemove}
    />
  }

  goToMatchEditor = (props) => () => {
    let match = false
    if (this.state.matchesLoaded) {
      match = this.state.matches.find((m) => {
        return m.RowKey === props.match.params.matchId
      })
    }
    const sequences = this.state.sequencesLoaded && match ? this.state.sequenceMap[match.RowKey] || [] : []
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'

    let userStream
    let vrStream
    let forceVr = false
    if (match && (match.videoType === 'panorama' || match.videoType === 'quarterBoxPanorama')) {
      userStream = match.userStreamAdaptive
      vrStream = match.userStreamAdaptive
      forceVr = true
    } else if (match) {
      if (match.userStreamAdaptive) {
        userStream = match.userStreamAdaptive
      }else {
        userStream = match.userStream
      }
      vrStream = match.vrStream
    }
    console.log('UserStream',userStream, match?.userStream, match?.userStreamAdaptive, match)
    return <Editor
      {...props}
      clipCount={clipCount}
      matchCount={matchCount}
      user={this.state.userData}
      authReady={this.state.authReady}
      setCurrentSite={this.setCurrentSite('draw')}
      match={match}
      src={userStream}
      vrSrc={vrStream}
      sequences={sequences}
      annotationsLoading={!this.state.annotationTagsLoaded}
      sequenceMap={this.state.sequenceMap}
      createSequence={this.createSequence}
      deleteSequence={this.deleteSequence}
      clipSequence={this.state.clipSequence}
      addToClip={this.addToClip}
      onClipSequenceSort={this.onClipSequenceSort}
      removeFromClip={this.removeFromClip}
      updateSequence={this.updateSequence}
      uploadAnnotation={this.uploadAnnotation}
      updateAnnotation={this.updateAnnotation}
      tagDefinitions={TAGDEFINITION}
      tags={match ? match.tags : []}
      tagsLoaded={this.state.tagsLoaded}
      // postTag={this.postTag}
      isVideoView
      forceVr={forceVr}
      dataReady={this.state.matchesLoaded}
      currentAuthHeader={this.state.currentAuthHeader}
      knownSequenceLabel={this.state.knownSequenceLabel}
      // deleteTag={this.deleteUserTag}
      matchEditor
      getMatchCornerPoints={this.getMatchCornerPoints}
      filterStateSequence={this.filterStateSequence}
      omniFilterAdd={this.omniFilterAdd}
      omniFilterRemove={this.omniFilterRemove}
      getAuthorization={this.getAuthorization}
    />
  }

  goToSequenceEditor = (props) => () => {
    const sequenceId = props.match.params.sequenceId
    const sequence = this.state.sequencesLoaded ? this.state.sequences[sequenceId] : null
    const src = sequence ? API.getVirtualSequenceSrc(sequence) : null
    const vrSrc = sequence && sequence.vrStream ? API.getVirtualSequenceSrc(sequence, true) : null
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'

    let match = false
    if (this.state.matchesLoaded) {
      match = this.state.matches.find((m) => {
        return m.RowKey === props.match.params.matchId
      })
    }

    return <Editor
      {...props}
      forceVr={sequence.isVrSequence}
      clipCount={clipCount}
      matchCount={matchCount}
      src={src}
      vrSrc={vrSrc}
      user={this.state.userData}
      loggedIn={this.state.loggedIn}
      authReady={this.state.authReady}
      setCurrentSite={this.setCurrentSite('draw')}
      sequence={sequence}
      dataReady={this.state.sequencesLoaded}
      getAuthorization={this.getAuthorization}
      uploadAnnotation={this.uploadAnnotation}
      updateAnnotation={this.updateAnnotation}
      updateSequence={this.updateSequence}
      knownSequenceLabel={this.state.knownSequenceLabel}
      deleteAnnotation={this.deleteAnnotation}
      tagDefinitions={TAGDEFINITION}
      currentAuthHeader={this.state.currentAuthHeader}
      getMatchCornerPoints={this.getMatchCornerPoints}
      sequenceEditor
      match={match}
    />
  }

  goToClipList = (props) => () => {
    const { clipsLoaded, sequencesLoaded, clips } = this.state
    const fullClips = clipsLoaded && sequencesLoaded ? clips.map((i) => this.linkSequencesToClip(i, this.state.sequences)) : []
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'
    return <ClipSite
      {...props}
      clipCount={clipCount}
      matchCount={matchCount}
      user={this.state.userData}
      loggedIn={this.state.loggedIn}
      getAuthorization={this.getAuthorization}
      setCurrentSite={this.setCurrentSite('videos')}
      clips={fullClips}
      clipsLoaded={this.state.clipsLoaded}
      sequenceMap={this.state.sequenceMap}
      sequencesLoaded={this.state.sequencesLoaded}
      getClips={this.getClips}
      setClipContext={this.setClipContext}
      deleteClip={this.deleteClip}
      isHighlightAdmin={this.state.isHighlightAdmin}
      toggleClipPublicity={this.toggleClipPublicity}
      knownSequenceClubs={this.state.knownSequenceClubs}
      knownSequenceLabel={this.state.knownSequenceLabel}
      knownSequenceLeagues={this.state.knownSequenceLeagues}
      downloadClip={API.downloadClip}
    />
  }

  goToClipDetails = (props) => () => {
    let clipIndex = false
    let clip = false
    if (this.state.clipsLoaded) {
      clipIndex = this.state.clips.findIndex((c) => {
        return c.RowKey === props.match.params.clipId
      })
      clip = this.state.clips[clipIndex]
      if (!clip) {
        return <Redirect to='/404/' />
      }
      clip = this.linkSequencesToClip(clip, this.state.sequences)
    }
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'
    return <ClipDetails
      {...props}
      clip={clip}
      user={this.state.userData}
      authReady={this.state.authReady}
      clipIndex={clipIndex}
      clipCount={clipCount}
      matchCount={matchCount}
      setClipContext={this.setClipContext}
      clipsLoaded={this.state.clipsLoaded}
      sequencesLoaded={this.state.sequencesLoaded}
      deleteClip={this.deleteClip}
      updateClip={this.updateClip}
      toggleClipPublicity={this.toggleClipPublicity}
      setCurrentSite={this.setCurrentSite('details')}
      getAuthorization={this.getAuthorization}
      getIOSClipPath={API.getIosClipPath}
      isHighlightAdmin={this.state.isHighlightAdmin}
      downloadClip={API.downloadClip}
      currentAuthHeader={this.state.currentAuthHeader}
    />
  }

  goToClipEditor = (props) => () => {
    let clip = false
    if (this.state.clipsLoaded) {
      clip = this.state.clips.find((c) => {
        return c.RowKey === props.match.params.clipId
      })
      if (!clip) {
        return <Redirect to='/404/' />
      }
      clip = this.linkSequencesToClip(clip, this.state.sequences)
    }
    const clipCount = this.state.clipsLoaded ? this.state.clips.length : 'Lade'
    const matchCount = this.state.matchesLoaded ? this.state.matches.length : 'Lade'
    let vrSrc = false
    if (clip.sequence && clip.sequence.some(c => !!c.vrStream)) {
      vrSrc = clip.vrStream
    }
    return <Editor
      {...props}
      clipCount={clipCount}
      matchCount={matchCount}
      setCurrentSite={this.setCurrentSite('draw')}
      clip={clip}
      src={clip.clipStream}
      vrSrc={vrSrc}
      user={this.state.userData}
      authReady={this.state.authReady}
      getAuthorization={this.getAuthorization}
      uploadAnnotation={this.uploadAnnotation}
      updateAnnotation={this.updateAnnotation}
      updateSequence={this.updateSequence}
      tagsLoaded={this.state.tagsLoaded}
      dataReady={this.state.clipsLoaded}
      matches={this.state.matches}
      matchesLoaded={this.state.matchesLoaded}
      sequenceMap={this.state.sequenceMap}
      sequencesLoaded={this.state.sequencesLoaded}
      toggleClipPublicity={this.toggleClipPublicity}
      clipSequence={this.state.clipSequence}
      clipContext={this.state.clipContext}
      removeFromClip={this.removeFromClip}
      addToClip={this.addToClip}
      // addToClip={this.addToClip}
      // removeFromClip={this.removeFromClip}
      // clipSequence={this.state.clipSequence}
      createClip={this.createClip}
      updateClip={this.updateClip}
      setClipContext={this.setClipContext}
      knownMatchLeagues={this.state.knownMatchLeagues}
      knownMatchClubs={this.state.knownMatchClubs}
      knownMatchAgeClasses={this.state.knownMatchAgeClasses}
      knownSequenceClubs={this.state.knownSequenceClubs}
      knownSequenceLabel={this.state.knownSequenceLabel}
      knownSequenceLeagues={this.state.knownSequenceLeagues}
      knownSequenceAgeClasses={this.state.knownSequenceAgeClasses}
      tagDefinitions={TAGDEFINITION}
      getMatchCornerPoints={this.getMatchCornerPoints}
      getIOSClipPath={API.getIosClipPath}
      currentAuthHeader={this.state.currentAuthHeader}
      clipEditor
    />
  }

  goToPublicView = (props, shouldTrackPlayerPositions) => () => {
    const isVideo = props.isVideo || shouldTrackPlayerPositions || false
    return <View
      {...props}
      setCurrentSite={this.setCurrentSite('view')}
      getClip={API.getClip}
      getClipSrc={API.getPublicClipPath}
      isVideo={isVideo}
      headers={this.headers}
      getVideo={API.getMatchById}
      getTagsForMatch={API.getTagsForMatch}
      getMatchCornerPoints={this.getMatchCornerPoints}
      showPlayerPositionTracker={shouldTrackPlayerPositions}
      currentAuthHeader={this.state.currentAuthHeader}
      getAuthorization={this.getAuthorization}
      loggedOff={!this.state.loggedIn && !this.checkingLogin}
    />
  }

  removeGlobalTimeRef = () => {
    window.GlobalCoachingTool = window.GlobalCoachingTool || {}
    window.GlobalCoachingTool.timeReference = 0
    window.GlobalCoachingTool.sequenceTimeReference = 0
  }

  onRouteChange = (path) => {
    const route = path.pathname
    window.gtag && window.gtag('event', 'page_view', {
      send_to: 'UA-101371445-7',
      userLoggedIn: String(this.state.loggedIn),
      env: String(process.env.REACT_APP_Environment),
      page_path: route
    })
  }

  render () {
    return (
      <BrowserRouter>
        {/* <Route render={() =>}> </Route> */}
        <div className='row gatekeeper'>
          {/* <I18NButtons changeLanguage={this.changeLanguage} /> */}
          <Route exact path='/' render={(props) => {
            return this.checkLoginThen(this.goToDashboard(props))
            // () => { return <Redirect to='/matches/' /> })
          }} />
          <Route render={(props) => {
            return <RouteListener {...props} onLocationChange={this.onRouteChange} />
          }} />
          <Route path='/login/' render={(props) => {
            if (this.state.loggedIn) {
              return <Redirect to='/' />
            }
            return <Login {...props} login={this.login} />
            // return <Login onSubmit={this.login} register={this.register} resetPassword={API.resetPassword} />
          }} />
          <Route path='/getReferer/' render={(props) => {
            if (!this.specialSiteFirstLogout && this.state.loggedIn) {
              this.specialSiteFirstLogout = true
              this.logout(false)
            }
            return <Login {...props} onKeycloakEvent={this.onKeycloakEvent} onKeycloakTokens={this.onKeycloakTokens} />
            // return <Login onSubmit={this.loginWithReferer} register={this.registerWithReferer} resetPassword={API.resetPassword} refered {...props} afterRegister={this.login} subTrainerReferrer />
          }} />
          <Route path='/redeemToken/:token/' render={(props) => {
            if (!this.specialSiteFirstLogout && this.state.loggedIn) {
              this.specialSiteFirstLogout = true
              this.logout(false)
            }
            // TODO: if loggedIn ? Logout onSubmit or on Registert
            return <Login {...props} onKeycloakEvent={this.onKeycloakEvent} onKeycloakTokens={this.onKeycloakTokens} />
            // return <Login onSubmit={this.loginWithToken} register={this.registerWithToken} afterRegister={this.login} resetPassword={API.resetPassword} {...props} TrainerToken />
          }} />
          <Route exact path='/user/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.checkLoginThen(this.goToUserArea(props))
          }} />
          <Route exact path='/user/subscription/:subId/' render={(props) => {
            return this.checkLoginThen(this.goToSubscriptionEdit(props))
          }} />
          <Route exact path='/user/newSubscription/' render={(props) => {
            return this.goToSubscriptionCreate(props)()
          }} />
          <Route exact path='/matches/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.checkLoginThen(this.goToMatchList(props))
          }} />
          <Route exact path='/matches/:matchId/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.checkLoginThen(this.goToMatchEditor(props))
          }} />
          <Route exact path='/matches/:matchId/edit/' render={(props) => {
            return this.checkLoginThen(this.goToMatchEditor(props))
          }} />
          <Route path='/matches/:matchId/playerPositions/' render={(props) => {
            const shouldTrackPlayerPositions = true
            return this.checkLoginThen(this.goToPublicView(props, shouldTrackPlayerPositions, true))
          }} />
          <Route path='/matches/:matchId/edit/sequence/:sequenceId/' render={(props) => {
            return this.checkLoginThen(this.goToSequenceEditor(props))
          }} />
          <Route path='/matches/:matchId/sequence/:sequenceId/' render={(props) => {
            return this.checkLoginThen(this.goToSequenceEditor(props))
          }} />
          <Route exact path='/clips/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.checkLoginThen(this.goToClipList(props))
          }} />
          <Route exact path='/clips/:clipId/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.checkLoginThen(this.goToClipDetails(props))
          }} />
          <Route exact path='/clips/:clipId/edit' render={(props) => {
            const slash = props.location.pathname.slice(-1) === '/' ? '' : '/'
            const orElseToPublicView = `${props.location.pathname}${slash}public`
            return this.checkLoginThen(this.goToClipEditor(props), orElseToPublicView)
          }} />
          <Route path='/clips/:ClipId/public/' render={(props) => {
            this.removeGlobalTimeRef()
            return this.goToPublicView(props)()
          }} />
          <Route path='/clips/:clipId/sequence/:sequenceId/' render={(props) => {
            return this.checkLoginThen(this.goToSequenceEditor({ ...props, fromSequence: true }))
          }} />
          <Route path='/clips/:clipId/edit/sequence/:sequenceId/' render={(props) => {
            return this.checkLoginThen(this.goToSequenceEditor({ ...props, fromSequence: true }))
          }} />
          <Route exact path='/publicView/:matchId/' render={(props) => {
            props.isVideo = true
            return this.goToPublicView(props)()
          }} />
          <Route exact path='/publicView/:matchId/vr/' render={(props) => {
            props.isVideo = true
            props.startInVr = true
            return this.goToPublicView(props)()
          }} />
          <Route path='/404/' component={Site404} />
          <Route path='/unsuportedBrowser/' component={UnsuportedBrowser} />
          {/* <Route exact path='/sequences' render={(props) => {
              if (!this.state.loggedIn) {
                return <Redirect to={'/login'} />
              }
              return <SequenceSite
                {...props}
                setCurrentSite={this.setCurrentSite('Sequences')}
                sequences={this.state.sequences}
                sequencesLoaded={this.state.sequencesLoaded}
                updateSequence={this.updateSequence}
                deleteSequence={this.deleteSequence}
                addToClip={this.addToClip}
                createClip={this.createClip}
                clipSequence={this.state.clipSequence}
                removeFromClip={this.removeFromClip}
                knownSequenceClubs={this.state.knownSequenceClubs}
                knownSequenceLabel={this.state.knownSequenceLabel}
                knownSequenceLeagues={this.state.knownSequenceLeagues}
                knownSequenceAgeClasses={this.state.knownSequenceAgeClasses}
              />
            }} /> */}

          <Popup headerText='ERROR' ref='error' display='top' cssClass='errorbox'>
            <span>{this.state.error}</span>
          </Popup>
          {(this.state.userOptedOut === null || this.state.userOptedOut === undefined) &&
            <div className='OptOutBanner center'>
              <p className='OptOutText'>
                Wir verwenden innerhalb dieser Web-App Google-Analytics um unser Coachingtool zu verbessern.
                Wenn sie der Datenerhebung mittels Google-Analytics wiedersprechen wollen, klicken sie bitte&nbsp;
                <span className='fakeLink' onClick={this.setOptOut(true)}>hier</span>!
              </p>
              <button className='btn success' onClick={this.setOptOut(false)}>Ok</button>
            </div>
          }
          <HelpModal />
        </div>
      </BrowserRouter>
    )
  }
}

export default App
