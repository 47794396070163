import discovery from '@soccerwatch/discovery'
import listTillFinished from '../lib/helper/listTillFinished'
import { getUserHeaders } from '../components/firebase'
import { UserList, HLAdminList } from '../lib/helper/eventTypeBlacklist'
import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function postTag (body) {
  const url = `${discovery.API_TAG}/UserTag/`
  const headers = await getUserHeaders({
    'content-type': 'application/json'
  })
  let tag
  try {
    const resp = await axios.post(url, body, { headers })
    tag = resp.data
  } catch (err) {
    console.error(err)
    return
  }
  return tag
}

async function deleteUserTag (id) {
  const url = `${discovery.API_TAG}/deleteUserTag/${id}/`
  const headers = await getUserHeaders({
    'content-type': 'application/json'
  })
  let response
  try {
    response = await axios.delete(url, { headers })
  } catch (err) {
    console.error(err)
  }
  return response.status === 200
}

const getTagsForMatch = async (matchId, tagDefs, highlightAdmin = false) => {
  let swTags
  // let userTags
  try {
    const headers = await getUserHeaders()
    swTags = await listTillFinished(`${discovery.API_TAG}/Tag/${matchId}`, headers)
    // swTags = swTagsResp.map((tag) => { tag.isUserTag = false; return tag })
    // const userTagsResp = await listTillFinished(`${discovery.API_TAG}/UserTagsOfVideo/${matchId}`, headers)
    // userTags = userTagsResp.map((tag) => { tag.isUserTag = true; return tag })
  } catch (error) {
    console.error(error)
    // return { error }
    // ugly quick fix - time is money and i have no money
    swTags = []
  }
  // const allTags = swTags.concat(userTags)
  const tags = filterTags(tagDefs, swTags, highlightAdmin)
  return tags
}

async function getTagDefinitions (highlightAdmin = false) {
  const eventTypeBlacklist = highlightAdmin ? HLAdminList : UserList
  // let tagDefs
  let tagDefsSw
  try {
    const headers = await getUserHeaders()
    // tagDefs = await listTillFinished(`${discovery.API_TAG}/TagDefinitions/`, headers)
    tagDefsSw = await listTillFinished(`${discovery.API_TAG}/TagDefinitionsSw/`, headers)
  } catch (error) {
    console.error(error)
    // return this.setState({ error: error.toString(), loadingDefinitions: false }, () => {
    //   this.refs.error.instance.show()
    // })
    return { error }
  }
  // const tagDefinitions = tagDefs.concat(tagDefsSw)
  const tagDefs = tagDefsSw.filter((itm) => { return Boolean(itm) && (itm.eventType || itm.eventType === 0) && !eventTypeBlacklist.includes(itm.eventType.toString()) })
  return tagDefs
}

export default { postTag, getTagDefinitions, getTagsForMatch, deleteUserTag, filterTags }

// /*
// ██╗   ██╗████████╗██╗██╗     ██╗████████╗██╗   ██╗
// ██║   ██║╚══██╔══╝██║██║     ██║╚══██╔══╝╚██╗ ██╔╝
// ██║   ██║   ██║   ██║██║     ██║   ██║    ╚████╔╝
// ██║   ██║   ██║   ██║██║     ██║   ██║     ╚██╔╝
// ╚██████╔╝   ██║   ██║███████╗██║   ██║      ██║
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚═╝   ╚═╝      ╚═╝
// */
function filterTags (defs, tags, highlightAdmin = false) {
  const eventTypeBlacklist = highlightAdmin ? HLAdminList : UserList
  return tags
    .filter((itm) => { return Boolean(itm) && (itm.eventType || itm.eventType === 0) && !eventTypeBlacklist.includes(itm.eventType.toString()) })
    .map(entry => {
      let color = '#013141'
      let name = entry.name
      const evtType = entry.eventType.toString()
      const definition = defs[evtType]
      if (definition) {
        color = definition.color
        name = definition.name
      }
      return {
        color: color,
        name: name,
        id: entry.RowKey,
        timestamp: entry.timestamp,
        comment: entry.comment,
        isUserTag: entry.isUserTag,
        commentSource: entry.commentSource,
        eventType: entry.eventType,
        level: entry.level,
        userId: entry.PartitionKey.startsWith(entry.reporterId)
          ? entry.reporterId : 'soccerwatch'
      }
    })
}
