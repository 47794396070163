import React, { Component } from 'react'
import PaypalSite from './paypal'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'
import i18n from 'i18next'
import './subscriptionCreate.scss'
import TokenSite from './token'

function MethodButton ({ onClick, label, iconClass }) {
  return <button onClick={onClick} className='Subscription col-xs own' >
    <span className={`clubLogo mbsc-ic mbsc-ic-fa-${iconClass}`} />
    {/* <img src={getLogoSrc(logoSrcId)} onError={() => { setlogoSrcId(false) }} className='image clubLogo' alt='ClubLogo' /> */}
    <div className='clubName'>
      {label}
    </div>
    <div className='addBtn'>
      {/* Gültig bis {sub.validDate} */}
    </div>
  </button>
}

class SubscriptionCreate extends Component {
  constructor (props) {
    super(props)
    let method = null
    if (props.location.search.includes('token=')) {
      method = 'Token'
    }
    this.state = {
      method
    }
  }

  setMethod = (method) => () => {
    this.setState({ method })
  }

  getMainContent = () => {
    switch (this.state.method) {
      case 'Token': return <TokenSite {...this.props} user={this.props.user} cancle={this.setMethod(null)} />
      // case 'Paypal': return <PaypalSite {...this.props} cancle={this.setMethod(null)} />
      default: return <>
        <div className='DataView row'>
          <div className='col-xs'>
            <h3 className='siteHeader'>{i18n.t('subscriptionCreate.newSubscription')}</h3>
          </div>
        </div>
        <div className='row'>
          <MethodButton onClick={this.setMethod('Token')} label='Token Einlösen' iconClass='qrcode' />
          {/* <MethodButton onClick={this.setMethod('Paypal')} label='Paypal Abbonemont' iconClass='paypal' /> */}
          <div className='Subscription col-xs filler' />
          <div className='Subscription col-xs filler' />
        </div>
      </>
    }
  }

  render () {
    const content = this.getMainContent()
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content history={this.props.history} className='userSite'>
          <div className='col-xs-12'>

            {content}
          </div>
        </Content>
      </>
    )
  }
  
}
export default SubscriptionCreate
