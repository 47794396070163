import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from '@mobiscroll/react'
import i18n from 'i18next'
import './ShortcutInfoPopup.scss'

const localStorageKey = 'hasSeenShortcutInfoPopup'

const Shift = () => <kbd>
  {i18n.t('shortcutInfoPopup.shift')}
</kbd>

const Ctrl = () => <kbd>
  {i18n.t('shortcutInfoPopup.ctrl')}
</kbd>

const Alt = () => <kbd>
  {i18n.t('shortcutInfoPopup.alt')}
</kbd>

const Space = () => <kbd>
  {i18n.t('shortcutInfoPopup.space')}
</kbd>

const footerWhenOpenedExplicitly = (onClose) => <div className="mbsc-btn-group-justified">
  <Button color="primary" onClick={onClose}>
    {i18n.t('default.button.close')}
  </Button>
</div>

const footerWhenOpenedFirstTime = (onClose) => {
  const onShowPopupAgain = () => {
    localStorage.setItem(localStorageKey, false.toString())
    onClose()
  }

  const onDontShowPopupAgain = () => {
    localStorage.setItem(localStorageKey, true.toString())
    onClose()
  }

  return <div className="mbsc-btn-group-justified">
    <Button color="secondary" onClick={onDontShowPopupAgain}>
      {i18n.t('shortcutInfoPopup.dontShowAgain')}
    </Button>
    <Button color="primary" onClick={onShowPopupAgain}>
      {i18n.t('shortcutInfoPopup.showAgain')}
    </Button>
  </div>
}

export const ShortcutInfoPopup = ({ wasOpenedExplicitly = false, onClose = () => {} }) => {
  const [popupHasBeenMounted, setPopupHasBeenMounted] = useState(false)
  const popupRef = useRef()

  let requestId = useRef(-1)
  useEffect(() => {
    const tryShowingPopupWhileNotMounted = () => {
      requestId.current = -1
      if (
        !popupHasBeenMounted &&
        popupRef.current && popupRef.current.instance &&
        typeof popupRef.current.instance.show === 'function'
      ) {
        popupRef.current.instance.show()
      } else {
        requestId.current = requestAnimationFrame(tryShowingPopupWhileNotMounted)
      }
    }

    tryShowingPopupWhileNotMounted()

    return () => cancelAnimationFrame(requestId.current)
  }, [popupHasBeenMounted])

  const onBeforeCloseModal = () => {
    onClose()

    // Returning false prevents the modal from closing. For some reason, it's own close function causes an error, so
    // it's closed via unmounting this component.
    return false
  }

  return <Popup
    ref={popupRef}
    showOverlay={true}
    closeOnOverlayTap
    display={'center'}
    layout={'liquid'}
    buttons={[]}
    onShow={() => setPopupHasBeenMounted(true)}
    onBeforeClose={onBeforeCloseModal}
    touchUi
    className={'shortcut-info-popup'}
  >
    <h1>{i18n.t('shortcutInfoPopup.modalTitle')}</h1>
    <p>{i18n.t('shortcutInfoPopup.intro')}</p>
    <p>
      <em>{i18n.t('shortcutInfoPopup.proTip')}</em>:&nbsp;
      {i18n.t('shortcutInfoPopup.howToFocusVideoPlayer')}
    </p>
    <table className={'striped'}>
      <tbody>
      <tr>
        <td><Space /></td>
        <td>{i18n.t('shortcutInfoPopup.playPause')}</td>
      </tr>
      <tr>
        <td><kbd>F</kbd></td>
        <td>{i18n.t('shortcutInfoPopup.fullscreen')}</td>
      </tr>
      <tr>
        <td><kbd>W</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.changePlayerNumber', { context: 'increment' })}
        </td>
      </tr>
      <tr>
        <td><kbd>S</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.changePlayerNumber', { context: 'decrement' })}
        </td>
      </tr>
      <tr>
        <td><kbd>A</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.skip', { direction: 'backward', seconds: 1 })}
        </td>
      </tr>
      <tr>
        <td><kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.skip', { direction: 'forward', seconds: 1 })}
        </td>
      </tr>
      <tr>
        <td><Shift /> + <kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.animateAhead', { seconds: 1 })}
        </td>
      </tr>
      <tr>
        <td><Alt /> + <kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.skip', { direction: 'forward', seconds: 5 })}
        </td>
      </tr>
      <tr>
        <td><Alt /> + <Shift /> + <kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.animateAhead', { seconds: 5 })}
        </td>
      </tr>
      <tr>
        <td><Ctrl /> + <kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.skip', { direction: 'forward', seconds: 20 })}
        </td>
      </tr>
      <tr>
        <td><Ctrl /> + <Shift /> + <kbd>D</kbd></td>
        <td>
          {i18n.t('shortcutInfoPopup.animateAhead', { seconds: 20 })}
        </td>
      </tr>
      </tbody>
    </table>

    <p>
      {i18n.t('shortcutInfoPopup.explenation', {
        shiftSplitter: '<SHIFT_SPLITTER>',
        altSplitter: '<ALT_SPLITTER>',
        ctrlSplitter: '<CTRL_SPLITTER>',
      })
        .split(/[<>]/gim)
        .map((str, index) => {
          if (str === 'SHIFT_SPLITTER') {
            return <Shift key={index} />
          } else if (str === 'ALT_SPLITTER') {
            return <Alt key={index} />
          } else if (str === 'CTRL_SPLITTER') {
            return <Ctrl key={index} />
          } else {
            return <React.Fragment key={index}>
              {str}
            </React.Fragment>
          }
        })}
    </p>
    {wasOpenedExplicitly ? footerWhenOpenedExplicitly(onClose) : footerWhenOpenedFirstTime(onClose)}
  </Popup>
}

ShortcutInfoPopup.propTypes = {
  wasOpenedExplicitly: PropTypes.bool,
  onClose: PropTypes.func,
}
