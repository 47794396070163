import React, { Component } from 'react'
// import mobiscroll from '@mobiscroll/react'
// import { Link } from 'react-router-dom'
import CreateClipUi from '../../utility/clips/createClipUi'
// import VortexSpinner from '../../utility/spinner/vortex.js'
// import Ribbon from '../../layout/ribbon'
import Content from '../../layout/content.js'
import Sidebar from '../../layout/sidebar'
import MatchItem from '../../utility/matches/matchItem.js'
import { OmniFilterUi, applyOmniFilter } from '../../utility/omniFilter/omniFilter'
import FastClipButtons from '../../utility/clips/fastClipButtons'
import { ForwardToPlayerTracking } from '../../ForwardToPlayerTracking/ForwardToPlayerTracking'
// const { Card, CardContent } = mobiscroll
class Matches extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  componentDidMount () {
    const el = document.querySelector('body')
    if (el.scrollTo) {
      el.scrollTo(0, 0)
    } else if (el.scroll) {
      el.scroll(0, 0)
    }
    window.scrollTo(0, 0)
  }

  formateDateTime = (timeString) => {
    const timeObj = new Date(timeString)
    const date = `${timeObj.getDate()}/${('0' + (timeObj.getMonth() + 1)).slice(-2)}/${timeObj.getFullYear()}`
    const time = `${timeObj.toISOString().split('T')[1].split(':').splice(0, 2).join(':')} Uhr`
    return { date, time }
  }

  deleteSequence = (item) => (confirm) => {
    if (confirm) {
      this.props.deleteSequence(item)
    }
  }

  deleteSequence = (item) => (confirm) => {
    this.props.deleteSequence(item)
  }

  updateClip = (isCreate) => async (clip) => {
    const result = isCreate ? await this.props.createClip(clip) : await this.props.updateClip(clip)
    if (!result) {
      // ERROR CASE
      return
    }
    const URL = `/clips/${result.RowKey}/`
    this.props.history.push(URL)
  }

  goToVideoEditSequenceTime = (sequence) => () => {
    window.GlobalCoachingTool.timeReference = sequence.from
    this.props.history.push('./edit/')
  }

  render () {
    const match = this.props.match
    const { addToClip, removeFromClip } = this.props
    const omniFilterState = this.props.filterStateSequence
    const omniFilterConfig = [
      { filterType: 'sequence', label: 'Szene Name', property: 'name', type: 'text' },
      { filterType: 'sequence', label: 'Label', property: 'label', type: 'option', options: this.props.knownSequenceLabel, singleValue: false }
      // { filterType: 'sequence', label: 'Szene Datum', property: 'date', type: 'date' },
    ]
    const sequences = this.props.sequences ? applyOmniFilter(this.props.sequences, omniFilterConfig, omniFilterState) : []
    const sequencesHidden = this.props.sequences ? this.props.sequences.length - sequences.length : 0
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          ribbonLabel={this.props.clipSequence.length}
          user={this.props.user}
          ribbon
        >
          <CreateClipUi
            key='CreateClipUi'
            matches={this.props.matches}
            sequences={this.props.clipSequence}
            removeSequence={this.props.removeFromClip}
            clip={this.props.clipContext}
            onSequenceSort={this.props.onClipSequenceSort}
            createSequence={this.props.createSequence}
            createClip={!this.props.clipContext ? this.updateClip(true) : undefined}
            updateClip={this.props.clipContext ? this.updateClip(false) : undefined}
            setClipContext={this.props.setClipContext}
            clipActions={{ addToClip, removeFromClip }}
            clipSequenceName={this.props.clipSequenceName}
            setClipSequenceName={this.props.setClipSequenceName}
            clipUiWorking={this.props.clipUiWorking}
            setClipUiWorking={this.props.setClipUiWorking}
            collapsible
          >
            <FastClipButtons isHighlightAdmin={this.props.isHighlightAdmin} sequenceMap={this.props.sequenceMap} matchList={this.props.match} addToClip={this.props.addToClip} isSelectedMatch />
          </CreateClipUi>
          { this.props.isHighlightAdmin &&
            <ForwardToPlayerTracking currentMatchId={match ? match.matchId : undefined} />
          }
        </Sidebar>
        <Content history={this.props.history} className='matchDetails'>
          {match && <MatchItem
            isHighlightAdmin={this.props.isHighlightAdmin}
            match={match}
            sequences={sequences}
            sequencesLoaded={this.props.sequences}
            extendable={false}
            showSequences
            showTags
            updateSequence={this.props.updateSequence}
            disableTagCreate
            onMinuteClick={this.goToVideoEditSequenceTime}
            createSequence={this.props.createSequence}
            clipSequence={this.props.clipSequence}
            clipActions={{ addToClip, removeFromClip }}
            history={this.props.history}
            deleteSequence={(seq) => (confirm) => {
              if (confirm) {
                this.props.deleteSequence(seq)
              }
            }}
            needsConfirm
            editSequence
            showPreview
            showSequenceCount
            disableLabelShowMore
            // showClubIcons
          >
            <OmniFilterUi
              onFilterRemove={this.props.omniFilterRemove}
              onFilterAdd={this.props.omniFilterAdd}
              config={omniFilterConfig}
              filterPrefix='filter'
              filters={omniFilterState}
            />
          </MatchItem>
          }
          { Boolean(sequencesHidden) && <div className='row centerContent'><span>{sequencesHidden} Szenen Ausgeblendet</span></div>}
        </Content>
      </>
    )
  }
}

export default Matches
