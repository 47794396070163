import axios from 'axios'
import * as rax from 'retry-axios'
rax.attach()

async function createSubscription (data) {
  const url = `/API/createSubscription/`
  const body = JSON.stringify(data)
  const headers = { 'content-type': 'application/json' }
  try {
    const response = await axios.post(url, body, { headers })
    return response.status
  } catch (err) {
    console.error(err)
    return { error: err }
  }
}

export default { createSubscription }
