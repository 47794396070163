import React, { Component } from 'react'
import Annotation from '../../plugins/annotationEditor/annotationEditor'
import SequenceBar from '../../plugins/intervalMarker/alternateProgressbar'
import * as VC from '@soccerwatch/videocanvas'
import materialColor from 'material-color-hash'
import Content from '../../layout/content'
import Sidebar from '../../layout/sidebar'
import ClipDetails from '../../utility/clips/clipDetails'
import * as DeviceCatigorisation from '../../../lib/helper/deviceCatigorisation'
import PropTypes from 'prop-types'
import { PositionEditorPlugin } from '../../plugins/PositionEditor/PositionEditorPlugin'
import VideoCanvasHelp from '../../plugins/VideoCanvasHelp/VideoCanvasHelp'
import FallbackCorners from '../../utility/FallbackCorners'
import i18next from 'i18next'
const VideoCanvas = VC.Video

class View extends Component {
  static propTypes = {
    showPlayerPositionTracker: PropTypes.bool,
    getTagsForMatch: PropTypes.func
  }

  static defaultProps = {
    showPlayerPositionTracker: false
  }

  constructor(props) {
    super(props)
    const params = this.props.match.params
    const isVideo = this.props.isVideo
    const id = isVideo ? params.matchId : params.ClipId
    const src = isVideo ? null : this.props.getClipSrc(id)
    const vrMode = props.startInVr || false
    const innerHeight = window.innerHeight
    this.isMobile = DeviceCatigorisation.isMobile()
    this.isFirefoxAndroid = DeviceCatigorisation.isFirefox() && DeviceCatigorisation.isAndroid()
    this.unsuportedBrowser = ((!DeviceCatigorisation.isMobile() && !DeviceCatigorisation.isIOS() && DeviceCatigorisation.isSafari()) || this.isFirefoxAndroid)
    this.browserNotice = DeviceCatigorisation.isMobile() ? 'Bitte verwenden sie die aktuelle Version des Chrome Browsers' : 'Bitte verwenden sie die aktuelle Version von Chrome oder Firefox.'
    this.videoRef = null
    this.state = {
      trashcan: [],
      isVideo,
      controls: true,
      clipData: null,
      videoData: null,
      innerHeight,
      vrMode,
      vrSrc: null,
      recMode: false,
      cornerPoints: null,
      src,
      keyboardShortcuts: {},
      isQuarterBoxPanorama: false
    }
    this.props.setCurrentSite()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInnerHeight)
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.updateInnerHeight)
    this.state.isVideo ? this.prepareVideo() : this.prepareClip()
  }

  getCornerPoints = async (videoId) => {
    let cornerPoints
    if (!videoId) {
      console.warn('Using FallBack MatchId to getCornerPoints')
      cornerPoints = FallbackCorners
      this.setState({ cornerPoints })
      // videoId = '38918'
    } else {
      cornerPoints = await this.props.getMatchCornerPoints(videoId)
    }
    if (!cornerPoints || !cornerPoints.panorama_corners) {
      this.setState({ cornerPoints: FallbackCorners })
      return
    }

    // Video calculation used to have a bug resulting in the corner points being off by a factor of 1.111. This bug
    // was fixed by June 2020. If the match was created (or took place) before June 2020, apply this fix manually.
    const matchDate = this.props.match && this.props.match.startTime
      ? new Date(this.props.match.startTime)
      : new Date(0)
    const bugFixDate = new Date(2020, 6, 1)
    const applyVideoFormatBugQuickfix = matchDate < bugFixDate
    if (applyVideoFormatBugQuickfix) {
      cornerPoints.panorama_corners = cornerPoints.panorama_corners.map((p, i) => i === 3 || i === 5 ? p * 1.111 : p)
    }

    this.setState({ cornerPoints })
  }

  async prepareClip() {
    const id = this.props.match.params.ClipId
    const clipData = await this.props.getClip(id)
    if (!clipData) {
      this.props.history.push('/404')
      return
    }
    if (clipData.error) {
      // TODO: Handle Error Properly
      window.alert(clipData.error)
      return
    }
    this.setState({ clipData })
    this.getCornerPoints()
    const clubSequence = clipData.sequence.reduce((string, seq, index) => {
      const slash = index === 0 ? '' : ' - '
      return string + slash + seq.clubAId + ' - ' + seq.clubBId
    }, '')
    window.gtag && window.gtag('event', 'clip viewed', {
      env: String(process.env.REACT_APP_Environment),
      clubSequence,
      event_category: 'clip',
      event_label: 'Public Clip Viewed'
      // 'value': ''
    })
  }

  async prepareVideo() {
    const id = this.props.match.params.matchId
    const headers = this.props.headers || null
    const videoData = await this.props.getVideo(id, headers)
    if (!videoData || !Object.keys(videoData).length) {
      this.props.history.push('/404')
      return
    }
    if (videoData.error) {
      window.alert(videoData.error)
      return
    }
    //! videoType is Deprecated                   // Use videoProcessing going forward
    const isQuarterBoxPanorama = (videoData.videoType === 'quarterBoxPanorama' || videoData.videoProcessing === 'quarterBoxPanorama')

    let src
    let vrSrc
    if (videoData.videoType === 'panorama' || videoData.videoProcessing === 'panorama' || isQuarterBoxPanorama) {
      src = videoData.userStreamAdaptive
      vrSrc = videoData.userStreamAdaptive
    } else {
      src = videoData.userStreamAdaptive || videoData.view_userStream
      vrSrc = videoData.vrStream
    }
    this.setState({ videoData, src, vrSrc, isQuarterBoxPanorama })
    this.getCornerPoints(id)

    // window.gtag && window.gtag('event', 'match viewed', {
    //   env: String(process.env.REACT_APP_Environment),
    //   clubSequence,
    //   event_category: 'clip',
    //   event_label: 'Public Clip Viewed'
    // // 'value': ''
    // })
  }

  /**
   * Adds shortcuts
   * @param {VC.KeyboardShortcuts} shortcuts
   */
  addKeyboardShortcuts = (shortcuts) => {
    this.setState({
      keyboardShortcuts: {
        ...this.state.keyboardShortcuts,
        ...shortcuts
      }
    })
  }

  seekTo = (time) => {
    if (this.videoRef) {
      this.videoRef.currentTime = time
    }
  }

  /**
   * @param {VC.VideoCanvasController} videoParent
   * @param {VC.VideoCanvasControllerStateType} videoState
   * @param {HTMLVideoElement} video
   * @param {paper.PaperScope} paper
   * @param {paper.Layer} drawingLayer
   */
  deliverPlugins = (videoParent, videoState, video, paper, drawingLayer) => {
    const sidebarContainer = document.getElementById('Sidebar')
    if (video && ((!this.videoRef) || (this.videoRef.src !== video.src))) {
      this.videoRef = video
    }
    if (paper && this.props.showPlayerPositionTracker) {
      const matchId = this.props.match.params.matchId
      const match = {
        RowKey: matchId
      }
      return <>
        <PositionEditorPlugin
          videoParent={videoParent}
          videoState={videoState}
          video={video}
          paper={paper}
          drawingLayer={drawingLayer}
          sidebarContainer={sidebarContainer}
          match={match}
          addKeyboardShortcuts={this.addKeyboardShortcuts}
          getTagsForMatch={this.props.getTagsForMatch}
          headers={this.props.headers}
          getAuthorization={this.props.getAuthorization}
        />
        <VideoCanvasHelp />
      </>
    } else if (paper && this.state.clipData) {
      const sequences = this.state.clipData.sequence
      const annotations = this.getFlatAnnotations(this.state.clipData.sequence, true)
      const intervals = sequences.map((seq) => {
        return {
          startTime: seq.relativeStartTime,
          duration: seq.relativeEndTime - seq.relativeStartTime,
          color: (materialColor(seq.timestamp + '')).backgroundColor,
          onClick: () => {
            this.seekTo(seq.relativeStartTime)
          }
        }
      })
      const useAnnotations = DeviceCatigorisation.isIOS() ? [] : annotations
      return <>
        <Annotation
          sidebarContainer={sidebarContainer}
          paper={paper}
          videoParent={videoParent}
          videoState={videoState}
          sequences={sequences}
          isPlaying={videoState.isPlaying}
          video={video}
          videoController={videoParent && videoParent.props ? videoParent.props.videoController : null}
          sidebarContent={
            <ClipDetails
              disableControls
              disableRename
              clip={this.state.clipData}
              showAnnotations
              showLabel
              useColor
              showSequences
            />
          }
          annotations={useAnnotations}
          annotationsLoading={false}
          ViewMode
          uploadAnnotation={() => { }}
          disableControls={this.disableControls}
          enableControls={this.enableControls}
          drawingLayer={drawingLayer}
        />
        {
          video && <SequenceBar intervals={intervals} totalDuration={video.duration} />
        }
      </>
    }
  }

  getFlatAnnotations = (sequences, inClip = false) => {
    return sequences.map((seq) => {
      if (!seq.annotations) {
        return []
      }
      return seq.annotations.map((anno) => {
        if (inClip) {
          anno.relativeTimestamp = seq.relativeTimestamp + (anno.timestamp - seq.timestamp)
        } else {
          anno.relativeTimestamp = anno.timestamp
        }
        return anno
      })
    }).flat()
      .sort((a, b) => a.relativeTimestamp - b.relativeTimestamp)
  }

  disableControls = () => {
    this.setState({ controls: false })
  }

  enableControls = () => {
    this.setState({ controls: true })
  }

  doPauseOnClick = () => {
    if (this.props.showPlayerPositionTracker) {
      return VideoCanvas.playPauseOnOptions.spaceButtonPress
    } else if (this.state.ViewMode) {
      return VideoCanvas.playPauseOnOptions.none
    } else {
      return VideoCanvas.playPauseOnOptions.leftClick
    }
  }

  updateInnerHeight = () => {
    const innerHeight = window.innerHeight
    this.setState({ innerHeight })
  }

  toggleVrMode = () => {
    const vrMode = !this.state.vrMode
    this.setState({ vrMode })
  }

  toggleRecMode = () => {
    const recMode = !this.state.recMode
    this.setState({ recMode })
  }

  render() {
    return (
      <div className='editorSite'>
        <Content disablePathMap>
          <div>
            {this.state.recMode &&
              <button style={{ position: 'fixed', zIndex: 999999 }} className='btn info' onClick={this.toggleRecMode}>RecScreen</button>}
            {this.unsuportedBrowser &&
              <div className='useOtherBrowserNotice row'>
                <p
                  className='navItemBack col-xs-2' onClick={() => {
                    if (window.GlobalCoachingTool && window.GlobalCoachingTool.goBack) {
                      window.GlobalCoachingTool.goBack()
                    } else {
                      this.props.history.push('/')
                    }
                  }}
                >
                  <span className='navIcon mbsc-ic mbsc-ic-fa-chevron-left' />
                  Zurück
                </p>
                <h3 className='col-xs-10'>
                  Der Coachingtool Videoeditor ist in ihrem aktuellen Browser leider nicht lauffähig.
                  {this.browserNotice}
                </h3>
              </div>}
            {!this.unsuportedBrowser &&
              <div>
                <div className={`VideoContainer ${this.state.recMode ? 'Fullscreen' : ''}`} style={{ height: this.state.innerHeight }}>
                  <VideoCanvas
                    disablePrecissionSeek
                    controls={this.state.controls}
                    playPauseOn={this.doPauseOnClick()}
                    dragCanvasKey={
                      this.props.showPlayerPositionTracker
                        ? VideoCanvas.dragCanvasKey.rightMouseButton
                        : VideoCanvas.dragCanvasKey.leftMouseButton
                    }
                    src={this.state.src}
                    vrSrc={this.state.vrSrc}
                    isQuarterBoxPanorama={this.state.isQuarterBoxPanorama}
                    drawOnCylinder={this.state.isQuarterBoxPanorama}
                    activateTouchControls={this.state.isVideo}
                    disableSkipButtons
                    evaluatePlugins={this.deliverPlugins}
                    useVrPlayer={this.state.vrMode || this.props.showPlayerPositionTracker}
                    vrGridOpacity={0}
                    keyboardShortcuts={this.state.keyboardShortcuts}
                    drawOnField={!this.props.showPlayerPositionTracker}
                    autofocus={this.props.showPlayerPositionTracker}
                    displayMilliseconds={this.props.showPlayerPositionTracker}
                    vrGridCorners={this.state.cornerPoints}
                  >
                    {!this.state.recMode &&
                      <Sidebar loggedOff={this.props.loggedOff} noNav disablePathMap inlineHeader ignoreBreakpoint ribbon ribbonLabel={<span className='mbsc-ic mbsc-ic-material-menu' />}>
                        {this.state.isVideo && this.state.videoData && this.state.vrSrc && !this.props.showPlayerPositionTracker &&
                          <div className='col-xs sidebarButtonContainer'>
                            <button
                              className='btn info'
                              onClick={this.toggleVrMode}
                            >
                              {this.state.vrMode ? 'Standard Mode' : 'Vr Mode'}
                            </button>
                            {!this.state.recMode && !this.isMobile &&
                              <button className='btn info' onClick={this.toggleRecMode}>RecScreen</button>}
                          </div>}
                        {DeviceCatigorisation.isIOS() &&
                          <div className='IOS_WARNING'>
                            {i18next.t('IOS_WARNING')}
                          </div>
                        }
                        <div key='Sidebar' id='Sidebar' className='SidebarContainer' />
                      </Sidebar>}
                  </VideoCanvas>
                </div>
              </div>
            }
          </div>
        </Content>
      </div>
    )
  }
}

export default View
