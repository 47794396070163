import React, { Component } from 'react'

import i18n from 'i18next'
import Sidebar from '../../layout/sidebar'
import Content from '../../layout/content'

class NoSubscription extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  logout () {
    if (window.GlobalCoachingTool) {
      window.GlobalCoachingTool.logout()
    }
  }

  render () {
    return (
      <>
        <Sidebar
          history={this.props.history}
          matchCount={this.props.matchCount}
          clipCount={this.props.clipCount}
          user={this.props.user}
          childrenBeforeLogo
        />
        <Content className='userSite'>
          <h1 className='siteHeader'>Sie besitzen momentan keine gültige Subscription</h1>
          <div onClick={this.logout} className='logout btn info'><small>{i18n.t('dashboard.logout')} <span className='mbsc-ic mbsc-ic-enter' /></small></div>
        </Content>
      </>
    )
  }
}

export default NoSubscription
