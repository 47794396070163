import React from 'react'
import ReactDOM from 'react-dom'
import './progressbarSection.scss'
import PropTypes from 'prop-types'

export const ProgressbarSection = (props) => {
  const durationInPercent = props.duration / props.totalDuration * 100
  const startPositionInPercent = props.startTime / props.totalDuration * 100

  const element = (
    <div
      className='progressbarSection'
      onClick={props.onClick}
      style={{
        width: `${Math.max(durationInPercent, 0.01)}%`,
        left: `${startPositionInPercent}%`,
        backgroundColor: props.color,
      }}
    >
      {props.onAddButtonClick && <button
        onClick={(e) => { props.onAddButtonClick(props, e) }}
        className='sectionAddBtn'
      >
        +
      </button>}
    </div>
  )
  return props.parent ? ReactDOM.createPortal(element, props.parent) : element
}

ProgressbarSection.propTypes = {
  duration: PropTypes.number.isRequired,
  startTime: PropTypes.number.isRequired,
  totalDuration: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  parent: PropTypes.oneOfType([PropTypes.element, PropTypes.instanceOf(Element)]),
  color: PropTypes.string,
  onAddButtonClick: PropTypes.func,
}
ProgressbarSection.defaultProps = {
  onClick: () => {},
  onAddButtonClick: null,
}

export default ProgressbarSection
