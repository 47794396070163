import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class Focus extends Component {
  onMouseDown = (evt, path) => {
    this.center = evt.localPoint
    const Paper = this.props.Paper
    if (!this.Rect) {
      this.Rect = new Paper.Path({ insert: false })
      this.Rect.add(new Paper.Point(0, 0))
      this.Rect.add(new Paper.Point(20000, 0))
      this.Rect.add(new Paper.Point(20000, 20000))
      this.Rect.add(new Paper.Point(0, 20000))
      this.Rect.setClosed(true)
      this.Rect.fillColor = new Paper.Color(0, 0, 0, 0.6)
      return { path }
    }
    return { path, isNewPath: false }
  }

  onMouseDrag = (evt, path) => {
    const Paper = this.props.Paper
    const rect = this.Rect.clone({ insert: true })

    const point = evt.localPoint.getDistance ? evt.localPoint : new Paper.Point(evt.localPoint.x, evt.localPoint.y)
    this.radius = point.getDistance(this.center)
    const mouseBkp = path.data.mouse.splice(0)

    path.remove()
    const circ = path = new Paper.Path.Circle(evt.localPoint, this.radius, { insert: false })
    path = rect.subtract(circ)
    path.data.annotation = true
    path.data.tool = 'Focus'
    path.data.mouse = mouseBkp.splice(0)
    // Changes Behavior; Keep Center of Circle on Click Position
    // And only Drag the Size of the Radius.
    // path = new Paper.Path.Circle(this.center, this.radius)
    this.props.Paper.project.activeLayer.addChild(path)
    rect.remove()
    circ.remove()
    return { path }
  }

  onMouseUp = (evt, path) => {
    this.Rect = path.clone({ insert: false })
    return { path, resetTime: true }
  }

  cleanUp = () => {
    this.Rect && this.Rect.clear()
    this.Rect = undefined
  }

  render () {
    return (
      <ProtoTool
        key='Focus'
        toolName='Focus'
        keepPath
        dontCleanOnChange
        cleanUp={this.cleanUp}
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-fa-crosshairs' />
      </ProtoTool>
    )
  }
}

export default Focus
