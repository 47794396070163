export class BaseService {
  notifyList = {}
  onFetchingUpdate = () => {}

  /**
   * Store URLs that are currently being fetched and their promises
   */
  currentlyFetching = {}

  /**
   * @param {function} onFetchingUpdate
   */
  constructor (onFetchingUpdate = () => {}) {
    this.onFetchingUpdate = onFetchingUpdate
  }

  /**
   * Register handler for when positions change
   * @param {string} name - Name of subscription
   * @param {function} fn  - Function to be called when positions change
   */
  subscribeToChange (name, fn) {
    if (typeof fn !== 'function') {
      console.error('Subscribe to Change fn Argument MUST be a Function!')
    } else {
      this.notifyList[name] = fn
    }
  }

  /**
   * Remove subscription to changes of positions
   * @param {string} name - Name of subcription. Must be identical to the name
   * the subscription was registered with.
   */
  unsubscribeFromChange (name) {
    this.notifyList[name] = undefined
    delete this.notifyList[name]
  }

  /**
   * Notifies all subscriptions that the positions have been changed.
   */
  onChange () {
    /**
     * @event PlayerPositionService#change
     * @type {*}
     */
    Object.values(this.notifyList).forEach(callback => callback())
  }

  startedFetching (url, promise, retryFunction) {
    if (!Object.values(this.currentlyFetching).filter(Boolean).length) {
      this.onFetchingUpdate(true)
    }

    this.currentlyFetching[url] = promise
      .then((result) => {
        this.currentlyFetching[url] = undefined

        if (!Object.values(this.currentlyFetching).filter(Boolean).length) {
          this.onFetchingUpdate(false)
        }

        return result
      })
      .catch(err => {
        this.currentlyFetching[url] = undefined
        console.error('Fetch failed; retrying...', err)
        return new Promise((resolve) => {
          let retryHappened = false

          const retry = () => {
            window.removeEventListener('online', retry)

            if (!retryHappened) {
              retryHappened = true
              resolve(retryFunction(url))
            }
          }

          window.addEventListener('online', retry)
          setTimeout(retry, 10000)
        })
      })
  }
}
