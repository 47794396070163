export default {
  IOS_WARNING: "Annotationen können unter iOS aktuell nicht angezeigt werden. Wir arbeiten an einer dauerhaften Lösung.",
  default: {
    button: {
      close: 'Schließen',
      cancel: 'Abbrechen',
      save: 'Speichern',
      ok: 'Ok',
    },
  },
  dashboard: {
    welcome: 'Staige',
    toClips: 'Zu den Clips',
    toGames: 'Zu den Spielen',
    logout: 'Abmelden',
  },
  sidebar: {
    subs: 'ABONNEMENTS',
    help: 'HILFE',
  },
  pathMap: {
    matches: {
      single: 'Spiele',
      id: 'Spiel Details',
      editRef: 'Spiel',
    },
    clips: {
      single: 'Clips',
      id: 'Clip Details',
      editRef: 'Clip',
    },
    sequence: {
      id: 'Szene Editieren',
    },
    user: {
      single: 'Abonnements',
    },
    subscription: {
      id: 'Abonnements für Gäste',
    },
    newSubscription: {
      single: 'Neues Abonnemont',
    },
    editor: '{{ref}} editieren',
    playerPositions: {
      single: 'Spielerpositionen erfassen',
    },
    404: {
      single: '???',
    },
  },
  createClipUi: {
    create: 'Clip erstellen',
    edit: 'Clip bearbeiten',
    info: 'Um einen Clip zu erstellen, füge Sequenzen über die Schnellauswahl oder aus Spielen hinzu.',
    addSequences: 'Sequenzen hinzufügen',
    nameClip: 'Clip benennen zum speichern',
  },
  fastClipButtons: {
    head: 'Szenen Schnellauswahl',
    standards: 'Standards',
    highlights: 'Highlights',
    goals: 'Tore',
    this: 'dieses Spiels',
    newest: 'des neusten Spiels',
    filtered: 'der gefilterten Spiele',
  },
  clipDetails: {
    watch: 'im Editor Ansehen',
    watchShort: 'Editor',
    edit: 'Bearbeiten',
    publish: 'Veröffentlichen',
    unPublish: 'Privat Stellen',
    copyLink: 'Link Kopieren',
    delete: 'Löschen',
    urlInClipboard: 'Url in die Zwischenablage kopiert',
    sceneCount: 'Szenen vom {{date}}',
    questionDelete: 'Willst du den Clip wirklich löschen?',
    cancle: 'Abbrechen',
  },
  clipList: {
    header: 'Alle Clips',
  },
  matchList: {
    header: 'Alle Spiele',
  },
  matchItem: {
    watch: 'im Editor Ansehen',
    sequencesLoading: 'Lade Szenen',
    sequencesLoaded: '{{count}} Szenen',
    from: 'vom',
    at: 'um',
  },
  sequenceItem: {
    edit: 'Bearbeiten',
    addToClip: 'Zu Clip Hinzufügen',
    removeFromClip: 'Entfernen',
    rename: 'Szene Umbenennen',
    gameInfo: 'Spielinformationen',
    annotations: 'Anmerkungen',
    delete: 'Löschen',
    deleteHead: 'Szene Löschen?',
    deleteMsg: 'Warnung: Die Szene wird dadurch auch aus allen Clips, in denen sie verwendet wurde, entfernt!',
    deleteOk: 'Löschen',
    deleteCancle: 'Abbrechen',
  },
  labelList: {
    new: 'Neues Label',
    more: 'Mehr',
    namePrompt: 'Label Benennen',
  },
  omniFilter: {
    search: 'Suchen...',
  },
  user: {
    from: 'von',
    guestSubs: 'Dein Gastzugang',
    guestSubFrom: 'zu {{userName}}´s Vereinen',
    subscription: 'Abon­ne­ments',
    invalidSubscriptions: 'Du hast keine gültigen Abon­ne­ments',
    clubNameLoading: 'Clubname wird geladen',
    validUntiL: 'Bezahlt bis',
    activeUntil: 'Gültig bis',
    manageAccess: 'Trainer verwalten',
    newSub: 'Neues Abonnement',
  },
  login: {
    loginFailed: 'Login Fehlgeschlagen',
    unknownMail: 'Email und/oder Passwort unbekannt',
    inputIncomplete: 'Bitte fülle alle Felder aus!',
    pwNoMatch: 'Die eingegebenen Passwörter stimmen nicht überein!',
    invalidEmail: 'Bitte gib eine gültige Email Adresse an.',
    resetPwError: 'Das Passwort konnte nicht erfolgreich zurückgesetzt werden.',
    shareSubs: 'möchte seine Subscription mit ihnen Teilen.',
    noAccount: 'Noch kein Konto?',
    password: 'Passwort',
    forgotPassword: 'Passwort Vergessen?',
    login: 'Anmelden',
    userName: 'Benutzername',
    gotAccount: 'Ich habe bereits einen Account',
    repeatPassword: 'Passwort Wiederholen',
    register: 'Registrieren',
    registerSuccess: 'Deine Registrierung  war erfolgreich. Bitte überprüfe dein E-Mail Postfach.',
    send: 'Absenden',
    pwResetComplete: 'Dein Passwort wurde erfolgreich zurückgesetzt. Bitte überprüfe dein E-Mail Postfach.',
    backToLogin: 'Zurück zur Anmeldung',
    loggingIn: 'Du wirst Angemeldet',
    TrainerToken: 'Registriere dich oder melden dich mit einem bestehenden Account an, um Zugriff auf dein neues Abon­ne­ment zu erhalten',
  },
  subscriptionEdit: {
    inviteUser: 'Nutzer Einladen',
    notSet: 'Nicht vergeben',
    guestSub: 'Gast Abonnemonts für',
    recieverMail: 'Empfänger E-Mail',
    send: 'Senden',
    back: 'Zurück',
    explanationP1: `Hier kannst du Trainern deines Vereins Zugriff auf das soccerwatch Coachingtool geben.
    Jeder Trainer erhält für die Laufzeit deines soccerwatch Coachingtools Zugriff auf die aufgezeichneten Spiele deines Vereins.
    Um einen Nutzer hinzuzufügen, klicke einfach auf einen der Slots und gib die Email-Adresse des Empfängers an.
    `,
    explanationP2: 'Der Trainer erhält dann eine E-Mail mit deiner Einladung als Trainer des Vereins {{CLUB}} das soccerwatch Coachingtool zu nutzen.',
    explanationP3: ``,
  },
  subscriptionCreate: {
    newSubscription: 'Neues Abonnement',
    working: 'Dein Auftrag wird bearbeitet...',
    choosenClub: 'Ausgewählter Club:',
    noClub: 'Kein Club Ausgewählt',
    badError: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte Notiere dir die folgende Nummer, damit wir deine Paypal Überweisung deinem Auftrag zuordnen können.',
    retry: 'Erneut Versuchen',
    headerSuccess: 'Clubzugang Freigeschaltet',
    textSuccess: 'Ihr Abonnement für den Verein {{club}} wurde erfolgreich angelegt.',
    back: 'Zurück zur Übersicht',
    chooseClub: 'Wähle den Club, für den du das Abonnement abschließen wilst',
  },
  tags: {
    0: 'Tor Heimclub',
    1: 'Tor Gegner',
    2: 'Torchance Heimclub',
    3: 'Torchance Gegner',
    4: 'Foul Heimclub',
    5: 'Foul Gegner',
    6: '11.Meter Heimclub',
    7: '11.Meter Gegner',
    8: 'Gelbe Karte Heimclub',
    9: 'Gelbe Karte Gegner',
    10: 'Rote Karte Heimclub',
    11: 'Rote Karte Gegner',
    12: 'Anpfiff 1. Halbzeit',
    13: 'Ende 1. Halbzeit',
    14: 'Anpfiff 2. Halbzeit',
    15: 'Ende 2. Halbzeit',
    16: 'Tricks',
    17: 'Trick Pass',
    18: 'Trick Schuss',
    19: 'Flitzer',
    25: 'Kopfball',
    26: 'Schwalbe',
    27: 'Verlängerung',
    28: 'Ende',
    33: 'Abseits Heimclub',
    34: 'Abseits Gegner',
    35: 'Ecke Heimclub',
    36: 'Ecke Gegner',
    37: 'Freistoß Heimclub',
    38: 'Freistoß Gegner',
    39: 'Auswechslung Heimclub',
    40: 'Auswechslung Gegner',
  },
  playerPositionTracker: {
    metadataFormTitle: 'Metadaten des zu markierenden Objekts',
    placeholder: 'Bitte eingeben...',
    playerNumber: 'Spielernummer',
    teamId: 'Team-ID',
    trackBall: 'Ball markieren',
    link: {
      title: 'Positionen markieren',
      placeholder: 'Spiel-ID',
      linkLabel: 'Los',
    },
    teamPositionsTitle: 'Team {{teamId}} - Bereits {{numberOfTrackedPlayers}} Spieler erfasst',
  },
  shortcutInfoPopup: {
    modalTitle: 'Tastaturbefehle',
    intro: 'Der Video-Player hat einige Tastaturbefehle. Diese funktionieren allerdings nur, wenn der Video-Player fokussiert ist.',
    howToFocusVideoPlayer: 'Durch einen Klick auf die schwarze Steuerungsleiste am unteren Rand des Video-Players kann der Player fokussiert werden, ohne eine Position zu markieren!',
    proTip: 'Pro-Tip',
    space: 'Leertaste',
    ctrl: 'Strg',
    shift: 'Umschalt',
    alt: 'Alt',
    playPause: 'Das Video fortsetzen / pausieren',
    fullscreen: 'Vollbildmodus aktivieren / deaktivieren',
    seconds: '{{count}} Sekunde',
    seconds_plural: '{{count}} Sekunden',
    direction_forward: 'vorne',
    direction_backward: 'hinten',
    skip: `$t(shortcutInfoPopup.seconds, { 'count': {{seconds}} }) nach $t(shortcutInfoPopup.direction, { 'context': '{{direction}}' }) springen`,
    animateAhead: `$t(shortcutInfoPopup.seconds, { 'count': {{seconds}} }) des Videos mit erhöhter Geschwindigkeit abspielen`,
    showAgain: 'Übersicht nächstes Mal wieder anzeigen',
    dontShowAgain: 'Übersicht nicht nochmal anzeigen',
    explenation: '{{shiftSplitter}} fügt also, so zu sagen, eine Animation zu einem Tastaturbefehl hinzu, während {{altSplitter}} bzw. {{ctrlSplitter}} die zu überspringende Zeit auf 5 bzw. 20 Sekunden setzt.',
    changePlayerNumber_increment: 'Spielernummer um 1 erhöhen',
    changePlayerNumber_decrement: 'Spielernummer um 1 verringern',
  },
  fieldCalibration: {
    startCalibration: 'Spielfeld kalibrieren',
    newLine: 'Neue Linie',
    createNewLine: 'Neue Linie hinzufügen',
    point: 'Punkt',
    inMeters: 'in Metern',
    name: 'Name',
    distance: 'Distanz',
  },
}
