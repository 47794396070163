import React from 'react'
import ReactDOM from 'react-dom'
import './alternateProgressbar.scss'
import PropTypes from 'prop-types'
import { ProgressbarSection } from './progressbarSection'

export const AlternateProgressbar = (props) => {
  const progressBar = window.document.getElementById('ProgressBarPlugins')
  if (!progressBar) {
    return null
  }

  const result = <>
    {props.intervals.map((interval, index) => <ProgressbarSection
      key={index}
      index={index}
      duration={interval.duration}
      startTime={interval.startTime}
      totalDuration={props.totalDuration}
      onClick={interval.onClick}
      onAddButtonClick={interval.onAddButtonClick}
      color={interval.color}
    />)}
  </>

  return ReactDOM.createPortal(result, progressBar)
}

AlternateProgressbar.propTypes = {
  totalDuration: PropTypes.number.isRequired,
  intervals: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    color: PropTypes.string,
    onAddButtonClick: PropTypes.func,
    onClick: PropTypes.func,
  })).isRequired,
}

export default AlternateProgressbar
