import paper from 'paper'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import discovery from '@soccerwatch/discovery'
import { getUserHeaders } from '../../firebase'

const localStorageKey = 'FieldCalibrationData'
const dataVersion = 1
const alreadyFetchedFromServerForField = {}

/**
 * @typedef Line
 * @type {{
 *   pointA?: paper.Point,
 *   pointB?: paper.Point,
 *   distance?: number,
 * }}
 */

/**
 * @typedef Lines
 * @type {Array<Line>}
 */

/**
 * Converts paper.Point to an object that can be converted
 * to JSON and then back to a paper.Point again
 * @param {{x: number, y: number}} point
 * @returns {{x: number, y: number}}
 */
const paperPointToJSON_ableObject = point => {
  if (point && point.x !== undefined && point.y !== undefined) {
    return {
      x: point.x,
      y: point.y
    }
  } else {
    return point
  }
}

// Always return complete object containing EXACTLY the expected keys.
// Nothing more, nothing less.
const createPaperPointsForLine = ({ pointA, pointB, distance, name }) => ({
  distance,
  pointA: pointA === undefined ? null : new paper.Point(pointA),
  pointB: pointB === undefined ? null : new paper.Point(pointB),
  name
})

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @returns {Lines}
 */
export const getFieldCalibrationFromLocalStorage = (fieldID) => {
  let lines = []

  if (window.localStorage) {
    const persisted = JSON.parse(localStorage.getItem(`${localStorageKey}.${fieldID}`))

    if (persisted !== null) {
      // If data is still stored in old format, update localStorage
      if (!persisted.version) {
        lines.push(createPaperPointsForLine(persisted))
        saveFieldCalibrationToLocalStorage(fieldID, lines)
      } else if (persisted.version >= 1) {
        // Turn { x, y } into paper.Point instances.
        lines = persisted.lines.map(createPaperPointsForLine)
      }
    }
  }

  return lines
}

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @param {Function} getAuthorization - Function that returns the user's authorization
 * @returns {Promise<Lines>}
 */
export const getFieldCalibrationFromServer = async (fieldID, getAuthorization) => {
  let lines = []
  const headers = await getUserHeaders()
  const url = `${discovery.API_ANALYTIC}/fieldSize/${fieldID}`
  try {
    const response = await axios.get(url, { headers })

    if (response.status >= 200 && response.status < 300) {
      lines = response.data.lines.map(createPaperPointsForLine)
    }
  } catch (e) {
    console.error('Error while getting field calibration', e)
  }

  return lines
}

export const saveFieldCalibrationToLocalStorage = (fieldID, lines) => {
  // get other lines to be saved along with current line
  const savableLines = lines.map(({ pointA, pointB, distance, name }) => ({
    distance,
    pointA: paperPointToJSON_ableObject(pointA),
    pointB: paperPointToJSON_ableObject(pointB),
    name
  }))

  const localStorageItem = {
    version: dataVersion,
    lines: savableLines
  }

  localStorage.setItem(`${localStorageKey}.${fieldID}`, JSON.stringify(localStorageItem))
}

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @param {Function} getAuthorization - Function that returns the user's authorization
 * @param {Lines} lines - Lines to be saved
 */
export const saveFieldCalibrationToServer = async (fieldID, getAuthorization, lines) => {
  // get other lines to be saved along with current line
  const savableLines = lines.map(({ pointA, pointB, distance, name }) => ({
    distance,
    pointA: paperPointToJSON_ableObject(pointA),
    pointB: paperPointToJSON_ableObject(pointB),
    name
  }))
  const headers = await getUserHeaders()
  const url = `${discovery.API_ANALYTIC}/fieldSize/${fieldID}`
  await axios.post(url, {
    version: dataVersion,
    lines: savableLines
  }, {
    headers
  })
}

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @param {Function} getAuthorization - Function that returns the user's authorization
 * @param {boolean} onlySaveLocally - Flag whether lines should only be saved to localStorage or also to server
 * @param {Lines} lines
 */
export const persistFieldCalibration = async (fieldID, getAuthorization, lines, onlySaveLocally = false) => {
  saveFieldCalibrationToLocalStorage(fieldID, lines)
  if (!onlySaveLocally) {
    await saveFieldCalibrationToServer(fieldID, getAuthorization, lines)
  }
}

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @param {Function} getAuthorization - Function that returns the user's authorization
 * @returns {Promise<Lines>}
 */
export const getPersistedFieldCalibration = async (fieldID, getAuthorization) => {
  // Only fetch from server on initial page load
  if (fieldID && getAuthorization && !alreadyFetchedFromServerForField[fieldID]) {
    const lines = await getFieldCalibrationFromServer(fieldID, getAuthorization)
    saveFieldCalibrationToLocalStorage(fieldID, lines)
    alreadyFetchedFromServerForField[fieldID] = true

    return lines
  } else {
    return getFieldCalibrationFromLocalStorage(fieldID)
  }
}

/**
 * @param {string | number} fieldID - ID of field to get the calibration for
 * @param {Function} getAuthorization - Function that returns the user's authorization
 * @param {number} lineIndex
 * @param {Line} line
 * @param {boolean} onlySaveLocally - Flag whether lines should only be saved to localStorage or also to server
 * @returns {Promise<void>}
 */
export const persistFieldCalibrationLine = async (fieldID, getAuthorization, lineIndex, line, onlySaveLocally = false) => {
  const lines = await getPersistedFieldCalibration(fieldID, getAuthorization)

  lines[lineIndex] = line

  await persistFieldCalibration(fieldID, getAuthorization, lines, onlySaveLocally)
}

/**
 * Calculates meters per pixel based on field calibration
 * @param line
 * @returns {number}
 */
export const getMetersPerPixel = line =>
  line.distance / line.pointA.getDistance(line.pointB)

export const getPersistedMetersPerPixel = async (fieldID, getAuthorization) => {
  const lines = await getPersistedFieldCalibration(fieldID, getAuthorization)

  // Calculate sum of all valid line metersPerPixels and number of valid lines. Later,
  // their average is computed and returned.
  const totalMetersPerPixel = lines.reduce(({ metersPerPixel, numberOfLines }, { pointA, pointB, distance }) => {
    if (pointA && pointB && distance) {
      return {
        metersPerPixel: metersPerPixel + getMetersPerPixel({ pointA, pointB, distance }),
        numberOfLines: numberOfLines + 1
      }
    } else {
      return metersPerPixel
    }
  }, { metersPerPixel: 0, numberOfLines: 0 })

  return totalMetersPerPixel.metersPerPixel / totalMetersPerPixel.numberOfLines
}

/**
 * React hook to provide a state derived from the fieldCalibration (such as lines[lineIndex].name). Depending on
 * params, a call to setState will be persisted to localStorage and/or the server.
 *
 * @param {string | number} fieldID
 * @param {Function} getAuthorization
 * @param {any} initialState
 * @param {Function} mapLinesToTarget - Receives field calibration lines and returns the desired state value
 * @param {Function} targetToLines - Receives current target and lines and returns new lines array
 * @param {{
 *   onlySaveLocally: boolean,
 *   persistStateUpdate: boolean,
 * }} config
 */
export const usePersistedStateDerivedFromConfiguration = (
  fieldID,
  getAuthorization,
  initialState,
  mapLinesToTarget,
  targetToLines,
  config = {}
) => {
  const { onlySaveLocally, persistStateUpdate } = config
  const [state, setState] = useState(initialState)
  const [dataHasBeenLoaded, setDataHasBeenLoaded] = useState(false)
  const [stateHasBeenModified, setStateHasBeenModified] = useState(false)


  useEffect(() => {
    if (fieldID && !dataHasBeenLoaded) {
      getPersistedFieldCalibration(fieldID, getAuthorization)
        .then(mapLinesToTarget)
        .then(result => {
          if (!dataHasBeenLoaded && !stateHasBeenModified) {
            setState(result)
          }

          setDataHasBeenLoaded(true)
        })
    }
  }, [dataHasBeenLoaded, fieldID, getAuthorization, mapLinesToTarget, stateHasBeenModified])

  const persistentSetState = useCallback((newState) => {
    setStateHasBeenModified(true)
    setState(newState)

    if (persistStateUpdate) {
      getPersistedFieldCalibration(fieldID, getAuthorization)
        .then(lines => targetToLines(newState, lines))
        .then(newLines => {
          return persistFieldCalibration(fieldID, getAuthorization, newLines, onlySaveLocally)
        })
    }
  }, [fieldID, getAuthorization, onlySaveLocally, persistStateUpdate, targetToLines])


  return [state, persistentSetState]
}

export const usePersistedLines = (fieldID, getAuthorization, onlySaveLocally) => {
  const [lines, persistentSetLines] = usePersistedStateDerivedFromConfiguration(
    fieldID,
    getAuthorization,
    [],
    useCallback(lines => lines, []),
    useCallback(lines => lines, []),
    { onlySaveLocally, persistStateUpdate: true }
  )

  return [lines, persistentSetLines]
}

/**
 * React hook for a certain attribute of one specific line. Depending on configuration, changes are saved to
 * localStorage and/or server
 *
 * @param {string | number} fieldID
 * @param {Function} getAuthorization
 * @param {boolean} onlySaveLocally
 * @param {boolean} persistStateUpdate
 * @param {number} lineIndex
 * @param {'name' | 'distance'} lineAttribute
 * @param {any} initialState
 */
export const usePersistedLineAttribute = (
  fieldID,
  getAuthorization,
  onlySaveLocally,
  persistStateUpdate,
  lineIndex,
  lineAttribute,
  initialState
) => {
  const linesToAttribute = useCallback(lines => {
    const line = lines[lineIndex] || {}
    return line[lineAttribute]
  }, [lineAttribute, lineIndex])
  const attributeToLines = useCallback((attribute, lines) => {
    if (lines[lineIndex]) {
      lines[lineIndex][lineAttribute] = attribute
    }
    return lines
  }, [lineAttribute, lineIndex])


  const [distance, setDistance] = usePersistedStateDerivedFromConfiguration(
    fieldID,
    getAuthorization,
    initialState,
    linesToAttribute,
    attributeToLines,
    { onlySaveLocally, persistStateUpdate }
  )

  return [distance, setDistance]
}

export const usePersistedDistance = (fieldID, getAuthorization, lineIndex) => usePersistedLineAttribute(
  fieldID,
  getAuthorization,
  true,
  false,
  lineIndex,
  'distance',
  ''
)

export const usePersistedName = (fieldID, getAuthorization, lineIndex) => usePersistedLineAttribute(
  fieldID,
  getAuthorization,
  true,
  false,
  lineIndex,
  'name',
  ''
)


export const usePersistedPoints = (fieldID, getAuthorization, lineIndex) => {
  const lineToPoints = useCallback(lines => {
    const line = lines[lineIndex] || {}
    return [line.pointA, line.pointB]
  }, [lineIndex])

  const pointsToLines = useCallback(([pointA, pointB], lines) => {
    if (lines[lineIndex]) {
      lines[lineIndex].pointA = pointA
      lines[lineIndex].pointB = pointB
    }
    return lines
  }, [lineIndex])

  const [points, setPoints] = usePersistedStateDerivedFromConfiguration(
    fieldID,
    getAuthorization,
    [null, null],
    lineToPoints,
    pointsToLines,
    { onlySaveLocally: true, persistStateUpdate: false }
  )


  return [points, setPoints]
}
