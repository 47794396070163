import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import discovery from '@soccerwatch/discovery'

const env = 'prod' || process.env.REACT_APP_Environment

discovery.init(env).then(() => {
  // console.log(env, discovery)
  ReactDOM.render(<App />, document.getElementById('root'))
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister({ forceWorker: true, onMessage })

async function onMessage (e) {
  console.log('ServiceWorkerMessage', e, e.data)
}
