import discovery from '@soccerwatch/discovery'
import listTillFinished from '../lib/helper/listTillFinished'
import { getUserHeaders } from '../components/firebase'
async function getClubs () {
  let clubs
  try {
    const headers = await getUserHeaders()
    const url = `${discovery.API_CLUB}/clubs/system`
    const response = await listTillFinished(url, headers)
    clubs = response
  } catch (error) {
    console.error(error)
    return { error }
  }
  return clubs.filter((c) => c.RowKey && c.name && c.name !== '')
}

async function getClubsByCity (city) {
  let clubs
  // headers['Content-Type'] = 'Application/Json'
  const headers = await getUserHeaders({
    'Content-Type': 'application/json',
  })
  try {
    const url = `${discovery.API_CLUB}/swClubsByCity/${city}`
    const response = await listTillFinished(url, headers)
    clubs = response
  } catch (error) {
    console.error(error)
    return { error }
  }
  return clubs.filter((c) => c.RowKey && c.name && c.name !== '')
}

export default { getClubs, getClubsByCity }
