import React, { Component } from 'react'
import Toolbar from './sub/toolBar'
import Sidebar from './sub/sidebar'
import './annotaton.scss'
import ContextButtons from './sub/contextButtons'
import { isMobile } from '../../../lib/helper/deviceCatigorisation'
import PropTypes from 'prop-types'
import { VideoController } from '@soccerwatch/videocanvas'

class AnnotationEditor extends Component {
  static propTypes = {
    useVrPlayer: PropTypes.bool,
    video: PropTypes.instanceOf(HTMLVideoElement),
    videoController: PropTypes.instanceOf(VideoController),
    metersPerPixel: PropTypes.number,
  }

  static defaultProps = {
    useVrPlayer: false,
  }

  constructor (props) {
    super(props)
    this.isMobile = isMobile()
    this.wasVideoPlaying = this.props.videoController ? this.props.videoController.isPlaying : false
    this.timeLeftMS = 0
    this.cancleCurrentRedraw = false
    this.state = {
      afterGlow: 10000,
      work: [],
      trashcan: [],
      redrawing: false,
      recordedPath: [],
      recording: false,
      uploading: false,
      videoBuffering: false,
      timeLeft: 0,
      annotationInProgress: false,
      updateToggle: false
    }
    if (this.props.subscribeToVideoTime) {
      this.props.subscribeToVideoTime('AnnotationEditor', this.updateViaVideoTime)
    }
  }

  componentDidUpdate = async (lastProps) => {
    if (lastProps.useVrPlayer !== this.props.useVrPlayer) {
      this.handleVideoPlayerChange()
    }
    if (this.props.videoController && (this.props.videoController.isPlaying !== this.wasVideoPlaying)) {
      this.wasVideoPlaying = this.props.videoController.isPlaying
      if (this.props.videoController.isPlaying) {
        if (this.cancleCurrentRedraw) {
          this.cancleCurrentRedraw = false
          return
        }
        if (this.state.redrawing) {
          this.cancleRedraw()
        }
        if (this.state.recording) {
          this.recordingDone()
          return
        }
        return this.setAnnotationTimeout(false, true)
      }
      return this.removeAnnotationTimeout()
    }
  }

  componentDidMount () {
    this.props.videoParent.subscribeToSeek(this.onSidebarSeek)
    this.props.videoParent.subscribeToBuffering(this.onVideoBuffering, this.onVideoBufferingEnd)
    document.addEventListener('keydown', this.handleKeyDown)
  }

  updateViaVideoTime = () => {
    const updateToggle = !this.state.updateToggle
    this.setState({ updateToggle })
    // if (this.props.video.playbackRate !== 1 && this.state.annotationInProgress) {
    //   this.cancleRedraw()
    // }
  }

  componentWillUnmount () {
    this.state.recording && this.cancleRecording()
    this.state.redrawing && this.cancleRedraw()
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleVideoPlayerChange = () => {
    if (this.state.recording) {
      this.cancleRecording()
    }

    if (this.state.redrawing) {
      this.cancleRedraw()
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.state.redrawing && this.cancleRedraw()
      this.state.recording && this.cancleRecording()
    }
  }

  onVideoBuffering = () => {
    this.buffering = true
    this.setState({ videoBuffering: true })
  }

  onVideoBufferingEnd = () => {
    this.buffering = false
    this.setState({ videoBuffering: false })
  }

  setAnnotationTimeout = (anno = false, willBePlaying = false) => {
    if (this.props.dontPlay || this.props.video.playbackRate !== 1) {
      // Don't do anything
      console.warn('Annotations are not supposed to play')
    } else if (!this.props.annotations || !this.props.annotations.length) {
      console.warn('No Annotations to Play')
    } else if (!this.props.videoController && this.props.videoController.isPlaying && !this.state.redrawing && !willBePlaying) {
      // If this is currently Redrawing another call to setAnnotationTimeout means, the user
      // wishes to See another Annotation now. So dont save Annotation for Later, shedule it now
      console.warn('Not Playing or Redrawing')

      if (anno) {
        this.requestedAnnotation = { ...anno }
      }
    } else {
      if (!anno && this.requestedAnnotation) {
        anno = { ...this.requestedAnnotation }
        this.requestedAnnotation = undefined
      }

      const currentTime = this.props.video.currentTime
      anno = anno || this.props.annotations.find((a) => a.relativeTimestamp >= currentTime)

      if (anno) {
        this.removeAnnotationTimeout()
        const diff = anno.relativeTimestamp - currentTime
        this.timeout = setTimeout(this.playAnnotation(anno), diff * 1000)
      }
    }
  }

  removeAnnotationTimeout = () => {
    if (this.timeout) {
      window.clearTimeout(this.timeout)
      delete this.timeout
    }
  }

  undo = () => {
    const work = this.props.drawingLayer.children
    if (!work.length) {
      return
    }
    const toBeRemoved = work[work.length - 1]
    const bkp = this.pathToSavebleObj(toBeRemoved)
    const tool = this.props.paper.tools.find((t) => {
      return t.name && t.name === toBeRemoved.tool
    })
    if (tool) {
      tool.cleanUp && tool.cleanUp()
    }
    toBeRemoved.remove()
    const trashcan = JSON.parse(JSON.stringify(this.state.trashcan))
    trashcan.push(bkp)

    this.setState({ trashcan, work })
  }

  pathToSavebleObj = (path) => {
    const strokeColor = path.strokeColor ? path.strokeColor.toCSS(false) : 'Red'
    const fillColor = path.fillColor ? path.fillColor.toCSS(false) : undefined
    return {
      ...path.data,
      closed: path.closed,
      strokeColor: strokeColor,
      strokeWidth: path.strokeWidth,
      fillColor: fillColor
    }
  }

  redo = async () => {
    if (!this.state.trashcan.length || this.state.redrawing) {
      return
    }
    const trashcan = JSON.parse(JSON.stringify(this.state.trashcan))
    const itm = trashcan.splice(trashcan.length - 1, 1)
    this.setState({ trashcan, redrawing: true })
    const path = itm[0]
    await this.redrawByMouse(path)
    this.setState({ redrawing: false })
  }

  clear = () => {
    // Only Clear Paths that have been Created though the Annotation Plugin
    if (!this.props.drawingLayer || !this.props.drawingLayer.children) {
      return
    }
    const annotations = this.props.drawingLayer.children.filter((p) => p.data.annotation)
    annotations.forEach((a) => {
      a.remove()
    })
    this.props.paper.tools.forEach((tool) => {
      tool.cleanUp && tool.cleanUp()
    })
    this.setState({ work: [], trashcan: [] })
    // }
  }

  cancleRecording = () => {
    if (!this.state.redrawing) {
      this.clear()
      const handButton = document.querySelector('.HandButton')
      if (handButton) { handButton.click() }
      this.setState({ recording: false })
      this.props.videoParent.playVideo()
      // this.props.enableControls()
    }
  }

  setStateAsync = (state) => {
    return new Promise((resolve, reject) => {
      this.setState(state, resolve)
    })
  }

  prepareAnnotation = async (annotation, drawingLayer) => {
    // const diff = this.props.video.currentTime - annotation.relativeTimestamp
    while (annotation.relativeTimestamp > this.props.video.currentTime + 0.2) {
      await this.requestAnimationFrameAsync()
    }

    this.props.videoParent.pauseVideo()
    await this.awaitVideoSeeked(annotation.relativeTimestamp)

    drawingLayer.activate()
    await this.setStateAsync({ redrawing: true })
    // this.props.disableControls()
    // Time is in drawing[x].mouse[y].time, So a DoubleReduce is Used to Accumulate all Neded Time
    const timeLeftMS = annotation.drawings
      .reduce((time, drawing) => {
        if (!drawing.skipAnimatedRedraw) {
          time += drawing.mouse.reduce((time, mouse) => time + mouse.time, 0)
        }
        return time
      }, this.state.afterGlow)
    await this.wait(500)

    const timeLeft = Math.round(timeLeftMS / 1000)
    this.countdown(timeLeft, true)
  }

  drawAnnotationParts = async (annotation) => {
    for (const path of annotation.drawings) {
      await this.redrawByMouse(path)
    }
  }

  cleanupAnnotation = async (mainLayer) => {
    if (!this.cancleCurrentRedraw) {
      await this.cancleableWait(this.state.afterGlow, 200)
    }

    this.stopCountdown()
    this.clear()

    mainLayer.activate()
    this.timeLeftMS = 0
    if (this.cancleCurrentRedraw) {
      this.cancleCurrentRedraw = false
    }
    this.seekTo(this.props.video.currentTime + 0.2)
    this.props.videoParent.playVideo()
    const handTool = this.props.paper.tools.find((t) => t.name === 'Hand')
    if (handTool) {
      handTool.activate()
    } else {
      console.error('<AnnotationEditorPlugin> Cloud Not set HandTool after Redraw')
    }

    this.setState({ redrawing: false })
  }

  playAnnotation = (annotation) => async () => {
    if (this.props.video.playbackRate !== 1) {
      return
    }
    if (!this.state.annotationInProgress) {
      await this.setStateAsync({ annotationInProgress: true })

      try {
        const drawingLayer = this.props.drawingLayer // mainLayer.children.find((c) => c.data.role === 'special')
        const mainLayer = drawingLayer.parent

        await this.prepareAnnotation(annotation, drawingLayer)
        await this.drawAnnotationParts(annotation)
        await this.cleanupAnnotation(mainLayer)
      } catch (error) {
        console.error(error)
      } finally {
        this.setState({ annotationInProgress: false })
      }
    }
  }

  seekTo = (time) => {
    if (this.props.video) {
      this.props.video.currentTime = time
    }
  }

  awaitVideoSeeked (time) {
    return new Promise((resolve, reject) => {
      const callOnce = () => {
        this.props.video.removeEventListener('seeked', callOnce)
        resolve()
      }
      this.props.video.addEventListener('seeked', callOnce)
      this.seekTo(time)
    })
  }

  redrawByMouse = async (path) => {
    const tool = this.props.paper.tools.find((t) => {
      return t.name && t.name === path.tool
    })
    if (!tool) {
      console.error('Redraw of', path, 'Failed: Tool', path.tool, 'Is Unknown')
      return
    }
    tool.activate()
    const data = JSON.parse(JSON.stringify(path))
    await this.batchDraw(data, tool)
    tool.cleanUp && tool.cleanUp(true)
  }

  batchDraw = async (data, tool, start = new Date().getTime(), sum = 0, first = true) => {
    await this.requestAnimationFrameAsync()
    const parts = data.mouse
    const timeElapsed = new Date().getTime() - start
    while (sum < timeElapsed) {
      const value = parts.shift()
      if (!value) {
        return
      }
      value.event = { button: 0 }
      if (!data.skipAnimatedRedraw) {
        this.timeLeftMS = this.timeLeftMS - value.time
        sum += value.time
      }
      const type = value.type
      if (type === 'onMouseDown' || (!type && first)) {
        tool.onMouseDown(value, { ...data })
        first = false
      } else
      if (type === 'onMouseDrag' || (!type && parts.length >= 1)) {
        tool.onMouseDrag(value, { ...data })
      } else if (type && type === 'onKeyDown') {
        tool.onKeyDown(value, { ...data })
      } else {
        tool.onMouseUp(value, { ...data })
        if (!type) {
          sum = 9999999999999
        }
      }
    }
    // const timeLeft = Math.ceil(this.timeLeftMS / 1000)
    // if (timeLeft !== this.state.timeLeft) {
    //   this.setState({ timeLeft })
    // }
    if (!parts.length) {
      return
    }
    if (this.cancleCurrentRedraw) {
      this.timeLeftMS = 0
      this.setState({ timeLeft: 0 })
      return
    }
    return this.batchDraw(data, tool, start, sum, false)
  }

  countdown = (time, initialCall = false) => {
    if (time !== 0 || initialCall) {
      this.setState({ timeLeft: time })
      this.countdownTimeout = setTimeout(() => {
        this.countdown(time - 1)
      }, 1000)
    }
  }

  stopCountdown = () => {
    clearTimeout(this.countdownTimeout)
    this.countdownTimeout = null
    this.setState({ timeLeft: 0 })
  }

  requestAnimationFrameAsync = () => {
    return new Promise((resolve) => {
      return window.requestAnimationFrame(resolve)
    })
  }

  record = () => {
    if (!this.state.recording && !this.state.redrawing && !this.state.videoBuffering) {
      this.clear()
      this.props.videoParent.pauseVideo()
      if (this.props.disableControls) {
      }
      this.setState({ recording: true, trashcan: [] })
    }
  }

  recordingDone = async () => {
    if (this.state.uploading || !this.state.recording) {
      return
    }
    const work = this.props.drawingLayer.children
    if (!work.length) {
      return this.setState({ recording: false }, () => {
        try {
          this.props.videoParent.playVideo()
        } catch (err) {
          console.error('You seem to be on an AppleDevice, hence i am not allowed to resume playing your Video, because "Security" and Stuff... Yeah, Tough Shit')
        }
      })
    }
    this.setState({ uploading: true })
    const drawings = work
      .filter(p => p.data.annotation)
      .map((path) => {
        const anno = this.pathToSavebleObj(path)
        delete anno.annotation
        return anno
      })
    this.clear()
    if (!drawings.length) {
      // this.props.enableControls()
      this.setState({ uploading: false, recording: false })
      try {
        this.props.videoParent.playVideo()
      } catch (err) {
        console.error('You seem to be on an AppleDevice, hence i am not allowed to resume playing your Video, because "Security" and Stuff... Yeah, Tough Shit')
      }
      return
    }
    const annotation = {
      drawings
    }
    await this.uploadAnnotation(annotation)

    // this.clear()
    // this.props.enableControls()
    const handButton = document.querySelector('.HandButton')
    if (handButton) { handButton.click() }
    this.setState({ uploading: false, recording: false, work })
    this.seekTo(this.props.video.currentTime + 0.1)
    try {
      this.props.videoParent.playVideo()
    } catch (err) {
      console.error('You seem to be on an AppleDevice, hence i am not allowed to resume playing your Video, because "Security" and Stuff... Yeah, Tough Shit')
    }
  }

  uploadAnnotation = async (anno) => {
    const currentTime = this.props.video.currentTime
    const sequence = this.props.sequences.find((seq) => {
      return seq.relativeStartTime <= currentTime && currentTime <= seq.relativeEndTime
    })
    if (!sequence) {
      console.error(`Could not find Sequence! Please Cheack code of UploadAnnotation()`)
      return
    }
    anno.relativeTimestamp = currentTime !== 0 ? currentTime : 0.1
    anno.sequenceOffset = currentTime - sequence.relativeTimestamp
    anno.timestamp = sequence.timestamp + anno.sequenceOffset
    anno.name = `Anmerkung ${sequence.annotationIds.length + 1}`
    anno.type = this.props.useVrPlayer ? 'Vr' : 'Basic'

    const annotation = await this.props.uploadAnnotation(sequence.RowKey, anno)
    sequence.annotationIds.push(annotation.RowKey)
    if (!sequence.annotations) {
      sequence.annotations = []
    }
    sequence.annotations.push(annotation)
    return this.props.updateSequence(sequence)
  }

  cancleRedraw = () => {
    this.cancleCurrentRedraw = true
  }

  onSidebarSeek = (annotation = false) => {
    this.state.recording && this.cancleRecording()
    this.state.redrawing && this.cancleRedraw()
    this.setAnnotationTimeout(annotation)
  }

  requestAnnotation = (annotation) => {
    this.requestedAnnotation = { ...annotation }
  }

  wait = (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, time)
    })
  }

  cancleableWait = async (time, checkInterval = 200) => {
    let timeLeft = time 
    while(timeLeft > 0 && !this.cancleCurrentRedraw) {
      const then = new Date().getTime()
      await this.wait(checkInterval)
      const now = new Date().getTime()
      timeLeft = timeLeft - (now - then)
    }
    return true
  }

  updateWorkState = () => {
    const work = this.props.drawingLayer ? this.props.drawingLayer.children : []
    this.setState({ work })
  }

  render () {
    const work = this.props.drawingLayer ? this.props.drawingLayer.children : []
    const currentTime = this.props.video ? this.props.video.currentTime : 0
    return <>
      <Sidebar
        noToggle={this.props.noToggle}
        items={this.props.sequences}
        // show={this.props.showSidebar}
        ViewMode={this.props.ViewMode}
        useAbsoluteTimestamps={this.props.useAbsoluteTimestamps}
        editSingleSequence={this.props.editSingleSequence}
        label='Anmerkung'
        updateAnnotation={this.props.updateAnnotation}
        updateSequence={this.props.updateSequence}
        sidebarContainer={this.props.sidebarContainer}
        sidebarContent={this.props.sidebarContent}
        video={this.props.video}
        loading={this.props.sequencesLoading}
        requestAnnotation={this.requestAnnotation}
        clipEditor={this.props.clipEditor}
        videoEditor={this.props.videoEditor}
        sequenceEditor={this.props.sequenceEditor}
        user={this.props.user}
      >
        <Toolbar
          Paper={this.props.paper}
          videoBuffering={this.state.videoBuffering}
          toolbarContainer={this.props.toolbarContainer}
          quickbarContainer={this.props.quickbarContainer}
          actionsContainer={this.props.actionsContainer}
          updateWorkState={this.updateWorkState}
          undo={this.undo}
          redo={this.redo}
          clear={this.clear}
          recording={this.state.recording}
          record={this.record}
          canUndo={Boolean(work && work.length)}
          canRedo={Boolean(this.state.trashcan && this.state.trashcan.length)}
          cancleRecording={this.cancleRecording}
          recordingDone={this.recordingDone}
          redrawing={this.state.redrawing}
          drawMode={this.props.drawMode}
          drawingLayer={this.props.drawingLayer}
          color={this.props.color}
          setColor={this.props.setColor}
          stroke={this.props.stroke}
          setStroke={this.props.setStroke}
          videoParent={this.props.videoParent}
          activateTouch={this.props.activateTouch}
          deactivateTouch={this.props.deactivateTouch}
          isMobile={this.isMobile}
          useVrPlayer={this.props.useVrPlayer}
          metersPerPixel={this.props.metersPerPixel}
        />
      </Sidebar>
      {!this.props.ViewMode &&
      <ContextButtons
        RenderToElement={this.props.ContextButtonsContainer}
        match={this.props.match}
        video={this.props.video}
        sequences={this.props.sequences}
        sequencesLoading={this.props.sequencesLoading}
        drawMode={this.props.drawMode}
        useAbsoluteTimestamps={this.props.useAbsoluteTimestamps}
        currentTime={currentTime}
        history={this.props.history}
        createSequence={this.props.createSequence}
        updateSequence={this.props.updateSequence}
        recording={this.state.recording}
        recordingDone={this.recordingDone}
        redrawing={this.state.redrawing}
        cancleRedraw={this.cancleRedraw}
        cancleRecording={this.cancleRecording}
        sequenceEditor={this.props.sequenceEditor}
        clipEditor={this.props.clipEditor}
        tagMode={this.props.matchEditor}
        tagDefinitions={this.props.tagDefinitions}
      />
      }
      {Boolean(this.state.timeLeft) && <div className='editorNotice timeleft'>
        <h1>{this.state.timeLeft}</h1>
      </div> }
      {this.state.recording && <div className='editorNotice recIndicator'><div className='redDot blink' /></div> }
    </>
  }
}

export default AnnotationEditor
