import React from 'react'
import PathMap from './PathMap'

export default function Content (props) {
  return <div id='Content' className={`col-xs outerContent ${props.className}`}>
    <div className='row'>
      <div className='innerContent'>
        {props.enablePathMap && <PathMap history={props.history} />}
        {props.children}
      </div>
    </div>
  </div>
}
