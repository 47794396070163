export default {
  'status': 200,
  'Corners': {
    'corners': [
      488,
      1168,
      1053,
      1586,
      2664,
      308,
      3425,
      2311
    ],
    'panorama_corners': [
      0.057131690979003905,
      0.5774564615885417,
      0.24403560638427735,
      0.2958194647894965,
      0.7434516906738281,
      0.2759216478135851,
      0.9177915954589844,
      0.5296615939670138
    ]
  }
}
    