import React from 'react'
import '../item.scss'
import materialColor from 'material-color-hash'
import mobiscroll from '@mobiscroll/react'

const prompt = (callback, value = '') => () => {
  return mobiscroll.prompt({
    placeholder: 'Anmerkung Benennen',
    inputType: 'text',
    value,
    callback
  })
}

const showConfirm = (callback) => (e) => {
  e.stopPropagation()
  return mobiscroll.confirm({
    title: 'Sind sie Sicher?',
    okText: 'Löschen',
    cancelText: 'Abbrechen',
    callback
  })
}

export default function AnnotationItem (props) {
  const { annotation, deleteAnnotation
    // , disableEdit,
  } = props
  function updateAnnotationName (name) {
    if (!name || name === '' || name === annotation.name) {
      return
    }
    const update = { ...annotation, name }
    props.updateAnnotation(update)
  }
  const handleElementClick = () => {
    if (props.onClick) {
      props.onClick(annotation)
      if (props.afterSeek) {
        props.afterSeek()
      }
    }
  }

  const _deleteAnnotation = async (e) => {
    if (deleteAnnotation && typeof deleteAnnotation === 'function') {
      await deleteAnnotation(annotation)
    }
  }

  const timestamp = props.useRelativeTime ? annotation.relativeTimestamp : annotation.timestamp
  const time = props.showFullTime ? `${pad(Math.floor(timestamp / 60))}:${pad(Math.floor(timestamp % 60))}` : `${pad(Math.round(timestamp / 60))}´`
  const colorCode = props.useColor ? materialColor(timestamp + '').backgroundColor : 'transparent'
  const style = props.useColor ? { borderLeft: `5px solid ${colorCode}`, borderRadius: '0.25rem' } : {}
  const nameFn = props.disableRename ? () => {} : prompt(updateAnnotationName, annotation.name)
  return (
    <React.Fragment key={annotation.RowKey}>
      <div className='annotationItem'>
        <div className='annotationItemContent'>
          <div className='basicData'>
            <div className='minuteLabel' style={style}>
              <span className='btn boyzone' onClick={handleElementClick}>{time}</span>
            </div>
            <div className='annotationData'>
              <span className='annotationName' onClick={nameFn}>
                {annotation.name}&nbsp;
                {!props.disableRename && <span className='mbsc-ic mbsc-ic-material-edit' />}
                {!!deleteAnnotation && <span className='removeBtn' style={{ marginLeft: 'auto', marginBottom: '0' }} ><span onClick={showConfirm(_deleteAnnotation)} className='mbsc-ic mbsc-ic-material-close warning' /></span> }
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
const pad = number => number <= 99 ? `0${number}`.slice(-2) : number
