import React from 'react'
import materialColor from 'material-color-hash'
import * as LevenDist from 'fast-levenshtein'
import i18n from 'i18next'
/*
const config = [
    {
        property: name,
        type: text
    },
    {
        property: date,
        type: date,
    },
    {
        property: label,
        type: option,
        options: knownLabel
    },
    {
        property: leagues,
        type: option,
        options: knownleagues
    },
    {
        property: clubs,
        type: option,
        options: knownClubs
    }
]

*/

function applyOmniFilter (list, config, filterState, filterPrefix = 'filter', copylist = true) {
  if (!list) {
    console.warn(`<OmniFilter>Could Not Read List ${list}`)
    return []
  }
  const listCopy = copylist ? JSON.parse(JSON.stringify(list)) : list
  const output = config.reduce((remaining, propConf) => {
    const property = propConf.property
    const prefix = propConf.filterType || filterPrefix
    const filterProp = `${prefix}_${property}`
    const filter = filterState[filterProp]
    if (!filter || !filter.length || filter === '') {
      return remaining
    }
    switch (propConf.type) {
      case 'text':
        return remaining.filter((obj) => {
          const val = obj[property]
          if (val === undefined) {
            console.error(`<OmniFilter> Configured Property "${property}" Nonexisting on obj ${JSON.stringify(obj)}`)
            return true
          }
          return filter.some((filterVal) => val.toLowerCase().includes(filterVal.toLowerCase()))
        })
      case 'date':
        // TODO: FIX ME OR LEAVE ME TO DIE...
        // Currently The Date Filter is realised with a text Property Filter. Seeme more Efficient
        return remaining.filter((obj) => {
          const val = new Date(obj[property])
          return filter.reduce((isValid, filter) => {
            const tmp = filter.split('=')
            const prop = tmp[0]
            const filterVal = parseInt(tmp[1], 10)
            const testAgainst = prop === 'day' ? val.getDate() : prop === 'month' ? (val.getMonth() + 1) : val.getFullYear()
            const filterValid = filterVal === testAgainst
            return isValid || filterValid
          }, false)
        })
      case 'bool':
        return remaining.filter((obj) => {
          const val = obj[property]
          if (val === undefined) {
            console.error(`<OmniFilter> Configured Property ${property} Nonexisting on obj ${JSON.stringify(obj)}`)
            return true
          }
          return filter.includes(val)
        })
      case 'option':
        return remaining.filter((obj) => {
          const val = obj[property]
          if (val === undefined) {
            console.error(`<OmniFilter> Configured Property ${property} Nonexisting on obj ${JSON.stringify(obj)}`)
            return true
          }
          if (propConf.singleValue) {
            return filter.some((filterVal) => val.toLowerCase().includes(filterVal.toLowerCase()))
          }
          return val.some((value) => filter.includes(value))
        })
      default:
        return remaining
    }
  }, listCopy)

  return output
}

function OmniFilterUi (props) {
  const { filterPrefix = 'filter', filters, onFilterRemove, onFilterAdd, config } = props

  const [currentInput, changeCurrentIntput] = React.useState('')
  const [selection, changeCurrentSelection] = React.useState(-1)
  function onInputChange (e) {
    changeCurrentIntput(e.currentTarget.value)
    changeCurrentSelection(-1)
  }
  const removeFilterTag = (value, type, filterType) => () => {
    const prefix = filterType || filterPrefix
    const prop = `${prefix}_${type}`
    onFilterRemove(prop, value)
  }

  const addFilterTag = (label, filterType = false, subType) => () => {
    const propNVal = label.split(' = ')
    const prefix = filterType || filterPrefix
    const prop = `${prefix}_${propNVal[0]}`
    let value = propNVal[1]
    let isBool = false
    if (value === '*true*') {
      value = true
      isBool = true
    } else if (value === '*false*') {
      value = false
      isBool = true
    }
    changeCurrentIntput('')
    onFilterAdd(prop, value, isBool, subType)
  }

  function getSuggestions () {
    const results = config.map((propConf, i) => {
      const color = materialColor(propConf.property, 900)
      let label, showLabel, showInput, showOption, component
      let distance = 9999999
      switch (propConf.type) {
        case 'text':
          if (propConf.requirement === 'number') {
            if (isNaN(currentInput)) {
              return { component: null, distance: 99999999 }
            }
          }
          if (propConf.requirement === 'date') {
            const numbers = currentInput.split('.')
            const isNumber = numbers.reduce((is, number) => is && !isNaN(number), true)
            if (!isNumber) {
              return { component: null, distance: 99999999 }
            }
          }
          label = `${propConf.property} = ${currentInput}`
          showLabel = `${propConf.label}`
          showInput = `${currentInput}`
          component = <small key={label} className='getSuggestionsResults' onClick={addFilterTag(label, propConf.filterType)}>
            <span className='showLabel' style={color}>{showLabel}</span>
            <span className='showInput'>{showInput}</span>
          </small>
          // distance = LevenDist.get(currentInput, currentInput)
          distance = propConf.sortDistance || 99
          return { distance, component }
        case 'date':
          const results = []
          if (isNaN(currentInput)) {
            component = null
            distance = 99999999
          } else {
            const inputNumber = parseInt(currentInput, 10)
            if (currentInput.length <= 2 && inputNumber < 12) {
              const key = `${propConf.property}.DAY = ${currentInput}`
              const labelDay = `${propConf.property} = ${currentInput}`
              showLabel = `${propConf.label}`
              showInput = `${currentInput}`
              component = <small key={key} className='getSuggestionsResults' onClick={addFilterTag(labelDay, propConf.filterType, 'day')}>
                <span className='showLabel' style={color}>Tag</span>
                <span className='showInput' >{showInput}</span>
              </small>
              results.push({ component, distance: 2 })
            }
            if (currentInput.length <= 2 && inputNumber < 32) {
              const key = `${propConf.property}.Month = ${currentInput}`
              const labelMonth = `${propConf.property} = ${currentInput}`
              showLabel = `${propConf.label}`
              showInput = `${currentInput}`
              component = <small key={key} className='getSuggestionsResults' onClick={addFilterTag(labelMonth, propConf.filterType, 'month')}>
                <span className='showLabel' style={color}>Monat</span>
                <span className='showInput'>{showInput}</span>
              </small>
              results.push({ component, distance: 2 })
            }
            const currentYear = new Date().getFullYear()
            if (currentInput.length <= 4 && inputNumber <= currentYear && inputNumber > currentYear - 100) {
              const key = `${propConf.property}.Year = ${currentInput}`
              const labelYear = `${propConf.property} = ${currentInput}`
              showLabel = `${propConf.label}`
              showInput = `${currentInput}`
              component = <small key={key} className='getSuggestionsResults' onClick={addFilterTag(labelYear, propConf.filterType, 'year')}>
                <span className='showLabel' style={color}>Jahr</span>
                <span className='showInput' >{showInput}</span>
              </small>
              results.push({ component, distance: 2 })
            }
          }
          if (!results.length) {
            return null
          }
          return results
        case 'option':
          return propConf.options.map((option) => {
            if (!option || !option.toLowerCase().includes(currentInput.toLowerCase())) {
              return { distance: 99999999, component: null }
            }
            const prefix = propConf.filterType || propConf.filterPrefix
            const prop = `${prefix}_${propConf.property}`
            const filterState = filters[prop] || []
            if (filterState.includes(option)) {
              return { distance: 99999999, component: null }
            }
            label = `${propConf.property} = ${option}`
            showLabel = `${propConf.label}`
            showOption = `${option}`
            component = <small key={label} className='getSuggestionsResults' onClick={addFilterTag(label, propConf.filterType)}>
              <span className='showLabel' style={color}>{showLabel}</span>
              <span className='showOption' >{showOption}</span>
            </small>
            distance = LevenDist.get(currentInput, option)
            return { distance, component }
          })
        case 'bool':
          const inKeyWord = propConf.keyWords.filter(word => word.toLowerCase().includes(currentInput.toLowerCase()))
          if (!inKeyWord || !inKeyWord.length) { return { distance: 99999999, component: null } }
          const labelOn = `${propConf.property} = *true*`
          const labelOff = `${propConf.property} = *false*`
          const componentOn = <small key={label} className='getSuggestionsResults' onClick={addFilterTag(labelOn, propConf.filterType)}>
            <span className='showLabel' style={color}>{propConf.label}</span>
            <span className='showInput' >Ja</span>
          </small>
          const componentOff = <small key={label} className='getSuggestionsResults' onClick={addFilterTag(labelOff, propConf.filterType)}>
            <span className='showLabel' style={color}>{propConf.label}</span>
            <span className='showInput' >Nein</span>
          </small>
          distance = inKeyWord.reduce((closest, keyWord) => {
            const dist = LevenDist.get(currentInput, keyWord)
            return dist < closest ? dist : closest
          }, 9999999)
          return [{ distance, component: componentOn }, { distance, component: componentOff }]
        default:
          console.error(`<OmniFilter> Missing Case for PropConfig.type ${propConf.type}`)
          return { distance: 99999999, component: null }
      }
    })
    const options = results.flat().filter((r) => !!r).sort((a, b) => a.distance - b.distance).filter(a => !!a.component).map((a, i) => {
      const component = i === selection && a.component ? React.cloneElement(a.component, {
        ...a.props,
        className: 'getSuggestionsResults selected'
      }) : a.component
      return component
    })
    return options
  }

  function getSingleFilterTag (value, config) {
    let showLabel = value
    if (value === true) {
      showLabel = 'Ja'
    } else if (value === false) {
      showLabel = 'Nein'
    }
    const color = materialColor(config.property, 900)
    return <small onClick={removeFilterTag(value, config.property, config.filterType)} key={showLabel} className='FilterTag' style={color}>
      <span className='labelTag' >{config.label}: {showLabel}</span>
      <span className='mbsc-ic mbsc-ic-fa-times' />
    </small>
  }

  const filterTags = config.map((propConf) => {
    const prefix = propConf.filterType || filterPrefix
    const propertyLabel = `${prefix}_${propConf.property}`
    const value = filters[propertyLabel]
    if (value === '' || value === null || value === 'null' || value === undefined) {
      return false
    }
    if (Array.isArray(value)) {
      return value.map((val) => {
        return getSingleFilterTag(val, propConf)
      })
    } else {
      return getSingleFilterTag(value, propConf)
    }
  }).flat().filter((i) => Boolean(i))
  const suggestions = currentInput.length ? getSuggestions() : null

  function onKeyDown (e) {
    if (!suggestions || !suggestions.length) {
      return
    }
    switch (e.keyCode) {
      // Arrow Up Key
      case 38:
        if (selection < 0) { return changeCurrentSelection(suggestions.length - 1) }
        return changeCurrentSelection(selection - 1)
      // Arrow Down Key
      case 40:
        if (selection > suggestions.length - 1) { return changeCurrentSelection(0) }
        return changeCurrentSelection(selection + 1)
      // Enter Key
      case 13:
        const item = document.querySelector('.getSuggestionsResults.selected')
        if (item) {
          item.click()
          changeCurrentSelection(-1)
        }
        break
      default:
    }
  }

  React.useEffect(() => {
    const item = document.querySelector('.getSuggestionsResults.selected')
    if (item) {
      item.scrollIntoView(false)
    }
  })

  return <><div className='OmniFilter'>
    <div className='FilterTagBar'>
      <input placeholder={i18n.t('omniFilter.search')} value={currentInput} onChange={onInputChange} onKeyDown={onKeyDown} />
    </div>
    {suggestions && !!suggestions.length &&
      <div className='suggestionBox'>
        {suggestions}
      </div>
    }
  </div>
    <div>
      {filterTags && !!filterTags.length && (<p className='activefilter'>aktive Filter:</p>)}
      {filterTags}
    </div></>
}

export default { OmniFilterUi, applyOmniFilter }
export { OmniFilterUi, applyOmniFilter }
