import React from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
import { ToolWithLineLength } from './ToolWithLineLength'

class Rectangle extends ToolWithLineLength {
  /**
   * @type {DisplayLengthAboveLine[]}
   */
  lengthAboveLines

  onMouseDown = (evt, path, redraw) => {
    this.min = evt.localPoint
    path = new this.props.Paper.Path.Rectangle(this.min, this.min)

    this.lengthAboveLines = [
      this.generateLineLengthIndicator(evt.localPoint, redraw && redraw.strokeColor ? redraw.strokeColor : this.props.color),
      this.generateLineLengthIndicator(evt.localPoint, redraw && redraw.strokeColor ? redraw.strokeColor : this.props.color),
    ]

    return { path }
  }

  onMouseDrag = (evt, path) => {
    this.max = evt.localPoint
    const Point = this.props.Paper.Point
    path.segments[0].point.set(this.max)
    path.segments[1].point.set(new Point(this.max.x, this.min.y))
    path.segments[2].point.set(this.min)
    path.segments[3].point.set(new Point(this.min.x, this.max.y))

    const segmentIndexToPoint = (indexes) => indexes.map(index => path.segments[index].point)

    const leftPoints = this.max.y > this.min.y ? [1, 2] : [3, 0]
    const topPoints = this.max.x > this.min.x ? [2, 3] : [0, 1]

    this.lengthAboveLines[0].setPoints(...segmentIndexToPoint(leftPoints))
    this.lengthAboveLines[1].setPoints(...segmentIndexToPoint(topPoints))

    return { path }
  }

  onMouseUp = (evt, path) => {
    return { path }
  }

  componentWillUnmount = this.clearIndicators

  render () {
    return (
      <ProtoTool
        key={`Rectangle ${this.generateNameSuffix()}`}
        toolName={`Rectangle ${this.generateNameSuffix()}`}
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        cleanUp={this.clearIndicators}
        dontCleanOnChange
        {...this.props}
      >
        <span className={`mbsc-ic mbsc-ic-material-${
          this.props.showLineLength ? 'aspect-ratio' : 'crop-square'
        }`} />
      </ProtoTool>
    )
  }
}

export default Rectangle
