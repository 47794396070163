import currentlyGlobalPaper from 'paper'

export class CornerMarkerTool {
  /**
   * @private
   * @type {currentlyGlobalPaper.Tool}
   */
  _tool

  /**
   * @private
   * @type {currentlyGlobalPaper.PaperScope}
   */
  _paper

  /**
   * @private
   * @readonly
   */
  _toolName = 'CornerMarker'

  /**
   * @private
   * @type {currentlyGlobalPaper.Color}
   */
  _color

  /**
   * @private
   * @type {currentlyGlobalPaper.Path | currentlyGlobalPaper.Group}
   */
  _pointer

  /**
   * @private
   * @type {() => void}
   */
  _setPointCallback

  /**
   * @private
   * @type {() => void}
   */
  _activateTouch

  /**
   * @private
   * @type {() => void}
   */
  _deactivateTouch

  /**
   * @private
   * @type {boolean}
   */
  _touchWasDragged = false

  constructor(paper, setPoint, initialPoint, activateTouch = () => {}, deactivateTouch = () => {}, color = new currentlyGlobalPaper.Color(1, 0, 0)) {
    this._paper = paper
    this._color = color
    this._setPointCallback = setPoint
    this._activateTouch = activateTouch
    this._deactivateTouch = deactivateTouch
    this._setup(initialPoint)
  }

  activate = () => {
    this._tool.activate()
    this._deactivateTouch()
  }

  clear = () => {
    this._cleanUp()
    this._tool.remove()
  }

  /**
   * @public
   * @param {'up' | 'down' | 'left' | 'right'} direction
   */
  move = (direction, delta = 1) => {
    switch (direction) {
      case 'up':
        this._pointer.position.y -= delta
        break
      case 'down':
        this._pointer.position.y += delta
        break
      case 'left':
        this._pointer.position.x -= delta
        break
      case 'right':
        this._pointer.position.x += delta
        break
      default:
        break
    }
  }

  /**
   * @private
   * @param {currentlyGlobalPaper.Point} initialPoint
   */
  _createTrianglePointer = (initialPoint) => {
    const paper = this._paper

    const radius = 15
    const trianglePointingUp = new paper.Path.RegularPolygon(new paper.Point(0, 0), 3, radius)
    trianglePointingUp.strokeColor = this._color
    trianglePointingUp.fillColor = this._color

    const trianglePointingDown = trianglePointingUp.clone()
    const trianglePointingLeft = trianglePointingUp.clone()
    const trianglePointingRight = trianglePointingUp.clone()
    trianglePointingLeft.rotate(-90)
    trianglePointingRight.rotate(90)
    trianglePointingDown.rotate(180)

    const offsetFromCenter = radius + 3

    trianglePointingUp.position.y += offsetFromCenter
    trianglePointingDown.position.y -= offsetFromCenter
    trianglePointingLeft.position.x += offsetFromCenter
    trianglePointingRight.position.x -= offsetFromCenter

    const triangles = [trianglePointingUp, trianglePointingDown, trianglePointingRight, trianglePointingLeft]

    triangles.forEach(t => t.remove())

    const group = new paper.Group(triangles)
    group.position = initialPoint

    this._pointer = group
  }

  /**
   * @private
   */
  _setup = (initialPoint = new this._paper.Point(-30, -30)) => {
    let oldActiveScope, oldActiveLayer

    if (currentlyGlobalPaper !== this._paper) {
      oldActiveScope = currentlyGlobalPaper
      oldActiveLayer = currentlyGlobalPaper.project ? currentlyGlobalPaper.project.activeLayer : { activate: () => {} }
      this._paper.activate()
    }

    this._tool = new this._paper.Tool()

    if (oldActiveLayer) {
      oldActiveScope.activate()
      oldActiveLayer.activate()
    }

    this._tool.name = this._toolName
    this._tool.onMouseDown = this._onMouseDown
    this._tool.onMouseDrag = this._onMouseDrag
    this._tool.onMouseUp = this._onMouseUp
    this._tool.on('touchmove', this._onTouchMove)
    this._tool.on('touchend', this._onTouchEnd)
    this._tool.cleanUp = this._cleanUp

    this._createTrianglePointer(initialPoint)

    // this._path = new this._paper.Path.Circle(initialPoint, 10)
    // this._path.strokeColor = this._color
    // this._path.fillColor = this._color
  }

  /**
   * @private
   */
  _cleanUp = () => {
    if (this._pointer) {
      const removed = this._pointer.remove()
      if (!removed) {
        console.warn(`CornerMarkerTool was not removed because it's already been removed! This strongly suggests some sort of bug`)
      }
    }

    this._activateTouch()
  }

  /**
   * @private
   * @param {currentlyGlobalPaper.Point} point
   * @param {string} eventType
   */
  _setPoint = (point, eventType) => {
    this._setPointCallback(point, eventType)
    if (this._pointer) {
      this._pointer.position = point
    } else {
      console.error('Circle has not been created!')
    }
  }

  /**
   * @private
   * @param {currentlyGlobalPaper.MouseEvent} evt
   */
  _onMouseDown = (evt) => {
    if (evt.event.button !== 0) {
      return
    }
    evt.stopPropagation()

    this.painting = true

    // Basic Setup
    if (!evt.localPoint) {
      const layer = this._paper.project.activeLayer
      evt.localPoint = layer.globalToLocal(evt.point).round()
    } else if (Array.isArray(evt.localPoint) && evt.localPoint[0] === 'Point') {
      evt.localPoint = new this._paper.Point(evt.localPoint[1], evt.localPoint[2])
    }

    this._setPoint(evt.localPoint, 'mouseDown')
  }

  /**
   * @private
   * @param {currentlyGlobalPaper.MouseEvent} evt
   */
  _onMouseDrag = (evt) => {
    // drag event doesn't have any "triggering" button, so event.button has no
    // useful value
    if (evt.event.which === 1) {
      if (!evt.localPoint) {
        const layer = this._paper.project.activeLayer
        evt.localPoint = layer.globalToLocal(evt.point).round()
      }

      evt.stopPropagation()

      this._setPoint(evt.localPoint, 'moseDrag')
    }
  }

  /**
   * @private
   */
  _onTouchMove = () => {
    this._touchWasDragged = true
  }

  /**
   * @private
   * @param {React.MouseEvent} evt
   */
  _onMouseUp = async (evt, force = false) => {
    if (force || evt.event.button === 0) {
      if (!evt.localPoint) {
        const layer = this._paper.project.activeLayer
        evt.localPoint = layer.globalToLocal(evt.point).round()
      }

      evt.stopPropagation()
      this._activateTouch()

      this._setPoint(evt.localPoint, 'mouseUp')
    }
  }

  /**
   * @private
   */
  _onTouchEnd = (wrapped) => {
    if (!this._touchWasDragged) {
      wrapped.point = wrapped.event.changedTouches.item(0).positionInVideo
      this._onMouseUp(wrapped, true)
    }

    this._touchWasDragged = false
  }
}
