import React, { Component } from 'react'
// import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import ProtoTool from './prototool'
class Circle extends Component {
  onMouseDown = (evt, path) => {
    this.center = evt.localPoint
    return { path }
  }

  onMouseDrag = (evt, path) => {
    const point = evt.localPoint.getDistance ? evt.localPoint : new this.props.Paper.Point(evt.localPoint.x, evt.localPoint.y)
    this.radius = point.getDistance(this.center)
    const mouseBkp = path.data.mouse.splice(0)
    const stroke = path.strokeWidth
    const color = path.strokeColor
    path.remove()
    path = new this.props.Paper.Path.Circle(evt.localPoint, this.radius)
    path.data.annotation = true
    path.data.tool = 'Circle'
    path.data.mouse = mouseBkp.splice(0)
    // Changes Behavior; Keep Center of Circle on Click Position
    // And only Drag the Size of the Radius.
    // path = new Paper.Path.Circle(this.center, this.radius)
    path.strokeColor = color
    path.strokeWidth = stroke
    return { path }
  }

  onMouseUp = (evt, path) => {
    return { path }
  }

  render () {
    return (
      <ProtoTool
        key='Circle'
        toolName='Circle'
        onMouseDown={this.onMouseDown}
        onMouseDrag={this.onMouseDrag}
        onMouseUp={this.onMouseUp}
        {...this.props}
      >
        <span className='mbsc-ic mbsc-ic-fa-circle-o' />
      </ProtoTool>
    )
  }
}

export default Circle
