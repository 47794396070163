import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion as faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import { ShortcutInfoPopup } from './ShortcutInfoPopup'
import './VideoCanvasHelp.scss'

const localStorageKey = 'hasSeenShortcutInfoPopup'

const VideoCanvasHelp = () => {
  let showPopupInitially = true

  if (localStorage) {
    showPopupInitially = localStorage.getItem(localStorageKey) !== 'true'
  }

  const controls = document.querySelector('.VideoContainer .controls')
  const [showHelp, setShowHelp] = useState(showPopupInitially)
  const [wasOpenedExplicitly, setWasOpenedExplicitly] = useState(!showPopupInitially)

  const onClosePopup = () => setShowHelp(false)
  const onShowHelpClick = () => {
    setShowHelp(true)
    setWasOpenedExplicitly(true)
  }

  const result = <>
    {showHelp ? <ShortcutInfoPopup wasOpenedExplicitly={wasOpenedExplicitly} onClose={onClosePopup} /> : null}
    <button className='controls-button show-help'>
      <FontAwesomeIcon icon={faQuestionCircle} onClick={onShowHelpClick} title={'Show Help'} />
    </button>
  </>

  return controls ? ReactDOM.createPortal(result, controls) : null
}

export default VideoCanvasHelp
